import axios from "axios";
import api from "./api";
import participantApi from "./participantApi";

export function login(payload) {
  return new Promise((resolve, reject) => {
    api
      .post("/admin/login", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
export function updateStation(payload) {
  return new Promise((resolve, reject) => {
    api
      .post("/admin/update-station", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
export function availableStationList(payload) {
  return new Promise((resolve, reject) => {
    api
      .get("admin/get-available-station/" + payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function getCompanyList(data) {
  return new Promise((resolve, reject) => {
    const offset = data.offset;
    const limit = data.limit;
    const search_by = data.search_by;

    api
      .get(
        "/admin/companyList/?&search_by=" +
          search_by +
          "&offset=" +
          offset +
          "&limit=" +
          limit
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function addCompany(payload) {
  return new Promise((resolve, reject) => {
    api
      .post("/admin/company", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function getUserByCompanyId(id) {
  return new Promise((resolve, reject) => {
    api
      .get("/admin/getUserByCompanyId/" + id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function addUser(payload) {
  return new Promise((resolve, reject) => {
    api
      .post("/admin/user", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function getParticipantByCompanyId(id, data) {
  return new Promise((resolve, reject) => {
    const offset = data.offset;
    const limit = data.limit;
    const search_by = data.search_by;

    api
      .get(
        "/admin/getParticipantByCompanyId/" +
          id +
          "?&search_by=" +
          search_by +
          "&offset=" +
          offset +
          "&limit=" +
          limit
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function addParticipant(payload) {
  return new Promise((resolve, reject) => {
    api
      .post("/admin/add-participant", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
export function addParticipantWithoutNfc(payload) {
  return new Promise((resolve, reject) => {
    api
      .post("/admin/add-participantwithounfc", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function getModuleList() {
  return new Promise((resolve, reject) => {
    api
      .get("/admin/moduleList")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function getParticipantDetailsByNfcId(id) {
  return new Promise((resolve, reject) => {
    api
      .get("/admin/getParticipantDetailsByNfcIdPublic/" + id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function addCompanyConfig(payload) {
  return new Promise((resolve, reject) => {
    api
      .post("/admin/addCompanyConfig", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function addCheckInStation(payload) {
  return new Promise((resolve, reject) => {
    api
      .post("/admin/addCheckInStation", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function getCheckInStationByCompanyId(id) {
  return new Promise((resolve, reject) => {
    api
      .get("/admin/getCheckInStationByCompanyIdPublic/" + id)
      // .get("/admin/getCheckInStationByCompanyIdPublic" + id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
export function getParticipantStation(id) {
  return new Promise((resolve, reject) => {
    api
      .get("/admin/get_participants_by_station/" + id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function deleteStation(id) {
  return new Promise((resolve, reject) => {
    api.delete("/admin/deleteStation/" + id).then((response) => {
      resolve(response.data);
    });
  });
}

export function deleteParticipant(id) {
  return new Promise((resolve, reject) => {
    api.delete("/admin/deleteParticipant/" + id).then((response) => {
      resolve(response.data);
    });
  });
}

export function getCompanyConfigByCompanyId(id) {
  return new Promise((resolve, reject) => {
    api
      .get("/admin/getCompanyConfigByCompanyIdPublic/" + id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
export function getParticipantWithoutNfcByCompanyId(id, data) {
  return new Promise((resolve, reject) => {
    const offset = data.offset;
    const limit = data.limit;
    const search_by = data.search_by;
    api
      .get(
        "/admin/getParticipantWithoutNfcByCompanyId/" +
          id +
          "?&search_by=" +
          search_by +
          "&offset=" +
          offset +
          "&limit=" +
          limit
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
export function deleteParticipantWithoutNfcByCompanyId(id) {
  return new Promise((resolve, reject) => {
    api
      .get("/admin/deleteParticipantWithoutNfcByCompanyId/" + id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
export function getMeetMaxMeetingCronDataForCompanyId(id) {
  return new Promise((resolve, reject) => {
    api
      .get("/admin/getMeetMaxMeetingCronDataForCompanyId/" + id)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error.response?.data);
      });
  });
}

export function deleteCompany(id) {
  return new Promise((resolve, reject) => {
    api.delete("/admin/deleteCompany/" + id).then((response) => {
      resolve(response.data);
    });
  });
}

export function participantLogin(payload) {
  return new Promise((resolve, reject) => {
    api
      .post("/admin/participantLogin", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
export function getParticipantDetailsByEmailOrName(search, company_id) {
  return new Promise((resolve, reject) => {
    api
      .get(
        "/admin/getParticipantDetailsByEmailOrName/" +
          search +
          "?company_id=" +
          company_id
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function getContactListByNfcId(id) {
  return new Promise((resolve, reject) => {
    participantApi
      .get("/admin/getContactListByNfcId/" + id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
}

export function addContact(payload) {
  // console.log("payload",payload);
  return new Promise((resolve, reject) => {
    participantApi
      .post("/admin/addContact", payload)
      .then((response) => {
        resolve(response.data);
        // console.log("api response",response);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function getModuleListByCompanyId(id) {
  return new Promise((resolve, reject) => {
    api
      .get("/admin/getModuleListByCompanyIdPublic/" + id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function bulkUpload(payload) {
  return new Promise((resolve, reject) => {
    api
      .post("/admin/bulkUpload", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
export function bulkUploadWithoutNfc(payload) {
  return new Promise((resolve, reject) => {
    api
      .post("/admin/bulkUploadWithoutNfc", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function updatePassword(payload) {
  return new Promise((resolve, reject) => {
    api
      .post("/admin/update-password", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function getCompanyIdByCompanyName(payload) {
  return new Promise((resolve, reject) => {
    api
      .post("/admin/getCompanyIdByName", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function checkNfcId(payload) {
  return new Promise((resolve, reject) => {
    api
      .post("/admin/checkNfcTag", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function checkInParticipant(payload) {
  return new Promise((resolve, reject) => {
    api
      .post("/admin/checkInParticipant", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function getParticipantCompleteDetail(id, page, page_size) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/admin/getParticipantCompleteDetail/${id}?page=${page}&page_size=${page_size}`
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
export function getNotes(id) {
  return new Promise((resolve, reject) => {
    api
      .get("/admin/getNotes/" + id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
export function getParticipantCompleteDetailWithNotes(id) {
  return new Promise((resolve, reject) => {
    api
      .get("/admin/getParticipantCompleteDetailWithNotes/" + id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function getReport(id) {
  return new Promise((resolve, reject) => {
    api
      .get("/admin/report/" + id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function saveCompanyFields(id, payload) {
  return new Promise((resolve, reject) => {
    api
      .post("/admin/SaveCompanyFields/" + id, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function getCompanyFieldsById(id) {
  return new Promise((resolve, reject) => {
    api
      .get("/admin/getCompanyFieldsById/" + id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function getParticipantCompanyFieldsById(id) {
  return new Promise((resolve, reject) => {
    participantApi
      .get("/admin/getCompanyFieldsById/" + id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function uploadPhotosPublic(payload) {
  return new Promise((resolve, reject) => {
    api
      .post("/admin/uploadPhotosPublic", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function getAllUsersByCompanyId(id) {
  return new Promise((resolve, reject) => {
    api
      .get("/admin/getAllUsersByCompanyId/" + id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function addManageUser(id, payload) {
  return new Promise((resolve, reject) => {
    api
      .post("/admin/addUser/" + id, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function deleteUser(id) {
  return new Promise((resolve, reject) => {
    api
      .delete("/admin/deleteUser/" + id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function getAllCustomButtonsByCompanyId(id) {
  return new Promise((resolve, reject) => {
    api
      .get("/admin/getAllCustomButtonsByCompanyId/" + id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function getAllCustomButtonsByCompanyIdPublic(id) {
  return new Promise((resolve, reject) => {
    api
      .get("/admin/getAllCustomButtonsByCompanyIdPublic/" + id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function addCustomButton(id, payload) {
  return new Promise((resolve, reject) => {
    api
      .post("/admin/addCustomButton/" + id, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function deleteCustomButton(id) {
  return new Promise((resolve, reject) => {
    api
      .delete("/admin/deleteCustomButton/" + id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function addNotes(payload) {
  return new Promise((resolve, reject) => {
    participantApi
      .post("/admin/addNotes", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function deletePhoto(id) {
  return new Promise((resolve, reject) => {
    api
      .delete("/admin/deletePhoto/" + id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function assignNewNfc(payload) {
  return new Promise((resolve, reject) => {
    api
      .post("/admin/assignNewNfc", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}

export function getAllParticipantData(payload) {
  return new Promise((resolve, reject) => {
    api
      .get("/admin/getAllParticipantData/" + payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
export function getGoogleAnalyticsData(payload) {
  return new Promise((resolve, reject) => {
    api
      .get(
        "/admin/getGoogleAnalyticsData?id=" +
          payload.id +
          "&start_date=" +
          payload.start_date +
          "&end_date=" +
          payload.end_date
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
export function addFooterImageApi(payload) {
  return new Promise((resolve, reject) => {
    api
      .post("/admin/add_footer_image", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
export function addFooterConfigApi(payload) {
  return new Promise((resolve, reject) => {
    api
      .post("/admin/add_sponsor_config", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
export function updateFooterImageApi(payload) {
  return new Promise((resolve, reject) => {
    api
      .post("/admin/update_footer_image", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
export function deleteFooterImageApi(payload) {
  return new Promise((resolve, reject) => {
    api
      .post("/admin/delete_footer_image", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
export function getFooterImageListApi(payload) {
  return new Promise((resolve, reject) => {
    api
      .get("/admin/get_footer_image/" + payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
