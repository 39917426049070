import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { loginState } from "./state";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function Navbar() {
  const classes = useStyles();
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    logOut();
    setAnchorEl(null);
  };
  const history = useHistory();

  const [loginDetails, setLoginDetails] = useRecoilState(loginState);
  // if (!loginDetails?.token) {
  //   history.push(-1);
  // }
  const { id } = useParams();
  // useEffect(() => {
  //   if (!loginDetails?.token) {
  //     history.push(`/StationLogin/${id}`);
  //   }
  // }, [loginDetails]);
  const logOut = () => {
    history.push(`/StationLogin/${id}`);
    setLoginDetails(null);
  };
  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ background: "#fab791" }}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Station Details
          </Typography>
          {auth && (
            <div className="d-flex align-items-center">
              <Typography>{loginDetails?.email}</Typography>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
