import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useRecoilState, useRecoilValue } from "recoil";
import { homePageApi } from "../../services/StationLoginApi";
import EditStation from "./EditStation";
import Navbar from "./Navbar";
import { loginState, spinnerState } from "./state";
import { Table } from "antd";
import { IconButton } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import axios from "axios";

export default function HomePage() {
  const [selectedRow, setSelectedRow] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useRecoilState(spinnerState);

  const loginDetails = useRecoilValue(loginState);
  const param = useParams();

  const getRecords = () => {
    setLoading(true);

    axios
      .get(homePageApi(param.id), {
        headers: {
          "x-auth-token": loginDetails?.token,
        },
      })
      .then((res) => setData(res.data.data?.list ? res.data.data?.list : []));
    setLoading(false);
  };

  useEffect(() => {
    if (loginDetails?.token) getRecords();
  }, [loginDetails?.token]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Status",
      dataIndex: "isConnected",
      key: "isConnected",

      render: (data, record) => {
        return (
          <IconButton onClick={() => setSelectedRow(record)}>
            <Edit />
          </IconButton>
        );
      },
    },
  ];
  return (
    <div>
      {selectedRow?.id ? (
        <EditStation row={selectedRow} setRow={setSelectedRow} getRecords={getRecords} />
      ) : (
        <>
          <Navbar></Navbar>
          <div className="p-3">
            <Table
              columns={columns}
              dataSource={data ? data : []}
              scroll={{ x: 800 }}
            />
          </div>
        </>
      )}
    </div>
  );
}
