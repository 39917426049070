import React, { useState, useEffect, useRef } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
// import moment from 'moment';
import Edit from '../../assets/images/edit.png';
import Modal from '@material-ui/core/Modal';
import { bulkUpload } from "../../services/adminApi";
import ClipLoader from "react-spinners/ClipLoader";

const BulkUpload = (props) => {

    const [participantName, setParticipantName] = useState('');
    const [participantNameError, setParticipantNameError] = useState('');

    const [participantId, setParticipantId] = useState();
    const [participantIdError, setParticipantIdError] = useState('');

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const [business, setBusiness] = useState();
    const [businessError, setBusinessError] = useState('');

    const [phone, setPhone] = useState();
    const [phoneError, setPhoneError] = useState('');

    const [linkedIn, setLinkedIn] = useState();
    const [linkedInError, setLinkedInError] = useState('');

    const [companyId, setCompanyId] = useState('');
    const [companyName, setCompanyName] = useState('');

    const [nfcTagId, setNfcTagId] = useState('');
    const [nfcTagIdError, setNfcTagIdError] = useState('');

    const [error, setError] = useState('');

    const [participantListDisplay, setParticipantListDisplay] = useState('');

    const [editModalFlag, setEditModalFlag] = useState(false);
    const [editModalData, setEditData] = useState({});
    const [editIndex, setEditIndex] = useState(0)

    const [bulkUploadArray, setBulkUploadArray] = useState([]);

    const [loaderFlag, setLoaderFlag] = useState(false)
    const [spinnerColor, setSpinnerColor] = useState("#000000");

    const [totalCount, setTotalCount] = useState(0);
    const [completedCount, setCompletedCount] = useState(0);

    const [participantListColumns, setParticipantListColumns] = useState([
        { name: "sr", title: "Sr. No." },
        { name: "name", title: "Name" },
        { name: "last_name", title: "Last Name" },
        { name: "email_id", title: "Email" },
        { name: "contact", title: "Contact" },
        { name: "business", title: "Business" },
        { name: "address", title: "Address" },
        { name: "nfc_tag_id", title: "Nfc-Id" },
        { name: "linkedin_id", title: "Linked-In Id" },
        { name: "facebook_id", title: "Facebook Id" },
        { name: "instagram_id", title: "Instagram Id" },
        { name: "twitter_id", title: "Twitter Id" },
        { name: "youtube_id", title: "Youtube Id" },
        { name: "pintrest_id", title: "Pinterest Id" },
        { name: "snapchat_id", title: "Snapchat Id" },
        { name: "tiktok_id", title: "Tiktok Id" },
        { name: "field_16", title: "Field 16" },
        { name: "field_17", title: "Field 17" },
        { name: "field_18", title: "Field 18" },
        { name: "field_19", title: "Field 19" },
        { name: "field_20", title: "Field 20" },
        { name: "field_21", title: "Field 21" },
        { name: "field_22", title: "Field 22" },
        { name: "field_23", title: "Field 23" },
        { name: "field_24", title: "Field 24" },
        { name: "field_25", title: "Field 25" },
        { name: "isRegistered", title: "Is-Registered" },

    ]);

    useEffect(() => {
        setCompanyId(props.companyId);
        if (props.bulkUploadArray.length) {
            setTotalCount(props.bulkUploadArray.length)
            setBulkUploadArray(props.bulkUploadArray)
        }
    }, [props.bulkUploadArray])

    useEffect(() => {
        if (bulkUploadArray.length) {
            bindTableDataHandler();
        }
    }, [bulkUploadArray])

    useEffect(() => {
        if (props.uploadMessage) {
            uploadBulkDataHandler()
        }
    }, [props.uploadMessage])



    const bindTableDataHandler = () => {
        let participantListTemp = [];

        setParticipantListDisplay([]);
        bulkUploadArray.map((usr, index) => {

            // participantListTemp.push(

            //     <TableRow key={usr.id}>
            //         {
            //             Object.keys(usr).map((key) => {
            //                 return (
            //                     <TableCell>
            //                         {usr[key]}
            //                     </TableCell>
            //                 )
            //             })

            //         }


            //     </TableRow>
            // )


            // bulkUploadArray.map((par, index) => {

            //     participantListTemp.push(


            //         <TableRow key={par.id}>
            //             <TableCell component="th" scope="row">
            //                 {index + 1}
            //             </TableCell>
            //             <TableCell>
            //                 {par.name}
            //             </TableCell>
            //             <TableCell>
            //                 {par.business}
            //             </TableCell>
            //             <TableCell>
            //                 {par.email}
            //             </TableCell>
            //             <TableCell>
            //                 {par.phone}
            //             </TableCell>
            //             <TableCell>
            //                 {par.linkedin}
            //             </TableCell>
            //             <TableCell>
            //                 {par.registered}
            //             </TableCell>
            //             <TableCell >
            //                 {par.nfcid}
            //             </TableCell>

            //         </TableRow>
            //     )

            participantListTemp.push(
                <TableRow
                    key={usr.id}
                // onClick={() => { localStorage.setItem('selectedUser', JSON.stringify(usr)); props.history.push('/admin/clientPage2') }}
                >
                    <TableCell component="th" scope="row">
                        {index + 1}
                    </TableCell>
                    <TableCell
                    // onClick={() => { localStorage.setItem('selectedUser', JSON.stringify(usr)); }}
                    >

                        {/* <a href={`${config.frontEndUrl}/${companyName.replace(/\s+/g, '')}/${usr.nfc_tag_id}`}
                                style={{ textDecoration: "none", color: "#000000" }}
                            > */}
                        {usr.name}
                        {/* </a> */}
                    </TableCell>
                    <TableCell>
                        {usr.last_name}
                    </TableCell>
                    <TableCell>
                        {usr.email_id}
                    </TableCell>
                    <TableCell>
                        {usr.contact}
                    </TableCell>
                    <TableCell>
                        {usr.business}
                    </TableCell>
                    <TableCell>
                        {usr.address}
                    </TableCell>
                    <TableCell>
                        {usr.nfc_tag_id}
                    </TableCell>
                    <TableCell>
                        {usr.linkedin_id}
                    </TableCell>
                    <TableCell>
                        {usr.facebook_id}
                    </TableCell>
                    <TableCell>
                        {usr.instagram_id}
                    </TableCell>
                    <TableCell>
                        {usr.twitter_id}
                    </TableCell>
                    <TableCell>
                        {usr.youtube_id}
                    </TableCell>
                    <TableCell>
                        {usr.pinterest_id}
                    </TableCell>
                    <TableCell>
                        {usr.snapchat_id}
                    </TableCell>
                    <TableCell>
                        {usr.tiktok_id}
                    </TableCell>
                    <TableCell>
                        {usr.field_16}
                    </TableCell>
                    <TableCell>
                        {usr.field_17}
                    </TableCell>
                    <TableCell>
                        {usr.field_18}
                    </TableCell>
                    <TableCell>
                        {usr.field_19}
                    </TableCell>
                    <TableCell>
                        {usr.field_20}
                    </TableCell>
                    <TableCell>
                        {usr.field_21}
                    </TableCell>
                    <TableCell>
                        {usr.field_22}
                    </TableCell>
                    <TableCell>
                        {usr.field_23}
                    </TableCell>
                    <TableCell>
                        {usr.field_24}
                    </TableCell>
                    <TableCell>
                        {usr.field_25}
                    </TableCell>
                    <TableCell>
                        {usr.is_registered}
                    </TableCell>


                </TableRow>
            )
            if (index === bulkUploadArray.length - 1) {
                setParticipantListDisplay(participantListTemp)

            }

        })
    }

    const loaderTest = () =>{
        setLoaderFlag(true);
    }

    const uploadBulkDataHandler = async () => {
        setLoaderFlag(true);
        let count = 0
        
        // let tempRequestObj = {
        //     "participants": [],
        // }
        // let j = 0;
        // for (j = 0; j < bulkUploadArray.length; j = j + 100) {
        //     for (let i = j; i < 100; i++) {


        //         let obj = bulkUploadArray[i];
        //         let registered = obj.is_registered === "TRUE" ? true : false
        //         let tempObj = {

        //             "name": obj.name,
        //             "email_id": obj.email_id,
        //             "contact": obj.contact,
        //             "company_id": companyId,
        //             "business": obj.business,
        //             "nfc_tag_id": obj.nfc_tag_id,
        //             "linkedin_id": obj.linkedin_id,
        //             "is_registered": registered,
        //             "last_name": obj.last_name,
        //             "address": obj.address,
        //             "facebook_id": obj.facebook_id,
        //             "instagram_id": obj.instagram_id,
        //             "twitter_id": obj.twitter_id,
        //             "youtube_id": obj.youtube_id,
        //             "pintrest_id": obj.pintrest_id,
        //             "snapchat_id": obj.snapchat_id,
        //             "tiktok_id": obj.tiktok_id,
        //             "field_16": obj.field_16,
        //             "field_17": obj.field_17 ? obj.field_17.replace(";", ",") : "",
        //             "field_18": obj.field_18 ? obj.field_18.replace(";", ",") : "",
        //             "field_19": obj.field_19 ? obj.field_19.replace(";", ",") : "",
        //             "field_20": obj.field_20 ? obj.field_20.replace(";", ",") : "",
        //             "field_21": obj.field_21 ? obj.field_21.replace(";", ",") : "",
        //             "field_22": obj.field_22 ? obj.field_22.replace(";", ",") : "",
        //             "field_23": obj.field_23 ? obj.field_23.replace(";", ",") : "",
        //             "field_24": obj.field_24 ? obj.field_24.replace(";", ",") : "",
        //             "field_25": obj.field_25 ? obj.field_25.replace(";", ",") : "",

        //         }


        //         tempRequestObj.participants.push(tempObj);
        //     }
        //     await bulkUpload(tempRequestObj).then(
        //         (response) => {
        //             console.log(response)

        //         }
        //     ).catch((error) => {
        //         console.log("error", error)
        //     })

        //     console.log(j)
        // }
        // console.log(j)
        // if(j > bulkUploadArray.length - 1){
        //     setLoaderFlag(false);
        //     props.emit(1);
        // }
        let i = 0
        for (i = 0; i < bulkUploadArray.length; i++) {
            let tempRequestObj = {
                "participants": [],
            }

            let obj = bulkUploadArray[i];
            let registered = obj.is_registered === "TRUE" ? true : false
            let tempObj = {

                "name": obj.name,
                "email_id": obj.email_id,
                "contact": obj.contact,
                "company_id": companyId,
                "business": obj.business,
                "nfc_tag_id": obj.nfc_tag_id,
                "linkedin_id": obj.linkedin_id,
                "is_registered": registered,
                "last_name": obj.last_name,
                "address": obj.address,
                "facebook_id": obj.facebook_id,
                "instagram_id": obj.instagram_id,
                "twitter_id": obj.twitter_id,
                "youtube_id": obj.youtube_id,
                "pintrest_id": obj.pintrest_id,
                "snapchat_id": obj.snapchat_id,
                "tiktok_id": obj.tiktok_id,
                "field_16": obj.field_16,
                "field_17": obj.field_17 ? obj.field_17.replace(";", ",") : "",
                "field_18": obj.field_18 ? obj.field_18.replace(";", ",") : "",
                "field_19": obj.field_19 ? obj.field_19.replace(";", ",") : "",
                "field_20": obj.field_20 ? obj.field_20.replace(";", ",") : "",
                "field_21": obj.field_21 ? obj.field_21.replace(";", ",") : "",
                "field_22": obj.field_22 ? obj.field_22.replace(";", ",") : "",
                "field_23": obj.field_23 ? obj.field_23.replace(";", ",") : "",
                "field_24": obj.field_24 ? obj.field_24.replace(";", ",") : "",
                "field_25": obj.field_25 ? obj.field_25.replace(";", ",") : "",

            }


            tempRequestObj.participants.push(tempObj);
            await bulkUpload(tempRequestObj).then(
                (response) => {
                    console.log(response);
                    count++;
                    setCompletedCount(count);
                    
                }
            ).catch((error) => {
                console.log("error", error)
            })
        }
        // await bulkUpload(tempRequestObj).then(
        //     (response) => {
        //         console.log(response)

        //     }
        // ).catch((error) => {
        //     console.log("error", error)
        // })

        // if (j > bulkUploadArray.length - 1) {
        //     setLoaderFlag(false);
        //     props.emit(1);
        // }

        if( i > bulkUploadArray.length - 1) {
            setLoaderFlag(false);
            props.emit(1);
        }

    }


    return (
        <div>

            <TableContainer component={Paper}>
                <Table aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            {/* {participantListColumns} */}
                            {participantListColumns.map((column) => (
                                <TableCell
                                    key={column.name}
                                    className="align-top"
                                >
                                    {column.name = column.title}
                                    {/* {column.name !== "action" ? (
                                                    <TableSortLabel
                                                        active={
                                                            inspectionListPayload
                                                                .sort_by === column.name
                                                        }
                                                        direction={
                                                            inspectionListPayload
                                                                .sort_by === column.name
                                                                ? inspectionListPayload
                                                                    .order_by
                                                                : "asc"
                                                        }
                                                        onClick={createSortHandler(
                                                            column.name
                                                        )}
                                                    >
                                                        {column.title}
                                                        {inspectionListPayload
                                                            .sort_by === column.name ? (
                                                            <span
                                                                style={{
                                                                    border: 0,
                                                                    clip: "rect(0 0 0 0)",
                                                                    height: 1,
                                                                    margin: -1,
                                                                    overflow: "hidden",
                                                                    padding: 0,
                                                                    position: "absolute",
                                                                    top: 20,
                                                                    width: 1,
                                                                }}
                                                            >
                                                                {inspectionListPayload
                                                                    .order_by === "desc"
                                                                    ? "sorted descending"
                                                                    : "sorted ascending"}
                                                            </span>
                                                        ) : null}
                                                    </TableSortLabel>
                                                ) : (
                                                    column.title
                                                )} */}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* {this.state.reportsUpcomingList} */}
                        {participantListDisplay}
                        {/* {this.state.loading ? <Spin indicator={<div class="loading">Loading&#8230;</div>}/> : this.state.reportsUpcomingList} */}
                    </TableBody>
                    {/* <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[
                                                5,
                                                10,
                                                25,
                                                50,
                                                { label: "All", value: -1 },
                                            ]}
                                            colSpan={9}
                                            count={tableCount}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: { "aria-label": "rows per page" },
                                                native: true,
                                            }}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={
                                                handleChangeRowsPerPage
                                            }
                                        />
                                    </TableRow>
                                </TableFooter> */}
                </Table>
            </TableContainer>

            {loaderFlag ? <div className="loading">Loading&#8230;</div> : null}
            {loaderFlag ? <div className="counting">{completedCount} of {totalCount}</div> : null}

        </div>
    )
}

export default BulkUpload;
