import { Snackbar, TextField } from "@material-ui/core";
import axios from "axios";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useRecoilState, useRecoilValue } from "recoil";
import * as XLSX from "xlsx";
import Config from "../../config.json";
import { homePageApi } from "../../services/StationLoginApi";
import { getParticipantStation, updateStation } from "../../services/adminApi";
import ImagePreview from "./ImagePreview";
import PreviewModal from "./PreviewModal";
import SocialMedia from "./SocialMedia";
import { companyImageState, loginState } from "./state";

const EditStation = ({ row, setRow,getRecords }) => {
  const [selectedSocialMedia, setSelectedSocialMedia] = useState([]);
  const [serverMediaList, setServerMediaList] = useState([]);
  const [selectedSocialMediaList, setSelectedSocialMediaList] = useState([]);
  const [stationName, setStationName] = useState("");
  const [preview, setPreview] = useState("");
  const [serverStationImg, setServerStationImg] = useState("");
  const [serverStationImgFooter, setServerStationImgFooter] = useState("");
  const [footerImage, setFooterImage] = useState("");
  const [stationImg, setStationImg] = useState("");
  const [stationNameError, setStationNameError] = useState("");

  const [stationDescription, setStationDescription] = useState("");
  const [stationDescriptionError, setStationDescriptionError] = useState("");

  const [selectedId, setSelectedId] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageFooter, setErrorMessageFooter] = useState("");

  const [htmlEditorFlag, setHtmlEditorFlag] = useState(true);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const [stationType, setStationType] = useState(0);
  const [showStation, setShowStation] = useState(1);

  const [headerImgUrl, setHeaderImgUrl] = useState("");
  const [headerImgOpen, setHeaderImgOpen] = useState(1);

  const [footerImgUrl, setFooterImgUrl] = useState("");
  const [footerImgOpen, setFooterImgOpen] = useState(1);

  const textFieldChangeHandler = (e) => {
    let { name, value, checked } = e.target;

    switch (name) {
      case "stationName":
        setStationName(value);
        setStationNameError("");

        break;

      case "stationDescription":
        setStationDescription(value);
        setStationDescriptionError("");

        break;

      case "stationType":
        setStationType(value);

        break;

      case "showStation":
        setShowStation(checked ? 1 : 0);

        break;

      default:
        console.log("");
    }
  };
  const param = useParams();
  const loginDetails = useRecoilValue(loginState);

  const addStationHandler = async () => {
    if (
      stationName.trim() == "" ||
      !Boolean(stationName.match(/^[\w-& ]+$/)) ||
      stationDescription == ""
    ) {
      if (
        stationName.trim() == "" ||
        !Boolean(stationName.match(/^[\w-& ]+$/))
      ) {
        setStationNameError(
          "Please enter valid station name (accepted special characters &,- and _)"
        );
      }

      if (stationDescription.trim() == "") {
        setStationDescriptionError("Please enter description");
      }
    } else {
      const img = stationImg
        ? await getBase64(stationImg)
        : serverStationImg
        ? row.image
        : "";
      const footerImageTemp = footerImage
        ? await getBase64(footerImage)
        : serverStationImgFooter
        ? row.footerImg
        : "";
      let tempObj = {
        id: row.id,
        name: stationName,
        company_id: row.company_id,
        description: stationDescription,
        image: img,
        footerImg: footerImageTemp,
        socialMedia: JSON.stringify(selectedSocialMedia),
        headerUrl: headerImgUrl,
        headerOpenIn: headerImgOpen ? 1 : 0,
        footerUrl: footerImgUrl,
        footerOpenIn: footerImgOpen ? 1 : 0,
      };
      const config = {
        headers: {
          "x-auth-token": loginDetails.token,
        },
      };

      updateStation(tempObj)
        .then(async (result) => {
          setMessage({ title: "Record successfully updated", open: "true" });
          try {
            const response = await axios.get(homePageApi(param.id), config);
            const responseData = response.data?.data?.list?.filter(
              (record) => record.id == row.id
            );
            if (responseData?.length) {
              setRow(responseData?.at(0));
              setStationImg(null);
              setFooterImage(null);
            }
          } catch (error) {
            console.error("Error fetching data: ", error);
            setMessage({ title: error?.message, open: "true" });
            // Handle error here
          }
        })
        .catch((err) => setMessage({ title: err?.message, open: "true" }));
    }
  };
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        // console.log("baseurl", baseURL);
        resolve(baseURL);
      };
      // console.log("fileinfo", fileInfo);
    });
  };
  const onEditorStateChange = (e) => {
    // console.log(convertToRaw(e.getCurrentContent()));
    // console.log(convertToRaw(e.getCurrentContent()));
    // if (convertToRaw(e.getCurrentContent()).blocks[0].type === "code") {
    //     setStationDescription(convertToRaw(e.getCurrentContent()).blocks[0].text)
    // }
    // else {
    let des = draftToHtml(convertToRaw(e.getCurrentContent()))
      .replaceAll("&lt;", "<")
      .replaceAll("&gt;", ">");
    // console.log("des", des);
    setStationDescription(des);
    // }
    setEditorState(e);
  };

  const handleHtmlEditor = () => {
    // console.log(!htmlEditorFlag);
    setHtmlEditorFlag(!htmlEditorFlag);
  };
  useEffect(() => {
    if (row.id) {
      setStationName(row.name);
      setStationDescription(row.description);
      if (Boolean(row?.image)) {
        setServerStationImg(`${Config?.imageApiUrl}/${row.image}`);
      }
      if (Boolean(row?.footerImg)) {
        setServerStationImgFooter(`${Config?.imageApiUrl}/${row.footerImg}`);
      }
      if (row.socialMedia) {
        setSelectedSocialMedia(JSON.parse(row.socialMedia));
        setServerMediaList(JSON.parse(row.socialMedia));
      }
      if (row.description) {
        const blocksFromHTML = convertFromHTML(row.description);
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        setHeaderImgUrl(row.headerUrl);
        setFooterImgUrl(row.footerUrl);
        setHeaderImgOpen(row.headerOpenIn);
        setFooterImgOpen(row.footerOpenIn);

        // console.log(EditorState.createWithContent(state));
        setEditorState(EditorState.createWithContent(state));
      }
    }
  }, [row]);

  const [companyState, setCompanyState] = useRecoilState(companyImageState);
  const [message, setMessage] = useState({
    title: "Success",
    open: false,
  });

  const convertJsonToCsv = (jsonData, fileName = "data.csv") => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert the JSON to worksheet
    const worksheet = XLSX.utils.json_to_sheet(jsonData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the CSV file
    XLSX.writeFile(workbook, fileName, { bookType: "csv" });
  };
  const handleDownload = (records) => {
    const temp = records?.map(({ user_timestamp, ...item }, index) => ({
      serialNumber: index + 1,
      ...item,
    }));
    convertJsonToCsv(temp, "stationInfo.csv");
  };

  const getParticipantListHandle = async (id) => {
    getParticipantStation(row.id).then(async (response) => {
      handleDownload(response.data.records ? response.data.records : []);
    });
  };

  return (
    <div className="row  justify-content-center p-3">
      <Snackbar
        open={message.open}
        onClose={() => setMessage({ message: "", open: false })}
        autoHideDuration={2000}
        color="white"
        // TransitionComponent={transition}
        message={message.title}
      />
      <div
        className="mobile-view custom-form"
        style={{ background: "white", height: "100%" }}
      >
        <div>
          <img src={companyState} className="company_img"></img>
        </div>
        <div className="d-flex justify-content-between mt-3 border-bottom-1 ">
          <h4>Setup</h4>
          <button
            className="custom-download-btn"
            onClick={getParticipantListHandle}
          >
            Download Activity Report
          </button>
        </div>
        <div className="mt-2">
          <h5>1. Station Name</h5>
          <span>This title will show in the user's profile page. </span>
          <div className="form-group gray-fill-input mr-b30">
            <TextField
              type="text"
              variant="outlined"
              //   className="form-control"
              name="stationName"
              className="mt-3"
              id=""
              value={stationName}
              onChange={textFieldChangeHandler}
              placeholder="Station Name"
              fullWidth
            />
            {stationNameError !== "" ? (
              <p className="error-alert mr-b20">{stationNameError}</p>
            ) : null}
          </div>
        </div>
        <div className="mt-2">
          <h5>2. Upload Header Image</h5>
          <span>
            This is usually your business logo but it can be a header image as
            well. Recommended image dimensions 1000px 300px. Max File size 1MB.
          </span>
          <ImagePreview
            fileImage={stationImg}
            setFileImage={setStationImg}
            imageUrl={serverStationImg}
            setImageUrl={setServerStationImg}
            error={errorMessage}
            setError={setErrorMessage}
            url={headerImgUrl}
            setUrl={setHeaderImgUrl}
            openIn={headerImgOpen}
            setOpenIn={setHeaderImgOpen}
          />
        </div>

        <div className="mt-2">
          <h5>3. Edit content</h5>
          <span>
            You can use the editor to add your text and links or paste your own
            custom HTML code
          </span>
          <div className="">
            <div
              className="form-group gray-fill-input mr-b30"
              style={{ overflow: "auto" }}
            >
              <div className="d-flex justify-content-end">
                Editor
                <label className="switch mr-b10 mr-l10">
                  <input
                    type="checkbox"
                    checked={htmlEditorFlag}
                    onChange={() => {
                      handleHtmlEditor();
                    }}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              {/* <div
                dangerouslySetInnerHTML={{
                  __html: stationDescription,
                }}
              ></div> */}
              {htmlEditorFlag ? (
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={(e) => onEditorStateChange(e)}
                  style={{ width: "100%", border: "1px solid" }}
                  toolbar={{
                    image: {
                      previewImage: true,
                      defaultSize: {
                        height: "250px",
                        width: "200px",
                      },
                    },
                  }}
                />
              ) : (
                <textarea
                  rows="10"
                  //   className="form-control"
                  style={{ width: "100%", border: "1px solid" }}
                  value={stationDescription}
                  onChange={(e) => {
                    setStationDescription(e.target.value);
                  }}
                />
              )}

              {stationDescriptionError !== "" ? (
                <p className="error-alert mr-b20">{stationDescriptionError}</p>
              ) : null}
            </div>
          </div>
          <SocialMedia
            selectedSocialMedia={selectedSocialMedia}
            setSelectedSocialMedia={setSelectedSocialMedia}
            selectedSocialMediaList={selectedSocialMediaList}
            setSelectedSocialMediaList={setSelectedSocialMediaList}
          />
          <div className="mt-2">
            <h5>5. Upload Footer Image</h5>
            <span>
              This entry is optional. This image will show at the bottom section
              of your content.
            </span>
            <div className="form-group gray-fill-input mr-b30">
              <ImagePreview
                fileImage={footerImage}
                setFileImage={setFooterImage}
                imageUrl={serverStationImgFooter}
                setImageUrl={setServerStationImgFooter}
                Footer
                error={errorMessageFooter}
                setError={setErrorMessageFooter}
                url={footerImgUrl}
                setUrl={setFooterImgUrl}
                openIn={footerImgOpen}
                setOpenIn={setFooterImgOpen}
              />
            </div>
          </div>

          <div>
            <div className="container-login100-form-btn">
              <button className="login100-form-btn" onClick={addStationHandler}>
                {" "}
                SAVE
              </button>
            </div>
            <div className="container-login100-form-btn">
              <button
                className="login100-form-btn"
                onClick={() => setPreview(true)}
              >
                {" "}
                PREVIEW
              </button>
            </div>
            <div
              className="container-login100-form-btn"
              onClick={() => {
                setRow(null);
                getRecords();
              }}
            >
              <button className="login100-form-btn"> Close</button>
            </div>
          </div>
        </div>
      </div>
      <PreviewModal
        row={row}
        preview={preview}
        setPreview={setPreview}
        img={stationImg ? URL.createObjectURL(stationImg) : serverStationImg}
        mediaList={serverMediaList}
        footerImage={serverStationImgFooter}
      />
    </div>
  );
};

export default EditStation;
