import React, { useState } from "react";
import {
  ArrowDownIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/solid";
const ChartCard = (props) => {
  const [collapse, setCollapse] = useState(true);
  return (
    <div className="mt-3">
      <div class="ibox">
        <div class="ibox-title d-flex justify-content-between">
          <h5>{props.title && props.title}</h5>
          <div class="ibox-tools">
            {collapse ? (
              <ChevronDownIcon
                fontSize={18}
                style={{ width: "24px", color: "black" }}
                onClick={() => setCollapse(false)}
              />
            ) : (
              <ChevronUpIcon
                fontSize={18}
                style={{ width: "24px", color: "black" }}
                onClick={() => setCollapse(true)}
              />
            )}
            {/* <i class="fa fa-chevron-up"> </i> */}
          </div>
        </div>
        <div
          class={`ibox-content ${
            collapse ? "show-body animate__backInDown" : "hide-body animate__backInUp"
          } bg-black`}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default ChartCard;
