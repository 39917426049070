import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
// import logo from '../../../assets/images/logo.png'

const SideNavigation = (props) => {
  return (
    <aside className="sidebar-wrap">
      {/* <div className="side-logo">
                <img src={logo} alt="Tap2Interact" title="Tap2Interact" />
            </div> */}
      <nav className="side-nav">
        <ul>
          <li className="active-link">
            <NavLink to={"/admin/companyList"} exact>
              <div className="nav-icon">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M13.3964 1.66666H16.2181C17.3866 1.66666 18.3335 2.62153 18.3335 3.79996V6.64543C18.3335 7.82385 17.3866 8.77873 16.2181 8.77873H13.3964C12.2279 8.77873 11.281 7.82385 11.281 6.64543V3.79996C11.281 2.62153 12.2279 1.66666 13.3964 1.66666Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.78219 1.66666H6.60382C7.77238 1.66666 8.71926 2.62153 8.71926 3.79996V6.64543C8.71926 7.82385 7.77238 8.77873 6.60382 8.77873H3.78219C2.61363 8.77873 1.66675 7.82385 1.66675 6.64543V3.79996C1.66675 2.62153 2.61363 1.66666 3.78219 1.66666ZM3.78219 11.2212H6.60382C7.77238 11.2212 8.71926 12.1761 8.71926 13.3546V16.2C8.71926 17.3776 7.77238 18.3333 6.60382 18.3333H3.78219C2.61363 18.3333 1.66675 17.3776 1.66675 16.2V13.3546C1.66675 12.1761 2.61363 11.2212 3.78219 11.2212ZM16.218 11.2212H13.3963C12.2278 11.2212 11.2809 12.1761 11.2809 13.3546V16.2C11.2809 17.3776 12.2278 18.3333 13.3963 18.3333H16.218C17.3865 18.3333 18.3334 17.3776 18.3334 16.2V13.3546C18.3334 12.1761 17.3865 11.2212 16.218 11.2212Z"
                    fill="white"
                  />
                </svg>
              </div>
              <span>Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={"/admin/ga4"}>
              <div className="nav-icon">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.07996 6.64999V6.65999C7.64896 6.65999 7.29996 7.00999 7.29996 7.43999C7.29996 7.86999 7.64896 8.21999 8.07996 8.21999H11.069C11.5 8.21999 11.85 7.86999 11.85 7.42899C11.85 6.99999 11.5 6.64999 11.069 6.64999H8.07996ZM15.92 12.74H8.07996C7.64896 12.74 7.29996 12.39 7.29996 11.96C7.29996 11.53 7.64896 11.179 8.07996 11.179H15.92C16.35 11.179 16.7 11.53 16.7 11.96C16.7 12.39 16.35 12.74 15.92 12.74ZM15.92 17.31H8.07996C7.77996 17.35 7.48996 17.2 7.32996 16.95C7.16996 16.69 7.16996 16.36 7.32996 16.11C7.48996 15.85 7.77996 15.71 8.07996 15.74H15.92C16.319 15.78 16.62 16.12 16.62 16.53C16.62 16.929 16.319 17.27 15.92 17.31Z"
                    fill="white"
                  />
                </svg>
              </div>
              <span> GA4 Dashboard </span>
            </NavLink>
          </li>
          {/* <li>
            <NavLink to={"/admin/ga"}>
              <div className="nav-icon">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.07996 6.64999V6.65999C7.64896 6.65999 7.29996 7.00999 7.29996 7.43999C7.29996 7.86999 7.64896 8.21999 8.07996 8.21999H11.069C11.5 8.21999 11.85 7.86999 11.85 7.42899C11.85 6.99999 11.5 6.64999 11.069 6.64999H8.07996ZM15.92 12.74H8.07996C7.64896 12.74 7.29996 12.39 7.29996 11.96C7.29996 11.53 7.64896 11.179 8.07996 11.179H15.92C16.35 11.179 16.7 11.53 16.7 11.96C16.7 12.39 16.35 12.74 15.92 12.74ZM15.92 17.31H8.07996C7.77996 17.35 7.48996 17.2 7.32996 16.95C7.16996 16.69 7.16996 16.36 7.32996 16.11C7.48996 15.85 7.77996 15.71 8.07996 15.74H15.92C16.319 15.78 16.62 16.12 16.62 16.53C16.62 16.929 16.319 17.27 15.92 17.31Z"
                    fill="white"
                  />
                </svg>
              </div>
              <span>Analytics</span>
            </NavLink>
          </li>
     
          </li> */}
          {/* <li>
                        <NavLink to={'/admin/settings'}>
                           
                                <div className="nav-icon">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12.012 14.8301C10.4075 14.8301 9.10962 13.5801 9.10962 12.0101C9.10962 10.4401 10.4075 9.1801 12.012 9.1801C13.6165 9.1801 14.8837 10.4401 14.8837 12.0101C14.8837 13.5801 13.6165 14.8301 12.012 14.8301Z"
                                            fill="white" />
                                        <path opacity="0.4"
                                            d="M21.2301 14.3701C21.036 14.0701 20.76 13.7701 20.4023 13.5801C20.1162 13.4401 19.9322 13.2101 19.7687 12.9401C19.2475 12.0801 19.5541 10.9501 20.4228 10.4401C21.4447 9.87011 21.7718 8.60011 21.179 7.61011L20.4943 6.43011C19.9118 5.44011 18.6344 5.09011 17.6226 5.67011C16.7233 6.15011 15.5685 5.83011 15.0473 4.98011C14.8838 4.70011 14.7918 4.40011 14.8122 4.10011C14.8429 3.71011 14.7203 3.34011 14.5363 3.04011C14.1582 2.42011 13.4735 2.00011 12.7172 2.00011H11.2763C10.5302 2.02011 9.84553 2.42011 9.4674 3.04011C9.27323 3.34011 9.16081 3.71011 9.18125 4.10011C9.20169 4.40011 9.10972 4.70011 8.9462 4.98011C8.425 5.83011 7.27019 6.15011 6.38109 5.67011C5.35913 5.09011 4.09191 5.44011 3.49917 6.43011L2.81446 7.61011C2.23194 8.60011 2.55897 9.87011 3.57071 10.4401C4.43937 10.9501 4.74596 12.0801 4.23498 12.9401C4.06125 13.2101 3.87729 13.4401 3.59115 13.5801C3.24368 13.7701 2.93709 14.0701 2.77358 14.3701C2.39546 14.9901 2.4159 15.7701 2.79402 16.4201L3.49917 17.6201C3.87729 18.2601 4.58245 18.6601 5.31825 18.6601C5.66572 18.6601 6.0745 18.5601 6.40153 18.3601C6.65702 18.1901 6.96361 18.1301 7.30085 18.1301C8.31259 18.1301 9.16081 18.9601 9.18125 19.9501C9.18125 21.1001 10.1215 22.0001 11.3069 22.0001H12.6968C13.872 22.0001 14.8122 21.1001 14.8122 19.9501C14.8429 18.9601 15.6911 18.1301 16.7029 18.1301C17.0299 18.1301 17.3365 18.1901 17.6022 18.3601C17.9292 18.5601 18.3278 18.6601 18.6855 18.6601C19.411 18.6601 20.1162 18.2601 20.4943 17.6201L21.2097 16.4201C21.5776 15.7501 21.6083 14.9901 21.2301 14.3701Z"
                                            fill="white" />
                                    </svg>
                                </div>
                                <span>Settings</span>
                           
                        </NavLink>
                    </li> */}
        </ul>
      </nav>
    </aside>
  );
};

export default SideNavigation;
