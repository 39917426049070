import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./index.css";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import * as XLSX from "xlsx";

import {
  addCheckInStation,
  deleteStation,
  getCheckInStationByCompanyId,
  getParticipantStation,
} from "../../services/adminApi";

import { IconButton, Tooltip } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import GetAppIcon from "@material-ui/icons/GetApp";
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Stations = () => {
  const [stationName, setStationName] = useState("");
  const [stationNameError, setStationNameError] = useState("");

  const [stationNfcId, setStationNfcId] = useState("");
  const [stationNfcIdError, setStationNfcIdError] = useState("");

  const [stationDescription, setStationDescription] = useState("");
  const [stationDescriptionShowCheckIN, setStationDescriptionShowCheckIN] =
    useState("");
  const [stationDescriptionError, setStationDescriptionError] = useState("");

  const [companyId, setCompanyId] = useState();
  const [companyName, setCompanyName] = useState("");

  const [addNewStationModalFlag, setAddNewStationModalFlag] = useState(false);

  const [tableCount, setTableCount] = useState();

  const [stationListDisplay, setStationListDisplay] = useState("");

  const [addSneakBarFlag, setAddSneakBarFlag] = useState(false);
  const [deleteSneakBarFlag, setDeleteSneakBarFlag] = useState(false);

  const [selectedId, setSelectedId] = useState();
  const [deleteModalFlag, setDeleteModalFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [editSneakerFlag, setEditSneakerFlag] = useState(false);
  const [addNewStationFlag, setAddNewStationFlag] = useState(false);
  const [htmlEditorFlag, setHtmlEditorFlag] = useState(false);
  const [htmlEditorFlagShowCheckIN, setHtmlEditorFlagShowCheckIN] =
    useState(false);

  const [error, setError] = useState("");

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorStateShowCheckIN, setEditorStateShowCheckIN] = useState(
    EditorState.createEmpty()
  );

  const [stationType, setStationType] = useState(0);
  const [showStation, setShowStation] = useState(1);
  const [showCheckInMessage, setShowCheckInMessage] = useState(1);
  const [stationListColumns, setStationListColumns] = useState([
    { name: "sr", title: "Sr. No." },
    { name: "name", title: "Name" },
    { name: "stationDescription", title: "Description" },
    { name: "stationNfcId", title: "NFC Id" },
    { name: "stationType", title: "Station Type" },
    { name: "includeInProfilePage", title: "Include In Profile Page" },
    { name: "action", title: "Action" },
  ]);

  const convertJsonToCsv = (jsonData, fileName = "data.csv") => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert the JSON to worksheet
    const worksheet = XLSX.utils.json_to_sheet(jsonData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the CSV file
    XLSX.writeFile(workbook, fileName, { bookType: "csv" });
  };

  const handleDownload = (records) => {
    const temp = records?.map(({ user_timestamp, ...item }, index) => ({
      serialNumber: index + 1,
      ...item,
    }));
    convertJsonToCsv(temp, "stationInfo.csv");
  };
  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("selectedCompany"));
    // console.log(data);
    setCompanyId(data.id);
    setCompanyName(data.company_name);
    getStationListHandler(data.id);
  }, []);

  useEffect(() => {
    setStationName("");
    setStationNameError("");

    setStationDescription("");
    setStationDescriptionError("");

    setStationNfcId("");
    setStationNfcIdError("");

    setStationType(0);

    setShowStation(1);

    setEditorState(EditorState.createEmpty());
  }, [addNewStationFlag]);

  const getParticipantListHandle = async (id) => {
    getParticipantStation(id).then(async (response) => {
      handleDownload(response.data.records ? response.data.records : []);
    });
  };
  const getStationListHandler = (id) => {
    getCheckInStationByCompanyId(id).then(async (response) => {
      // console.log(response);
      let tempData = response.data;

      setTableCount(response.data.length);

      const stationListTemp = [];
      tempData.map((sta, index) => {
        stationListTemp.push(
          <TableRow
            key={sta.id}
            // onClick={() => { localStorage.setItem('selectedCompany', JSON.stringify(ins)); props.history.push('/admin/userList') }}
          >
            <TableCell component="th" scope="row">
              {index + 1}
            </TableCell>
            <TableCell>{sta.name}</TableCell>
            <TableCell
              dangerouslySetInnerHTML={{
                __html: sta.description,
              }}
            ></TableCell>
            <TableCell>{sta.nfc_tag_id}</TableCell>
            <TableCell>
              {sta.is_checkout_enable == 1
                ? "Check-in and check-out both"
                : sta.is_checkout_enable == 2
                ? "Link Check-in"
                : "Check-in only"}
            </TableCell>
            <TableCell>{sta.show_station == 1 ? "Yes" : "No"}</TableCell>
            <TableCell>
              <div className="d-flex">
                <Tooltip title="Edit">
                  <IconButton
                    type="button"
                    // className="trans-btn"
                    onClick={() => {
                      localStorage.setItem(
                        "selectedStation",
                        JSON.stringify(sta)
                      );
                      getStationDetails();
                      setAddNewStationModalFlag(true);
                      setEditFlag(true);
                      // setEditorState(EditorState.createEmpty());
                      setEditSneakerFlag(true);
                    }}
                  >
                    <EditIcon color="primary" fontSize="small" />{" "}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    type="button"
                    // className="trans-btn"
                    fontSize="small"
                    onClick={() => {
                      setSelectedId(sta.id);
                      setDeleteModalFlag(true);
                    }}
                  >
                    <DeleteIcon color="primary" fontSize="small" />
                  </IconButton>
                </Tooltip>{" "}
                {sta.is_checkout_enable == 2 && (
                  <Tooltip title="Download">
                    <IconButton
                      onClick={() => getParticipantListHandle(sta.id)}
                    >
                      <GetAppIcon color="primary" fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </TableCell>
          </TableRow>
        );
        if (index === tempData.length - 1) {
          setStationListDisplay(stationListTemp);
        }
      });
    });
  };

  const textFieldChangeHandler = (e) => {
    let { name, value, checked } = e.target;

    switch (name) {
      case "stationName":
        setStationName(value);
        setStationNameError("");

        break;

      case "stationNfcId":
        setStationNfcId(value);
        setStationNfcIdError("");

        break;

      case "stationDescription":
        setStationDescription(value);
        setStationDescriptionError("");

        break;

      case "stationType":
        setStationType(value);

        break;

      case "showStation":
        setShowStation(checked ? 1 : 0);

        break;
      case "checkinMessage":
        setShowCheckInMessage(checked ? 1 : 0);

        break;

      default:
        console.log("");
    }
  };

  const getStationDetails = () => {
    let data = JSON.parse(localStorage.getItem("selectedStation"));
    // console.log(data);
    setSelectedId(data.id);
    setStationName(data.name);
    setStationNfcId(data.nfc_tag_id);
    // let des = data.description.replaceAll("&lt;", "<").replaceAll("&gt;", ">");
    // des = des.replaceAll("&gt;", ">");
    // console.log(des);
    setStationDescription(data.description);
    setStationDescriptionShowCheckIN(data.show_checkin_message)

    // const blocksFromHtml = htmlToDraft(data.description);
    if (data.description) {
      const blocksFromHTML = convertFromHTML(data.description);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );

      // console.log(EditorState.createWithContent(state));
      setEditorState(EditorState.createWithContent(state));
    }
    if (data.show_checkin_message) {
      const blocksFromHTML = convertFromHTML(data.show_checkin_message);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );

      // console.log(EditorState.createWithContent(state));
      setEditorStateShowCheckIN(EditorState.createWithContent(state));
    }
    setStationType(data.is_checkout_enable);
    setShowStation(data.show_station);
    setShowCheckInMessage(data.flag_checkin_message);
  };

  const addStationHandler = (id) => {
    if (
      stationName.trim() == "" ||
      !Boolean(stationName.match(/^[\w-& ]+$/)) ||
      stationNfcId == "" ||
      stationDescription == ""
    ) {
      if (
        stationName.trim() == "" ||
        !Boolean(stationName.match(/^[\w-& ]+$/))
      ) {
        setStationNameError(
          "Please enter valid station name (accepted special characters &,- and _)"
        );
      }

      if (stationDescription.trim() == "") {
        setStationDescriptionError("Please enter description");
      }

      if (stationNfcId.trim() == "") {
        setStationNfcIdError("Please enter NFC ID");
      }
    } else {
      let tempObj = {
        id: id,
        name: stationName,
        company_id: companyId,
        nfc_tag_id: stationNfcId,
        description: stationDescription,
        is_checkout_enable: stationType,
        show_station: showStation,
        show_checkin_message :stationDescriptionShowCheckIN,
        flag_checkin_message :showCheckInMessage
      };
      console.log(tempObj);
      document.getElementById("addStationSubmit").disabled = true;

      addCheckInStation(tempObj)
        .then((response) => {
          // console.log(response);
          document.getElementById("addStationSubmit").disabled = false;
          setAddNewStationModalFlag(false);
          setAddSneakBarFlag(true);
          getStationListHandler(companyId);
          setAddNewStationFlag(false);
          setEditFlag(false);
        })
        .catch((error) => {
          console.log(error);
          setStationNfcIdError(error.message);
          document.getElementById("addStationSubmit").disabled = false;
        });
    }
  };

  const deleteStationHandler = () => {
    deleteStation(selectedId).then((response) => {
      // console.log(response)
      setDeleteModalFlag(false);
      setDeleteSneakBarFlag(true);
      getStationListHandler(companyId);
    });
  };

  const onEditorStateChange = (e) => {
    // console.log(convertToRaw(e.getCurrentContent()));
    // console.log(convertToRaw(e.getCurrentContent()));
    // if (convertToRaw(e.getCurrentContent()).blocks[0].type === "code") {
    //     setStationDescription(convertToRaw(e.getCurrentContent()).blocks[0].text)
    // }
    // else {
    let des = draftToHtml(convertToRaw(e.getCurrentContent()))
      .replaceAll("&lt;", "<")
      .replaceAll("&gt;", ">");
    console.log("des", des);
    setStationDescription(des);
    // }
    setEditorState(e);
  };

  const onEditorStateChangeShowCheckIN = (e) => {
    // console.log(convertToRaw(e.getCurrentContent()));
    // console.log(convertToRaw(e.getCurrentContent()));
    // if (convertToRaw(e.getCurrentContent()).blocks[0].type === "code") {
    //     setStationDescription(convertToRaw(e.getCurrentContent()).blocks[0].text)
    // }
    // else {
    let des = draftToHtml(convertToRaw(e.getCurrentContent()))
      .replaceAll("&lt;", "<")
      .replaceAll("&gt;", ">");
    console.log("des", des);
    setStationDescriptionShowCheckIN(des);
    // }
    setEditorStateShowCheckIN(e);
  };

  const handleHtmlEditor = () => {
    console.log(!htmlEditorFlag);
    setHtmlEditorFlag(!htmlEditorFlag);
  };
  const handleHtmlEditorShowCheckIN = () => {
    setHtmlEditorFlagShowCheckIN(!htmlEditorFlagShowCheckIN);
  };

  return (
    <div>
      <div className="icon">
        <NavLink to={"/admin/companyList"}>
          <button type="button" className="trans-btn mr-r10">
            <svg
              width="20"
              height="16"
              viewBox="0 0 20 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.33333 2.86765e-05C8.66437 -0.00183006 8.98849 0.0915604 9.26435 0.268294C9.54022 0.445028 9.75534 0.697096 9.88228 0.992364C10.0092 1.28763 10.0422 1.61272 9.97713 1.92618C9.91201 2.23965 9.75171 2.52728 9.51667 2.75241L4.01667 8.04794L9.51667 13.3435C9.78971 13.6514 9.93239 14.0475 9.91618 14.4525C9.89998 14.8576 9.7261 15.2419 9.42927 15.5286C9.13245 15.8152 8.73455 15.9832 8.31509 15.9988C7.89563 16.0144 7.4855 15.8767 7.16667 15.613L0.499999 9.17464C0.18958 8.87307 0.015344 8.46512 0.0153439 8.03989C0.0153439 7.61466 0.18958 7.20671 0.499999 6.90513L7.16667 0.466808C7.4771 0.169448 7.8961 0.00180636 8.33333 2.86765e-05Z"
                fill="#0099CC"
              />
              <path
                d="M1.66667 6.43835L18.3333 6.43835C18.7754 6.43835 19.1993 6.60793 19.5118 6.90979C19.8244 7.21164 20 7.62105 20 8.04793C20 8.47482 19.8244 8.88422 19.5118 9.18608C19.1993 9.48793 18.7754 9.65752 18.3333 9.65752L1.66667 9.65752C1.22464 9.65752 0.800716 9.48794 0.488155 9.18608C0.175594 8.88423 -6.57872e-07 8.47482 -6.95192e-07 8.04794C-7.32511e-07 7.62105 0.175594 7.21164 0.488155 6.90979C0.800716 6.60793 1.22464 6.43835 1.66667 6.43835Z"
                fill="#0099CC"
              />
            </svg>
          </button>
        </NavLink>
        <span className="">Dashboard</span>
        <span className=""> &#62; {companyName}</span>
      </div>

      <div className="mr-t50">
        <div className="data-head">
          {/* <div className="cus-search">
                            <input type="text" name="" id="" value={inspectionListPayload.search_by} onChange={searchByKeyword} placeholder="Search" />
                        </div> */}
          <div className="page-title mr-l10">
            <h3>Stations</h3>
          </div>
          <div className="add-btn">
            <button
              type="button"
              className="s-blue-btn m-btn mr-l20 mr-r10"
              data-toggle="modal"
              data-target="#addInsuranceModal"
              onClick={() => {
                setAddNewStationModalFlag(true);
                setAddNewStationFlag(true);
              }}
            >
              + Add New
            </button>
          </div>
        </div>
      </div>
      <div className="cus-table">
        <div className="table-str">
          <TableContainer component={Paper}>
            <Table aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  {stationListColumns.map((column) => (
                    <TableCell key={column.name} className="align-top">
                      {(column.name = column.title)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {this.state.reportsUpcomingList} */}
                {stationListDisplay}
                {/* {this.state.loading ? <Spin indicator={<div class="loading">Loading&#8230;</div>}/> : this.state.reportsUpcomingList} */}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <Modal
        open={addNewStationModalFlag}
        disableBackdropClick={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className="modal modal-xl blue-modal fade"
          id="addInsuranceModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable"
            style={{ maxWidth: "1000px" }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add Station
                </h5>
              </div>
              <div className="modal-body">
                <div className="ins-modal-body">
                  <form action="">
                    <div className="row">
                      <div className="">
                        <div className="form-group gray-fill-input mr-b30">
                          <label htmlFor="">Station Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="stationName"
                            id=""
                            value={stationName}
                            onChange={textFieldChangeHandler}
                            placeholder="Station Name"
                          />
                          {stationNameError !== "" ? (
                            <p className="error-alert mr-b20">
                              {stationNameError}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="">
                        <div className="form-group gray-fill-input mr-b30">
                          <label htmlFor="">Station Description</label>
                          <div className="d-flex justify-content-end">
                            Html Editor
                            <label className="switch mr-b10 mr-l10">
                              <input
                                type="checkbox"
                                checked={htmlEditorFlag}
                                onChange={() => {
                                  handleHtmlEditor();
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: stationDescription,
                            }}
                          ></div>
                          {htmlEditorFlag ? (
                            <Editor
                              editorState={editorState}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              onEditorStateChange={(e) =>
                                onEditorStateChange(e)
                              }
                              toolbar={{
                                image: {
                                  previewImage: true,
                                  defaultSize: {
                                    height: "250px",
                                    width: "200px",
                                  },
                                },
                              }}
                            />
                          ) : (
                            <textarea
                              rows="10"
                              className="form-control"
                              value={stationDescription}
                              onChange={(e) => {
                                setStationDescription(e.target.value);
                              }}
                            />
                          )}

                          {stationDescriptionError !== "" ? (
                            <p className="error-alert mr-b20">
                              {stationDescriptionError}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="">
                        <div className="form-group gray-fill-input mr-b30">
                          <label htmlFor="">Check-in confirmation modal text</label>
                          <div className="d-flex justify-content-end">
                            Html Editor
                            <label className="switch mr-b10 mr-l10">
                              <input
                                type="checkbox"
                                checked={htmlEditorFlagShowCheckIN}
                                onChange={() => {
                                  handleHtmlEditorShowCheckIN();
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: stationDescriptionShowCheckIN,
                            }}
                          ></div>
                          {htmlEditorFlagShowCheckIN ? (
                            <Editor
                              editorState={editorStateShowCheckIN}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              onEditorStateChange={(e) =>
                                onEditorStateChangeShowCheckIN(e)
                              }
                              toolbar={{
                                image: {
                                  previewImage: true,
                                  defaultSize: {
                                    height: "250px",
                                    width: "200px",
                                  },
                                },
                              }}
                            />
                          ) : (
                            <textarea
                              rows="10"
                              className="form-control"
                              value={stationDescriptionShowCheckIN}
                              onChange={(e) => {
                                setStationDescriptionShowCheckIN(
                                  e.target.value
                                );
                              }}
                            />
                          )}

                          {stationDescriptionError !== "" ? (
                            <p className="error-alert mr-b20">
                              {stationDescriptionError}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group gray-fill-input mr-b30 d-flex">
                        <label htmlFor="">Check-in confirmation</label>
                        <div>
                          <input
                            type="checkbox"
                            className="mr-l20"
                            name="checkinMessage"
                            id="checkin"
                            // value={showStation}
                            checked={showCheckInMessage == 1 ? true : false}
                            onChange={(e) => {
                              textFieldChangeHandler(e);
                            }}
                          />
                          {/* &nbsp; Check-in Only */}
                          &nbsp; {showCheckInMessage == 1 ? "Yes" : "No"}
                        </div>
                        {/* {linkedInError !== '' ? <p className="error-alert mr-b20">{linkedInError}</p> : null} */}
                      </div>
                      <div className="">
                        <div className="form-group gray-fill-input mr-b30">
                          <label htmlFor="">Station NFC ID</label>
                          <input
                            type="text"
                            className="form-control"
                            name="stationNfcId"
                            id=""
                            disabled={editFlag}
                            value={stationNfcId}
                            onChange={textFieldChangeHandler}
                            placeholder="Station NFC ID"
                          />
                          {stationNfcIdError !== "" ? (
                            <p className="error-alert mr-b20">
                              {stationNfcIdError}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group gray-fill-input mr-b30">
                        <label htmlFor="">Station Type</label>
                        <div>
                          <label>
                            <input
                              type="radio"
                              className=""
                              name="stationType"
                              id=""
                              value={0}
                              checked={stationType == 0 ? true : false}
                              onChange={(e) => {
                                textFieldChangeHandler(e);
                              }}
                            />
                            &nbsp; Check-in Only
                          </label>
                        </div>
                        <div>
                          <input
                            type="radio"
                            className=""
                            name="stationType"
                            id=""
                            checked={stationType == 1 ? true : false}
                            value={1}
                            onChange={(e) => {
                              textFieldChangeHandler(e);
                            }}
                          />
                          &nbsp; Check-in and check-out both
                        </div>
                        <div>
                          <input
                            type="radio"
                            className=""
                            name="stationType"
                            id=""
                            checked={stationType == 2 ? true : false}
                            value={2}
                            onChange={(e) => {
                              textFieldChangeHandler(e);
                            }}
                          />
                          &nbsp; Link Check-in
                        </div>
                        {/* {linkedInError !== '' ? <p className="error-alert mr-b20">{linkedInError}</p> : null} */}
                      </div>

                      <div className="form-group gray-fill-input mr-b30 d-flex">
                        <label htmlFor="">Include In Profile Page : </label>
                        <div>
                          <input
                            type="checkbox"
                            className="mr-l20"
                            name="showStation"
                            id=""
                            // value={showStation}
                            checked={showStation == 1 ? true : false}
                            onChange={(e) => {
                              textFieldChangeHandler(e);
                            }}
                          />
                          {/* &nbsp; Check-in Only */}
                          &nbsp; {showStation == 1 ? "Yes" : "No"}
                        </div>
                        {/* {linkedInError !== '' ? <p className="error-alert mr-b20">{linkedInError}</p> : null} */}
                      </div>
                  
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer pd-b30">
                <button
                  type="button"
                  className="s-blue-border-btn m-btn"
                  data-dismiss="modal"
                  onClick={() => {
                    setAddNewStationModalFlag(false);
                    setEditFlag(false);
                    setAddNewStationFlag(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="s-blue-btn m-btn"
                  data-dismiss="modal"
                  id="addStationSubmit"
                  // onClick={addStationHandler}
                  onClick={() => addStationHandler(editFlag ? selectedId : 0)}
                >
                  {editFlag ? "Update " : "Add "}
                  Station
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={deleteModalFlag}
        disableBackdropClick={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className="modal blue-modal fade"
          id="addInsuranceModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Confirmation
                </h5>
              </div>
              <div className="modal-body">
                Want to delete Selected Station ?
              </div>

              <div className="modal-footer pd-b30">
                <button
                  type="button"
                  className="s-blue-border-btn m-btn"
                  data-dismiss="modal"
                  onClick={() => setDeleteModalFlag(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="s-blue-btn m-btn"
                  onClick={deleteStationHandler}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Snackbar
        open={addSneakBarFlag}
        autoHideDuration={6000}
        onClose={() => {
          setAddSneakBarFlag(false);
          setEditSneakerFlag(false);
        }}
      >
        <Alert
          onClose={() => {
            setAddSneakBarFlag(false);
            setEditSneakerFlag(false);
          }}
          severity="success"
        >
          Station Is Successfully {editSneakerFlag ? "Updated" : "Added"}
        </Alert>
      </Snackbar>

      <Snackbar
        open={deleteSneakBarFlag}
        autoHideDuration={6000}
        onClose={() => setDeleteSneakBarFlag(false)}
      >
        <Alert onClose={() => setDeleteSneakBarFlag(false)} severity="success">
          Station Is Successfully Deleted
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Stations;
