import { Box, CircularProgress, Modal } from "@material-ui/core";
import React from "react";

function ModelLoader({ loading, recordFetched, tableCount }) {
  return (
    <Modal
      open={loading}
      //   onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 40,
        }}
      >
        <CircularProgress
          color="success"
          className="text-white"
          fontSize={28}
        />
        <h6 className="text-white mx-2 mt-3">loading...</h6>
        <span className="text-white mx-2 mt-3">
          Fetching records {1000 *  recordFetched}/{tableCount}{" "}
        </span>
      </Box>
    </Modal>
  );
}

export default ModelLoader;
