import {
  faBlogger,
  faFacebook,
  faInstagram,
  faLinkedinIn,
  faPaypal,
  faPinterest,
  faSnapchat,
  faTiktok,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect, useState } from "react";
import socialMediaList from "../../Utils/socialMediaList";
import MailIcon from "@material-ui/icons/Mail";
import CallIcon from "@material-ui/icons/Call";
import { Close } from "@material-ui/icons";

const SocialMedia = ({
  selectedSocialMedia,
  setSelectedSocialMedia,
  selectedSocialMediaList,
  setSelectedSocialMediaList,
}) => {
  const [socialMedia, setSocialMedia] = useState({ name: "None", link: "" });

  const handleChange = (event) => {
    setSocialMedia({
      ...socialMedia,
      [event.target.name]: event.target.value,
    });
  };
  const socialMediaIcon = (temp) => {
    let socialMediaIconsTempList = [];
    for (let sm of temp) {
      if (sm.name == "Facebook")
        socialMediaIconsTempList.push(
          <div className="row">
            <div className="col-1">
              <a href={sm.link} className="p-1" target="__blank">
                <FontAwesomeIcon icon={faFacebook} size="2x" />
              </a>
            </div>
            <div className="col-9">
              <TextField
                value={sm.link}
                name="link"
                type="text"
                variant="outlined"
                size="small"
                fullWidth
                disabled={true}
              />
            </div>
            <div className="col-2 mb-3">
              <Tooltip title="Remove">
                <IconButton onClick={() => handleRemover(sm.name)}>
                  <Close />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        );
      if (sm.name == "X(Twitter)")
        socialMediaIconsTempList.push(
          <div className="row">
            <div className="col-1">
              <a href={sm.link} className="p-1" target="__blank">
                <FontAwesomeIcon icon={faXTwitter} size="2x" />
              </a>
            </div>
            <div className="col-9">
              <TextField
                value={sm.link}
                name="link"
                type="text"
                variant="outlined"
                size="small"
                fullWidth
                disabled={true}
              />
            </div>
            <div className="col-2 mb-3">
              <Tooltip title="Remove">
                <IconButton onClick={() => handleRemover(sm.name)}>
                  <Close />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        );
      if (sm.name == "Instagram")
        socialMediaIconsTempList.push(
          <div className="row">
            <div className="col-1">
              <a href={sm.link} className="p-1" target="__blank">
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
            </div>
            <div className="col-9">
              <TextField
                value={sm.link}
                type="text"
                variant="outlined"
                size="small"
                fullWidth
                disabled={true}
              />
            </div>
            <div className="col-2 mb-3">
              <Tooltip title="Remove">
                <IconButton onClick={() => handleRemover(sm.name)}>
                  <Close />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        );
      if (sm.name == "YouTube")
        socialMediaIconsTempList.push(
          <div className="row">
            <div className="col-1">
              <a href={sm.link} className="p-1" target="__blank">
                <FontAwesomeIcon icon={faYoutube} size="2x" />
              </a>
            </div>
            <div className="col-9">
              <TextField
                value={sm.link}
                name="link"
                type="text"
                variant="outlined"
                size="small"
                fullWidth
                disabled={true}
              />
            </div>
            <div className="col-2 mb-3">
              <Tooltip title="Remove">
                <IconButton onClick={() => handleRemover(sm.name)}>
                  <Close />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        );
      if (sm.name == "TikTok")
        socialMediaIconsTempList.push(
          <div className="row">
            <div className="col-1">
              <a href={sm.link} className="p-1" target="__blank">
                <FontAwesomeIcon icon={faTiktok} size="2x" />
              </a>
            </div>

            <div className="col-9">
              <TextField
                value={sm.link}
                name="link"
                type="text"
                variant="outlined"
                size="small"
                fullWidth
                disabled={true}
              />
            </div>
            <div className="col-2 mb-3">
              <Tooltip title="Remove">
                <IconButton onClick={() => handleRemover(sm.name)}>
                  <Close />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        );
      if (sm.name == "LinkedIn")
        socialMediaIconsTempList.push(
          <div className="row">
            <div className="col-1">
              <a href={sm.link} className="p-1" target="__blank">
                <FontAwesomeIcon icon={faLinkedinIn} size="2x" />
              </a>
            </div>
            <div className="col-9">
              <TextField
                value={sm.link}
                name="link"
                type="text"
                variant="outlined"
                size="small"
                fullWidth
                disabled={true}
              />
            </div>
            <div className="col-2 mb-3">
              <Tooltip title="Remove">
                <IconButton onClick={() => handleRemover(sm.name)}>
                  <Close />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        );
      if (sm.name == "PayPal")
        socialMediaIconsTempList.push(
          <div className="row">
            <div className="col-1">
              <a href={sm.link} className="p-1" target="__blank">
                <FontAwesomeIcon icon={faPaypal} size="2x" />
              </a>{" "}
            </div>
            <div className="col-9">
              <TextField
                value={sm.link}
                name="link"
                type="text"
                variant="outlined"
                size="small"
                fullWidth
                disabled={true}
              />
            </div>
            <div className="col-2 mb-3">
              <Tooltip title="Remove">
                <IconButton onClick={() => handleRemover(sm.name)}>
                  <Close />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        );
      if (sm.name == "Pinterest")
        socialMediaIconsTempList.push(
          <div className="row">
            <div className="col-1">
              <a href={sm.link} className="p-1" target="__blank">
                <FontAwesomeIcon icon={faPinterest} size="2x" />
              </a>
            </div>
            <div className="col-9">
              <TextField
                value={sm.link}
                name="link"
                type="text"
                variant="outlined"
                size="small"
                fullWidth
                disabled={true}
              />
            </div>
            <div className="col-2 mb-3">
              <Tooltip title="Remove">
                <IconButton onClick={() => handleRemover(sm.name)}>
                  <Close />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        );
      if (sm.name == "Email")
        socialMediaIconsTempList.push(
          <div className="row">
            <div className="col-1">
              <a href={sm.link} className="p-1" target="__blank">
                <MailIcon fontSize="large" />
              </a>
            </div>
            <div className="col-9">
              <TextField
                value={sm.link}
                name="link"
                type="text"
                variant="outlined"
                size="small"
                fullWidth
                disabled={true}
              />
            </div>
            <div className="col-2 mb-3">
              <Tooltip title="Remove">
                <IconButton onClick={() => handleRemover(sm.name)}>
                  <Close />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        );
      if (sm.name == "Phone")
        socialMediaIconsTempList.push(
          <div className="row mb-2">
            <div className="col-1">
              <a href={sm.link} className="p-1" target="__blank">
                <CallIcon fontSize="large" />
              </a>
            </div>
            <div className="col-9">
              <TextField
                value={sm.link}
                name="link"
                type="text"
                variant="outlined"
                size="small"
                fullWidth
                disabled={true}
              />
            </div>
            <div className="col-2 mb-3">
              <Tooltip title="Remove">
                <IconButton onClick={() => handleRemover(sm.name)}>
                  <Close />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        );
      if (sm.name == "SnapChat")
        socialMediaIconsTempList.push(
          <div className="row">
            <div className="col-1">
              <a href={sm.link} className="p-1" target="__blank">
                <FontAwesomeIcon icon={faSnapchat} size="2x" />
              </a>
            </div>
            <div className="col-9">
              <TextField
                value={sm.link}
                name="link"
                type="text"
                variant="outlined"
                size="small"
                fullWidth
                disabled={true}
              />
            </div>
            <div className="col-2 mb-3">
              <Tooltip title="Remove">
                <IconButton onClick={() => handleRemover(sm.name)}>
                  <Close />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        );
      if (sm.name == "Blog")
        socialMediaIconsTempList.push(
          <div className="row">
            <div className="col-1">
              <a href={sm.link} className="p-1" target="__blank">
                <FontAwesomeIcon icon={faBlogger} size="2x" />
              </a>
            </div>
            <div className="col-9">
              <TextField
                value={sm.link}
                name="link"
                type="text"
                variant="outlined"
                size="small"
                fullWidth
                disabled={true}
              />
            </div>
            <div className="col-2 mb-3">
              <Tooltip title="Remove">
                <IconButton onClick={() => handleRemover(sm.name)}>
                  <Close />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        );
      if (sm.name == "Venmo")
        socialMediaIconsTempList.push(
          <div className="row">
            <div className="col-1">
              <a href={sm.link} className="p-1" target="__blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1333.33 1333.33"
                  shape-rendering="geometricPrecision"
                  text-rendering="geometricPrecision"
                  image-rendering="optimizeQuality"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                >
                  <g fill-rule="nonzero">
                    <path
                      d="M157.62 0h1018.09c87.04 0 157.62 70.58 157.62 157.62v1018.09c0 87.04-70.58 157.62-157.62 157.62H157.62C70.58 1333.33 0 1262.74 0 1175.7V157.62C0 70.58 70.59 0 157.63 0z"
                      fill="#3396cd"
                    />
                    <path
                      d="M995.24 271.32c28.68 47.29 41.55 96.05 41.55 157.62 0 196.38-167.62 451.42-303.67 630.49H422.45L297.88 314.34 570 288.5l66.17 530.15c61.5-100.31 137.55-257.93 137.55-365.32 0-58.84-10.08-98.84-25.84-131.78l247.36-50.23z"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </a>
            </div>
            <div className="col-9">
              <TextField
                value={sm.link}
                name="link"
                type="text"
                variant="outlined"
                size="small"
                fullWidth
                disabled={true}
              />
            </div>
            <div className="col-2 mb-3">
              <Tooltip title="Remove">
                <IconButton onClick={() => handleRemover(sm.name)}>
                  <Close />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        );
      if (sm.name == "Website")
        socialMediaIconsTempList.push(
          <div className="row">
            <div className="col-1">
              <a href={sm.link} className="p-1" target="__blank">
                <svg
                  width="36"
                  className="mb-2"
                  viewBox="0 0 64 64"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke-width="3"
                  stroke="#0d6efd"
                  fill="none"
                >
                  <path d="M39.93,55.72A24.86,24.86,0,1,1,56.86,32.15a37.24,37.24,0,0,1-.73,6" />
                  <path d="M37.86,51.1A47,47,0,0,1,32,56.7" />
                  <path d="M32,7A34.14,34.14,0,0,1,43.57,30a34.07,34.07,0,0,1,.09,4.85" />
                  <path d="M32,7A34.09,34.09,0,0,0,20.31,32.46c0,16.2,7.28,21,11.66,24.24" />
                  <line x1="10.37" y1="19.9" x2="53.75" y2="19.9" />
                  <line x1="32" y1="6.99" x2="32" y2="56.7" />
                  <line x1="11.05" y1="45.48" x2="37.04" y2="45.48" />
                  <line x1="7.14" y1="32.46" x2="56.86" y2="31.85" />
                  <path d="M53.57,57,58,52.56l-8-8,4.55-2.91a.38.38,0,0,0-.12-.7L39.14,37.37a.39.39,0,0,0-.46.46L42,53.41a.39.39,0,0,0,.71.13L45.57,49Z" />
                </svg>
              </a>
            </div>
            <div className="col-9">
              <TextField
                value={sm.link}
                name="link"
                type="text"
                variant="outlined"
                size="small"
                fullWidth
                disabled={true}
              />
            </div>
            <div className="col-2 mb-3">
              <Tooltip title="Remove">
                <IconButton onClick={() => handleRemover(sm.name)}>
                  <Close />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        );
    }
    setSelectedSocialMediaList(socialMediaIconsTempList);
  };
  const handleRemover = (name) => {
    const temp = selectedSocialMedia.filter((record) => record.name != name);
    setSocialMedia({});
    setSelectedSocialMedia(temp);
    // socialMediaIcon(temp);
  };
  const handleSelected = () => {
    const temp = [...selectedSocialMedia];
    temp.push(socialMedia);
    setSocialMedia({ name: "None", link: "" });
    if (Object.keys(socialMedia)?.length) setSelectedSocialMedia(temp);
    // socialMediaIcon(temp);
  };
  useEffect(() => {
    socialMediaIcon(selectedSocialMedia?.length ? selectedSocialMedia : []);
  }, [selectedSocialMedia]);
  return (
    <div className="mt-2">
      <h5>4. Social Media</h5>
      <span>
        This entry is optional. You social media links will show as icons under
        the content section.{" "}
      </span>

      <div className="row my-2">
        <div className="col-12">
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="social-media-label">Social Media</InputLabel>
            <Select
              labelId="social-media-label"
              id="social-media-select"
              value={socialMedia?.name}
              onChange={handleChange}
              label="Social Media"
              name="name"
            >
              {socialMediaList?.map((record) => (
                <MenuItem key={record.name} value={record.name}>
                  {record.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            type="text"
            variant="outlined"
            //   className="form-control"
            name="link"
            className="mt-3"
            value={socialMedia?.link}
            id=""
            onChange={handleChange}
            placeholder="Link"
            fullWidth
          />
        </div>
        <div className="col-12 text-end mt-3 mb-2">
          <Button
            variant="contained"
            // color="primary"
            // size="large"
            onClick={handleSelected}
            style={{ background: "#c7e1bf", color: "white" }}
          >
            <AddIcon /> Add
          </Button>
        </div>
      </div>
      <div>{selectedSocialMediaList}</div>
      <div className="form-group gray-fill-input mr-b30"> </div>
    </div>
  );
};

export default SocialMedia;
