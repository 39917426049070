import "animate.css";
import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import "./css/style.css";

import { ExportToCsv } from "export-to-csv";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import ViewContacts from "../../assets/images/ViewContacts.png";
import ViewHistory from "../../assets/images/ViewHistory-ico.png";
import ViewHistory1 from "../../assets/images/icon1.png";
import Config from "../../config.json";
import {
  addContact,
  addNotes,
  checkInParticipant,
  deletePhoto,
  getCheckInStationByCompanyId,
  getContactListByNfcId,
  getParticipantCompanyFieldsById,
  participantLogin,
  uploadPhotosPublic,
} from "../../services/adminApi";

import Modal from "@material-ui/core/Modal";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import ClipLoader from "react-spinners/ClipLoader";

import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faPinterest,
  faSnapchat,
  faTiktok,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CallIcon from "@material-ui/icons/Call";
import LanguageIcon from "@material-ui/icons/Language";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import MailIcon from "@material-ui/icons/Mail";

import ArrowLeft from "./images/arrow-left.png";
import ArrowRight from "./images/arrow-right.png";
import CheckIn from "./images/check-in.png";
import Close from "./images/close.png";
import Contact from "./images/contact.png";
import EditIcon from "./images/edit.png";
import NavIcon1 from "./images/nav-icon1.png";

import Download from "../../assets/images/download.png";
import DotMenu from "../../assets/images/menu.png";

import Notes from "../../assets/images/notes.png";
import Trash from "../../assets/images/trash.png";

import Business from "../../assets/images/business.png";
import Email from "../../assets/images/email.png";
import Phone from "../../assets/images/phone.png";
import Vcard from "../../assets/images/vcard.png";

import { Box, LinearProgress } from "@material-ui/core";
import Compressor from "compressorjs";
import { detectIncognito } from "detectincognitojs";
import QRCode from "react-qr-code";
import RegistrationWithoutNfc from "../withoutNfc/RegistrationWithoutNfc";
import ScrollTop from "./ScrollButton";
import SponsorList from "./SponsorList";
// import LazyImage from "../../Utils/LazyImage";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const carouselOptions = {
  loop: false,
  margin: 10,
  items: 1,
  center: true,
  nav: true,
  dots: false,
  navText: [`<img   src=${ArrowLeft}>`, `<img   src=${ArrowRight}>`],
  // autoplay: true
  autoHeight: true,
  rewind: true,
};

const ClientPageWithoutNfc = (props) => {
  const [participantId, setParticipantId] = useState("");
  const [privateTabMessage, setPrivateTabMessage] = useState("");
  const [privateTab, setPrivateTab] = useState(false);
  const [enableContactButton, setEnableContactButton] = useState(0);
  const [confirmDownload, setConfirmDownload] = useState(false);
  const [participantName, setParticipantName] = useState("");
  const [logoImg, setLogoImg] = useState();
  const [sponsorImg, setSponsorImg] = useState();
  const [sponsorImages, setSponsorImgages] = useState([]);
  const [defaultProfileImg, setDefaultProfileImg] = useState("");
  const [description, setDescription] = useState("Welcome to the Event");
  // const [linkedIn, setLinkedIn] = useState('');
  const [backgroundColor, setBackgroundColor] = useState("#ebebeb");
  const [buttonBackgroundColor, setButtonBackgroundColor] = useState("#4287f5");
  const [buttonTextColor, setButtonTextColor] = useState("#f5f5f5");
  const [textColor, setTextColor] = useState("#f5f5f5");
  const [logoUrl, setLogoUrl] = useState();
  const [sponsorUrl, setSponsorUrl] = useState();
  const [color, setColor] = useState(false);

  const [lastName, setLastName] = useState("");

  const [address, setAddress] = useState("");

  const [contact, setContact] = useState("");
  const [emailId, setEmailId] = useState("");
  const [linkedIn, setLinkedIn] = useState();
  const [facebookId, setFacebookId] = useState("");
  const [instagramId, setInstagramId] = useState("");
  const [twitterId, setTwitterId] = useState("");
  const [youtubeId, setYoutubeId] = useState("");
  const [pintrestId, setPinterestId] = useState("");
  const [snapchatId, setSnapchatId] = useState("");
  const [tiktokId, setTiktokId] = useState("");

  const [field16, setField16] = useState("");
  const [field17, setField17] = useState("");
  const [field18, setField18] = useState("");
  const [field19, setField19] = useState("");
  const [field20, setField20] = useState("");
  const [field21, setField21] = useState("");
  const [field22, setField22] = useState("");
  const [field23, setField23] = useState("");
  const [field24, setField24] = useState("");
  const [field25, setField25] = useState("");

  const [loaderFlag, setLoaderFlag] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState();
  const [tapparazziItems, setTapparazziItems] = useState([]);
  const [stationList, setStationList] = useState([]);
  const [stationListDisplay, setStationListDisplay] = useState([]);
  const [contactListDisplay, setContactListDisplay] = useState([]);
  const [moduleListDisplay, setModuleListDisplay] = useState([]);
  const [fullContactListDisplay, setFullContactListDisplay] = useState([]);
  const [socialMediaIconDisplayList, setSocialMediaIconDisplayList] = useState(
    []
  );

  const [checkInButton, setCheckInButton] = useState(false);
  const [tapparazziButton, setTapparazziButton] = useState(false);
  const [contactShareButton, setContactShareButton] = useState(false);

  const [checkedAt, setCheckedAt] = useState([]);
  const [companyId, setCompanyId] = useState();
  const [companyName, setCompanyName] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [notes, setNotes] = useState("");

  const [participantLoginModalFlag, setParticipantLoginModalFlag] =
    useState(false);
  const [downloadVcardModalFlag, setDownloadVcardModalFlag] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState({
    action: false,
    id: 0,
  });
  const [addViewNotesModalFlag, setAddViewNotesModalFlag] = useState(false);
  const [stationNfcTagModalFlag, setStationNfcTagModalFlag] = useState(false);
  const [checkInStatusModalFlag, setCheckInStatusModalFlag] = useState(false);
  const [viewContactModalFlag, setViewContactModalFlag] = useState(false);
  const [saveContactModal, setSaveContactModal] = useState(false);
  const [showQrCode, setQrCode] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [optionsSponsor, setOptionsSponsor] = useState({
    items: 1, // Number of items to display
    nav: true, // Display navigation buttons
    dots: false, // Display dots for navigation
    loop: true, // Loop the carousel
    autoHeight: true,
    autoplay: true,
    autoplayTimeout: 4000, // Delay between slides in milliseconds (e.g., 2000ms = 2 seconds)
    animateOut: "",
    // animateIn: "animate__slideInRight",

    // navText: [`<img   src=${ArrowLeft}>`, `<img   src=${ArrowRight}>`],
  });

  const vCardsJS = require("vcards-js");

  const [error, setError] = useState("");
  const [filterIcon, setFilterIcon] = useState(false);

  const [companyConfigloading, setCompanyConfigLoading] = useState(true);

  const [spinnerColor, setSpinnerColor] = useState("#000000");

  const [isParticipant, setIsParticipant] = useState(false);
  const [isStation, setIsStation] = useState(false);
  const [stationInfo, setStationInfo] = useState({});
  const [isNone, setIsNone] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [isRegistered1, setIsRegistered1] = useState(true);

  const [includeCncList, setIncludeCncList] = useState([]);

  const [enableProfilePhotoEdit, setEnableProfilePhotoEdit] = useState(false);

  const [registerButtonText, setRegisterButtonText] = useState("Register");
  const [profilePhotoSnackbarFlag, setProfilePhotoSnackbarFlag] =
    useState(false);

  const [customButtonsList, setCustomButtonsList] = useState([]);

  const closeButtonRef = useRef(null);
  const closeSideMenu = useRef(null);
  const { onClickOutside } = props;

  const [isMenuOpen, setisMenuOpen] = useState("");

  const inputRef = useRef(0);
  // States for search and filter
  const [searchText, setSearchText] = useState("");
  const [filterType, setFilterType] = useState("");
  const searchTextRef = useRef(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        document.getElementById("sidebar-nav")?.classList.contains("shownav") &&
        event.target.id != "menu-toggle-btn"
      ) {
        let node = document.getElementById("sidebar-nav");
        node.classList.toggle("shownav");
        let node1 = document.getElementById("menu-toggle-btn");
        node1.classList.toggle("close-toggle");
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);

  // useEffect(() => {
  //   let data = props.match.params;
  //   // console.log(data);
  //   setParticipantId(data.id);
  //   setCompanyName(data.companyName);

  //   getAllParticipantData(data.id).then((response) => {
  //     if (response.data.sponsorConfig?.id) {
  //       setOptionsSponsor({
  //         ...optionsSponsor,
  //         autoplayTimeout: response.data.sponsorConfig?.delay,
  //         animateOut: "animate__fadeOut",
  //         // animateIn: "animate__zoomIn",
  //         animateIn: response.data.sponsorConfig?.transion_effect,
  //         // animateOut: response.data.sponsorConfig?.transion_effect,
  //       });
  //     }
  //     getData(response.data, data.id, data.companyName);
  //     getCompanyConfigHandler(response.data);
  //   });
  // }, [loggedIn]);
  // console.log(props.match.params);
  const getAllParticipant = () => {};

  useState(() => {
    setEmail("");
    setEmailError("");
  }, [participantLoginModalFlag]);

  useEffect(() => {
    check();
  }, []);

  const check = async () => {
    detectIncognito().then((result) => {
      // console.log(result.browserName, result.isPrivate);
      setPrivateTab(result.isPrivate);
    });
  };

  const getData = async (response, participant_id, company_name) => {
    // console.log("response ", response);
    if (Object.keys(response.checkNfcTag.isStation).length !== 0) {
      if (localStorage.getItem("participantNfcId")) {
        setIsStation(true);
        setIsParticipant(false);
        setIsNone(false);
        // console.log(
        //   response.checkNfcTag.isStation[0] && response.checkNfcTag.isStation[0]
        // );
        if (
          response.checkNfcTag.isStation[0] &&
          response.checkNfcTag.isStation[0]
        )
          if (response.checkNfcTag.isStation[0].is_checkout_enable == 0)
            addCheckInParticipantHandler(
              response.checkNfcTag.isStation[0].company_id,
              response.checkNfcTag.isStation[0].id,
              localStorage.getItem("participantNfcId"),
              company_name,
              response.checkNfcTag.isStation[0].name,
              0
            );
        if (response.checkNfcTag.isStation[0].is_checkout_enable == 1) {
          setStationInfo(response.checkNfcTag.isStation[0]);
        }
      } else {
        setStationNfcTagModalFlag(true);
      }
    } else if (Object.keys(response.checkNfcTag.isParticipant).length !== 0) {
      window["gtag"]("event", company_name, {
        nfc_id: participant_id,
        event_name: "total_profile_page_visits",
      });

      if (localStorage.getItem("participantNfcId")) {
        if (localStorage.getItem("participantNfcId") === participant_id) {
          setLoggedIn(true);
          getParticipantDetailHandler(response);
        } else {
          addContactHandler(
            localStorage.getItem("participantNfcId"),
            participant_id,
            company_name
          );
        }
      } else {
        // console.log(
        //   "response.checkNfcTag.isParticipant.is_registered",
        //   response.checkNfcTag.isParticipant.is_registered
        // );
        if (!response.checkNfcTag.isParticipant.is_registered) {
          setCompanyId(response.checkNfcTag.isParticipant.company_id);
          setIsStation(false);
          setIsParticipant(false);
          setIsNone(false);
          setIsRegistered(true);
          setIsRegistered1(
            Boolean(response.checkNfcTag.isParticipant.is_registered)
          );
          // console.log(
          //   "response.checkNfcTag.isParticipant.is_registered",
          //   response.checkNfcTag.isParticipant.is_registered
          // );
        } else {
          getParticipantDetailHandler(response);
        }
      }
    } else if (response.checkNfcTag.isNone) {
      setIsStation(false);
      setIsParticipant(false);
      setIsNone(true);
    } else {
      // console.log("check Nfc Tag Error");
    }
  };

  const handleStationsSelected = (choice) => {
    addCheckInParticipantHandler(
      stationInfo.company_id,
      stationInfo.id,
      localStorage.getItem("participantNfcId"),
      companyName,
      stationInfo.name,
      choice
    );
  };

  const setOwlStageHeight = (event) => {
    var maxHeight = 0;
    $(".owl-item.active").each(function () {
      // LOOP THROUGH ACTIVE ITEMS
      var thisHeight = parseInt($(this).height());
      maxHeight = maxHeight >= thisHeight ? maxHeight : thisHeight;
    });
    $(".owl-carousel").css("height", maxHeight);
    $(".owl-stage-outer").css("height", maxHeight); // CORRECT DRAG-AREA SO BUTTONS ARE CLICKABLE
    $(".owl-nav").css("top", (maxHeight * -1) / 2);
  };

  const addCheckInParticipantHandler = (
    company_id,
    station_id,
    participant_id,
    company,
    station_name,
    is_checkout_enable
  ) => {
    debugger;
    let tempObj = {
      company_id: company_id,
      station_id: station_id,
      participant_nfc_id: participant_id,
      station_variation_type: is_checkout_enable ? "check out" : "check in",
    };

    // console.log(company_id);

    checkInParticipant(tempObj).then((response) => {
      window["gtag"]("event", company, {
        nfc_id: participantId,
        event_name: "total_checkin",
        station_name: station_name,
        checkin_mode: "self",
      });
      let url = Config.frontEndUrl + "/" + company + "/" + participant_id;
      window.open(url, "_parent");
    });
  };

  const addContactHandler = (participantNfcId, contactNfcId, company) => {
    let tempObj = {
      participant_nfc_id: participantNfcId,
      contact_nfc_id: contactNfcId,
    };

    addContact(tempObj)
      .then((response) => {
        let companyN = localStorage.getItem("companyName");

        let url = Config.frontEndUrl + "/" + companyN + "/" + participantNfcId;
        window.open(url, "_parent");
      })
      .catch((error) => {
        // console.log(error);
        let companyN = localStorage.getItem("companyName");
        let url = Config.frontEndUrl + "/" + companyN + "/" + participantNfcId;
        window.open(url, "_parent");
      });
  };

  const getCompanyConfigHandler = async (response) => {
    let data = "";
    // console.log("response", response);
    data = response.companyDetails;

    if (Boolean(response?.companyDetails)) {
      setLogoImg(data?.event_logo);
      setDefaultProfileImg(data?.default_profile_photo);
      setDescription(
        data?.description ? data.description : "Welcome to the Event "
      );
      setSponsorImg(
        response?.sponsors
          ? response?.sponsors[response.sponsors.length - 1]?.image_url
          : ""
      );
      setButtonBackgroundColor(
        data?.button_background_color ? data.button_background_color : "#4287f5"
      );
      setBackgroundColor(
        data?.background_color ? data.background_color : "#ebebeb"
      );
      setTextColor(data?.text_color ? data.text_color : "#f5f5f5");
      setButtonTextColor(
        data?.button_text_color ? data?.button_text_color : "#f5f5f5"
      );
      setLogoUrl(data?.header_url ? data?.header_url : null);
      setSponsorUrl(data?.footer_url ? data?.footer_url : "");
      setCompanyConfigLoading(false);
      setEnableContactButton(data?.contact_save_button ? 1 : 0);
      setEnableProfilePhotoEdit(
        data?.enable_profile_photo_edit === 1 ? true : false
      );

      setPrivateTabMessage(data?.custom_instruction_private);
      const tempSponsorObj = {
        id: response?.sponsors
          ? response?.sponsors[response.sponsors.length - 1]?.id
          : "",
        image: response?.sponsors
          ? response?.sponsors[response.sponsors.length - 1]?.image_url
          : "",
        // open:0,
        target_url: data?.footer_url ? data?.footer_url : "",
      };

      const tempArr = [...response?.sponsorImages];
      if (Boolean(tempSponsorObj.image)) tempArr.push(tempSponsorObj);
      setSponsorImgages(tempArr);
    }

    return data;
  };

  const getParticipantDetailHandler = async (response) => {
    // console.log("participantDetails", response);
    let data = response.participantDetail;
    // console.log("data", contact);
    let companyConfigData = response.companyDetails;
    setParticipantName(data.name);
    setCompanyId(data.company_id);
    setContact(data.contact);
    setEmailId(data.email_id);
    setLinkedIn(data.linkedin_id);
    setFacebookId(data.facebook_id);
    setInstagramId(data.insgram_id);
    setTwitterId(data.twitter_id);
    setYoutubeId(data.youtube_id);
    setPinterestId(data.pintrest_id);
    setSnapchatId(data.snapchat_id);
    setTiktokId(data.tiktok_id);

    let socialMediaIconsTempList = [];

    if (data.contact || !data.contact == "") {
      socialMediaIconsTempList.push(
        <a className="p-1" href={`tel:${data.contact}`} target="__blank">
          {/* <FontAwesomeIcon icon="fa-brands fa-envelope" size="2x" /> */}
          <CallIcon fontSize="large" />
        </a>
      );
    }
    if (data.email_id || !data.email_id == "") {
      socialMediaIconsTempList.push(
        <a className="p-1" href={`mailto:${data.email_id}`} target="__blank">
          {/* <FontAwesomeIcon icon={faEnvelope} size="2x" /> */}
          <MailIcon fontSize="large" />
        </a>
      );
    }
    if (data.field_16 || !data.field_16 == "") {
      socialMediaIconsTempList.push(
        <a
          className="p-1"
          href={"//" + data.field_16.replace(/^https?:\/\//, "")}
          target="__blank"
        >
          {/* <FontAwesomeIcon icon={faEnvelope} size="2x" /> */}
          <LanguageIcon fontSize="large" />
        </a>
      );
    }

    if (data.facebook_id || !data.facebook_id == "") {
      socialMediaIconsTempList.push(
        <a
          className="p-1"
          href={"//" + data.facebook_id.replace(/^https?:\/\//, "")}
          target="__blank"
        >
          <FontAwesomeIcon icon={faFacebook} size="2x" />
        </a>
      );
    }

    if (data.instagram_id || !data.instagram_id == "") {
      socialMediaIconsTempList.push(
        <a
          className="p-1"
          href={"//" + data.instagram_id.replace(/^https?:\/\//, "")}
          target="__blank"
        >
          <FontAwesomeIcon icon={faInstagram} size="2x" />
        </a>
      );
    }

    if (data.twitter_id || !data.twitter_id == "") {
      socialMediaIconsTempList.push(
        <a
          className="p-1"
          href={"//" + data.twitter_id.replace(/^https?:\/\//, "")}
          target="__blank"
        >
          <FontAwesomeIcon icon={faTwitter} size="2x" />
        </a>
      );
    }
    if (data.youtube_id || !data.youtube_id == "") {
      socialMediaIconsTempList.push(
        <a
          className="p-1"
          href={"//" + data.youtube_id.replace(/^https?:\/\//, "")}
          target="__blank"
        >
          <FontAwesomeIcon icon={faYoutube} size="2x" />
        </a>
      );
    }
    if (data.pintrest_id || !data.pintrest_id == "") {
      socialMediaIconsTempList.push(
        <a
          className="p-1"
          href={"//" + data.pintrest_id.replace(/^https?:\/\//, "")}
          target="__blank"
        >
          <FontAwesomeIcon icon={faPinterest} size="2x" />
        </a>
      );
    }
    if (data.linkedin_id || !data.linkedin_id == "") {
      socialMediaIconsTempList.push(
        <a
          className="p-1"
          href={"//" + data.linkedin_id.replace(/^https?:\/\//, "")}
          target="__blank"
        >
          <FontAwesomeIcon icon={faLinkedin} size="2x" />
        </a>
      );
    }
    if (data.snapchat_id || !data.snapchat_id == "") {
      socialMediaIconsTempList.push(
        <a
          className="p-1"
          href={"//" + data.snapchat_id.replace(/^https?:\/\//, "")}
          target="__blank"
        >
          <FontAwesomeIcon icon={faSnapchat} size="2x" />
        </a>
      );
    }
    if (data.tiktok_id || !data.tiktok_id == "") {
      socialMediaIconsTempList.push(
        <a
          className="p-1"
          href={"//" + data.tiktok_id.replace(/^https?:\/\//, "")}
          target="__blank"
        >
          <FontAwesomeIcon icon={faTiktok} size="2x" />
        </a>
      );
    }

    setSocialMediaIconDisplayList(socialMediaIconsTempList);

    let checkedArray = data.checkin_at
      ? data.checkin_at.map(({ id }) => id)
      : [];
    // let checkedArray = data.checkin_at.map(({ id }) => id)
    setCheckedAt(checkedArray);

    let tempData = response?.participantImages
      ? response?.companyDetails?.sort_img === 0
        ? response.participantImages.reverse()
        : response.participantImages
      : [];

    // let taparazziArray = tempData.filter((x) => x.is_taparazzi);
    // taparazziArray = taparazziArray.reverse();
    // let profileArray = tempData.filter((x) => x.is_gallery);

    let tap = [];

    // const profileImages = profileArray.map((m) => (
    //   <div
    //     className={`${companyName}-participant-page-profile-img-item-div participant-page-profile-img-item-div`}
    //   >
    //     <div
    //       className={`${companyName}-participant-page-profile-photo-card-div participant-page-profile-photo-card-div`}
    //     >
    //       <div
    //         className={`${companyName}-participant-page-profile-photo-auth-div participant-page-profile-photo-auth-div`}
    //         style={{ height: "400px" }}
    //       >
    //         {/* <a href={`${Config.imageApiUrl}/${m.url}`} download="AwesomeImage.png"> */}
    //         <img loading="lazy"
    //           src={`${Config.imageApiUrl}/${m.url}`}
    //           alt=""
    //           className={`${companyName}-participant-page-profile-photo-img participant-page-profile-photo-img responsive`}
    //           style={{
    //             borderColor: buttonBackgroundColor,
    //             zIndex: "0",
    //             position: "absolute",
    //           }}
    //           // onClick={() => { clickEditProfilePhoto() }}
    //         />
    //         {/* </a> */}

    //         <img loading="lazy"
    //           className="float-end mr10"
    //           style={{ width: "20px", zIndex: "10", position: "relative" }}
    //           src={Download}
    //           onClick={() => {
    //             downloadGalleryImage(m);
    //           }}
    //         />

    //         <img loading="lazy"
    //           className="float-end mr10"
    //           style={{ width: "16px", zIndex: "10", position: "relative" }}
    //           src={Trash}
    //           onClick={() => {
    //             deletePhotoHandler(m.id);
    //           }}
    //         />
    //       </div>
    //     </div>
    //   </div>
    // ));

    // // if (profileImages.length !== 0) {
    // //     tap.push(profileImages[profileImages.length - 1]);
    // // }

    // // tap.push(profileImages ? profileImages.reverse() : null);

    // if (profileImages.length !== 0) {
    //   tap.push(profileImages.reverse());
    // }

    // taparazziArray.map((m) =>
    //   tap.push(
    //     <div
    //       className={`${companyName}-participant-page-taparazzi-item-div participant-page-taparazzi-item-div item`}
    //     >
    //       <div
    //         className={`${companyName}-participant-page-taparazzi-card-div participant-page-taparazzi-card-div`}
    //       >
    //         <div
    //           className={`${companyName}-participant-page-taparazzi-auth-div participant-page-taparazzi-auth-div t-auth-img`}
    //           style={{ height: "400px" }}
    //         >
    //           <img loading="lazy"
    //             src={`${Config.imageApiUrl}/${m.url}`}
    //             alt=""
    //             className={`${companyName}-participant-page-tapparazzi-gallery-img participant-page-tapparazzi-gallery-img responsive`}
    //             style={{ borderColor: buttonBackgroundColor }}
    //           />
    //           <img loading="lazy"
    //             className="float-end mr10"
    //             style={{
    //               width: "20px",
    //               zIndex: "10",
    //               position: "relative",
    //             }}
    //             src={Download}
    //             onClick={() => {
    //                 downloadGalleryImage(m);
    //             }}
    //           />
    //           <img loading="lazy"
    //             className="float-end mr10"
    //             style={{
    //               width: "16px",
    //               zIndex: "10",
    //               position: "relative",
    //             }}
    //             src={Trash}
    //             onClick={() => {
    //               deletePhotoHandler(m.id);
    //             }}
    //           />
    //         </div>
    //       </div>
    //     </div>
    //   )
    // );
    tempData.map((m) =>
      tap.push(
        <div
          className={`${companyName}-participant-page-taparazzi-item-div participant-page-taparazzi-item-div item`}
        >
          <div
            className={`${companyName}-participant-page-taparazzi-card-div participant-page-taparazzi-card-div`}
          >
            <div
              className={`${companyName}-participant-page-taparazzi-auth-div participant-page-taparazzi-auth-div t-auth-img`}
              // style={{ height: "400px" }}
            >
              {/* <LazyLoad key={m.id} style={{height:"100%"}} > */}
              <img
                src={`${Config.imageApiUrl}/${m.url}`}
                alt=""
                // loading="lazy"
                className={`${companyName}-participant-page-tapparazzi-gallery-img participant-page-tapparazzi-gallery-img responsive`}
                style={{ borderColor: buttonBackgroundColor }}
                onLoad={(event) => setOwlStageHeight(event)}
              />
              {/* </LazyLoad> */}

              <div className={"wrapper mt-3"} id={`color_${m.id}`}>
                <div className={`search`} id={`search_${m.id}`}>
                  <div
                    ref={inputRef}
                    className={`menuDiv`}
                    id={`image_${m.id}`}
                  >
                    <img
                      className="float-end mr10"
                      style={{
                        width: "20px",
                        // zIndex: "10",
                        // position: "relative",
                      }}
                      src={Download}
                      onClick={() => {
                        downloadGalleryImage(m);
                      }}
                    />
                    {!loggedIn ? null : (
                      <img
                        loading="lazy"
                        className="float-end mr10"
                        style={{
                          width: "16px",
                          // zIndex: "10",
                          // position: "relative",
                        }}
                        src={Trash}
                        onClick={() => {
                          setDeleteConfirm({ action: true, id: m.id });
                        }}
                      />
                    )}
                  </div>
                  <button
                    className={`uil uil-${isMenuOpen ? "multiply" : "search"}`}
                    onClick={() => {
                      handleMenuClick(m.id);
                    }}
                  >
                    <img loading="lazy" src={DotMenu} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );

    if (tap.length == 0) {
      tap.push(
        companyConfigData?.default_profile_photo ? (
          <div
            className={`${companyName}-participant-page-profile-img-item-div participant-page-profile-img-item-div`}
            key={companyConfigData?.default_profile_photo}
          >
            <div
              className={`${companyName}-participant-page-profile-photo-card-div participant-page-profile-photo-card-div`}
            >
              <div
                className={`${companyName}-participant-page-profile-photo-auth-div participant-page-profile-photo-auth-div`}
              >
                <img
                  className={`${companyName}-participant-page-default-profile-photo participant-page-default-profile-photo responsive`}
                  src={`${Config.imageApiUrl}/${companyConfigData.default_profile_photo}`}
                  onLoad={(event) => setOwlStageHeight(event)}
                />
              </div>
            </div>
          </div>
        ) : null
      );
    }
    setTapparazziItems(tap);
    // setProfilePhoto(profileImages[profileImages.length - 1]);
    getCheckedModuleHandler(response);
    // getContactListHandler(data.company_id, id);
    getCustomButtonsHandler(response);
    // getStationListHandler(data.company_id);
    // setParticipantDetailLoading(false);

    // .catch((error) => {
    //   // console.log(error);
    //   setError(JSON.parse(error.data).statusCode);
    //   setErrorMessage(JSON.parse(error.data).message);
    //   setParticipantDetailLoading(false);
    // });
  };

  const handleMenuClick = (id) => {
    let img = document.getElementById(`image_${id}`);
    let srch = document.getElementById(`search_${id}`);
    let color = document.getElementById(`color_${id}`);
    img.classList.toggle("open");
    srch.classList.toggle("open");
    color.classList.toggle("t-color");
    // setColor(id);
  };

  const downloadGalleryImage = async (m) => {
    let fileName = `${m.nfc_id}.png`;
    var a = document.createElement("a");
    a.href = await getBase64Image(`${Config.imageApiUrl}/${m.url}`); //Image Base64 Goes here
    a.download = fileName; //File name Here
    a.click(); //Downloaded file
  };

  const getBase64Image = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const deletePhotoHandler = (id) => {
    // Swal.fire({
    //   title: "Are you sure you want to delete image? ",
    //   showCancelButton: true,
    //   confirmButtonText: "Delete",
    // }).then((result) => {
    //   if (result.isConfirmed) {
    deletePhoto(deleteConfirm.id)
      .then((response) => {
        getParticipantDetailHandler(props.match.params.id);
      })
      .then((error) => {
        // console.log(error);
      });
    setDeleteConfirm({ action: false, id: 0 });
    //   }
    // });
  };

  const getCustomButtonsHandler = (response) => {
    // console.log("response", response.customButtons);
    setCustomButtonsList(response.customButtons);
  };

  const getCheckedModuleHandler = (response) => {
    let tempData = response.moduleList;
    const checkedModuleListTemp = [];

    let modulesArray = tempData.filter(
      (x) => x.id == 2 || x.id == 3 || x.id == 5
    );

    modulesArray.map((mod, i) => {
      if (mod.id == 2) {
        setCheckInButton(true);
      } else if (mod.id == 3) {
        setTapparazziButton(true);
      } else if (mod.id == 5) {
        setContactShareButton(true);
      } else {
        // console.log("");
      }
    });
  };

  const getStationListHandler = (id) => {
    getCheckInStationByCompanyId(id).then(async (response) => {
      let tempData = response.data;
      await setStationList(response.data);
      const stationListTemp = [];
      // Filter and sort the data based on the user's selections
      if (filterType === "alphaAscending") {
        tempData = response?.data?.sort((a, b) =>
          a?.name.localeCompare(b?.name)
        );
      } else if (filterType === "alphaDescending") {
        tempData = response?.data?.sort((a, b) =>
          b?.name.localeCompare(a?.name)
        );
      } else if (filterType === "lastStation") {
        // Sort by "updated_at" date in descending order
        tempData = response?.data?.sort(
          (a, b) => new Date(b?.updated_at) - new Date(a?.updated_at)
        );
      }
      tempData = tempData?.filter((item) =>
        item?.name?.toLowerCase()?.includes(searchText?.toLowerCase())
      );
      if (tempData?.length > 0) {
        tempData?.map((sta, index) => {
          let tempChecked;

          tempChecked = checkedAt
            ? checkedAt.includes(sta.id)
              ? "Checked in"
              : "Pending"
            : "Pending";

          if (checkedAt.includes(sta.id) && sta.show_station == 1) {
            stationListTemp.push(
              // <div className={`${companyName}-participant-page-checkin-cards participant-page-checkin-cards`}>
              //     <div className={`${companyName}-participant-page-checkin-cards-title participant-page-checkin-cards-title`}>
              //         <div className={`${companyName}-participant-page-checkin-cards-title-text participant-page-checkin-cards-title-text`}>Check-in {sta.name}</div>
              //         <div className={`${companyName}-participant-page-checkin-status participant-page-checkin-status`}>Status: {tempChecked}</div>
              //     </div>
              //     <div className={`${companyName}-participant-page-station-description participant-page-station-description`}>
              //         <div dangerouslySetInnerHTML={{ __html: sta.description }}>
              //         </div>
              //     </div>
              // </div>

              <div
                className={`${companyName}-participant-page-checkin-cards-div participant-page-checkin-cards-div`}
              >
                <div
                  className={`${companyName}-participant-page-checkin-cards-title-div participant-page-checkin-cards-title-div text-center`}
                >
                  <h4
                    className={`${companyName}-participant-page-checkin-cards-title-text participant-page-checkin-cards-title-text`}
                  >
                    Check-in {sta.name}
                  </h4>
                  <h5
                    className={`${companyName}-participant-page-checkin-status participant-page-checkin-status`}
                  >
                    Status: {tempChecked}
                  </h5>
                </div>
                <div
                  className={`${companyName}-participant-page-station-description-div participant-page-station-description-div`}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: sta.description }}
                  ></div>
                </div>
              </div>
            );
          }
          if (index === tempData.length - 1) {
            setStationListDisplay(stationListTemp);
          }
        });
      } else {
        setStationListDisplay([]);
      }
    });
  };

  useEffect(() => {
    if (searchTextRef?.current) {
      setLoading(true);
      const timeoutId = setTimeout(() => {
        getStationListHandler(companyId);
        setLoading(false);
      }, 1000);
      return () => {
        clearTimeout(timeoutId);
      };
    }
    searchTextRef.current = true;
  }, [searchText, filterType]);

  const download = (vCardString, fileName) => {
    const data = new Blob([vCardString.replace(/;CHARSET=UTF-8/g, "")], {
      type: "text/vcard",
    });
    let fileURL = window.URL.createObjectURL(data);
    let fileLink = document.createElement("a");
    fileLink.href = fileURL;
    fileLink.setAttribute("download", `${fileName}.vcf`);
    document.body.appendChild(fileLink);
    fileLink.click();
  };

  const downloadVCard = () => {
    const vCard = vCardsJS();
    let data = JSON.parse(localStorage.getItem("contactDetails"));

    vCard.firstName = data.name;

    if (includeCncList.filter((x) => x.field_name == "last_name")[0]) {
      vCard.lastName = data.last_name;
    }
    if (includeCncList.filter((x) => x.field_name == "email_id")[0]) {
      vCard.email = data.email_id;
    }

    if (includeCncList.filter((x) => x.field_name == "contact")[0]) {
      vCard.cellPhone = data.contact;
    }
    if (includeCncList.filter((x) => x.field_name == "business")[0]) {
      vCard.organization = data.business;
    }
    if (includeCncList.filter((x) => x.field_name == "address")[0]) {
      vCard.workAddress.label = data.address;
    }
    if (includeCncList.filter((x) => x.field_name == "linkedin_id")[0]) {
      vCard.socialUrls["linkedIn"] = data.linkedin_id;
    }
    if (includeCncList.filter((x) => x.field_name == "facebook_id")[0]) {
      vCard.socialUrls["facebook"] = data.facebook_id;
    }
    if (includeCncList.filter((x) => x.field_name == "instagram_id")[0]) {
      vCard.socialUrls["instagram"] = data.instagram_id;
    }
    if (includeCncList.filter((x) => x.field_name == "twitter_id")[0]) {
      vCard.socialUrls["twitter"] = data.twitter_id;
    }
    if (includeCncList.filter((x) => x.field_name == "youtube_id")[0]) {
      vCard.socialUrls["youtube"] = data.youtube_id;
    }
    if (includeCncList.filter((x) => x.field_name == "pintrest_id")[0]) {
      vCard.socialUrls["pinterest"] = data.pintrest_id;
    }
    if (includeCncList.filter((x) => x.field_name == "snapchat_id")[0]) {
      vCard.socialUrls["snapchat"] = data.snapchat_id;
    }
    if (includeCncList.filter((x) => x.field_name == "tiktok_id")[0]) {
      vCard.socialUrls["tiktok"] = data.tiktok_id;
    }
    if (includeCncList.filter((x) => x.field_name == "field_16")[0]) {
      vCard.url = data.field_16;
    }

    const formattedString = vCard.getFormattedString();
    download(formattedString, data.name);

    setDownloadVcardModalFlag(false);
  };

  const downloadSelfVCard = () => {
    const vCard = vCardsJS();
    let data = JSON.parse(localStorage.getItem("contactDetails"));

    vCard.firstName = participantName;

    if (includeCncList.filter((x) => x.field_name == "last_name")[0]) {
      vCard.lastName = data?.last_name ? data?.last_name : "";
    }
    if (emailId?.length) {
      vCard.email = emailId;
    }

    if (typeof contact !== null || typeof contact !== undefined) {
      vCard.cellPhone = contact ? contact : "";
    }
    // if ()) {
    //   vCard.organization = data.business;
    // }
    if (address?.length) {
      vCard.workAddress.label = address;
    }
    if (linkedIn?.length) {
      vCard.socialUrls["linkedIn"] = linkedIn;
    }
    if (facebookId?.length) {
      vCard.socialUrls["facebook"] = facebookId;
    }
    if (instagramId?.length) {
      vCard.socialUrls["instagram"] = instagramId;
    }
    if (twitterId) {
      vCard.socialUrls["twitter"] = twitterId;
    }
    if (youtubeId?.length) {
      vCard.socialUrls["youtube"] = youtubeId;
    }
    if (pintrestId?.length) {
      vCard.socialUrls["pinterest"] = pintrestId;
    }
    if (snapchatId) {
      vCard.socialUrls["snapchat"] = snapchatId;
    }
    if (tiktokId?.length) {
      vCard.socialUrls["tiktok"] = tiktokId;
    }
    if (field16?.length) {
      vCard.url = data.field_16;
    }

    const formattedString = vCard.getFormattedString();
    download(formattedString, participantName);

    setDownloadVcardModalFlag(false);
  };
  const getContactListHandler = async (companyId, participantId) => {
    let includeCncArray = [];

    await getParticipantCompanyFieldsById(companyId).then((response) => {
      let tempData = response.data;
      includeCncArray = tempData.filter((x) => x.include_in_cc);
      setIncludeCncList(tempData.filter((x) => x.include_in_cc));
    });

    getContactListByNfcId(participantId)
      .then(async (response) => {
        let tempData = response.data;

        let includeCncNameArray = includeCncArray.map((x) => x.field_name);

        const contactListTemp = [];

        tempData.map((con, index) => {
          const contactRowsTemp = [];
          let newUrl = window.decodeURIComponent(con.facebook_id);
          includeCncNameArray.map((row) => {
            if (
              row.toLowerCase() == "business" &&
              con.business !== "" &&
              con.business !== null
            ) {
              contactRowsTemp.push(
                <div className="container ">
                  {includeCncArray.filter((x) => x.field_name == row)[0]
                    .field_icon &&
                  includeCncArray.filter((x) => x.field_name == row)[0]
                    .field_icon != "" ? (
                    <img
                      loading="lazy"
                      style={{ height: "30px" }}
                      src={`${
                        includeCncArray.filter((x) => x.field_name == row)[0]
                          .field_icon
                      }`}
                    />
                  ) : (
                    // <BusinessTwoToneIcon />
                    <img loading="lazy" src={Business} width="24px" />
                  )}
                  {/* <img loading="lazy"  style={{height:"30px"}} src={`${Config.imageApiUrl}/${includeCncArray.filter(x => x.field_name == row)[0].field_icon}`} /> */}
                  {/* <BusinessTwoToneIcon /> */}
                  <span
                    className={`${companyName}-participant-page-card-body-text participant-page-card-body-text`}
                  >
                    <a
                      href=""
                      style={{ textDecoration: "none", pointerEvents: "none" }}
                    >
                      {con.business.length > 25
                        ? con.business.substring(0, 25) + "..."
                        : con.business}
                    </a>
                  </span>
                </div>
              );
            } else if (
              row.toLowerCase() == "contact" &&
              con.contact !== "" &&
              con.contact !== null
            ) {
              contactRowsTemp.push(
                <div className="container ">
                  {includeCncArray.filter((x) => x.field_name == row)[0]
                    .field_icon &&
                  includeCncArray.filter((x) => x.field_name == row)[0]
                    .field_icon != "" ? (
                    <img
                      loading="lazy"
                      style={{ height: "30px" }}
                      src={`${
                        includeCncArray.filter((x) => x.field_name == row)[0]
                          .field_icon
                      }`}
                    />
                  ) : (
                    // <CallIcon />
                    <img loading="lazy" src={Phone} width="24px" />
                  )}
                  {/* <CallIcon /> */}
                  {/* <img loading="lazy"  style={{height:"30px"}} src={`${Config.imageApiUrl}/${includeCncArray.filter(x => x.field_name == row)[0].field_icon}`} /> */}
                  <span
                    className={`${companyName}-participant-page-card-body-text participant-page-card-body-text`}
                  >
                    <a
                      href={`tel:${con.contact}`}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      {con.contact}
                    </a>
                  </span>
                </div>
              );
            } else if (
              row.toLowerCase() == "email_id" &&
              con.email_id !== "" &&
              con.email_id !== null
            ) {
              contactRowsTemp.push(
                <div className="container ">
                  {includeCncArray.filter((x) => x.field_name == row)[0]
                    .field_icon &&
                  includeCncArray.filter((x) => x.field_name == row)[0]
                    .field_icon != "" ? (
                    <img
                      loading="lazy"
                      style={{ height: "30px" }}
                      src={`${
                        includeCncArray.filter((x) => x.field_name == row)[0]
                          .field_icon
                      }`}
                    />
                  ) : (
                    // <MailIcon />
                    <img loading="lazy" src={Email} width="24px" />
                  )}
                  {/* <MailIcon /> */}
                  {/* <img loading="lazy"  style={{height:"30px"}} src={`${Config.imageApiUrl}/${includeCncArray.filter(x => x.field_name == row)[0].field_icon}`} /> */}
                  <span
                    className={`${companyName}-participant-page-card-body-text participant-page-card-body-text`}
                  >
                    <a
                      href={`mailto:${con.email_id}`}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      {con.email_id.length > 25
                        ? con.email_id.substring(0, 25) + "..."
                        : con.email_id}
                    </a>
                  </span>
                </div>
              );
            } else if (
              row.toLowerCase() == "facebook_id" &&
              con.facebook_id !== "" &&
              con.facebook_id !== null
            ) {
              contactRowsTemp.push(
                <div className="container ">
                  {includeCncArray.filter((x) => x.field_name == row)[0]
                    .field_icon &&
                  includeCncArray.filter((x) => x.field_name == row)[0]
                    .field_icon != "" ? (
                    <img
                      loading="lazy"
                      style={{ height: "30px" }}
                      src={`${
                        includeCncArray.filter((x) => x.field_name == row)[0]
                          .field_icon
                      }`}
                    />
                  ) : (
                    <FontAwesomeIcon icon={faFacebook} size="lg" />
                  )}
                  {/* <FontAwesomeIcon icon={faFacebook} size="lg" /> */}
                  {/* <img loading="lazy"  style={{height:"30px"}} src={`${Config.imageApiUrl}/${includeCncArray.filter(x => x.field_name == row)[0].field_icon}`} /> */}

                  <span
                    className={`${companyName}-participant-page-card-body-text participant-page-card-body-text`}
                  >
                    <a
                      href={
                        /^(http|https)?:?\/\//i.test(con.facebook_id)
                          ? con.facebook_id.replace(
                              /^(https|http)?:?\/\//,
                              "//"
                            )
                          : "//" + con.facebook_id
                      }
                      target="__blank"
                      style={{ textDecoration: "none" }}
                    >
                      {con.facebook_id.length > 25
                        ? con.facebook_id.substring(0, 25) + "..."
                        : con.facebook_id}
                    </a>
                  </span>
                </div>
              );
            } else if (
              row.toLowerCase() == "instagram_id" &&
              con.instagram_id !== "" &&
              con.instagram_id !== null
            ) {
              contactRowsTemp.push(
                <div className="container ">
                  {includeCncArray.filter((x) => x.field_name == row)[0]
                    .field_icon &&
                  includeCncArray.filter((x) => x.field_name == row)[0]
                    .field_icon != "" ? (
                    <img
                      loading="lazy"
                      style={{ height: "30px" }}
                      src={`${
                        includeCncArray.filter((x) => x.field_name == row)[0]
                          .field_icon
                      }`}
                    />
                  ) : (
                    <FontAwesomeIcon icon={faInstagram} size="lg" />
                  )}
                  {/* <FontAwesomeIcon icon={faInstagram} size="lg" /> */}
                  {/* <img loading="lazy"  style={{height:"30px"}} src={`${Config.imageApiUrl}/${includeCncArray.filter(x => x.field_name == row)[0].field_icon}`} /> */}
                  <span
                    className={`${companyName}-participant-page-card-body-text participant-page-card-body-text`}
                  >
                    <a
                      href={
                        /^(http|https)?:?\/\//i.test(con.instagram_id)
                          ? con.instagram_id.replace(
                              /^(https|http)?:?\/\//,
                              "//"
                            )
                          : "//" + con.instagram_id
                      }
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      {con.instagram_id.length > 25
                        ? con.instagram_id.substring(0, 25) + "..."
                        : con.instagram_id}
                    </a>
                  </span>
                </div>
              );
            } else if (
              row.toLowerCase() == "youtube_id" &&
              con.youtube_id !== "" &&
              con.youtube_id !== null
            ) {
              contactRowsTemp.push(
                <div className="container ">
                  {includeCncArray.filter((x) => x.field_name == row)[0]
                    .field_icon &&
                  includeCncArray.filter((x) => x.field_name == row)[0]
                    .field_icon != "" ? (
                    <img
                      loading="lazy"
                      style={{ height: "30px" }}
                      src={`${
                        includeCncArray.filter((x) => x.field_name == row)[0]
                          .field_icon
                      }`}
                    />
                  ) : (
                    <FontAwesomeIcon icon={faYoutube} size="lg" />
                  )}
                  {/* <FontAwesomeIcon icon={faYoutube} size="lg" /> */}
                  {/* <img loading="lazy"  style={{height:"30px"}} src={`${Config.imageApiUrl}/${includeCncArray.filter(x => x.field_name == row)[0].field_icon}`} /> */}
                  <span
                    className={`${companyName}-participant-page-card-body-text participant-page-card-body-text`}
                  >
                    <a
                      href={
                        /^(http|https)?:?\/\//i.test(con.youtube_id)
                          ? con.youtube_id.replace(/^(https|http)?:?\/\//, "//")
                          : "//" + con.youtube_id
                      }
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      {con.youtube_id.length > 25
                        ? con.youtube_id.substring(0, 25) + "..."
                        : con.youtube_id}
                    </a>
                  </span>
                </div>
              );
            } else if (
              row.toLowerCase() == "twitter_id" &&
              con.twitter_id !== "" &&
              con.twitter_id !== null
            ) {
              contactRowsTemp.push(
                <div className="container ">
                  {includeCncArray.filter((x) => x.field_name == row)[0]
                    .field_icon &&
                  includeCncArray.filter((x) => x.field_name == row)[0]
                    .field_icon != "" ? (
                    <img
                      loading="lazy"
                      style={{ height: "30px" }}
                      src={`${
                        includeCncArray.filter((x) => x.field_name == row)[0]
                          .field_icon
                      }`}
                    />
                  ) : (
                    <FontAwesomeIcon icon={faTwitter} size="lg" />
                  )}
                  {/* <FontAwesomeIcon icon={faTwitter} size="lg" /> */}
                  {/* <img loading="lazy"  style={{height:"30px"}} src={`${Config.imageApiUrl}/${includeCncArray.filter(x => x.field_name == row)[0].field_icon}`} /> */}
                  <span
                    className={`${companyName}-participant-page-card-body-text participant-page-card-body-text`}
                  >
                    <a
                      href={
                        /^(http|https)?:?\/\//i.test(con.twitter_id)
                          ? con.twitter_id.replace(/^(https|http)?:?\/\//, "//")
                          : "//" + con.twitter_id
                      }
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      {con.twitter_id.length > 25
                        ? con.twitter_id.substring(0, 25) + "..."
                        : con.twitter_id}
                    </a>
                  </span>
                </div>
              );
            } else if (
              row.toLowerCase() == "linkedin_id" &&
              con.linkedin_id !== "" &&
              con.linkedin_id !== null
            ) {
              contactRowsTemp.push(
                <div className="container ">
                  {includeCncArray.filter((x) => x.field_name == row)[0]
                    .field_icon &&
                  includeCncArray.filter((x) => x.field_name == row)[0]
                    .field_icon != "" ? (
                    <img
                      loading="lazy"
                      style={{ height: "30px" }}
                      src={`${
                        includeCncArray.filter((x) => x.field_name == row)[0]
                          .field_icon
                      }`}
                    />
                  ) : (
                    <LinkedInIcon />
                  )}
                  {/* <LinkedInIcon /> */}
                  {/* <img loading="lazy"  style={{height:"30px"}} src={`${Config.imageApiUrl}/${includeCncArray.filter(x => x.field_name == row)[0].field_icon}`} /> */}
                  <span
                    className={`${companyName}-participant-page-card-body-text participant-page-card-body-text`}
                  >
                    <a
                      href={
                        /^(http|https)?:?\/\//i.test(con.linkedin_id)
                          ? con.linkedin_id.replace(
                              /^(https|http)?:?\/\//,
                              "//"
                            )
                          : "//" + con.linkedin_id
                      }
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      {con.linkedin_id.length > 25
                        ? con.linkedin_id.substring(0, 25) + "..."
                        : con.linkedin_id}
                    </a>
                  </span>
                </div>
              );
            } else if (
              row.toLowerCase() == "pintrest_id" &&
              con.pintrest_id !== "" &&
              con.pintrest_id !== null
            ) {
              contactRowsTemp.push(
                <div className="container ">
                  {includeCncArray.filter((x) => x.field_name == row)[0]
                    .field_icon &&
                  includeCncArray.filter((x) => x.field_name == row)[0]
                    .field_icon != "" ? (
                    <img
                      loading="lazy"
                      style={{ height: "30px" }}
                      src={`${
                        includeCncArray.filter((x) => x.field_name == row)[0]
                          .field_icon
                      }`}
                    />
                  ) : (
                    <FontAwesomeIcon icon={faPinterest} size="lg" />
                  )}
                  {/* <FontAwesomeIcon icon={faPinterest} size="lg" /> */}
                  {/* <img loading="lazy"  style={{height:"30px"}} src={`${Config.imageApiUrl}/${includeCncArray.filter(x => x.field_name == row)[0].field_icon}`} /> */}
                  <span
                    className={`${companyName}-participant-page-card-body-text participant-page-card-body-text`}
                  >
                    <a
                      href={
                        /^(http|https)?:?\/\//i.test(con.pintrest_id)
                          ? con.pintrest_id.replace(
                              /^(https|http)?:?\/\//,
                              "//"
                            )
                          : "//" + con.pintrest_id
                      }
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      {con.pintrest_id.length > 25
                        ? con.pintrest_id.substring(0, 25) + "..."
                        : con.pintrest_id}
                    </a>
                  </span>
                </div>
              );
            } else if (
              row.toLowerCase() == "snapchat_id" &&
              con.snapchat_id !== "" &&
              con.snapchat_id !== null
            ) {
              contactRowsTemp.push(
                <div className="container ">
                  {includeCncArray.filter((x) => x.field_name == row)[0]
                    .field_icon &&
                  includeCncArray.filter((x) => x.field_name == row)[0]
                    .field_icon != "" ? (
                    <img
                      loading="lazy"
                      style={{ height: "30px" }}
                      src={`${
                        includeCncArray.filter((x) => x.field_name == row)[0]
                          .field_icon
                      }`}
                    />
                  ) : (
                    <FontAwesomeIcon icon={faSnapchat} size="lg" />
                  )}

                  {/* <FontAwesomeIcon icon={faSnapchat} size="lg" /> */}
                  {/* <img loading="lazy"  style={{height:"30px"}} src={`${Config.imageApiUrl}/${includeCncArray.filter(x => x.field_name == row)[0].field_icon}`} /> */}
                  <span
                    className={`${companyName}-participant-page-card-body-text participant-page-card-body-text`}
                  >
                    <a
                      href={
                        /^(http|https)?:?\/\//i.test(con.snapchat_id)
                          ? con.snapchat_id.replace(
                              /^(https|http)?:?\/\//,
                              "//"
                            )
                          : "//" + con.snapchat_id
                      }
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      {con.snapchat_id.length > 25
                        ? con.snapchat_id.substring(0, 25) + "..."
                        : con.snapchat_id}
                    </a>
                  </span>
                </div>
              );
            } else if (
              row.toLowerCase() == "tiktok_id" &&
              con.tiktok_id !== "" &&
              con.tiktok_id !== null
            ) {
              contactRowsTemp.push(
                <div className="container ">
                  {includeCncArray.filter((x) => x.field_name == row)[0]
                    .field_icon &&
                  includeCncArray.filter((x) => x.field_name == row)[0]
                    .field_icon != "" ? (
                    <img
                      loading="lazy"
                      style={{ height: "30px" }}
                      src={`${
                        includeCncArray.filter((x) => x.field_name == row)[0]
                          .field_icon
                      }`}
                    />
                  ) : (
                    <FontAwesomeIcon icon={faTiktok} size="lg" />
                  )}

                  <span
                    className={`${companyName}-participant-page-card-body-text participant-page-card-body-text`}
                  >
                    <a
                      href={
                        /^(http|https)?:?\/\//i.test(con.tiktok_id)
                          ? con.tiktok_id.replace(/^(https|http)?:?\/\//, "//")
                          : "//" + con.tiktok_id
                      }
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      {con.tiktok_id.length > 25
                        ? con.tiktok_id.substring(0, 25) + "..."
                        : con.tiktok_id}
                    </a>
                  </span>
                </div>
              );
            } else if (
              row.toLowerCase() == "field_16" &&
              con.field_16 !== "" &&
              con.field_16 !== null
            ) {
              contactRowsTemp.push(
                <div className="container ">
                  {includeCncArray.filter((x) => x.field_name == row)[0]
                    .field_icon &&
                  includeCncArray.filter((x) => x.field_name == row)[0]
                    .field_icon != "" ? (
                    <img
                      loading="lazy"
                      style={{ height: "30px" }}
                      src={`${
                        includeCncArray.filter((x) => x.field_name == row)[0]
                          .field_icon
                      }`}
                    />
                  ) : (
                    <LanguageIcon />
                  )}

                  {/* <FontAwesomeIcon icon={faSnapchat} size="2x" /> */}

                  <span
                    className={`${companyName}-participant-page-card-body-text participant-page-card-body-text`}
                  >
                    <a
                      href={
                        /^(http|https)?:?\/\//i.test(con.field_16)
                          ? con.field_16.replace(/^(https|http)?:?\/\//, "//")
                          : "//" + con.field_16
                      }
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      {con.field_16.length > 25
                        ? con.field_16.substring(0, 25) + "..."
                        : con.field_16}
                    </a>
                  </span>
                </div>
              );
            } else if (
              row.toLowerCase() == "field_17" &&
              con.field_17 !== "" &&
              con.field_17 !== null
            ) {
              contactRowsTemp.push(
                <div className="container ">
                  <img
                    loading="lazy"
                    style={{ height: "30px" }}
                    src={`${
                      includeCncArray.filter((x) => x.field_name == row)[0]
                        .field_icon
                    }`}
                  />
                  <span
                    className={`${companyName}-participant-page-card-body-text participant-page-card-body-text`}
                  >
                    <a
                      href={
                        /^(http|https)?:?\/\//i.test(con.field_17)
                          ? con.field_17.replace(/^(https|http)?:?\/\//, "//")
                          : "//" + con.field_17
                      }
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      {con.field_17.length > 25
                        ? con.field_17.substring(0, 25) + "..."
                        : con.field_17}
                    </a>
                  </span>
                </div>
              );
            } else if (
              row.toLowerCase() == "field_18" &&
              con.field_18 !== "" &&
              con.field_18 !== null
            ) {
              contactRowsTemp.push(
                <div className="container ">
                  <img
                    loading="lazy"
                    style={{ height: "30px" }}
                    src={`${
                      includeCncArray.filter((x) => x.field_name == row)[0]
                        .field_icon
                    }`}
                  />
                  <span
                    className={`${companyName}-participant-page-card-body-text participant-page-card-body-text`}
                  >
                    <a
                      href={
                        /^(http|https)?:?\/\//i.test(con.field_18)
                          ? con.field_18.replace(/^(https|http)?:?\/\//, "//")
                          : "//" + con.field_18
                      }
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      {con.field_18.length > 25
                        ? con.field_18.substring(0, 25) + "..."
                        : con.field_18}
                    </a>
                  </span>
                </div>
              );
            } else if (
              row.toLowerCase() == "field_19" &&
              con.field_19 !== "" &&
              con.field_19 !== null
            ) {
              contactRowsTemp.push(
                <div className="container ">
                  <img
                    loading="lazy"
                    style={{ height: "30px" }}
                    src={`${
                      includeCncArray.filter((x) => x.field_name == row)[0]
                        .field_icon
                    }`}
                  />
                  <span
                    className={`${companyName}-participant-page-card-body-text participant-page-card-body-text`}
                  >
                    <a
                      href={
                        /^(http|https)?:?\/\//i.test(con.field_19)
                          ? con.field_19.replace(/^(https|http)?:?\/\//, "//")
                          : "//" + con.field_19
                      }
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      {con.field_19.length > 25
                        ? con.field_19.substring(0, 25) + "..."
                        : con.field_19}
                    </a>
                  </span>
                </div>
              );
            } else if (
              row.toLowerCase() == "field_20" &&
              con.field_20 !== "" &&
              con.field_20 !== null
            ) {
              contactRowsTemp.push(
                <div className="container ">
                  <img
                    loading="lazy"
                    style={{ height: "30px" }}
                    src={`${
                      includeCncArray.filter((x) => x.field_name == row)[0]
                        .field_icon
                    }`}
                  />
                  <span
                    className={`${companyName}-participant-page-card-body-text participant-page-card-body-text`}
                  >
                    <a
                      href={
                        /^(http|https)?:?\/\//i.test(con.field_20)
                          ? con.field_20.replace(/^(https|http)?:?\/\//, "//")
                          : "//" + con.field_20
                      }
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      {con.field_20.length > 25
                        ? con.field_20.substring(0, 25) + "..."
                        : con.field_20}
                    </a>
                  </span>
                </div>
              );
            } else if (
              row.toLowerCase() == "field_21" &&
              con.field_21 !== "" &&
              con.field_21 !== null
            ) {
              contactRowsTemp.push(
                <div className="container ">
                  <img
                    loading="lazy"
                    style={{ height: "30px" }}
                    src={`${
                      includeCncArray.filter((x) => x.field_name == row)[0]
                        .field_icon
                    }`}
                  />
                  <span
                    className={`${companyName}-participant-page-card-body-text participant-page-card-body-text`}
                  >
                    <a
                      href={
                        /^(http|https)?:?\/\//i.test(con.field_21)
                          ? con.field_21.replace(/^(https|http)?:?\/\//, "//")
                          : "//" + con.field_21
                      }
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      {con.field_21.length > 25
                        ? con.field_21.substring(0, 25) + "..."
                        : con.field_21}
                    </a>
                  </span>
                </div>
              );
            } else if (
              row.toLowerCase() == "field_22" &&
              con.field_22 !== "" &&
              con.field_22 !== null
            ) {
              contactRowsTemp.push(
                <div className="container ">
                  <img
                    loading="lazy"
                    style={{ height: "30px" }}
                    src={`${
                      includeCncArray.filter((x) => x.field_name == row)[0]
                        .field_icon
                    }`}
                  />
                  <span
                    className={`${companyName}-participant-page-card-body-text participant-page-card-body-text`}
                  >
                    <a
                      href={
                        /^(http|https)?:?\/\//i.test(con.field_22)
                          ? con.field_22.replace(/^(https|http)?:?\/\//, "//")
                          : "//" + con.field_22
                      }
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      {con.field_22.length > 25
                        ? con.field_22.substring(0, 25) + "..."
                        : con.field_22}
                    </a>
                  </span>
                </div>
              );
            } else if (
              row.toLowerCase() == "field_23" &&
              con.field_23 !== "" &&
              con.field_23 !== null
            ) {
              contactRowsTemp.push(
                <div className="container ">
                  <img
                    loading="lazy"
                    style={{ height: "30px" }}
                    src={`${
                      includeCncArray.filter((x) => x.field_name == row)[0]
                        .field_icon
                    }`}
                  />
                  <span
                    className={`${companyName}-participant-page-card-body-text participant-page-card-body-text`}
                  >
                    <a
                      href={
                        /^(http|https)?:?\/\//i.test(con.field_23)
                          ? con.field_23.replace(/^(https|http)?:?\/\//, "//")
                          : "//" + con.field_23
                      }
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      {con.field_23.length > 25
                        ? con.field_23.substring(0, 25) + "..."
                        : con.field_23}
                    </a>
                  </span>
                </div>
              );
            } else if (
              row.toLowerCase() == "field_24" &&
              con.field_24 !== "" &&
              con.field_24 !== null
            ) {
              contactRowsTemp.push(
                <div className="container ">
                  <img
                    loading="lazy"
                    style={{ height: "30px" }}
                    src={`${
                      includeCncArray.filter((x) => x.field_name == row)[0]
                        .field_icon
                    }`}
                  />
                  <span
                    className={`${companyName}-participant-page-card-body-text participant-page-card-body-text`}
                  >
                    <a
                      href={
                        /^(http|https)?:?\/\//i.test(con.field_24)
                          ? con.field_24.replace(/^(https|http)?:?\/\//, "//")
                          : "//" + con.field_24
                      }
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      {con.field_24.length > 25
                        ? con.field_24.substring(0, 25) + "..."
                        : con.field_24}
                    </a>
                  </span>
                </div>
              );
            } else if (
              row.toLowerCase() == "field_25" &&
              con.field_25 !== "" &&
              con.field_25 !== null
            ) {
              contactRowsTemp.push(
                <div className="container">
                  <img
                    loading="lazy"
                    style={{ height: "30px" }}
                    src={`${
                      includeCncArray.filter((x) => x.field_name == row)[0]
                        .field_icon
                    }`}
                  />
                  <span
                    className={`${companyName}-participant-page-card-body-text participant-page-card-body-text`}
                  >
                    <a
                      href={
                        /^(http|https)?:?\/\//i.test(con.field_25)
                          ? con.field_25.replace(/^(https|http)?:?\/\//, "//")
                          : "//" + con.field_25
                      }
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      {con.field_25.length > 25
                        ? con.field_25.substring(0, 25) + "..."
                        : con.field_25}
                    </a>
                  </span>
                </div>
              );
            }
          });
          contactListTemp.push(
            <div
              className={`${companyName}-participant-page-contact-cards participant-page-contact-cards`}
            >
              <div
                className={`${companyName}-participant-page-contact-cards-title participant-page-contact-cards-title text-center`}
              >
                <h4
                  className={`${companyName}-participant-page-contact-cards-title-text participant-page-contact-cards-title-text`}
                >
                  {con.name} {con.last_name}
                </h4>
              </div>
              <div
                className={`${companyName}-participant-page-contact-detail-div participant-page-contact-detail-div`}
              >
                {contactRowsTemp}
              </div>
              <div className="container">
                {/* <ContactMail /> */}
                <img loading="lazy" src={Vcard} width="24px" />
                <span
                  className={`${companyName}-participant-page-contact-fields participant-page-contact-fields`}
                >
                  {/* <a href="" onClick={() => { localStorage.setItem("contactDetails", JSON.stringify(con)); setDownloadVcardModalFlag(true) }} style={{ textDecoration: "none" }}>
                                             Download this vCard on your Phone </a> */}
                  <button
                    className={`${companyName}-participant-page-download-vcard-button participant-page-download-vcard-button btn btn-link`}
                    style={{ textDecoration: "none" }}
                    onClick={() => {
                      localStorage.setItem(
                        "contactDetails",
                        JSON.stringify(con)
                      );
                      setDownloadVcardModalFlag(true);
                    }}
                  >
                    Download vCard
                  </button>
                </span>
              </div>
              <div className="container">
                <img loading="lazy" src={Notes} />
                <span
                  className={`${companyName}-participant-page-contact-fields participant-page-contact-fields`}
                >
                  {/* <a href="" onClick={() => { localStorage.setItem("contactDetails", JSON.stringify(con)); setDownloadVcardModalFlag(true) }} style={{ textDecoration: "none" }}>
                                             Download this vCard on your Phone </a> */}
                  <button
                    className={`${companyName}-participant-page-add-notes-button participant-page-add-notes-button btn btn-link`}
                    style={{ textDecoration: "none" }}
                    onClick={() => {
                      localStorage.setItem("notes", JSON.stringify(con));
                      getNotes();
                    }}
                  >
                    Add/View Notes
                  </button>
                </span>
              </div>
            </div>
          );
          if (index === tempData.length - 1) {
            setFullContactListDisplay(contactListTemp);
            setContactListDisplay(contactListTemp.slice(0, 10));
          }
        });
      })
      .catch((error) => {
        // console.log("contactlist", error);
      });
  };

  const downloadContactCsv = () => {
    let contactFieldsLabel = [];
    contactFieldsLabel = includeCncList.map(({ label, field_name }) => ({
      label,
      field_name,
    }));

    getContactListByNfcId(participantId).then(async (response) => {
      let tempData = response.data;

      const contactListTemp = [];
      tempData.map((con, index) => {
        let tempObj = {};

        contactFieldsLabel.map((x) => {
          tempObj[x.label] = con[x.field_name];
        });

        tempObj.notes = con.notes ? con.notes : "";
        contactListTemp.push(tempObj);
      });

      const options = {
        fieldSeparator: ",",
        filename: "contacts",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: true,
        title: "Contacts List",
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
      };

      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(contactListTemp);
    });
  };

  const textFieldChangeHandler = (e) => {
    let { name, value } = e.target;

    switch (name) {
      case "email":
        setEmail(value);
        setEmailError("");

        break;

      case "notes":
        setNotes(value);

        break;

      default:
      // console.log("");
    }
  };

  const buttonClickHandler = (e) => {
    let { name } = e.target;
    switch (name) {
      case "contactListButton":
        window["gtag"]("event", companyName, {
          nfc_id: participantId,
          event_name: "total_clicks_view_my_contacts",
        });
        setViewContactModalFlag(true);
        // document.getElementById("contactsListDiv").style.display = loggedIn ? "block" : "none"
        // document.getElementById("tapparazziDiv").style.display = "none"
        break;

      case "stationListButton":
        window["gtag"]("event", companyName, {
          nfc_id: participantId,
          event_name: "total_clicks_view_checkin_history",
        });
        setCheckInStatusModalFlag(true);
        // document.getElementById("contactsListDiv").style.display = "none"
        // document.getElementById("tapparazziDiv").style.display = "none"
        break;

      default:
      // console.log("");
    }
  };

  const participantLoginHandler = () => {
    if (
      email === "" ||
      !Boolean(email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
    ) {
      if (
        email === "" ||
        !Boolean(email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
      ) {
        setEmailError("Please enter valid email");
      }
    } else {
      let tempObj = {
        email: email.toLowerCase(),
        nfc_tag_id: participantId,
      };

      participantLogin(tempObj)
        .then((response) => {
          window["gtag"]("event", companyName, {
            nfc_id: participantId,
            event_name: "total_logins_connect_collect",
          });
          setLoggedIn(true);
          setParticipantLoginModalFlag(false);
          sessionStorage.setItem("participantLoginToken", response.data.token);
          localStorage.setItem("participantToken", response.data.token);
          localStorage.setItem("participantNfcId", participantId);
          localStorage.setItem("companyName", companyName);
        })
        .catch((error) => {
          setEmailError(
            "Invalid email address. Please make sure you entered the correct email address. Note that your entry is case sensitive."
          );
          setEmail("");
          // setError(error.message)
        });
    }
  };

  const participantLogoutHandler = () => {
    setLoggedIn(false);
    localStorage.clear();
    sessionStorage.clear();
  };

  const headerClickHandler = () => {
    window["gtag"]("event", companyName, {
      nfc_id: participantId,
      event_name: "total_clicks_header",
    });
  };

  const sponsorClickHandler = () => {
    window["gtag"]("event", companyName, {
      nfc_id: participantId,
      event_name: "total_clicks_sponsor",
    });
  };

  const responsive = {
    0: { items: 1 },
    375: { items: 1 },
    1024: { items: 3 },
  };

  const clickEditProfilePhoto = () => {
    let node = document.getElementById("profilePhoroEditInput");
    node.click();
  };

  const onChangeProfilePhotoHandler = (e) => {
    let { file } = {
      file: null,
      base64URL: "",
    };
    file = e.target.files[0];
    e.target.value = "";

    if (file.size > 10000000) {
      setProfilePhotoSnackbarFlag(true);
    } else {
      new Compressor(file, {
        quality: 0.4,

        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        success(compressFile) {
          getBase64(compressFile)
            .then((result) => {
              file["base64"] = result;
              // setLogoImg(result);

              let tempObj = {
                nfc_ids: [participantId],
                base64: result,
                is_gallery: true,
                is_taparazzi: false,
              };
              setLoaderFlag(true);
              uploadPhotosPublic(tempObj)
                .then((response) => {
                  setLoaderFlag(false);
                  let url =
                    Config.frontEndUrl +
                    "/" +
                    companyName +
                    "/" +
                    participantId;
                  window.open(url, "_parent");
                })
                .catch((error) => {
                  // console.log(error);
                });
            })
            .catch((err) => {
              // console.log(err);
            });
        },
        error(err) {
          // console.log(err.message);
        },
      });
      // getBase64(file)
      //   .then((result) => {
      //     file["base64"] = result;
      //     // setLogoImg(result);

      //     let tempObj = {
      //       nfc_ids: [participantId],
      //       base64: result,
      //       is_gallery: true,
      //       is_taparazzi: false,
      //     };
      //     setLoaderFlag(true);
      //     uploadPhotosPublic(tempObj)
      //       .then((response) => {
      //         setLoaderFlag(false);
      //         let url =
      //           Config.frontEndUrl + "/" + companyName + "/" + participantId;
      //         window.open(url, "_parent");
      //       })
      //       .catch((error) => {
      //         // console.log(error);
      //       });
      //   })
      //   .catch((err) => {
      //     // console.log(err);
      //   });
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const openSideNavHandler = () => {
    let node = document.getElementById("sidebar-nav");
    node.classList.toggle("shownav");
    let node1 = document.getElementById("menu-toggle-btn");
    node1.classList.toggle("close-toggle");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      participantLoginHandler();
    }
  };

  const scrollToTop = () => {
    closeButtonRef.current.focus();
  };

  const getNotes = () => {
    let data = JSON.parse(localStorage.getItem("notes"));
    setNotes(data.notes);
    setAddViewNotesModalFlag(true);
  };

  const submitNotes = () => {
    if (notes == "" || notes == null) {
      // console.log("error");
    } else {
      setLoaderFlag(true);
      let data = JSON.parse(localStorage.getItem("notes"));

      let tempObj = {
        participant_nfc_id: participantId,
        contact_nfc_id: data.nfc_tag_id,
        notes: notes,
      };

      addNotes(tempObj)
        .then((response) => {
          setNotes("");
          setAddViewNotesModalFlag(false);
          setLoaderFlag(false);
          getContactListHandler(companyId, participantId);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };
  const isIPhone = /iPhone/i.test(navigator.userAgent);

  const handleCloseClick = () => {
    // Copy the current URL to the clipboard
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        console.log("URL copied to clipboard");
      })
      .catch((error) => {
        console.error("Failed to copy URL to clipboard:", error);
      });
    setPrivateTab(false);
  };

  const options = {
    items: 1, // Number of items to display
    nav: true, // Display navigation buttons
    dots: false, // Display dots for navigation
    loop: true, // Loop the carousel
    autoHeight: true,
    autoplay: true,
    autoplayTimeout: 4000, // Delay between slides in milliseconds (e.g., 2000ms = 2 seconds)
    animateOut: "animate__rotateInDownRight",
    // animateIn: "animate__bounce",

    // navText: [`<img   src=${ArrowLeft}>`, `<img   src=${ArrowRight}>`],
  };
  return (
    <div>
      {isNone ? (
        <div id="notfound">
          <div className="notfound-bg"></div>
          <div className="notfound">
            <div className="notfound-404">
              <h1>503</h1>
            </div>
            <h2>
              we are sorry, but the Participant Detail you requested was not
              found
            </h2>
            {/* <h2>{errorMessage}</h2> */}
          </div>
        </div>
      ) : !isRegistered ? (
        <div
          className={`${companyName}-participant-page-registration-div participant-page-registration-div`}
        >
          <RegistrationWithoutNfc
            participantId={participantId}
            companyName={companyName}
            companyId={props.match.params.id}
            companyName1={props.match.params.companyName}
            registerButtonText={registerButtonText}
            isRegistered={isRegistered1}
            setIsRegistered1={setIsRegistered1}
          />
        </div>
      ) : (
        <div
          className={`${companyName}-participant-page-main-div participant-page-main-div`}
          style={{ backgroundColor: backgroundColor }}
        >
          <header
            className={`${companyName}-participant-page-header-bar participant-page-header-bar`}
          >
            <div
              className={`${companyName}-participant-page-menu-button-div participant-page-menu-button-div d-flex`}
            >
              <button
                type="button"
                className={`${companyName}-participant-page-menu-button participant-page-menu-button menu-toggle-btn`}
                id="menu-toggle-btn"
                onClick={openSideNavHandler}
              ></button>
              <h5
                className={`${companyName}-participant-page-menu-text participant-page-menu-text`}
              >
                Menu
              </h5>
            </div>

            <div
              className={`${companyName}-participant-page-login-button-div participant-page-login-button-div`}
            >
              {!loggedIn ? (
                <button
                  type="button"
                  className={`${companyName}-participant-page-login-button participant-page-login-button`}
                  onClick={() => {
                    setParticipantLoginModalFlag(true);
                  }}
                >
                  Login
                </button>
              ) : (
                <button
                  type="button"
                  className={`${companyName}-participant-page-logout-button participant-page-logout-button`}
                  onClick={() => participantLogoutHandler()}
                >
                  Logout
                </button>
              )}
            </div>
          </header>
          <aside
            className={`${companyName}-participant-page-sidebar-aside participant-page-sidebar-aside`}
            id="sidebar-nav"
            ref={closeSideMenu}
          >
            <div
              className={`${companyName}-participant-page-sidebar-header-div participant-page-sidebar-header-div`}
            >
              <ul
                className={`${companyName}-participant-page-sidebar-header-ul participant-page-sidebar-header-ul`}
              >
                {loggedIn ? (
                  <li
                    className={`${companyName}-participant-page-sidebar-profile-li participant-page-sidebar-profile-li`}
                  >
                    <div
                      className={`${companyName}-participant-page-sidebar-profile-div participant-page-sidebar-profile-div nav-icon`}
                    >
                      <img
                        loading="lazy"
                        className={`${companyName}-participant-page-sidebar-profile-img participant-page-sidebar-profile-img`}
                        src={EditIcon}
                        alt=""
                        title=""
                      />
                    </div>

                    <button
                      className={`${companyName}-participant-page-sidebar-profile-button participant-page-sidebar-profile-button btn`}
                      type="button"
                      id="editProfileButton"
                      onClick={() => {
                        setIsRegistered(true);
                        setRegisterButtonText("Update");
                      }}
                    >
                      Edit Profile
                    </button>
                  </li>
                ) : null}

                {loggedIn && enableProfilePhotoEdit ? (
                  <li
                    className={`${companyName}-participant-page-sidebar-profile-photo-li participant-page-sidebar-profile-photo-li`}
                  >
                    <div
                      className={`${companyName}-participant-page-sidebar-profile-photo-div participant-page-sidebar-profile-photo-div nav-icon`}
                    >
                      <img
                        loading="lazy"
                        src={NavIcon1}
                        className={`${companyName}-participant-page-sidebar-profile-photo-img participant-page-sidebar-profile-photow-img`}
                        alt=""
                        title=""
                      />
                    </div>

                    <button
                      className={`${companyName}-participant-page-sidebar-profile-photo-button participant-page-sidebar-profile-photo-button btn`}
                      type="button"
                      id="editProfilePhotoButton"
                      onClick={() => {
                        clickEditProfilePhoto();
                      }}
                    >
                      Upload/Take Photo
                    </button>
                  </li>
                ) : null}

                {checkInButton ? (
                  <li
                    data-bs-toggle="modal"
                    data-bs-target="#cinHistoryModal"
                    className={`${companyName}-participant-page-sidebar-checkin-li participant-page-sidebar-checkin-li`}
                  >
                    <div
                      className={`${companyName}-participant-page-sidebar-checkin-div participant-page-sidebar-checkin-div nav-icon`}
                    >
                      <img
                        loading="lazy"
                        src={CheckIn}
                        className={`${companyName}-participant-page-sidebar-checkin-img participant-page-sidebar-checkin-img`}
                        alt=""
                        title=""
                      />
                    </div>

                    <button
                      className={`${companyName}-participant-page-sidebar-checkin-button participant-page-sidebar-checkin-button btn`}
                      type="button"
                      name="stationListButton"
                      // onClick={()=>{props.history.push('/participant/checkInHistory')}}
                      onClick={(e) => {
                        buttonClickHandler(e);
                        getStationListHandler(companyId);
                      }}
                    >
                      Check-in History
                    </button>
                  </li>
                ) : null}

                {contactShareButton ? (
                  <li
                    className={`${companyName}-participant-page-sidebar-contact-li participant-page-sidebar-contact-li`}
                  >
                    <div
                      className={`${companyName}-participant-page-sidebar-contact-div participant-page-sidebar-contact-div nav-icon`}
                    >
                      <img
                        loading="lazy"
                        src={Contact}
                        className={`${companyName}-participant-page-sidebar-contact-img participant-page-sidebar-contact-img`}
                        alt=""
                        title=""
                      />
                    </div>
                    {!loggedIn ? (
                      <button
                        className={`${companyName}-participant-page-sidebar-contact-login-button participant-page-sidebar-contact-login-button btn`}
                        type="button"
                        id="loginButton"
                        onClick={(e) => {
                          setParticipantLoginModalFlag(true);
                        }}
                      >
                        Connect & Collect
                      </button>
                    ) : (
                      <button
                        className={`${companyName}-participant-page-sidebar-viewcontact-button participant-page-sidebar-viewcontact-button btn`}
                        type="button"
                        name="contactListButton"
                        onClick={(e) => {
                          buttonClickHandler(e);
                          getContactListHandler(companyId, participantId);
                        }}
                      >
                        View My Contacts
                      </button>
                    )}
                  </li>
                ) : null}
              </ul>
            </div>
            <div
              className={`${companyName}-participant-page-sidebar-info-div participant-page-sidebar-info-div`}
            >
              Please login using your email address in order to access the menu
            </div>
            <div
              className={`${companyName}-participant-page-sidebar-footer-div participant-page-sidebar-footer-div`}
            >
              <a
                className={`${companyName}-participant-page-sidebar-privacy-a participant-page-sidebar-privacy-a`}
                href="#"
              >
                Privacy Policy
              </a>
              <a
                className={`${companyName}-participant-page-sidebar-terms-a participant-page-sidebar-terms-a`}
                href="#"
              >
                Terms and Conditions
              </a>
            </div>
          </aside>
          <section
            className={`${companyName}-participant-page-logo-section participant-page-logo-section banner-wrap`}
          >
            <div
              className={`${companyName}-participant-page-logo-outer-div participant-page-logo-outer-div`}
            >
              <a
                href={logoUrl ? logoUrl : ""}
                onClick={headerClickHandler}
                target="_blank"
                rel="noreferrer"
                className={`${companyName}-participant-page-logo-a participant-page-logo-a`}
              >
                <div
                  className={`${companyName}-participant-page-logo-div participant-page-logo-div`}
                >
                  {
                    companyConfigloading ? (
                      <div
                        className={`${companyName}-participant-page-logo-spinner-div participant-page-logo-spinner-div d-flex justify-content-center`}
                      >
                        <ClipLoader
                          color={spinnerColor}
                          loading={companyConfigloading}
                          size={50}
                        />
                      </div>
                    ) : logoImg ? (
                      <img
                        loading="lazy"
                        className={`${companyName}-participant-page-logo-img participant-page-logo-img responsive`}
                        src={`${Config.imageApiUrl}/` + logoImg}
                        // src={logoImg ? logoImg : LogoPlaceholder }
                      ></img>
                    ) : (
                      <img
                        loading="lazy"
                        className={`${companyName}-participant-page-default-logo participant-page-default-logo responsive`}
                      ></img>
                    )
                    // <ClipLoader className="d-flex justify-content-center" color={spinnerColor} loading={loading}  size={150} />
                  }
                </div>
              </a>
            </div>
          </section>
          <section
            className={`${companyName}-participant-page-carousel-section participant-page-carousel-section`}
          >
            {Boolean(tapparazziItems?.length) && tapparazziItems[0] != null && (
              <OwlCarousel
                className={`${companyName}-participant-page-carousel participant-page-carousel owl-theme autoHeightClass`}
                {...carouselOptions}
                onInitialized={setOwlStageHeight}
                onResized={setOwlStageHeight}
                onTranslated={setOwlStageHeight}
              >
                {tapparazziItems}
              </OwlCarousel>
            )}
          </section>
          <div
            className={`${companyName}-participant-page-social-icons-div participant-page-social-icons-div`}
          >
            {socialMediaIconDisplayList}
          </div>
          <div
            className={`${companyName}-participant-page-name-div participant-page-name-div`}
          >
            <span
              className={`${companyName}-participant-page-hello-span participant-page-hello-span`}
            >
              Hello{" "}
            </span>
            <span
              className={`${companyName}-participant-page-name-span participant-page-name-span`}
            >
              {participantName ? participantName : null}
            </span>

            <p
              className={`${companyName}-participant-page-description-p participant-page-description-p`}
            >
              <div
                className={`${companyName}-participant-page-description participant-page-description text-wrap text-break`}
                // style={{ color: textColor }}
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
            </p>
          </div>
          <section
            className={`${companyName}-participant-page-btm-section participant-page-btm-section`}
          >
            <div
              className={`${companyName}-participant-page-button-div participant-page-button-div`}
            >
              {customButtonsList.map((btn, index) => {
                return (
                  <a
                    className={
                      `${companyName}-participant-page-viewcontact-${btn.label
                        ?.trim()
                        ?.replace(/\s+/g, "-")}-button`
                      // participant-page-${btn.label
                      // ?.trim()
                      // ?.replace(/\s+/g, "-")}-button`
                    }
                    style={{
                      color: buttonTextColor,
                      backgroundColor: buttonBackgroundColor,
                    }}
                    type="button"
                    href={`${btn.url}?${
                      btn.query_param_1 == "Event Id"
                        ? `event_id=${companyName}`
                        : btn.query_param_1 == "NFC Id"
                        ? `nfc_id=${participantId}`
                        : ""
                    }${btn.query_param_1 && btn.query_param_2 ? `&` : ""}${
                      btn.query_param_2 == "Event Id"
                        ? `event_id=${companyName}`
                        : btn.query_param_2 == "NFC Id"
                        ? `nfc_id=${participantId}`
                        : ""
                    }`}
                    target={btn.windows == "1" ? "_blank" : "_self"}
                    rel="noreferrer"
                  >
                    {btn.label}
                  </a>
                );
              })}
              {enableContactButton ? (
                <button
                  className={`${companyName}-participant-page-checkin-button participant-page-checkin-button`}
                  type="button"
                  name="stationListButton"
                  // onClick={()=>{props.history.push('/participant/checkInHistory')}}
                  onClick={(e) => {
                    setSaveContactModal(true);
                  }}
                  style={{
                    color: buttonTextColor,
                    backgroundColor: buttonBackgroundColor,
                    display: "block",
                  }}
                >
                  Save/Share Contact
                </button>
              ) : null}
              {checkInButton ? (
                <button
                  className={`${companyName}-participant-page-checkin-button participant-page-checkin-button`}
                  type="button"
                  name="stationListButton"
                  // onClick={()=>{props.history.push('/participant/checkInHistory')}}
                  onClick={(e) => {
                    buttonClickHandler(e);
                    getStationListHandler(companyId);
                  }}
                  style={{
                    color: buttonTextColor,
                    backgroundColor: buttonBackgroundColor,
                    display: "block",
                  }}
                >
                  View My Check-in History
                </button>
              ) : null}

              {contactShareButton ? (
                !loggedIn ? (
                  <button
                    className={`${companyName}-participant-page-contact-login-button participant-page-contact-login-button`}
                    type="button"
                    id="loginButton"
                    onClick={(e) => {
                      setParticipantLoginModalFlag(true);
                    }}
                    style={{
                      color: buttonTextColor,
                      backgroundColor: buttonBackgroundColor,
                      display: "block",
                    }}
                  >
                    Connect & Collect
                  </button>
                ) : (
                  <button
                    className={`${companyName}-participant-page-viewcontact-button participant-page-viewcontact-button`}
                    type="button"
                    name="contactListButton"
                    onClick={(e) => {
                      buttonClickHandler(e);
                      getContactListHandler(companyId, participantId);
                    }}
                    style={{
                      color: buttonTextColor,
                      backgroundColor: buttonBackgroundColor,
                      display: "block",
                    }}
                  >
                    View My Contacts
                  </button>
                )
              ) : null}
            </div>
            <div
              className={`${companyName}-participant-page-sponsor-logo-outer-div participant-page-sponsor-logo-outer-div btm-logo`}
            >
              <div
                className={`${companyName}-participant-page-sponsor-logo-div participant-page-sponsor-logo-div`}
              >
                {/* <a
                  className={`${companyName}-participant-page-sponsor-logo-a participant-page-sponsor-logo-a`}
                  href={sponsorUrl ? sponsorUrl : ""}
                  onClick={sponsorClickHandler}
                  target="_blank"
                > */}
                {/* <div className="logoImg" style={sponsorImg ? { backgroundImage: `url(${"http://52.140.79.232:4089/files/" + sponsorImg})` } : { backgroundColor: "#fab791" }}></div> */}
                {/* {sponsorImg ? (
                    <img
                      loading="lazy"
                      className={`${companyName}-participant-page-footer-img participant-page-footer-img`}
                      src={`${Config.imageApiUrl}/${sponsorImg}`}
                    />
                  ) : (
                    <img
                      loading="lazy"
                      className={`${companyName}-participant-page-footer-default-img participant-page-footer-default-img`}
                      style={{
                        width: "100%",
                        backgroundColor: backgroundColor,
                      }}
                    ></img>
                  )} */}

                {sponsorImages?.length && (
                  <SponsorList
                    options={optionsSponsor}
                    sponsorImages={sponsorImages}
                  />
                )}
                {/* </a> */}
              </div>
            </div>
          </section>

          <Modal
            open={participantLoginModalFlag}
            disableBackdropClick={false}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div
              className={`${companyName}-participant-page-login-modal participant-page-login-modal modal blue-modal fade`}
              id="addInsuranceModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                className={`${companyName}-participant-page-login-modal-dialog participant-page-login-modal-dialog modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable`}
              >
                <div
                  className={`${companyName}-participant-page-login-modal-content participant-page-login-modal-content modal-content`}
                >
                  <div
                    className={`${companyName}-participant-page-login-modal-header participant-page-login-modal-header modal-header`}
                  >
                    <h5
                      className={`${companyName}-participant-page-login-modal-title participant-page-login-modal-title modal-title`}
                      id="exampleModalLabel"
                    >
                      Login
                    </h5>
                  </div>

                  <div
                    className={`${companyName}-participant-page-login-modal-warning-div participant-page-login-modal-warning-div`}
                  >
                    <h5
                      className={`${companyName}-participant-page-login-modal-warning participant-page-login-modal-warning`}
                    >
                      Connect and collect or check-in will not work in private
                      browser mode. When you tap an NFC contact to connect or a
                      sign for check-in, please make sure to tap and open the
                      notification link on your phone in order to collect their
                      contact information or check-in.
                    </h5>
                  </div>
                  <div
                    className={`${companyName}-participant-page-login-modal-body-div participant-page-login-modal-body-div modal-body`}
                  >
                    <div
                      className={`${companyName}-participant-page-login-modal-insbody-div participant-page-login-modal-insbody-div ins-modal-body`}
                    >
                      <form
                        action=""
                        className={`${companyName}-participant-page-login-modal-form participant-page-login-modal-form`}
                      >
                        <div
                          className={`${companyName}-participant-page-login-modal-row-div participant-page-login-modal-row-div row`}
                        >
                          <div
                            className={`${companyName}-participant-page-login-modal-email-col-div participant-page-login-modal-email-col-div col-sm-6`}
                          >
                            <div className="form-group gray-fill-input mr-b30">
                              <label
                                htmlFor=""
                                className={`${companyName}-participant-page-login-modal-email-title participant-page-login-modal-email-title`}
                              >
                                Email
                              </label>
                              <input
                                type="text"
                                className={`${companyName}-participant-page-login-modal-email-input participant-page-login-modal-email-input form-control`}
                                name="email"
                                id=""
                                value={email}
                                onChange={textFieldChangeHandler}
                                placeholder="Email"
                                onKeyPress={(e) => {
                                  handleKeyPress(e);
                                }}
                              />
                              {emailError !== "" ? (
                                <p
                                  className={`${companyName}-participant-page-login-modal-email-error participant-page-login-modal-email-error error-alert mr-b20`}
                                >
                                  {emailError}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          {/* <div className="col-sm-6">
                                                <div className="form-group gray-fill-input mr-b30">
                                                    <label htmlFor="">NFC ID</label>
                                                    <input type="text" className="form-control" name="nfcId" id="" disabled
                                                        value={participantId}
                                                        // onChange={textFieldChangeHandler}
                                                        placeholder="NFC ID" />
                                                    
                                                </div>
                                            </div> */}
                        </div>
                      </form>
                    </div>
                  </div>
                  {error !== "" ? (
                    <p
                      className={`${companyName}-participant-page-login-modal-error participant-page-login-modal-error error-alert mr-b20 mr-l40`}
                    >
                      {error}
                    </p>
                  ) : null}
                  <div
                    className={`${companyName}-participant-page-login-modal-footer-div participant-page-login-modal-footer-div modal-footer pd-b30`}
                  >
                    <button
                      type="button"
                      className={`${companyName}-participant-page-login-modal-cancel-button participant-page-login-modal-cancel-button s-blue-border-btn m-btn`}
                      data-dismiss="modal"
                      onClick={() => {
                        setParticipantLoginModalFlag(false);
                        setEmail("");
                        setEmailError("");
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className={`${companyName}-participant-page-login-modal-submit-button participant-page-login-modal-submit-button s-blue-btn m-btn`}
                      data-dismiss="modal"
                      id="addParticipantSubmit"
                      onClick={(e) => participantLoginHandler(e)}
                    >
                      {/* {inspectionId ? 'Update' : 'Add'} */}
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            open={downloadVcardModalFlag}
            disableBackdropClick={false}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div
              className="modal blue-modal fade"
              id="addInsuranceModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Confirmation
                    </h5>
                  </div>
                  <div className="modal-body">
                    Would you like to download this vCard ? Once the file is
                    downloaded please tap on the file, locate and tap on the
                    share icon and then choose to add it to your contacts.
                  </div>

                  <div className="modal-footer pd-b30">
                    <button
                      type="button"
                      className="s-blue-border-btn m-btn"
                      data-dismiss="modal"
                      onClick={() => setDownloadVcardModalFlag(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="s-blue-btn m-btn"
                      onClick={() => {
                        downloadVCard();
                        setDownloadVcardModalFlag(false);
                      }}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            open={downloadVcardModalFlag}
            disableBackdropClick={false}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div
              className="modal blue-modal fade"
              id="addInsuranceModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Confirmation
                    </h5>
                  </div>
                  <div className="modal-body">
                    Would you like to download this vCard ? Once the file is
                    downloaded please tap on the file, locate and tap on the
                    share icon and then choose to add it to your contacts.
                  </div>

                  <div className="modal-footer pd-b30">
                    <button
                      type="button"
                      className="s-blue-border-btn m-btn"
                      data-dismiss="modal"
                      onClick={() => setDownloadVcardModalFlag(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="s-blue-btn m-btn"
                      onClick={() => {
                        downloadVCard();
                        setDownloadVcardModalFlag(false);
                      }}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            open={confirmDownload}
            disableBackdropClick={false}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div
              className="modal blue-modal fade"
              id="addInsuranceModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Confirmation
                    </h5>
                  </div>
                  <div className="modal-body">
                    Would you like to download this vCard ? Once the file is
                    downloaded please tap on the file, locate and tap on the
                    share icon and then choose to add it to your contacts.
                  </div>

                  <div className="modal-footer pd-b30">
                    <button
                      type="button"
                      className="s-blue-border-btn m-btn"
                      data-dismiss="modal"
                      onClick={() => setConfirmDownload(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="s-blue-btn m-btn"
                      onClick={() => {
                        downloadSelfVCard();
                        setConfirmDownload(false);
                      }}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            open={addViewNotesModalFlag}
            disableBackdropClick={false}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div
              className={`${companyName}-participant-page-contact-modal participant-page-contact-modal modal blue-modal fade`}
              id="addInsuranceModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                className={`${companyName}-participant-page-assign-modal-dialog participant-page-assign-modal-dialog modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable`}
              >
                <div
                  className={`${companyName}-participant-page-assign-modal-content participant-page-assign-modal-content modal-content`}
                >
                  <div
                    className={`${companyName}-participant-page-assign-modal-header participant-page-assign-modal-header modal-header`}
                  >
                    <h5
                      className={`${companyName}-participant-page-assign-modal-title participant-page-assign-modal-title modal-title`}
                      id="exampleModalLabel"
                    >
                      Add / View Notes
                    </h5>
                  </div>
                  <div
                    className={`${companyName}-participant-page-assign-modal-body participant-page-assign-modal-body modal-body`}
                  >
                    {/* Add / View Notes */}
                    <textarea
                      name="notes"
                      value={notes}
                      onChange={textFieldChangeHandler}
                      placeholder="Add Notes"
                      cols="50"
                      rows="4"
                    ></textarea>
                  </div>

                  <div
                    className={`${companyName}-participant-page-assign-modal-footer participant-page-assign-modal-footer modal-footer pd-b30`}
                  >
                    <button
                      type="button"
                      className={`${companyName}-participant-page-assign-cancel-btn participant-page-assign-cancel-btn s-blue-border-btn m-btn`}
                      data-dismiss="modal"
                      onClick={() => {
                        setNotes("");
                        setAddViewNotesModalFlag(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className={`${companyName}-participant-page-assign-add-btn participant-page-assign-add-btn s-blue-btn m-btn`}
                      onClick={() => {
                        submitNotes();
                      }}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            open={stationNfcTagModalFlag}
            disableBackdropClick={false}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div
              className="modal blue-modal fade"
              id="addInsuranceModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Check-in error
                    </h5>
                  </div>
                  <div className="modal-body mr-b40">
                    In order to check-in you will need to login into your
                    profile page. Please tap your NFC band with your smartphone.
                    Tap on the notification URL and once you open your profile
                    page please tap on the login and enter your email address.
                    Please note that this feature will not work in private
                    browser mode.
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            open={viewContactModalFlag}
            disableBackdropClick={false}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div
              className={`${companyName}-participant-page-contact-modal participant-page-contact-modal modal blue-modal fade `}
              id="addInsuranceModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                className={`${companyName}-participant-page-contact-modal-dialog participant-page-contact-modal-dialog modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable `}
              >
                <div
                  className={`${companyName}-participant-page-contact-modal-content participant-page-contact-modal-content modal-content modal-icon modal-margin-top-30`}
                >
                  <div class="action-sheetstyled__Header-card__sc-1n3yh67-4 jDqeQC">
                    <div class="elevation__Elevation-card__sc-kxnq68-0 action-sheetstyled__Avatar-card__sc-1n3yh67-0 etbhvz iyheF mdc-elevation--z6">
                      <img
                        loading="lazy"
                        src={ViewContacts}
                        alt=""
                        style={{ width: 50 }}
                        className=""
                      />
                    </div>
                    <button
                      type="button"
                      className={`${companyName}-participant-page-contact-modal-title-button  participant-page-contact-modal-title-button btn-close`}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setViewContactModalFlag(false);
                      }}
                      ref={closeButtonRef}
                    >
                      <img
                        loading="lazy"
                        className={`${companyName}-participant-page-contact-modal-title-button-img  participant-page-contact-modal-title-button-img`}
                        src={Close}
                        alt=""
                      />
                    </button>
                  </div>
                  <div
                    className={`${companyName}-participant-page-contact-modal-body participant-page-contact-modal-body modal-body`}
                    id="stationListDiv"
                  >
                    {/* <div className="cirle-icon d-flex align-items-center">
                      <div className="cirle text-center">
                        <img loading="lazy" 
                          src={ViewContacts}
                          alt=""
                          style={{ width: 50 }}
                          className="mt-2"
                        />
                      </div>
                    </div> */}

                    <div
                      className={`${companyName}-participant-page-contact-modal-title  participant-page-contact-modal-title`}
                    >
                      <h4
                        className={`${companyName}-participant-page-contact-modal-title-text  participant-page-contact-modal-title-text`}
                      >
                        Contacts
                      </h4>
                    </div>

                    <div
                      className={`${companyName}-participant-page-contact-card-div participant-page-contact-card-div`}
                    >
                      {loadMore ? (
                        <div
                          className={`${companyName}-participant-page-full-contact-list-section-div participant-page-full-contact-list-section-div`}
                        >
                          <div
                            className={`${companyName}-participant-page-full-contact-list-div participant-page-full-contact-list-div`}
                          >
                            {fullContactListDisplay}
                          </div>
                          <div
                            className={`${companyName}-participant-page-showless-contact-button-div participant-page-showless-contact-button-div`}
                          >
                            <button
                              className="participant-page-contact-download-csv-button"
                              onClick={() => {
                                downloadContactCsv();
                              }}
                            >
                              Download contact history CSV
                            </button>
                            <button
                              className={`${companyName}-participant-page-showless-contact-button participant-page-showless-contact-button`}
                              onClick={() => setLoadMore(false)}
                            >
                              Click to show less
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div
                          className={`${companyName}-participant-page-less-contact-list-section-div participant-page-less-contact-list-section-div`}
                        >
                          <div
                            className={`${companyName}-participant-page-less-contact-list-div participant-page-less-contact-list-div`}
                          >
                            {contactListDisplay}
                          </div>
                          <div
                            className={`${companyName}-participant-page-showmore-contact-button-div participant-page-showmore-contact-button-div`}
                          >
                            <button
                              className="participant-page-contact-download-csv-button"
                              onClick={() => {
                                downloadContactCsv();
                              }}
                            >
                              Download contact history CSV
                            </button>
                            <button
                              className={`${companyName}-participant-page-showmore-contact-button participant-page-showmore-contact-button`}
                              onClick={() => setLoadMore(true)}
                            >
                              Expand to show all
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    className={`${companyName}-participant-page-scrolltop-div participant-page-scrolltop-div`}
                  >
                    <ScrollTop scrollToTop={scrollToTop} />
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            open={saveContactModal}
            disableBackdropClick={false}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div
              className={`${companyName}-participant-page-contact-modal participant-page-contact-modal modal blue-modal fade`}
              id="addInsuranceModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                className={`${companyName}-participant-page-contact-modal-dialog participant-page-contact-modal-dialog modal-dialog modal656 modal-dialog-end modal-dialog-scrollable`}
              >
                <div
                  className={`${companyName}-participant-page-contact-modal-content participant-page-contact-modal-content modal-content modal-icon`}
                >
                  <div class="action-sheetstyled__Header-card__sc-1n3yh67-4 jDqeQC">
                    <div class="elevation__Elevation-card__sc-kxnq68-0 action-sheetstyled__Avatar-card__sc-1n3yh67-0 etbhvz iyheF mdc-elevation--z6">
                      <img
                        loading="lazy"
                        src={ViewContacts}
                        alt=""
                        style={{ width: 50 }}
                        className=""
                      />
                    </div>
                    <button
                      type="button"
                      className={`${companyName}-participant-page-contact-modal-title-button  participant-page-contact-modal-title-button btn-close`}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setSaveContactModal(false);
                      }}
                      ref={closeButtonRef}
                    >
                      <img
                        loading="lazy"
                        className={`${companyName}-participant-page-contact-modal-title-button-img  participant-page-contact-modal-title-button-img`}
                        src={Close}
                        alt=""
                      />
                    </button>
                  </div>
                  <div
                    className={`${companyName}-participant-page-contact-modal-body participant-page-contact-modal-body modal-body`}
                    id="stationListDiv"
                  >
                    {" "}
                    <div
                      className={`${companyName}-participant-page-contact-modal-title  participant-page-contact-modal-title`}
                    >
                      {/* <h4
                        className={`${companyName}-participant-page-contact-modal-title-text  participant-page-contact-modal-title-text`}
                      >
                        Contacts
                      </h4> */}
                      {/* <button
                        type="button"
                        className={`${companyName}-participant-page-contact-modal-title-button  participant-page-contact-modal-title-button btn-close`}
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          setSaveContactModal(false);
                        }}
                        ref={closeButtonRef}
                      >
                        <img loading="lazy" 
                          className={`${companyName}-participant-page-contact-modal-title-button-img  participant-page-contact-modal-title-button-img`}
                          src={Close}
                          alt=""
                        />
                      </button> */}
                    </div>
                    <div
                      className={`${companyName}-participant-page-contact-card-div participant-page-contact-card-div`}
                    >
                      <button
                        className={`${companyName}-participant-page-checkin-button participant-page-checkin-button`}
                        type="button"
                        name="stationListButton"
                        // onClick={()=>{props.history.push('/participant/checkInHistory')}}
                        onClick={(e) => {
                          isIPhone
                            ? downloadSelfVCard()
                            : setConfirmDownload(true);
                        }}
                        style={{
                          color: buttonTextColor,
                          backgroundColor: buttonBackgroundColor,
                          display: "block",
                        }}
                      >
                        ADD TO CONTACTS
                      </button>
                      <div class="line mt-2">
                        <span class="text">OR</span>
                      </div>
                      <button
                        className={`${companyName}-participant-page-checkin-button participant-page-checkin-button mt-3`}
                        type="button"
                        name="stationListButton"
                        // onClick={()=>{props.history.push('/participant/checkInHistory')}}
                        onClick={(e) => {
                          setQrCode(true);
                          setSaveContactModal(false);
                        }}
                        style={{
                          color: buttonTextColor,
                          backgroundColor: buttonBackgroundColor,
                          display: "block",
                        }}
                      >
                        Share QR Contact{" "}
                      </button>
                    </div>
                  </div>

                  <div
                    className={`${companyName}-participant-page-scrolltop-div participant-page-scrolltop-div`}
                  >
                    {/* <ScrollTop scrollToTop={scrollToTop} /> */}
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            open={showQrCode}
            disableBackdropClick={false}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div
              className={`${companyName}-participant-page-contact-modal participant-page-contact-modal modal blue-modal fade`}
              id="addInsuranceModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                className={`${companyName}-participant-page-contact-modal-dialog participant-page-contact-modal-dialog modal-dialog modal656 modal-dialog-end modal-dialog-scrollable`}
              >
                <div
                  className={`${companyName}-participant-page-contact-modal-content participant-page-contact-modal-content modal-content`}
                >
                  <div
                    className={`${companyName}-participant-page-contact-modal-body participant-page-contact-modal-body modal-body`}
                    id="stationListDiv"
                  >
                    <div
                      className={`${companyName}-participant-page-contact-modal-title  participant-page-contact-modal-title`}
                    >
                      {/* <h4
                        className={`${companyName}-participant-page-contact-modal-title-text  participant-page-contact-modal-title-text`}
                      >
                        Contacts
                      </h4> */}
                      <button
                        type="button"
                        className={`${companyName}-participant-page-contact-modal-title-button  participant-page-contact-modal-title-button btn-close`}
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          setSaveContactModal(true);
                          setQrCode(false);
                        }}
                        ref={closeButtonRef}
                      >
                        <img
                          loading="lazy"
                          className={`${companyName}-participant-page-contact-modal-title-button-img  participant-page-contact-modal-title-button-img`}
                          src={Close}
                          alt=""
                        />
                      </button>
                    </div>

                    <div
                      className={`${companyName}-participant-page-contact-card-div participant-page-contact-card-div text-center`}
                    >
                      <div>
                        <h3>Scan to share your page </h3>
                      </div>
                      <div className=" d-flex justify-content-center mt-4">
                        <div className="qr-code">
                          <QRCode
                            size={150}
                            style={{
                              height: "auto",
                              maxWidth: "100%",
                              width: "100%",
                            }}
                            value={window.location.href}
                            viewBox={`0 0 256 256`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`${companyName}-participant-page-scrolltop-div participant-page-scrolltop-div`}
                  >
                    {/* <ScrollTop scrollToTop={scrollToTop} /> */}
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            open={checkInStatusModalFlag}
            disableBackdropClick={false}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div
              className={`${companyName}-participant-page-checkin-modal participant-page-checkin-modal modal blue-modal fade`}
              id="addInsuranceModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                className={`${companyName}-participant-page-checkin-modal-dialog participant-page-checkin-modal-dialog modal-dialog modal656 modal-dialog-end modal-dialog-scrollable`}
              >
                <div
                  className={`${companyName}-participant-page-checkin-modal-content participant-page-checkin-modal-content modal-content modal-icon`}
                >
                  <div class="action-sheetstyled__Header-card__sc-1n3yh67-4 jDqeQC">
                    <div className="d-flex">
                      {" "}
                      <img
                        loading="lazy"
                        src={ViewHistory1}
                        alt=""
                        style={{ width: 35, cursor: "pointer" }}
                        onClick={() => setFilterIcon(!filterIcon)}
                        // className="cursor"
                      />
                    </div>
                    <div class="elevation__Elevation-card__sc-kxnq68-0 action-sheetstyled__Avatar-card__sc-1n3yh67-0 etbhvz iyheF mdc-elevation--z6">
                      <img
                        loading="lazy"
                        src={ViewHistory}
                        alt=""
                        style={{ width: 50 }}
                        className=""
                      />
                    </div>
                    <button
                      type="button"
                      className={`${companyName}-participant-page-checkin-modal-close-button participant-page-checkin-modal-close-button btn-close`}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setCheckInStatusModalFlag(false);
                      }}
                    >
                      <img
                        loading="lazy"
                        className={`${companyName}-participant-page-checkin-modal-close-button-img participant-page-checkin-modal-close-button-img`}
                        src={Close}
                        alt=""
                      />
                    </button>
                  </div>
                  {/* <div className="cirle-icon d-flex align-items-center">
                    <div className="cirle text-center">
                      <img loading="lazy" 
                        src={ViewHistory}
                        alt=""
                        style={{ width: 50 }}
                        className="mt-2"
                      />
                    </div>
                  </div> */}

                  <div
                    className={`${companyName}-participant-page-checkin-modal-body participant-page-checkin-modal-body modal-body pt-0`}
                    id="stationListDiv"
                  >
                    <div
                      className="sticky-top pt-2 bg-white"
                      style={{ zIndex: "1" }}
                    >
                      <div
                        className={`${companyName}-participant-page-checkin-modal-title participant-page-checkin-modal-title`}
                      >
                        <h4
                          className={`${companyName}-participant-page-checkin-modal-title-text participant-page-checkin-modal-title-text`}
                        >
                          Check-in History
                        </h4>
                      </div>

                      {filterIcon && (
                        <div className="search-filter">
                          <div className="row d-flex justify-content-end">
                            <div className="col-sm-4 mb-3">
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Search"
                                  value={searchText}
                                  onChange={(e) => {
                                    setSearchText(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-sm-4 mb-3">
                              <select
                                class="form-select"
                                value={filterType}
                                onChange={(e) => {
                                  setFilterType(e.target.value);
                                }}
                              >
                                <option selected>Select....</option>
                                <option value="lastStation">
                                  Last Station Visited
                                </option>
                                <option value="alphaAscen ding">
                                  Alpha Ascending
                                </option>
                                <option value="alphaDescending">
                                  Alpha Descending
                                </option>
                              </select>
                            </div>
                            <div className="col-sm-2 mb-3">
                              <button
                                type="button"
                                class="btn btn-primary w-100"
                                onClick={() => {
                                  setFilterType("");
                                  setSearchText("");
                                }}
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {loading && (
                      <Box
                        sx={{ width: "100%", color: "red", my: 1 }}
                        spacing={2}
                      >
                        <LinearProgress />
                      </Box>
                    )}
                    {stationListDisplay.length ? (
                      stationListDisplay
                    ) : (
                      <div
                        className={`${companyName}-participant-page-checkin-modal-nodata-div participant-page-checkin-modal-nodata-div`}
                      >
                        <div
                          className={`${companyName}-participant-page-checkin-modal-nodata-text participant-page-checkin-modal-nodata-text`}
                        >
                          No Data History Available
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            open={deleteConfirm.action}
            disableBackdropClick={false}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div
              className="modal blue-modal fade"
              id="addInsuranceModal"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Confirmation
                    </h5>
                  </div>
                  <div className="modal-body">
                    Are you sure you want to delete image?
                  </div>

                  <div className="modal-footer pd-b30">
                    <button
                      type="button"
                      className="s-blue-border-btn m-btn"
                      data-dismiss="modal"
                      onClick={() => setDeleteConfirm({ action: false, id: 0 })}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="s-blue-btn m-btn"
                      onClick={() => {
                        deletePhotoHandler();
                      }}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <Snackbar
            open={profilePhotoSnackbarFlag}
            autoHideDuration={6000}
            onClose={() => setProfilePhotoSnackbarFlag(false)}
          >
            <Alert
              onClose={() => setProfilePhotoSnackbarFlag(false)}
              severity="success"
            >
              File Size is over 10 mb. Please upload a image of less than 10 mb
            </Alert>
          </Snackbar>
          <input
            name="profilePhoroEditInput"
            type="file"
            accept=".png, .jpg, .jpeg"
            id="profilePhoroEditInput"
            className="mr-l20 mr-t20"
            style={{ display: "none" }}
            onChange={(event) => onChangeProfilePhotoHandler(event)}
          />
          {loaderFlag ? <div className="loading">Loading&#8230;</div> : null}
        </div>
      )}
      <Modal
        open={privateTab && Boolean(privateTabMessage)}
        disableBackdropClick={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className="modal blue-modal fade"
          id="addInsuranceModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable private-modal-dialog">
            <div
              className={`${companyName}-modal-content modal-content private-modal-content`}
            >
              {/* <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Confirmation
                    </h5>
                  </div> */}
              <div
                className={`${companyName}-modal-body modal-body private-modal-body`}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: privateTabMessage,
                  }}
                ></div>
              </div>

              <div className="modal-footer pd-b30">
                <button
                  type="button"
                  className="s-blue-border-btn m-btn"
                  data-dismiss="modal"
                  onClick={() => setPrivateTab(false)}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="s-blue-btn m-btn"
                  onClick={() => {
                    handleCloseClick();
                  }}
                >
                  Copy URL
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={stationInfo?.is_checkout_enable}
        disableBackdropClick={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className="modal blue-modal fade"
          id="addInsuranceModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className={`modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable  ${companyName}-modal-dialog-search modal-dialog-search`}
          >
            <div
              className={`modal-content  ${companyName}-search-modal-content search-modal-content`}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Station Action
                </h5>
              </div>
              {/* <div className="modal-body">
                Are you sure you want to delete image?
              </div> */}

              <div className=" pd-b30 mx-auto">
                <div className="m-3">
                  <button
                    type="button"
                    className={`s-blue-btn search-user-btn ${companyName}-search-user-btn`}
                    data-dismiss="modal"
                    onClick={() => {
                      handleStationsSelected(0);
                    }}
                  >
                    Check In{" "}
                  </button>
                </div>
                <div className="m-3">
                  <button
                    type="button"
                    className={`s-blue-btn search-user-btn ${companyName}-search-user-btn`}
                    onClick={() => {
                      handleStationsSelected(1);
                    }}
                  >
                    Check Out{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default ClientPageWithoutNfc;
