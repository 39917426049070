import {
  BanknotesIcon,
  UserPlusIcon,
  UserIcon,
  ChartBarIcon,
  EyeIcon,
  CursorArrowRaysIcon,
  ArrowLongUpIcon,
  UsersIcon,
} from "@heroicons/react/24/solid";
import statisticsChartsData from "./statistics-charts-data";
import Chart from "react-apexcharts";
import { useEffect, useRef, useState } from "react";
import {
  getCompanyList,
  getGoogleAnalyticsData,
} from "../../services/adminApi";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import ChartCard from "./ChartCard";
import moment from "moment/moment";
import generatePDF, { usePDF } from "react-to-pdf";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { CSVLink } from "react-csv";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
const statisticsCardsData = [
  {
    color: "blue",
    icon: BanknotesIcon,
    title: "Company Count",
    value: "10",
    footer: {
      color: "text-green-500",
      value: "+55%",
      label: "than last week",
    },
  },
  {
    color: "pink",
    icon: UserIcon,
    title: "Total Registration",
    value: "10",
    footer: {
      color: "text-green-500",
      value: "+3%",
      label: "than last month",
    },
  },
  {
    color: "green",
    icon: UserPlusIcon,
    title: "Total Clicks Sponsor",
    value: "10",
    footer: {
      color: "text-red-500",
      value: "-2%",
      label: "than yesterday",
    },
  },
  {
    color: "orange",
    icon: ChartBarIcon,
    title: "Total Clicks Header",
    value: "10",
    footer: {
      color: "text-green-500",
      value: "+5%",
      label: "than yesterday",
    },
  },
];
const chartsConfig = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  title: {
    show: "",
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    labels: {
      style: {
        // colors: "#fff",
        fontSize: "13px",
        fontFamily: "inherit",
        fontWeight: 300,
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        // colors: "#fff",
        fontSize: "13px",
        fontFamily: "inherit",
        fontWeight: 300,
      },
    },
  },
  grid: {
    show: true,
    // borderColor: "#ffffff40",
    strokeDashArray: 5,
    xaxis: {
      lines: {
        show: true,
      },
    },
    padding: {
      top: 5,
      right: 20,
    },
  },
  fill: {
    opacity: 0.8,
  },
  tooltip: {
    theme: "dark",
  },
};

const Ga4Public = () => {
  const [apiData, setApiData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const csvRef = useRef();
  const { id } = useParams();
  const [areaChart, setAreaChart] = useState({
    type: "area",
    height: 220,
    series: [
      {
        data: [],
      },
    ],
    options: {
      ...chartsConfig,
      // colors: ["#fff"],
      stroke: {
        lineCap: "round",
      },
      markers: {
        size: 5,
      },
      xaxis: {
        ...chartsConfig.xaxis,
        type: "date",

        categories: [],
      },
    },
  });
  const [totalUsers, setTotalUsers] = useState({
    type: "area",
    height: 220,
    series: [{ label: "Users", data: [] }],
    options: {
      ...chartsConfig,
      // colors: ["#fff"],
      stroke: {
        lineCap: "round",
      },
      markers: {
        size: 5,
      },
      xaxis: {
        ...chartsConfig.xaxis,
        type: "date",

        categories: [],
      },
    },
  });
  const [pageViews, setPageViews] = useState({
    type: "area",
    height: 220,
    series: [
      {
        data: [],
      },
    ],
    options: {
      ...chartsConfig,
      // colors: ["#fff"],
      stroke: {
        lineCap: "round",
      },
      markers: {
        size: 5,
      },
      xaxis: {
        ...chartsConfig.xaxis,
        type: "date",

        categories: [],
      },
    },
  });
  const [totalClicksSponsor, setTotalClicksSponsor] = useState({
    type: "area",
    height: 220,
    series: [
      {
        data: [],
      },
    ],
    options: {
      ...chartsConfig,
      // colors: ["#fff"],
      stroke: {
        lineCap: "round",
      },
      markers: {
        size: 5,
      },
      xaxis: {
        ...chartsConfig.xaxis,
        type: "date",

        categories: [],
      },
    },
  });
  const [totalRegistration, setTotalRegistration] = useState({
    type: "bar",
    height: 220,
    series: [
      {
        name: "Views",
        data: [],
      },
    ],
    options: {
      ...chartsConfig,
      // colors: "#fff",
      plotOptions: {
        bar: {
          // columnWidth: "16%",
          borderRadius: 5,
        },
      },
      xaxis: {
        ...chartsConfig.xaxis,
        categories: [],
      },
    },
  });
  const [totalLoginsConnectCollect, setTotalLoginsConnectCollect] = useState({
    type: "bar",
    height: 220,
    series: [
      {
        name: "Views",
        data: [],
      },
    ],
    options: {
      ...chartsConfig,
      // colors: "#fff",
      plotOptions: {
        bar: {
          // columnWidth: "16%",
          borderRadius: 5,
        },
      },
      xaxis: {
        ...chartsConfig.xaxis,
        categories: [],
      },
    },
  });
  const [selectedEventData, setSelectedEventData] = useState({
    eventName: {},
    details: [],
  });

  const [companyListPayload, setCompanyListPayload] = useState({
    // sort_by: 'sr',
    // order_by: 'desc',
    offset: 0,
    limit: 290,
    // status: 1,
    search_by: "",
  });
  const [params, setParams] = useState({
    // sort_by: 'sr',
    // order_by: 'desc',
    offset: 0,
    limit: 290,
    name: "All",
    // status: 1,
    search_by: "",
    id: id,
    start_date: "2023-10-01",
    end_date: moment().format("YYYY-MM-DD"),
  });
  const [companyList, setCompanyList] = useState([]);
  const handleChange = (id) => {
    const filterCompany = companyList.filter((record) => record.id == id);

    setParams({
      ...params,
      id: id,
      name: filterCompany[0]?.company_name
        ? filterCompany[0]?.company_name
        : "",
    });
  };
  useEffect(() => {
    getGoogleAnalyticsData(params)
      .then((result) => {
        setCsvData([
          {
            ...result.data?.details,
            company_name: result.data?.details?.companyName
              ? result.data?.details?.companyName
              : "All",
            report_start_date: params.start_date,
            report_end_date: params.end_date,
          },
        ]);
        setApiData(result.data?.details);
        setAreaChart({
          type: "area",
          height: 220,
          series: [
            {
              data: result.data.totalClickGraph.data,
            },
          ],
          options: {
            ...chartsConfig,
            // colors: ["#fff"],
            stroke: {
              lineCap: "round",
            },
            markers: {
              size: 5,
            },
            xaxis: {
              ...chartsConfig.xaxis,
              type: "datetime",
              categories: result.data.totalClickGraph.categories,
            },
          },
        });
        setTotalUsers({
          ...totalUsers,
          series: [{ data: result.data.totalUsers.data }],
          options: {
            ...totalUsers.options,
            xaxis: {
              ...chartsConfig.xaxis,
              type: "date",
              categories: result.data.totalUsers.categories,
            },
          },
        });
        setPageViews({
          ...pageViews,
          series: [{ data: result.data.pageViews.data }],
          options: {
            ...pageViews.options,
            xaxis: {
              ...chartsConfig.xaxis,
              type: "date",
              categories: result.data.pageViews.categories,
            },
          },
        });
        setTotalClicksSponsor({
          ...totalClicksSponsor,
          series: [{ data: result.data.totalClicksSponsor.data }],
          options: {
            ...totalClicksSponsor.options,
            xaxis: {
              ...chartsConfig.xaxis,
              type: "date",
              categories: result.data.totalClicksSponsor.categories,
            },
          },
        });
        setTotalRegistration({
          series: [
            {
              name: "Views",
              data: result.data.totalRegistration.data,
            },
          ],
          options: {
            ...chartsConfig,
            // colors: "#fff",
            plotOptions: {
              bar: {
                // columnWidth: "16%",
                borderRadius: 5,
              },
            },
            xaxis: {
              ...chartsConfig.xaxis,
              categories: result.data.totalRegistration.categories,
            },
          },
        });
        setTotalLoginsConnectCollect({
          ...totalLoginsConnectCollect,
          series: [{ data: result.data.totalLoginsConnectCollect.data }],
          options: {
            ...totalLoginsConnectCollect.options,
            xaxis: {
              ...chartsConfig.xaxis,
              type: "date",
              categories: result.data.totalLoginsConnectCollect.categories,
            },
          },
        });
      })
      .catch((err) => console.log(err));
  }, [params]);

  const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });
  const headers = [
    { label: "Company Name", key: "company_name" },
    { label: "Total Users", key: "totalUsers" },
    { label: "Total Check In", key: "totalCheckIn" },
    { label: "Total Images", key: "imageGallaryCount" },
    { label: "Total Logic Connect Collect", key: "totalLoginsConnectCollect" },
    {
      label: "Total Clicks View Check In History",
      key: "totalClicksViewCheckinHistory",
    },
    {
      label: "Total Clicks View My Contacts",
      key: "totalClicksViewMyContacts",
    },
    {
      label: "Total Header Clicks",
      key: "totalClicksHeader",
    },
    { label: "Total Clicks Sponsor", key: "totalClicksSponsor" },
    { label: "Report Start Date", key: "report_start_date" },
    { label: "Report End Date", key: "report_end_date" },
    // { label: "Total Clicks Sponsor", key: "totalClicksSponsor" },
    // { label: "Last Name", key: "details.lastName" },
    // { label: "Job", key: "job" },
  ];

  return (
    <div className="row m-3 dashboard-background" ref={targetRef}>
      <div className="d-flex justify-content-between">
        <div>
          <h5>Company Name - {apiData?.companyName}</h5>
        </div>
        {/* <CSVLink
          data={csvData}
          headers={headers}
          style={{ display: "none" }}
          ref={csvRef}
        >
          Download me
        </CSVLink>
        ; */}
        <div>
          <Tooltip title="Download PDF Report">
            <IconButton onClick={toPDF}>
              <PictureAsPdfIcon className="text-primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download CSV Report">
            <CSVLink
              data={csvData}
              headers={headers}
              // style={{ display: "none" }}
              filename={"Google Analytics Reports.csv"}
              ref={csvRef}
            >
              <IconButton>
                <AssessmentIcon className="text-primary" />
              </IconButton>
            </CSVLink>
          </Tooltip>
        </div>
      </div>

      <div className="col-sm-12 col-md-6">
        <FormControl fullWidth>
          <label id="demo-simple-select-label">Start Date</label>

          <TextField
            id="date"
            // label="Birthday"
            type="date"
            className="input-border"
            value={params.start_date}
            onChange={(event) =>
              setParams({ ...params, start_date: event.target.value })
            }
            // className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
      </div>
      <div className="col-sm-12 col-md-6">
        <FormControl fullWidth>
          <label id="demo-simple-select-label">End Date</label>

          <TextField
            id="date"
            // label="Birthday"
            type="date"
            className="input-border"
            value={params.end_date}
            onChange={(event) =>
              setParams({ ...params, end_date: event.target.value })
            } // className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
      </div>

      {/* <div className="col-sm-12 col-md-3">
        <div className="ga-card">
          <div className="ga-container mx-2">
            <div className="ga-icon-container">
              <div className="ga-icon ga-green">
                <BanknotesIcon fontSize={18} style={{ width: "24px" }} />
              </div>
            </div>
          </div>
          <label>Total Click Header</label>
          <span>
            {apiData?.totalClicksHeader ? apiData?.totalClicksHeader : 0}
          </span>
        </div>
      </div> */}
      <div className="col-sm-12 col-md-3">
        <div className="ga-card">
          <div className="ga-container mx-2">
            <div className="ga-icon-container">
              <div className="ga-icon ga-pink">
                <ChartBarIcon fontSize={18} style={{ width: "24px" }} />
              </div>
            </div>
          </div>
          <label>Total Users</label>
          <span>{apiData?.totalUsers ? apiData?.totalUsers : 0}</span>
        </div>
      </div>
      {/* <div className="col-sm-12 col-md-3">
        <div className="ga-card">
          <div className="ga-container mx-2">
            <div className="ga-icon-container">
              <div className="ga-icon ga-blue">
                <UserIcon fontSize={18} style={{ width: "24px" }} />
              </div>
            </div>
          </div>
          <label>Total View</label>
          <span>{apiData?.view ? apiData?.view : 0}</span>{" "}
        </div>
      </div> */}
      <div className="col-sm-12 col-md-3">
        <div className="ga-card">
          <div className="ga-container mx-2">
            <div className="ga-icon-container">
              <div className="ga-icon ga-orange">
                <UserPlusIcon fontSize={18} style={{ width: "24px" }} />
              </div>
            </div>
          </div>
          <label>Total Clicks Header</label>
          <span>
            {apiData?.totalClicksHeader ? apiData?.totalClicksHeader : 0}
          </span>{" "}
        </div>
      </div>
      <div className="col-sm-12 col-md-3">
        <div className="ga-card">
          <div className="ga-container mx-2">
            <div className="ga-icon-container">
              <div className="ga-icon ga-orange">
                <ArrowLongUpIcon fontSize={18} style={{ width: "24px" }} />
              </div>
            </div>
          </div>
          <label>Total Clicks Sponsors</label>
          <span>
            {apiData?.totalClicksSponsor ? apiData?.totalClicksSponsor : 0}
          </span>{" "}
        </div>
      </div>
      <div className="col-sm-12 col-md-3">
        <div className="ga-card">
          <div className="ga-container mx-2">
            <div className="ga-icon-container">
              <div className="ga-icon ga-green">
                <UsersIcon fontSize={18} style={{ width: "24px" }} />
              </div>
            </div>
          </div>
          <label>Total View My Contacts</label>
          <span>
            {apiData?.totalClicksViewMyContacts
              ? apiData?.totalClicksViewMyContacts
              : 0}
          </span>{" "}
        </div>
      </div>
      <div className="col-sm-12 col-md-3">
        <div className="ga-card">
          <div className="ga-container mx-2">
            <div className="ga-icon-container">
              <div className="ga-icon ga-pink">
                <EyeIcon fontSize={18} style={{ width: "24px" }} />
              </div>
            </div>
          </div>
          <label>Total View Checkin History</label>
          <span>
            {apiData?.totalClicksViewCheckinHistory
              ? apiData?.totalClicksViewCheckinHistory
              : 0}
          </span>{" "}
        </div>
      </div>
      <div className="col-sm-12 col-md-3">
        <div className="ga-card">
          <div className="ga-container mx-2">
            <div className="ga-icon-container">
              <div className="ga-icon ga-blue">
                <CursorArrowRaysIcon fontSize={18} style={{ width: "24px" }} />
              </div>
            </div>
          </div>
          <label>Total Connect Collect</label>
          <span>
            {apiData?.totalLoginsConnectCollect
              ? apiData?.totalLoginsConnectCollect
              : 0}
          </span>{" "}
        </div>
      </div>
      <div className="col-sm-12 col-md-3">
        <div className="ga-card">
          <div className="ga-container mx-2">
            <div className="ga-icon-container">
              <div className="ga-icon ga-green">
                <BanknotesIcon fontSize={18} style={{ width: "24px" }} />
              </div>
            </div>
          </div>
          <label>Total Photos</label>
          <span>
            {apiData?.imageGallaryCount ? apiData?.imageGallaryCount : 0}
          </span>
        </div>
      </div>
      <div className="col-sm-12 col-md-3">
        <div className="ga-card">
          <div className="ga-container mx-2">
            <div className="ga-icon-container">
              <div className="ga-icon ga-pink">
                <EyeIcon fontSize={18} style={{ width: "24px" }} />
              </div>
            </div>
          </div>
          <label>Total Check-ins </label>
          <span>{apiData?.totalCheckIn ? apiData?.totalCheckIn : 0}</span>{" "}
        </div>
      </div>
      {/* {statisticsChartsData.map((props) => (
        <div className="col-sm-12 col-md-4">
          <div className="ga-graph-card">
            <div className="ga-graph-body">
              <div className="ga-graph-container">
                <div className={props.color + " ga-graph"}>
                  <Chart {...props?.chart} />
                </div>
              </div>
            </div>
            <div className="ga-graph-heading">
              <h5>{props?.title}</h5>
              <h6>{props.description}</h6>
            </div>
          </div>
        </div>
      ))} */}
      {/* {statisticsChartsData.map((props) => (
        <ChartCard>
          {" "}
          <Chart {...props?.chart} />
        </ChartCard>
      ))} */}
      <div className="row">
        <div className="col-md-12 col-lg-6">
          <ChartCard title={"Total Clicks ViewCheck in History"}>
            {" "}
            <Chart {...areaChart} />
          </ChartCard>
        </div>
        <div className="col-md-12 col-lg-6">
          <ChartCard title={"Total Clicks Sponsor"}>
            {" "}
            <Chart {...totalClicksSponsor} />
          </ChartCard>{" "}
        </div>
        <div className="col-md-12 col-lg-6">
          <ChartCard title={"Total Registration"}>
            {" "}
            <Chart
              options={totalRegistration.options}
              series={totalRegistration.series}
              type="bar"
              height={220}
              // width="500"
            />{" "}
          </ChartCard>
          {/* <ChartCard title={"Total Registration"}>
        {" "}
        <Chart {...totalRegistration} />
      </ChartCard> */}{" "}
        </div>
        <div className="col-md-12 col-lg-6">
          <ChartCard title={"Total Logins Connect Collect"}>
            {" "}
            <Chart {...totalLoginsConnectCollect} />
          </ChartCard>
        </div>
        <div className="col-md-12 col-lg-6">
          <ChartCard title={"Total Users"}>
            {" "}
            <Chart {...totalUsers} />
          </ChartCard>
        </div>
        <div className="col-md-12 col-lg-6">
          <ChartCard title={"Page Views"}>
            {" "}
            <Chart {...pageViews} />
          </ChartCard>
        </div>
      </div>
    </div>
  );
};
export default Ga4Public;
