import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Input from "@material-ui/core/Input";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import { ExportToCsv } from "export-to-csv";
import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  addParticipant,
  assignNewNfc,
  deleteParticipant,
  deleteParticipantWithoutNfcByCompanyId,
  getCheckInStationByCompanyId,
  getCompanyFieldsById,
  getMeetMaxMeetingCronDataForCompanyId,
  getParticipantCompleteDetail,
  getParticipantDetailsByNfcId,
  getParticipantWithoutNfcByCompanyId,
  getReport,
} from "../../services/adminApi";
import BulkUpload from "../BulkUpload/BulkUpload";
import BulkUploadWithoutNfc from "../BulkUpload/BulkUploadWithoutNfc";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 275,
    maxWidth: 275,
  },
}));

const styles = (theme) => ({
  field: {
    margin: "10px 0",
  },
  countryList: {
    ...theme.typography.body1,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ParticipantListWithoutNfc = (props) => {
  const classes = useStyles();

  const [tableCount, setTableCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [name, setParticipantName] = useState("");
  const [participantNameError, setParticipantNameError] = useState("");

  const [participantId, setParticipantId] = useState();
  const [participantIdError, setParticipantIdError] = useState("");

  const [email_id, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [business, setBusiness] = useState();
  const [businessError, setBusinessError] = useState("");

  const [contact, setPhone] = useState();
  const [phoneError, setPhoneError] = useState("");

  const [linkedin_id, setLinkedIn] = useState();
  const [linkedInError, setLinkedInError] = useState("");

  const [companyId, setCompanyId] = useState("");
  const [companyName, setCompanyName] = useState("");

  const [is_registered, setIsRegistered] = useState(false);

  const [nfc_tag_id, setNfcTagId] = useState("");
  const [nfcTagIdError, setNfcTagIdError] = useState("");

  const [last_name, setLastName] = useState("");

  const [address, setAddress] = useState("");

  const [facebook_id, setFacebookId] = useState("");
  const [instagram_id, setInstagramId] = useState("");
  const [twitter_id, setTwitterId] = useState("");
  const [youtube_id, setYoutubeId] = useState("");
  const [pintrest_id, setPinterestId] = useState("");
  const [snapchat_id, setSnapchatId] = useState("");
  const [tiktok_id, setTiktokId] = useState("");

  const [field_16, setField16] = useState("");
  const [field_17, setField17] = useState("");
  const [field_18, setField18] = useState("");
  const [field_19, setField19] = useState("");
  const [field_20, setField20] = useState("");
  const [field_21, setField21] = useState("");
  const [field_22, setField22] = useState("");
  const [field_23, setField23] = useState("");
  const [field_24, setField24] = useState("");
  const [field_25, setField25] = useState("");

  const [error, setError] = useState("");

  const [participantListDisplay, setParticipantListDisplay] = useState("");
  const [participantList, setParticipantList] = useState([]);
  const [stationList, setStationList] = useState([]);
  const [participantsDetails, setParticipantsDetails] = useState([]);
  const [reportData, setReportData] = useState({});
  const [reportDataKeys, setReportDataKeys] = useState([]);

  const [addNewParticipantModalFlag, setAddNewParticipantModalFlag] =
    useState(false);

  const [editFlag, setEditFlag] = useState(false);
  const [addNewFlag, setAddNewFlag] = useState(false);
  const [editSneakerFlag, setEditSneakerFlag] = useState(false);

  const [selectedId, setSelectedId] = useState();
  const [deleteModalFlag, setDeleteModalFlag] = useState(false);
  const [assignModalFlag, setAssignModalFlag] = useState(false);

  const [addSneakBarFlag, setAddSneakBarFlag] = useState(false);
  const [deleteSneakBarFlag, setDeleteSneakBarFlag] = useState(false);
  const [assignNewNfcSneakBarFlag, setAssignNewNfcSneakBarFlag] =
    useState(false);

  const [bulkUploadFlag, setBulkUploadFlag] = useState(false);
  const [bulkUploadArray, setBulkUploadArray] = useState([]);
  const [bulkUploadFlagWithoutNfc, setBulkUploadFlagWithoutNfc] =
    useState(false);
  const [bulkUploadArrayWithoutNfc, setBulkUploadArrayWithoutNfc] = useState(
    []
  );
  const [sendUploadMsg, setSendUploadMsg] = useState(0);
  const [sendUploadMsgWithoutNfc, setSendUploadMsgWithoutNfc] = useState(0);

  const [columnsNames, setColumnNames] = useState([]);
  const [isRequiredList, setIsRequiredList] = useState([]);

  let tempList16 = [];
  let tempList17 = [];
  let tempList18 = [];
  let tempList19 = [];
  let tempList20 = [];
  let tempList21 = [];
  let tempList22 = [];
  let tempList23 = [];
  let tempList24 = [];
  let tempList25 = [];

  const [participantListColumns, setParticipantListColumns] = useState([]);

  const [addNewFormFieldsList, setAddNewFormFieldsList] = useState([]);
  const [companyFormFieldList, setCompanyFormFieldList] = useState([]);

  const [loaderFlag, setLoaderFlag] = useState(false);

  const [newNfcId, setNewNfcId] = useState("");
  const [newNfcIdError, setNewNfcIdError] = useState("");

  const [participantListPayload, setParticipantListPayload] = useState({
    // sort_by: 'sr',
    // order_by: 'desc',
    offset: 0,
    limit: 50,
    // status: 1,
    search_by: "",
  });

  const fileSelect = useRef();

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("selectedCompany"));
    // console.log(data);
    setCompanyId(data.id);
    setCompanyName(data.company_name);
    getParticipantListHandler(data.id, data.company_name);
    getStationListHandler(data.id);
    getAddNewFormFields(data.id, false);
    getReportDataHandler(data.id);
  }, [participantListPayload, tableCount]);

  useEffect(() => {}, [participantListColumns, name]);

  useEffect(() => {
    setParticipantName("");
    setParticipantNameError("");

    setBusiness("");
    setBusinessError("");

    setEmail("");
    setEmailError("");

    setPhone("");
    setPhoneError("");

    setLinkedIn("");
    setLinkedInError("");

    setNfcTagId("");
    setNfcTagIdError("");

    setLastName("");
    setAddress("");
    setFacebookId("");
    setInstagramId("");
    setTwitterId("");
    setYoutubeId("");
    setSnapchatId("");
    setTiktokId("");
    setPinterestId("");
    setField16("");
    setField16("");
    setField17("");
    setField18("");
    setField19("");
    setField20("");
    setField21("");
    setField22("");
    setField23("");
    setField24("");
    setField25("");
    setIsRegistered(false);
    setError("");

    // setModuleName('');
  }, [addNewFlag]);

  useEffect(() => {}, [participantListColumns, addNewFormFieldsList, editFlag]);

  const getStationListHandler = (id) => {
    getCheckInStationByCompanyId(id).then(async (response) => {
      // console.log("station list", response);
      let tempData = response.data;
      setStationList(response.data);
    });
  };

  const bulkUpload = () => {
    let node = document.getElementById("fileSelect");

    node.click();
  };
  const meetMaxApi = () => {
    let data = JSON.parse(localStorage.getItem("selectedCompany"));
    getMeetMaxMeetingCronDataForCompanyId(data.id)
      .then((result) => toast.success("Success"))
      .catch((err) => toast.error("Error"));
  };
  const bulkUploadWithoutNfc = () => {
    fileSelect.current.click();
  };
  const deleteAllRecords = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          let data = JSON.parse(localStorage.getItem("selectedCompany"));

          deleteParticipantWithoutNfcByCompanyId(data.id).then((res) =>
            getParticipantListHandler(data.id, data.company_name)
          );
        }
      });
  };

  const onFileChange = (e) => {
    var reader = new FileReader();
    reader.onload = async function (e) {
      // Use reader.result

      let result = csvJSON(reader.result);
      // console.log(result)
      const result1 = [...new Set(result.map((item) => item.nfcid))];
      // debugger
      await setBulkUploadArray(result);
      // console.log("result", result);
      setBulkUploadFlag(true);
    };
    reader.readAsText(e.target.files[0]);
  };
  const onFileWithotNfcChange = (e) => {
    var reader = new FileReader();
    reader.onload = async function (e) {
      // Use reader.result

      let result = csvJSON(reader.result);
      // console.log(result)
      const result1 = [...new Set(result.map((item) => item.nfcid))];
      // debugger
      await setBulkUploadArrayWithoutNfc(result);
      // console.log("result", result);
      setBulkUploadFlagWithoutNfc(true);
    };
    reader.readAsText(e.target.files[0]);
  };

  const csvJSON = (csvText) => {
    let lines = [];
    const linesArray = csvText.split("\n");
    // for trimming and deleting extra space

    linesArray.forEach((e) => {
      const row = e.replace(/[\s]+[,]+|[,]+[\s]+/g, ",").trim();
      lines.push(row);
    });
    // for removing empty record
    lines.splice(lines.length - 1, 1);
    const result = [];
    const headers = lines[0].split(",");

    for (let i = 1; i < lines.length; i++) {
      const obj = {};
      const currentline = lines[i].split(",");

      if (!result.some((item) => item.nfcid === currentline[5])) {
        for (let j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentline[j];
        }

        result.push(obj);
      }
    }

    return result;
  };

  const doneUploadHandler = (data) => {
    setSendUploadMsg(0);
    setSendUploadMsgWithoutNfc(0);
    setBulkUploadFlag(false);
    setBulkUploadFlagWithoutNfc(false);
    getParticipantListHandler(companyId, companyName);
  };

  const getAddNewFormFields = (id, edit) => {
    setAddNewFormFieldsList([]);
    getCompanyFieldsById(id).then((response) => {
      let tempData = response.data;
      let tempList = response.data.filter((x) => x.is_active);
      setIsRequiredList(
        response.data.filter((x) => x.is_required && x.is_active)
      );
      setCompanyFormFieldList(response.data);
      let participantListColumnsTempList = [];
      participantListColumnsTempList.push({
        name: "sr. no.",
        title: "Sr. No.",
      });
      console.log(tempData);
      tempData.map((row) => {
        // console.log("row",row);
        participantListColumnsTempList.push({
          name: row.field_name,
          title: row.label ? row.label : row.field_name,
        });
      });

      setParticipantListColumns(participantListColumnsTempList);

      let addParticipantFormFieldsTempList = [];

      tempList.map((field, i) => {
        if (field.type.toLowerCase() == "text") {
          addParticipantFormFieldsTempList.push(
            inputField(
              field.field_name,
              field.label,
              edit ? getParticipantsDetails(field.field_name) : "",
              field.is_required
            )
          );
        } else if (field.type.toLowerCase() == "checkbox") {
          addParticipantFormFieldsTempList.push(
            checkboxField(
              field.field_name,
              field.label,
              edit ? getParticipantsDetails(field.field_name) : "",
              field.value,
              edit ? getParticipantsDetails(field.field_name) : "",
              field.is_required
            )
          );
        } else if (field.type.toLowerCase() == "dropdown") {
          addParticipantFormFieldsTempList.push(
            dropdownField(
              field.field_name,
              field.label,
              edit ? getParticipantsDetails(field.field_name) : "",
              field.value,

              field.is_required
            )
          );
        } else if (field.type.toLowerCase() == "radio") {
          addParticipantFormFieldsTempList.push(
            radioField(
              field.field_name,
              field.label,
              edit ? getParticipantsDetails(field.field_name) : "",
              // "Male",
              field.value,
              edit ? getParticipantsDetails(field.field_name) : "",
              field.is_required
            )
          );
        }
      });
      setAddNewFormFieldsList(addParticipantFormFieldsTempList);
    });
    if (edit) {
      setAddNewParticipantModalFlag(true);
    }
  };

  const inputField = (id, name, value, isRequired, error) => {
    return (
      <div className="col-sm-6">
        <div className="form-group gray-fill-input mr-b30">
          <label htmlFor="">{isRequired ? "*" + name : name}</label>
          <input
            type="text"
            className="form-control"
            name={id}
            id={id}
            defaultValue={value ? value : ""}
            onChange={(e) => {
              textFieldChangeHandler(id, e);
            }}
            placeholder={name}
            // disabled={(row.field_name == "nfc_tag_id" && editFlag) ? true : false}
          />

          {isRequired ? (
            error !== "" ? (
              <p className="error-alert mr-b20">{error}</p>
            ) : null
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  const checkboxField = (
    id,
    name,
    value,
    option,
    checked,
    isRequired,
    error
  ) => {
    let typeValuesTemp = option.split(",").map((item) => item.trim());
    let selectedValues = value
      ? value.split(",").map((item) => item.trim())
      : [];
    let valuesTemp = [];
    typeValuesTemp.map((tvalue) => {
      valuesTemp.push(
        <FormControlLabel
          value={tvalue}
          control={
            <Checkbox defaultChecked={selectedValues.includes(tvalue)} />
          }
          label={tvalue}
          name={id}
        />
      );
    });

    return (
      <div className="col-sm-6">
        <div className="form-group gray-fill-input mr-b30">
          <label htmlFor="">{isRequired ? "*" + name : name}</label>
          <div>
            <FormGroup
              onChange={(e) => {
                checkboxChangeHandler(e);
              }}
              defaultValue="Delhi"
            >
              {valuesTemp}
            </FormGroup>
          </div>
          {error !== "" ? <p className="error-alert mr-b20">{error}</p> : null}
        </div>
      </div>
    );
  };

  const dropdownField = (id, name, value, option, isRequired, error) => {
    let typeValuesTemp = option.split(",").map((item) => item.trim());

    let valuesTemp = [];
    typeValuesTemp.map((tvalue) => {
      valuesTemp.push(
        { name: tvalue, id: tvalue.toLowerCase() }
        // <Dropdown.Item eventKey={tvalue} value={tvalue}>{tvalue}</Dropdown.Item>
      );
    });

    return (
      <div className="col-sm-6">
        <div className="form-group gray-fill-input mr-b30">
          <label htmlFor="">{isRequired ? "*" + name : name}</label>
          <FormControl className={classes.formControl}>
            <Select
              className="form-control"
              labelId="mutiple-module-checkbox-label"
              defaultValue={value ? value : ""}
              // value={value}
              onChange={(e) => {
                textFieldChangeHandler(id, e);
              }}
              input={<Input id={id} />}
              renderValue={(selected) => selected}
              MenuProps={MenuProps}
              disableUnderline
              name={id}
              placeholder="Choose value"
            >
              {valuesTemp.map((name) => (
                <MenuItem key={name.id} value={name.name}>
                  {/* <Checkbox checked={row.field_name.indexOf(name.name) > -1} /> */}
                  <ListItemText primary={name.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {error !== "" ? <p className="error-alert mr-b20">{error}</p> : null}
        </div>
      </div>
    );
  };

  const radioField = (id, name, value, option, checked, isRequired, error) => {
    let typeValuesTemp = option.split(",").map((item) => item.trim());

    let valuesTemp = [];
    typeValuesTemp.map((tvalue) => {
      valuesTemp.push(
        <FormControlLabel
          value={tvalue}
          control={<Radio />}
          label={tvalue}
          name={id}

          // checked={checked.toLowerCase() == tvalue.toLowerCase()}
        />
      );
    });
    return (
      <div className="col-sm-6">
        <div className="form-group gray-fill-input mr-b30">
          <label htmlFor="">{isRequired ? "*" + name : name}</label>
          <div>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="gender"
                name="row-radio-buttons-group"
                onChange={(e) => {
                  textFieldChangeHandler(id, e);
                }}
                defaultValue={value ? value : ""}
              >
                {valuesTemp}
              </RadioGroup>
            </FormControl>
          </div>
          {/* {participantNameError !== '' ? <p className="error-alert mr-b20">{participantNameError}</p> : null} */}
        </div>
      </div>
    );
  };

  const getParticipantListHandler = (id, company_name) => {
    getParticipantWithoutNfcByCompanyId(id, participantListPayload).then(
      async (response) => {
        console.log("response.data", response.data);
        let tempData = response.data.participant_list;
        setParticipantList(response.data.participant_list);
        setTableCount(response.data.count);

        const participantListTemp = [];
        setParticipantListDisplay([]);
        tempData?.map((usr, index) => {
          participantListTemp?.push(
            <TableRow key={usr.id}>
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>

              <TableCell>{usr.name}</TableCell>
              <TableCell>{usr.last_name}</TableCell>
              <TableCell>{usr.email_id}</TableCell>
              <TableCell>{usr.contact}</TableCell>
              <TableCell>{usr.business}</TableCell>
              <TableCell>{usr.address}</TableCell>
              <TableCell>{"-"}</TableCell>
              <TableCell>{usr.linkedin_id}</TableCell>
              <TableCell>{usr.facebook_id}</TableCell>
              <TableCell>{usr.instagram_id}</TableCell>
              <TableCell>{usr.twitter_id}</TableCell>
              <TableCell>{usr.youtube_id}</TableCell>
              <TableCell>{usr.pintrest_id}</TableCell>
              <TableCell>{usr.snapchat_id}</TableCell>
              <TableCell>{usr.tiktok_id}</TableCell>
              <TableCell>{usr.field_16}</TableCell>
              <TableCell>{usr.field_17}</TableCell>
              <TableCell>{usr.field_18}</TableCell>
              <TableCell>{usr.field_19}</TableCell>
              <TableCell>{usr.field_20}</TableCell>
              <TableCell>{usr.field_21}</TableCell>
              <TableCell>{usr.field_22}</TableCell>
              <TableCell>{usr.field_23}</TableCell>
              <TableCell>{usr.field_24}</TableCell>
              <TableCell>{usr.field_25}</TableCell>
            </TableRow>
          );

          if (index === tempData.length - 1) {
            setParticipantListDisplay(participantListTemp);
          }
        });
      }
    );

    // getAddNewFormFields()
  };

  const getParticipantsDetails = (id) => {
    let data = JSON.parse(localStorage.getItem("selectedParticipant"));

    if (id == "name") {
      return data.name;
    }
    if (id == "last_name") {
      return data.last_name;
    }
    if (id == "contact") {
      return data.contact;
    }
    if (id == "business") {
      return data.business;
    }
    if (id == "address") {
      return data.address;
    }
    if (id == "email_id") {
      return data.email_id;
    }
    if (id == "nfc_tag_id") {
      return data.nfc_tag_id;
    }
    if (id == "facebook_id") {
      return data.facebook_id;
    }
    if (id == "instagram_id") {
      return data.instagram_id;
    }
    if (id == "twitter_id") {
      return data.twitter_id;
    }
    if (id == "linkedin_id") {
      return data.linkedin_id;
    }
    if (id == "youtube_id") {
      return data.youtube_id;
    }
    if (id == "pintrest_id") {
      return data.pintrest_id;
    }
    if (id == "snapchat_id") {
      return data.snapchat_id;
    }
    if (id == "tiktok_id") {
      return data.tiktok_id;
    }
    if (id == "field_16") {
      return data.field_16;
    }
    if (id == "field_17") {
      return data.field_17;
    }
    if (id == "field_18") {
      return data.field_18;
    }
    if (id == "field_19") {
      return data.field_19;
    }
    if (id == "field_20") {
      return data.field_20;
    }
    if (id == "field_21") {
      return data.field_21;
    }
    if (id == "field_22") {
      return data.field_22;
    }
    if (id == "field_23") {
      return data.field_23;
    }
    if (id == "field_24") {
      return data.field_24;
    }
    if (id == "field_25") {
      return data.field_25;
    }
    setParticipantId(data.id);
    setParticipantName(data.name);
    setLastName(data.last_name);
    setEmail(data.email_id);
    setBusiness(data.business);
    setPhone(data.contact);
    setAddress(data.address);
    setLinkedIn(data.linkedin_id);
    setNfcTagId(data.nfc_tag_id);
    setIsRegistered(data.is_registered);
    setFacebookId(data.facebook_id);
    setInstagramId(data.instagram_id);
    setYoutubeId(data.youtube_id);
    setTwitterId(data.twitter_id);
    setPinterestId(data.pintrest_id);
    setSnapchatId(data.snapchat_id);
    setTiktokId(data.tiktok_id);
    setField16(data.field_16);
    setField17(data.field_17);
    setField18(data.field_18);
    setField19(data.field_19);
    setField20(data.field_20);
    setField21(data.field_21);
    setField22(data.field_22);
    setField23(data.field_23);
    setField24(data.field_24);
    setField25(data.field_25);
  };

  const getParticipantDetailByNfcIdHandler = async (id, company_id) => {
    setEditFlag(true);

    await getParticipantDetailsByNfcId(id).then(async (response) => {
      // console.log("participant details", response);
      let data = response.data[0];

      setParticipantId(data.id);
      setParticipantName(data.name);
      setLastName(data.last_name);
      setEmail(data.email_id);
      setBusiness(data.business);
      setPhone(data.contact);
      setAddress(data.address);
      setLinkedIn(data.linkedin_id);
      setNfcTagId(response.data[0].nfc_tag_id);
      setIsRegistered(data.is_registered);
      setFacebookId(data.facebook_id);
      setInstagramId(data.instagram_id);
      setYoutubeId(data.youtube_id);
      setTwitterId(data.twitter_id);
      setPinterestId(data.pintrest_id);
      setSnapchatId(data.snapchat_id);
      setTiktokId(data.tiktok_id);
      setField16(data.field_16);
      setField17(data.field_17);
      setField18(data.field_18);
      setField19(data.field_19);
      setField20(data.field_20);
      setField21(data.field_21);
      setField22(data.field_22);
      setField23(data.field_23);
      setField24(data.field_24);
      setField25(data.field_25);
    });
  };

  const checkboxChangeHandler = (e) => {
    document.getElementById("addParticipantSubmit").disabled = false;
    setError("");
    let { name, defaultValue, checked } = e.target;

    switch (name) {
      case "field_16":
        if (checked) {
          tempList16.push(defaultValue);
        } else if (!checked) {
          tempList16 = tempList16.filter((x) => x != defaultValue);
        } else console.log(checked);

        setField16(tempList16.toString());
        break;

      case "field_17":
        if (checked) {
          tempList17.push(defaultValue);
        } else if (!checked) {
          tempList17 = tempList17.filter((x) => x != defaultValue);
        } else console.log(checked);

        setField17(tempList17.toString());
        break;

      case "field_18":
        if (checked) {
          tempList18.push(defaultValue);
        } else if (!checked) {
          tempList18 = tempList18.filter((x) => x != defaultValue);
        } else console.log(checked);

        setField18(tempList18.toString());
        break;

      case "field_19":
        if (checked) {
          tempList19.push(defaultValue);
        } else if (!checked) {
          tempList19 = tempList19.filter((x) => x != defaultValue);
        } else console.log(checked);

        setField19(tempList19.toString());
        break;

      case "field_20":
        if (checked) {
          tempList20.push(defaultValue);
        } else if (!checked) {
          tempList20 = tempList20.filter((x) => x != defaultValue);
        } else console.log(checked);

        setField20(tempList20.toString());
        break;

      case "field_21":
        if (checked) {
          tempList21.push(defaultValue);
        } else if (!checked) {
          tempList21 = tempList21.filter((x) => x != defaultValue);
        } else console.log(checked);

        setField21(tempList21.toString());
        break;

      case "field_22":
        if (checked) {
          tempList22.push(defaultValue);
        } else if (!checked) {
          tempList22 = tempList22.filter((x) => x != defaultValue);
        } else console.log(checked);

        setField22(tempList22.toString());
        break;

      case "field_23":
        if (checked) {
          tempList23.push(defaultValue);
        } else if (!checked) {
          tempList23 = tempList23.filter((x) => x != defaultValue);
        } else console.log(checked);

        setField23(tempList23.toString());
        break;

      case "field_24":
        if (checked) {
          tempList24.push(defaultValue);
        } else if (!checked) {
          tempList24 = tempList24.filter((x) => x != defaultValue);
        } else console.log(checked);

        setField24(tempList24.toString());
        break;

      case "field_25":
        if (checked) {
          tempList25.push(defaultValue);
        } else if (!checked) {
          tempList25 = tempList25.filter((x) => x != defaultValue);
        } else console.log(checked);

        setField25(tempList25.toString());
        break;

      default:
        console.log(e);
    }
  };

  const textFieldChangeHandler = (n, e) => {
    document.getElementById("addParticipantSubmit").disabled = false;
    setError("");

    let obj = { name: "", value: "" };

    if (typeof e == "string") {
      obj.name = n;
      obj.value = e;

      document.getElementById("dropdown" + n).title = e;
      document.getElementById("dropdown" + n).value = e;
    } else {
      obj.name = e.target.name;
      obj.value = e.target.value;
    }
    switch (obj.name) {
      case "name":
        setParticipantName(obj.value);
        setParticipantNameError("");

        break;

      case "email_id":
        setEmail(obj.value);
        setEmailError("");

        break;

      case "contact":
        setPhone(obj.value);
        setPhoneError("");

        break;

      case "linkedin_id":
        setLinkedIn(obj.value);
        setLinkedInError("");

        break;

      case "business":
        setBusiness(obj.value);
        setBusinessError("");

        break;

      case "nfc_tag_id":
        setNfcTagId(obj.value);
        setNfcTagIdError("");

        break;

      case "last_name":
        setLastName(obj.value);
        // setNfcTagIdError('')

        break;
      case "address":
        setAddress(obj.value);
        // setNfcTagIdError('')

        break;
      case "facebook_id":
        setFacebookId(obj.value);
        // setNfcTagIdError('')

        break;
      case "instagram_id":
        setInstagramId(obj.value);
        // setNfcTagIdError('')

        break;
      case "twitter_id":
        setTwitterId(obj.value);
        // setNfcTagIdError('')

        break;
      case "youtube_id":
        setYoutubeId(obj.value);
        // setNfcTagIdError('')

        break;
      case "pintrest_id":
        setPinterestId(obj.value);
        // setNfcTagIdError('')

        break;
      case "snapchat_id":
        setSnapchatId(obj.value);
        // setNfcTagIdError('')

        break;
      case "tiktok_id":
        setTiktokId(obj.value);
        // setNfcTagIdError('')

        break;
      case "field_16":
        setField16(obj.value);
        // setNfcTagIdError('')

        break;
      case "field_17":
        setField17(obj.value);
        // setNfcTagIdError('')

        break;

      case "field_18":
        setField18(obj.value);
        // setNfcTagIdError('')

        break;
      case "field_19":
        setField19(obj.value);
        // setNfcTagIdError('')

        break;
      case "field_20":
        setField20(obj.value);
        // setNfcTagIdError('')

        break;
      case "field_21":
        setField21(obj.value);
        // setNfcTagIdError('')

        break;
      case "field_22":
        setField22(obj.value);
        // setNfcTagIdError('')

        break;
      case "field_23":
        setField23(obj.value);
        // setNfcTagIdError('')

        break;
      case "field_24":
        setField24(obj.value);
        // setNfcTagIdError('')

        break;
      case "field_25":
        setField25(obj.value);
        // setNfcTagIdError('')

        break;

      case "is_registered":
        if (obj.value === "true") setIsRegistered(true);
        else if (obj.value === "false") setIsRegistered(false);
        else setIsRegistered(false);
        // setNfcTagIdError('')

        break;

      default:
        console.log("");
    }
  };

  const assignNewNfcTextFielcdChangeHandler = (e) => {
    let { name, value } = e.target;

    switch (name) {
      case "newNfcId":
        setNewNfcId(value);
        setNewNfcIdError("");

        break;

      default:
        console.log("");
    }
  };

  const assignNewNfcIdSubmitHandler = (e) => {
    if (!newNfcId) {
      if (!newNfcId) {
        setNewNfcIdError("Please enter valid NFC Id");
      }
    } else {
      let tempObj = {
        old_nfc_tag_id: selectedId,
        new_nfc_tag_id: newNfcId,
      };

      assignNewNfc(tempObj)
        .then((response) => {
          setNewNfcId("");
          setNewNfcIdError("");
          setAssignNewNfcSneakBarFlag(true);
          setAssignModalFlag(false);
          getParticipantListHandler(companyId, companyName);
        })
        .catch((error) => {
          setNewNfcIdError(error.message);
          // setAssignModalFlag(false);
          getParticipantListHandler(companyId, companyName);
        });
    }
  };

  const addParticipantHandler = (id) => {
    let requiredFields = isRequiredList.map((x) => x.field_name);
    if (
      (requiredFields.includes("name") && (!name || name.trim() == "")) ||
      (requiredFields.includes("last_name") &&
        (!last_name || last_name.trim() == "")) ||
      (requiredFields.includes("email_id") &&
        (!email_id || email_id.trim() == "")) ||
      (requiredFields.includes("contact") &&
        (!contact || contact.trim() == "")) ||
      (requiredFields.includes("address") &&
        (!address || address.trim() == "")) ||
      (requiredFields.includes("business") &&
        (!business || business.trim() == "")) ||
      (requiredFields.includes("nfc_tag_id") &&
        (!nfc_tag_id || nfc_tag_id.trim() == "")) ||
      (requiredFields.includes("faebook_id") &&
        (!facebook_id || facebook_id.trim() == "")) ||
      (requiredFields.includes("instagram_id") &&
        (!instagram_id || instagram_id.trim() == "")) ||
      (requiredFields.includes("twitter_id") &&
        (!twitter_id || twitter_id.trim() == "")) ||
      (requiredFields.includes("youtube_id") &&
        (!youtube_id || youtube_id.trim() == "")) ||
      (requiredFields.includes("linkedin_id") &&
        (!linkedin_id || linkedin_id.trim() == "")) ||
      (requiredFields.includes("pintrest_id") &&
        (!pintrest_id || pintrest_id.trim() == "")) ||
      (requiredFields.includes("snapchat_id") &&
        (!snapchat_id || snapchat_id.trim() == "")) ||
      (requiredFields.includes("tiktok_id") &&
        (!tiktok_id || tiktok_id.trim() == "")) ||
      (requiredFields.includes("field_16") &&
        (!field_16 || field_16.trim() == "")) ||
      (requiredFields.includes("field_17") &&
        (!field_17 || field_17.trim() == "")) ||
      (requiredFields.includes("field_18") &&
        (!field_18 || field_18.trim() == "")) ||
      (requiredFields.includes("field_19") &&
        (!field_19 || field_19.trim() == "")) ||
      (requiredFields.includes("field_20") &&
        (!field_20 || field_20.trim() == "")) ||
      (requiredFields.includes("field_21") &&
        (!field_21 || field_21.trim() == "")) ||
      (requiredFields.includes("field_22") &&
        (!field_22 || field_22.trim() == "")) ||
      (requiredFields.includes("field_23") &&
        (!field_23 || field_23.trim() == "")) ||
      (requiredFields.includes("field_24") &&
        (!field_24 || field_24.trim() == "")) ||
      (requiredFields.includes("field_25") &&
        (!field_25 || field_25.trim() == ""))
    ) {
      if (requiredFields.includes("name") && (!name || name.trim() == "")) {
        setError(`error, please check Name for data input error`);
      }
      if (
        requiredFields.includes("last_name") &&
        (!last_name || last_name.trim() == "")
      ) {
        setError(`error, please check Last Name for data input error`);
      }
      if (
        requiredFields.includes("email_id") &&
        (!email_id || email_id.trim() == "")
      ) {
        setError(`error, please check Email Id for data input error`);
      }
      if (
        requiredFields.includes("contact") &&
        (!contact || contact.trim() == "")
      ) {
        setError(`error, please check Contact for data input error`);
      }
      if (
        requiredFields.includes("address") &&
        (!address || address.trim() == "")
      ) {
        setError(`error, please check Address for data input error`);
      }
      if (
        requiredFields.includes("business") &&
        (!business || business.trim() == "")
      ) {
        setError(`error, please check Business for data input error`);
      }
      if (
        requiredFields.includes("nfc_tag_id") &&
        (!nfc_tag_id || nfc_tag_id.trim() == "")
      ) {
        setError(`error, please check NFC Tag Id for data input error`);
      }
      if (
        requiredFields.includes("faebook_id") &&
        (!facebook_id || facebook_id.trim() == "")
      ) {
        setError(`error, please check Facebook Id for data input error`);
      }
      if (
        requiredFields.includes("instagram_id") &&
        (!instagram_id || instagram_id.trim() == "")
      ) {
        setError(`error, please check Instagram Id for data input error`);
      }
      if (
        requiredFields.includes("twitter_id") &&
        (!twitter_id || twitter_id.trim() == "")
      ) {
        setError(`error, please check Twitter Id for data input error`);
      }
      if (
        requiredFields.includes("youtube_id") &&
        (!youtube_id || youtube_id.trim() == "")
      ) {
        setError(`error, please check Youtube Id for data input error`);
      }
      if (
        requiredFields.includes("linkedin_id") &&
        (!linkedin_id || linkedin_id.trim() == "")
      ) {
        setError(`error, please check LinkedIn Id for data input error`);
      }
      if (
        requiredFields.includes("pintrest_id") &&
        (!pintrest_id || pintrest_id.trim() == "")
      ) {
        setError(`error, please check Pinterest Id for data input error`);
      }
      if (
        requiredFields.includes("snapchat_id") &&
        (!snapchat_id || snapchat_id.trim() == "")
      ) {
        setError(`error, please check Snapchat Id for data input error`);
      }
      if (
        requiredFields.includes("tiktok_id") &&
        (!tiktok_id || tiktok_id.trim() == "")
      ) {
        setError(`error, please check Tiktok Id for data input error`);
      }
      if (
        requiredFields.includes("field_16") &&
        (!field_16 || field_16.trim() == "")
      ) {
        setError(`error, please check Website for data input error`);
      }
      if (
        requiredFields.includes("field_17") &&
        (!field_17 || field_17.trim() == "")
      ) {
        setError(
          `error, please check ${
            participantListColumns.filter((x) => x.name == "field_17")[0]
              ? participantListColumns.filter((x) => x.name == "field_17")[0]
                  .title
              : null
          } for data input error`
        );
      }
      if (
        requiredFields.includes("field_18") &&
        (!field_18 || field_18.trim() == "")
      ) {
        setError(
          `error, please check ${
            participantListColumns.filter((x) => x.name == "field_18")[0]
              ? participantListColumns.filter((x) => x.name == "field_18")[0]
                  .title
              : null
          }  for data input error`
        );
      }
      if (
        requiredFields.includes("field_19") &&
        (!field_19 || field_19.trim() == "")
      ) {
        setError(
          `error, please check ${
            participantListColumns.filter((x) => x.name == "field_19")[0]
              ? participantListColumns.filter((x) => x.name == "field_19")[0]
                  .title
              : null
          }  for data input error`
        );
      }
      if (
        requiredFields.includes("field_20") &&
        (!field_20 || field_20.trim() == "")
      ) {
        setError(
          `error, please check ${
            participantListColumns.filter((x) => x.name == "field_20")[0]
              ? participantListColumns.filter((x) => x.name == "field_20")[0]
                  .title
              : null
          }  for data input error`
        );
      }
      if (
        requiredFields.includes("field_21") &&
        (!field_21 || field_21.trim() == "")
      ) {
        setError(
          `error, please check ${
            participantListColumns.filter((x) => x.name == "field_21")[0]
              ? participantListColumns.filter((x) => x.name == "field_21")[0]
                  .title
              : null
          }  for data input error`
        );
      }
      if (
        requiredFields.includes("field_22") &&
        (!field_22 || field_22.trim() == "")
      ) {
        setError(
          `error, please check ${
            participantListColumns.filter((x) => x.name == "field_22")[0]
              ? participantListColumns.filter((x) => x.name == "field_22")[0]
                  .title
              : null
          }  for data input error`
        );
      }
      if (
        requiredFields.includes("field_23") &&
        (!field_23 || field_23.trim() == "")
      ) {
        setError(
          `error, please check ${
            participantListColumns.filter((x) => x.name == "field_23")[0]
              ? participantListColumns.filter((x) => x.name == "field_23")[0]
                  .title
              : null
          }  for data input error`
        );
      }
      if (
        requiredFields.includes("field_24") &&
        (!field_24 || field_24.trim() == "")
      ) {
        setError(
          `error, please check ${
            participantListColumns.filter((x) => x.name == "field_24")[0]
              ? participantListColumns.filter((x) => x.name == "field_24")[0]
                  .title
              : null
          }  for data input error`
        );
      }
      if (
        requiredFields.includes("field_25") &&
        (!field_25 || field_25.trim() == "")
      ) {
        setError(
          `error, please check ${
            participantListColumns.filter((x) => x.name == "field_25")[0]
              ? participantListColumns.filter((x) => x.name == "field_25")[0]
                  .title
              : null
          }  for data input error`
        );
      }
    } else {
      let tempObj = {
        id: id,
        name: name ? name.trim() : "",
        email: email_id ? email_id.trim() : "",
        contact: contact ? contact.trim() : "",
        company_id: companyId,
        business: business ? business.trim() : "",
        nfc_tag_id: nfc_tag_id ? nfc_tag_id.trim() : "",
        linkedin_id: linkedin_id ? linkedin_id.trim() : "",
        is_registered: is_registered,
        last_name: last_name ? last_name.trim() : "",
        address: address ? address.trim() : "",
        facebook_id: facebook_id ? facebook_id.trim() : "",
        instagram_id: instagram_id ? instagram_id.trim() : "",
        twitter_id: twitter_id ? twitter_id.trim() : "",
        youtube_id: youtube_id ? youtube_id.trim() : "",
        pintrest_id: pintrest_id ? pintrest_id.trim() : "",
        snapchat_id: snapchat_id ? snapchat_id.trim() : "",
        tiktok_id: tiktok_id ? tiktok_id.trim() : "",
        field_16: field_16 ? field_16.trim() : "",
        field_17: field_17 ? field_17.trim() : "",
        field_18: field_18 ? field_18.trim() : "",
        field_19: field_19 ? field_19.trim() : "",
        field_20: field_20 ? field_20.trim() : "",
        field_21: field_21 ? field_21.trim() : "",
        field_22: field_22 ? field_22.trim() : "",
        field_23: field_23 ? field_23.trim() : "",
        field_24: field_24 ? field_24.trim() : "",
        field_25: field_25 ? field_25.trim() : "",
      };

      document.getElementById("addParticipantSubmit").disabled = true;

      addParticipant(tempObj)
        .then((response) => {
          // console.log(response);
          document.getElementById("addParticipantSubmit").disabled = true;
          setAddNewParticipantModalFlag(false);
          setAddSneakBarFlag(true);
          getParticipantListHandler(companyId, companyName);
          setEditFlag(false);
          setAddNewFlag(false);
        })
        .catch((error) => {
          setError("Please fill the * marked fields correctly", error.message);

          document.getElementById("addParticipantSubmit").disabled = false;
        });
    }
  };

  const deleteParticipantHandler = () => {
    deleteParticipant(selectedId).then((response) => {
      // console.log(response)
      setDeleteSneakBarFlag(true);
      setDeleteModalFlag(false);

      getParticipantListHandler(companyId, companyName);
    });
  };
  const getReportDataHandler = (id) => {
    getReport(id).then(async (response) => {
      await setReportData(response.data);
      await setReportDataKeys(Object.keys(response.data));
    });
  };

  const downloadParticipantCsv = async () => {
    let participantDataTemp = [];

    let field16 = companyFormFieldList.filter(
      (x) => x.field_name == "field_16"
    )[0].label
      ? companyFormFieldList.filter((x) => x.field_name == "field_16")[0].label
      : "field 16";
    let field17 = companyFormFieldList.filter(
      (x) => x.field_name == "field_17"
    )[0].label
      ? companyFormFieldList.filter((x) => x.field_name == "field_17")[0].label
      : "field 17";
    let field18 = companyFormFieldList.filter(
      (x) => x.field_name == "field_18"
    )[0].label
      ? companyFormFieldList.filter((x) => x.field_name == "field_18")[0].label
      : "field 18";
    let field19 = companyFormFieldList.filter(
      (x) => x.field_name == "field_19"
    )[0].label
      ? companyFormFieldList.filter((x) => x.field_name == "field_19")[0].label
      : "field 19";
    let field20 = companyFormFieldList.filter(
      (x) => x.field_name == "field_20"
    )[0].label
      ? companyFormFieldList.filter((x) => x.field_name == "field_20")[0].label
      : "field 20";
    let field21 = companyFormFieldList.filter(
      (x) => x.field_name == "field_21"
    )[0].label
      ? companyFormFieldList.filter((x) => x.field_name == "field_21")[0].label
      : "field 21";
    let field22 = companyFormFieldList.filter(
      (x) => x.field_name == "field_22"
    )[0].label
      ? companyFormFieldList.filter((x) => x.field_name == "field_22")[0].label
      : "field 22";
    let field23 = companyFormFieldList.filter(
      (x) => x.field_name == "field_23"
    )[0].label
      ? companyFormFieldList.filter((x) => x.field_name == "field_23")[0].label
      : "field 23";
    let field24 = companyFormFieldList.filter(
      (x) => x.field_name == "field_24"
    )[0].label
      ? companyFormFieldList.filter((x) => x.field_name == "field_24")[0].label
      : "field 24";
    let field25 = companyFormFieldList.filter(
      (x) => x.field_name == "field_25"
    )[0].label
      ? companyFormFieldList.filter((x) => x.field_name == "field_25")[0].label
      : "field 25";

    getParticipantCompleteDetail(companyId).then(async (response) => {
      setParticipantsDetails(response.data);

      let participantsDetailsTemp = response.data;
      let objKeys = Object.keys(response.data[0]);
      participantsDetailsTemp.map((obj, index) => {
        let row = {};

        row = {
          name: obj.name ? obj.name : "",
          "last name": obj.last_name ? obj.last_name : "",
          "register date": obj.register_timestamp
            ? moment(obj.register_timestamp).format("MM-DD-YYYY HH:MM")
            : "-",
          email: obj.email_id ? obj.email_id : "",
          business: obj.business ? obj.business : "",
          contact: obj.contact ? obj.contact : "",
          address: obj.address ? obj.address : "",
          nfcid: obj.nfc_tag_id ? obj.nfc_tag_id : "",
          linkedin: obj.linkedin_id ? obj.linkedin_id : "",
          "facebook id": obj.facebook_id ? obj.facebook_id : "",
          "instagram id": obj.instagram_id ? obj.instagram_id : "",
          "twitter id": obj.twitter_id ? obj.twitter_id : "",
          "youtube id": obj.youtube_id ? obj.youtube_id : "",
          "pinterest id": obj.pintrest_id ? obj.pintrest_id : "",
          "snapchat id": obj.snapchat_id ? obj.snapchat_id : "",
          "tiktok id": obj.tiktok_id ? obj.tiktok_id : "",
          [field16]: obj.field_16 ? obj.field_16 : "",
          [field17]: obj.field_17 ? obj.field_17 : "",
          [field18]: obj.field_18 ? obj.field_18 : "",
          [field19]: obj.field_19 ? obj.field_19 : "",
          [field20]: obj.field_20 ? obj.field_20 : "",
          [field21]: obj.field_21 ? obj.field_21 : "",
          [field22]: obj.field_22 ? obj.field_22 : "",
          [field23]: obj.field_23 ? obj.field_23 : "",
          [field24]: obj.field_24 ? obj.field_24 : "",
          [field25]: obj.field_25 ? obj.field_25 : "",
          registered: obj.is_registered ? "True" : "False",
          contact_count: obj.contact_count ? obj.contact_count : 0,
          profile_photo_count: obj.profile_photo_count
            ? obj.profile_photo_count
            : 0,
          taparazzi_count: obj.taparazzi_count ? obj.taparazzi_count : 0,
          check_in_count: obj.check_in_count ? obj.check_in_count : 0,
        };
        stationList.map((stn, index) => {
          // console.log(stn.name.replace(/ /g, "_")+ "_Duration",obj[stn.name.replace(/ /g, "_")+ "_Duration"]);
          row[stn.name + " checked in"] = obj[stn.name.replace(/ /g, "_")];
          row[stn.name + " timings"] =
            obj[stn.name.replace(/ /g, "_") + "_Timings"];
          row[stn.name + " duration (in mins)"] =
            obj[stn.name.replace(/ /g, "_") + "_Duration"];
        });

        participantDataTemp.push(row);
      });

      reportDataKeys.map((data) => {
        let row = {
          name: "total number of " + data,
          "last name": reportData[data],
          email: "",
          business: "",
          contact: "",
          address: "",
          nfcid: "",
          linkedin: "",
          "facebook id": "",
          "instagram id": "",
          "twitter id": "",
          "youtube id": "",
          "pinterest id": "",
          "snapchat id": "",
          "tiktok id": "",
          [field16]: "",
          [field17]: "",
          [field18]: "",
          [field19]: "",
          [field20]: "",
          [field21]: "",
          [field22]: "",
          [field23]: "",
          [field24]: "",
          [field25]: "",
          registered: "",
          contact_count: "",
          profile_photo_count: "",
          taparazzi_count: "",
          check_in_count: "",
        };
        stationList.map((stn, index) => {
          row[stn.name + " checked in"] = "";
          row[stn.name + " timings"] = "";
          row[stn.name + " duration (in mins)"] = "";
        });
        participantDataTemp.push(row);
      });

      const options = {
        fieldSeparator: ",",
        filename: companyName,
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: true,
        title: "Participant Details",
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
      };

      const csvExporter = new ExportToCsv(options);

      csvExporter.generateCsv(participantDataTemp);
    });
  };

  const searchByKeyword = (e) => {
    let tempParticipantListPayload = { ...participantListPayload };
    tempParticipantListPayload.search_by = e.target.value;
    // console.log("company search", tempParticipantListPayload);
    setParticipantListPayload(tempParticipantListPayload);
  };

  const createSortHandler = (property) => (event) => {
    let tempParticipantListPayload = { ...participantListPayload };
    tempParticipantListPayload.sort_by = property;
    tempParticipantListPayload.order_by =
      participantListPayload.order_by === "asc" ? "desc" : "asc";
    setParticipantListPayload(tempParticipantListPayload);
    // this.getClientReportsListHandler(this.inspectionListPayload);
  };

  const handleChangePage = (event, newPage) => {
    let tempParticipantListPayload = { ...participantListPayload };
    tempParticipantListPayload.offset = newPage + 1;

    setParticipantListPayload(tempParticipantListPayload);
    // this.getClientReportsListHandler(this.inspectionListPayload);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let tempParticipantListPayload = { ...participantListPayload };
    tempParticipantListPayload.limit = parseInt(event.target.value);
    setParticipantListPayload(tempParticipantListPayload);
    // this.getClientReportsListHandler(this.inspectionListPayload);
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  console.log(participantListColumns);

  return (
    <div>
      <div className="icon">
        <NavLink to={"/admin/companyList"}>
          <button type="button" className="trans-btn mr-r10">
            <svg
              width="20"
              height="16"
              viewBox="0 0 20 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.33333 2.86765e-05C8.66437 -0.00183006 8.98849 0.0915604 9.26435 0.268294C9.54022 0.445028 9.75534 0.697096 9.88228 0.992364C10.0092 1.28763 10.0422 1.61272 9.97713 1.92618C9.91201 2.23965 9.75171 2.52728 9.51667 2.75241L4.01667 8.04794L9.51667 13.3435C9.78971 13.6514 9.93239 14.0475 9.91618 14.4525C9.89998 14.8576 9.7261 15.2419 9.42927 15.5286C9.13245 15.8152 8.73455 15.9832 8.31509 15.9988C7.89563 16.0144 7.4855 15.8767 7.16667 15.613L0.499999 9.17464C0.18958 8.87307 0.015344 8.46512 0.0153439 8.03989C0.0153439 7.61466 0.18958 7.20671 0.499999 6.90513L7.16667 0.466808C7.4771 0.169448 7.8961 0.00180636 8.33333 2.86765e-05Z"
                fill="#0099CC"
              />
              <path
                d="M1.66667 6.43835L18.3333 6.43835C18.7754 6.43835 19.1993 6.60793 19.5118 6.90979C19.8244 7.21164 20 7.62105 20 8.04793C20 8.47482 19.8244 8.88422 19.5118 9.18608C19.1993 9.48793 18.7754 9.65752 18.3333 9.65752L1.66667 9.65752C1.22464 9.65752 0.800716 9.48794 0.488155 9.18608C0.175594 8.88423 -6.57872e-07 8.47482 -6.95192e-07 8.04794C-7.32511e-07 7.62105 0.175594 7.21164 0.488155 6.90979C0.800716 6.60793 1.22464 6.43835 1.66667 6.43835Z"
                fill="#0099CC"
              />
            </svg>
          </button>
        </NavLink>
        <span className="">Dashboard</span>
        <span className=""> &#62; {companyName}</span>
      </div>

      <div className="mr-t20">
        <div className="d-flex justify-content-end mr-r10">
          {/* <a href={window.location.origin + "/Sample_Csv.csv"}>
            {" "}
            Download Sample CSV File{" "}
          </a> */}
        </div>
        <div className="data-head">
          {/* <div className="cus-search">
                            <input type="text" name="" id="" value={inspectionListPayload.search_by} onChange={searchByKeyword} placeholder="Search" />
                        </div> */}

          <div className="page-title mr-l10">
            <h3>Participant List</h3>
          </div>
          {bulkUploadFlagWithoutNfc && (
            <div className="head-right-block">
              <div className="add-btn">
                <button
                  type="button"
                  className="s-blue-btn m-btn mr-l20"
                  onClick={() => setBulkUploadFlagWithoutNfc(false)}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  className="s-blue-btn m-btn mr-l20"
                  onClick={() => setSendUploadMsgWithoutNfc(1)}
                >
                  Upload NFC
                </button>
              </div>
            </div>
          )}
          {!bulkUploadFlag && !bulkUploadFlagWithoutNfc && (
            <div className="add-btn d-flex">
              <div className="cus-search">
                <input
                  type="text"
                  name=""
                  id=""
                  value={participantListPayload.search_by}
                  onChange={searchByKeyword}
                  placeholder="Search"
                />
              </div>
              <button
                type="button"
                className="s-blue-btn m-btn mr-l20"
                onClick={bulkUploadWithoutNfc}
              >
                Bulk Without NFC
              </button>
              <button
                type="button"
                className="s-blue-btn m-btn mr-l20"
                onClick={deleteAllRecords}
              >
                Delete All Record{" "}
              </button>
              <button
                type="button"
                className="s-blue-btn m-btn mr-l20"
                onClick={meetMaxApi}
              >
                MeetMaX Data Update
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="cus-table">
        <div className="table-str">
          {!bulkUploadFlag && !bulkUploadFlagWithoutNfc ? (
            <TableContainer component={Paper}>
              <Table aria-label="custom pagination table">
                <TableHead>
                  <TableRow>
                    {/* {participantListColumns} */}
                    {participantListColumns.map((column) => (
                      <TableCell key={column.name} className="align-top">
                        {(column.name = column.title)}
                        {/* {column.name !== "action" ? (
                                                    <TableSortLabel
                                                        active={
                                                            inspectionListPayload
                                                                .sort_by === column.name
                                                        }
                                                        direction={
                                                            inspectionListPayload
                                                                .sort_by === column.name
                                                                ? inspectionListPayload
                                                                    .order_by
                                                                : "asc"
                                                        }
                                                        onClick={createSortHandler(
                                                            column.name
                                                        )}
                                                    >
                                                        {column.title}
                                                        {inspectionListPayload
                                                            .sort_by === column.name ? (
                                                            <span
                                                                style={{
                                                                    border: 0,
                                                                    clip: "rect(0 0 0 0)",
                                                                    height: 1,
                                                                    margin: -1,
                                                                    overflow: "hidden",
                                                                    padding: 0,
                                                                    position: "absolute",
                                                                    top: 20,
                                                                    width: 1,
                                                                }}
                                                            >
                                                                {inspectionListPayload
                                                                    .order_by === "desc"
                                                                    ? "sorted descending"
                                                                    : "sorted ascending"}
                                                            </span>
                                                        ) : null}
                                                    </TableSortLabel>
                                                ) : (
                                                    column.title
                                                )} */}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {this.state.reportsUpcomingList} */}
                  {participantListDisplay}
                  {/* {this.state.loading ? <Spin indicator={<div class="loading">Loading&#8230;</div>}/> : this.state.reportsUpcomingList} */}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        50,
                        { label: "All", value: -1 },
                      ]}
                      colSpan={9}
                      count={tableCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          ) : bulkUploadFlagWithoutNfc ? (
            <BulkUploadWithoutNfc
              bulkUploadArrayWithoutNfc={bulkUploadArrayWithoutNfc}
              uploadMessage={sendUploadMsgWithoutNfc}
              companyId={companyId}
              emit={(data) => doneUploadHandler(data)}
            />
          ) : (
            <BulkUpload
              bulkUploadArray={bulkUploadArray}
              uploadMessage={sendUploadMsg}
              companyId={companyId}
              emit={(data) => doneUploadHandler(data)}
            />
          )}
        </div>
      </div>

      <input
        id="fileSelect"
        type="file"
        style={{ display: "none" }}
        onChange={onFileChange}
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      <input
        id="fileSelect"
        type="file"
        style={{ display: "none" }}
        ref={fileSelect}
        onChange={onFileWithotNfcChange}
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />

      <Modal
        open={addNewParticipantModalFlag}
        disableBackdropClick={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className="modal blue-modal fade"
          id="addInsuranceModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  {editFlag ? "Update" : "Add"} User
                </h5>
              </div>
              <div className="modal-body">
                <div className="ins-modal-body">
                  <form action="">
                    <div className="row">
                      {addNewFormFieldsList}
                      {/* <div className="col-sm-6">
                                                <div className="form-group gray-fill-input mr-b30">
                                                    <label htmlFor="">Participant Name</label>
                                                    <input type="text" className="form-control" name="participantName" id=""
                                                        value={participantName}
                                                        onChange={textFieldChangeHandler}
                                            
                                                        placeholder="Participant Name" />
                                                    {participantNameError !== '' ? <p className="error-alert mr-b20">{participantNameError}</p> : null}
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group gray-fill-input mr-b30">
                                                    <label htmlFor="">Business</label>
                                                    <input type="text" className="form-control" name="business" id=""
                                                        value={business}
                                                        onChange={textFieldChangeHandler}
                                                        placeholder="Business" />
                                                    {businessError !== '' ? <p className="error-alert mr-b20">{businessError}</p> : null}
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group gray-fill-input mr-b30">
                                                    <label htmlFor="">Email</label>
                                                    <input type="text" className="form-control" name="email" id=""
                                                        value={email}
                                                        onChange={textFieldChangeHandler}
                                                        placeholder="Email" />
                                                    {emailError !== '' ? <p className="error-alert mr-b20">{emailError}</p> : null}
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group gray-fill-input mr-b30">
                                                    <label htmlFor="">Phone</label>
                                                    <input type="text" className="form-control" name="phone" id=""
                                                        value={phone}
                                                        onChange={textFieldChangeHandler}
                                                        placeholder="44 1234567890" />
                                                    {phoneError !== '' ? <p className="error-alert mr-b20">{phoneError}</p> : null}
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group gray-fill-input mr-b30">
                                                    <label htmlFor="">Linked-In Id</label>
                                                    <input type="text" className="form-control" name="linkedIn" id=""
                                                        value={linkedIn}
                                                        onChange={textFieldChangeHandler}
                                                        placeholder="Linked-In Id" />
                                                    {linkedInError !== '' ? <p className="error-alert mr-b20">{linkedInError}</p> : null}
                                                </div>
                                            </div>

                                            <div className="col-sm-6">
                                                <div className="form-group gray-fill-input mr-b30">
                                                    <label htmlFor="">NFC ID</label>
                                                    <input type="text" className="form-control" name="nfcTagId" id="nfcTagId"
                                                        disabled={editFlag}
                                                        value={nfcTagId}
                                                        onChange={textFieldChangeHandler}
                                                        placeholder="NFC ID" />
                                                    {nfcTagIdError !== '' ? <p className="error-alert mr-b20">{nfcTagIdError}</p> : null}
                                                </div>
                                            </div> */}

                      <div className="col-sm-6">
                        <div className="form-group gray-fill-input mr-b30">
                          <label htmlFor="">Is Registered</label>
                          <div>
                            <label>
                              <input
                                type="radio"
                                className=""
                                name="is_registered"
                                id=""
                                value={true}
                                checked={is_registered == 1 ? true : false}
                                onChange={(e) => {
                                  textFieldChangeHandler("is_registered", e);
                                }}
                              />
                              &nbsp; True
                            </label>
                          </div>
                          <div>
                            <label>
                              <input
                                type="radio"
                                className=""
                                name="is_registered"
                                id=""
                                checked={is_registered == 0 ? true : false}
                                value={false}
                                onChange={(e) => {
                                  textFieldChangeHandler("is_registered", e);
                                }}
                              />
                              &nbsp; False
                            </label>
                          </div>
                          {/* {linkedInError !== '' ? <p className="error-alert mr-b20">{linkedInError}</p> : null} */}
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="mr-l20 text-danger mr-b20">
                    Note : * marked are mandatory fields
                  </div>
                </div>
              </div>

              {error !== "" ? (
                <p className="error-alert mr-b20 mr-l40">{error}</p>
              ) : null}
              <div className="modal-footer pd-b30">
                <button
                  type="button"
                  className="s-blue-border-btn m-btn"
                  data-dismiss="modal"
                  onClick={() => {
                    setAddNewParticipantModalFlag(false);
                    setEditFlag(false);
                    setIsRegistered(false);
                    setAddNewFlag(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="s-blue-btn m-btn"
                  data-dismiss="modal"
                  id="addParticipantSubmit"
                  onClick={() =>
                    addParticipantHandler(editFlag ? participantId : 0)
                  }
                >
                  {editFlag ? "Update" : "Add"} Participant
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={deleteModalFlag}
        disableBackdropClick={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className="modal blue-modal fade"
          id="addInsuranceModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Confirmation
                </h5>
              </div>
              <div className="modal-body">
                Want to delete Selected Participant ?
              </div>

              <div className="modal-footer pd-b30">
                <button
                  type="button"
                  className="s-blue-border-btn m-btn"
                  data-dismiss="modal"
                  onClick={() => setDeleteModalFlag(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="s-blue-btn m-btn"
                  onClick={deleteParticipantHandler}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={assignModalFlag}
        disableBackdropClick={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className="modal blue-modal fade"
          id="addInsuranceModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Assign New NFC ID
                </h5>
              </div>

              <div className="modal-body">
                <div className="ins-modal-body">
                  <form action="">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group gray-fill-input mr-b30">
                          <label htmlFor="">New NFC ID</label>
                          <input
                            type="text"
                            className="form-control"
                            name="newNfcId"
                            id=""
                            value={newNfcId}
                            onChange={assignNewNfcTextFielcdChangeHandler}
                            placeholder="New NFC ID"
                          />
                          {newNfcIdError !== "" ? (
                            <p className="error-alert mr-b20">
                              {newNfcIdError}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="modal-footer pd-b30">
                <button
                  type="button"
                  className="s-blue-border-btn m-btn"
                  data-dismiss="modal"
                  onClick={() => {
                    setAssignModalFlag(false);
                    setNewNfcId("");
                    setNewNfcIdError("");
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="s-blue-btn m-btn"
                  onClick={assignNewNfcIdSubmitHandler}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Snackbar
        open={addSneakBarFlag}
        autoHideDuration={6000}
        onClose={() => {
          setAddSneakBarFlag(false);
          setEditSneakerFlag(false);
        }}
      >
        <Alert
          onClose={() => {
            setAddSneakBarFlag(false);
            setEditSneakerFlag(false);
          }}
          severity="success"
        >
          Participant Is Successfully {editSneakerFlag ? "Updated" : "Added"}
        </Alert>
      </Snackbar>

      <Snackbar
        open={deleteSneakBarFlag}
        autoHideDuration={6000}
        onClose={() => {
          setDeleteSneakBarFlag(false);
        }}
      >
        <Alert
          onClose={() => {
            setDeleteSneakBarFlag(false);
          }}
          severity="success"
        >
          Participant Is Successfully Deleted
        </Alert>
      </Snackbar>

      <Snackbar
        open={assignNewNfcSneakBarFlag}
        autoHideDuration={6000}
        onClose={() => {
          setAssignNewNfcSneakBarFlag(false);
        }}
      >
        <Alert
          onClose={() => {
            setAssignNewNfcSneakBarFlag(false);
          }}
          severity="success"
        >
          New NFC Id Is successfully assigned.
        </Alert>
      </Snackbar>

      {loaderFlag ? <div className="loading">Loading&#8230;</div> : null}
    </div>
  );
};

export default ParticipantListWithoutNfc;
