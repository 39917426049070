import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import React from "react";
import draftToHtml from "draftjs-to-html";

const TextEditor = ({
  switchEditor,
  setSwitchEditor,
  htmlInstruction,
  setHtmlInstruction,
  plainText,
  setPlainText,
  title,
}) => {
  const handleCustomInstructionHtmlEditor = () => {
    setSwitchEditor(!switchEditor);
  };
  const onEditorStateChange = (e) => {
    let des = draftToHtml(convertToRaw(e.getCurrentContent()))
      .replaceAll("&lt;", "<")
      .replaceAll("&gt;", ">");
      setPlainText(des);
    setHtmlInstruction(e);
  };
  return (
    <div>
      {" "}
      <div className="">
        <div className="form-group gray-fill-input mr-b30">
          <label htmlFor="">{title}</label>

          <div className="d-flex justify-content-end">
            Html Editor
            <label className="switch mr-b10 mr-l10">
              <input
                type="checkbox"
                checked={switchEditor}
                onChange={() => {
                  handleCustomInstructionHtmlEditor();
                }}
              />
              <span className="slider round"></span>
            </label>
          </div>

          <div
            dangerouslySetInnerHTML={{
              __html: plainText,
            }}
          ></div>
        </div>
      </div>
      <div className="">
        <div className="form-group gray-fill-input mr-b30">
          {switchEditor ? (
            <Editor
              editorState={htmlInstruction}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={(e) => onEditorStateChange(e)}
              toolbar={{
                image: {
                  previewImage: true,
                  defaultSize: {
                    height: "250px",
                    width: "200px",
                  },
                },
              }}
            />
          ) : (
            <textarea
              rows="100"
              className="form-control"
              value={plainText}
              onChange={(e) => {
                setPlainText(e.target.value);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TextEditor;
