import React from "react";
import ViewHistory1 from "../../assets/images/icon1.png";
import ViewHistory from "../../assets/images/ViewHistory-ico.png";
import { Close } from "@material-ui/icons";
import { Modal } from "@material-ui/core";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import FooterSocialMediaIcon from "./FooterSocialMediaIcon";
import { useRecoilState } from "recoil";
import { companyNameState } from "./state";

const PreviewModal = ({
  row,
  preview,
  setPreview,
  img,
  selectedSocialMediaList,
  mediaList,
  footerImage,
}) => {
  const param = useParams();
  const [companyName, setCompanyName] = useRecoilState(companyNameState);
  return (
    <Modal
      open={preview}
      disableBackdropClick={false}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      key={row?.name}
    >
      <div
        className={`${companyName}-participant-page-checkin-modal participant-page-checkin-modal modal blue-modal fade`}
        id="addInsuranceModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className={`${companyName}-participant-page-checkin-modal-dialog participant-page-checkin-modal-dialog modal-dialog modal656 modal-dialog-end modal-dialog-scrollable`}
        >
          <div
            className={`${companyName}-participant-page-checkin-modal-content participant-page-checkin-modal-content modal-content modal-icon`}
          >
            <div class="action-sheetstyled__Header-card__sc-1n3yh67-4 jDqeQC">
              <div className="d-flex">
                {" "}
                <img
                  loading="lazy"
                  src={ViewHistory1}
                  alt=""
                  style={{ width: 35, cursor: "pointer" }}
                  //   onClick={() => setFilterIcon(!filterIcon)}
                  // className="cursor"
                />
              </div>
              <div class="elevation__Elevation-card__sc-kxnq68-0 action-sheetstyled__Avatar-card__sc-1n3yh67-0 etbhvz iyheF mdc-elevation--z6">
                <img
                  loading="lazy"
                  src={ViewHistory}
                  alt=""
                  style={{ width: 50 }}
                  className=""
                />
              </div>
              <button
                type="button"
                className={`${companyName}-participant-page-checkin-modal-close-button participant-page-checkin-modal-close-button btn-close`}
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setPreview(false);
                }}
              >
                <Close onClick={() => setPreview(false)} />
              </button>
            </div>
            {/* <div className="cirle-icon d-flex align-items-center">
            <div className="cirle text-center">
              <img loading="lazy" 
                src={ViewHistory}
                alt=""
                style={{ width: 50 }}
                className="mt-2"
              />
            </div>
          </div> */}

            <div
              className={`${companyName}-participant-page-checkin-modal-body participant-page-checkin-modal-body modal-body pt-0`}
              id="stationListDiv"
            >
              <div className="sticky-top pt-2 bg-white" style={{ zIndex: "1" }}>
                <div
                  className={`${companyName}-participant-page-checkin-modal-title participant-page-checkin-modal-title`}
                >
                  <h4
                    className={`${companyName}-participant-page-checkin-modal-title-text ${companyName}-participant-page-checkin-modal-title-text-custom participant-page-checkin-modal-title-text-custom  participant-page-checkin-modal-title-text`}
                  ></h4>
                </div>

                {false && (
                  <div className="search-filter">
                    <div className="row d-flex justify-content-end">
                      <div className="col-sm-4 mb-3">
                        <div class="form-group">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Search"
                            // value={searchText}
                            // onChange={(e) => {
                            //   setSearchText(e.target.value);
                            // }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-4 mb-3">
                        <select
                          class="form-select"
                          //   value={filterType}
                          //   onChange={(e) => {
                          //     setFilterType(e.target.value);
                          //   }}
                          defaultValue={"lastStation"}
                        >
                          <option selected>Select....</option>
                          <option value="lastStation">
                            Last Station Visited
                          </option>
                          <option value="alphaAscending">
                            Alpha Ascending
                          </option>
                          <option value="alphaDescending">
                            Alpha Descending
                          </option>
                        </select>
                      </div>
                      <div className="col-sm-2 mb-3">
                        <button
                          type="button"
                          class="btn btn-primary w-100"
                          //   onClick={() => {
                          //     setFilterType("lastStation");
                          //     setSearchText("");
                          //   }}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {true ? (
                <div
                  className={`${companyName}-participant-page-checkin-cards-div participant-page-checkin-cards-div`}
                >
                  <div
                    className={`${companyName}-participant-page-checkin-cards-title-div participant-page-checkin-cards-title-div text-center`}
                  >
                    <h4
                      className={`${companyName}-participant-page-checkin-cards-title-text ${companyName}-participant-page-checkin-modal-subtitle-text-custom participant-page-checkin-cards-title-text participant-page-checkin-modal-subtitle-text-custom`}
                    >
                      {row.name}
                    </h4>
                    <h5
                      className={`${companyName}-participant-page-checkin-status participant-page-checkin-status`}
                    >
                      Status: {"Checked in"}
                    </h5>
                  </div>
                  <div
                    className={`${companyName}-participant-page-station-description-div participant-page-station-description-div`}
                  >
                    <div>
                      <img
                        src={img}
                        alt=""
                        style={{ width: "100%" }}
                        className={row?.headerUrl ? "cursor" : ""}
                        onClick={() =>
                          window.open(
                            row?.headerUrl?.includes("http")
                              ? row?.headerUrl
                              : "https://" + row?.headerUrl,
                            row.headerOpenIn ? "_blank" : "_self"
                          )
                        }
                      />
                    </div>

                    <div
                      dangerouslySetInnerHTML={{ __html: row.description }}
                    ></div>
                    {}
                    <FooterSocialMediaIcon iconList={mediaList} />
                    <div>
                      <img
                        src={footerImage}
                        alt=""
                        style={{ width: "100%" }}
                        className={row?.footerUrl ? "cursor" : ""}
                        onClick={() =>
                          window.open(
                            row.footerUrl?.includes("http")
                              ? row.footerUrl
                              : "https://" + row.footerUrl,
                            row.footerOpenIn ? "_blank" : "_self"
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={`${companyName}-participant-page-checkin-modal-nodata-div participant-page-checkin-modal-nodata-div`}
                >
                  <div
                    className={`${companyName}-participant-page-checkin-modal-nodata-text participant-page-checkin-modal-nodata-text`}
                  >
                    No Data History Available
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PreviewModal;
