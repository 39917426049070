import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';


const TopNavigation = (props) => {

    return (
        <header className="head-wrap">
            <div className="head-title">
                <h5>Welcome Admin</h5>
                <button type="button" className="trans-btn">
                    <NavLink to={'/'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M9.31995 6.5L11.8799 3.94L14.4399 6.5" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M11.88 14.18V4.00999" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M4 12C4 16.42 7 20 12 20C17 20 20 16.42 20 12" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </NavLink>

                </button>
            </div>
        </header>
    )
}

export default TopNavigation;