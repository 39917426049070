import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Modal, IconButton } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";

import * as Yup from "yup";
import {
  Close,
  CloseOutlined,
  ClosedCaptionOutlined,
  EditOutlined,
  Label,
} from "@material-ui/icons";
import { NavLink } from "react-router-dom";
import {
  addFooterConfigApi,
  addFooterImageApi,
  deleteFooterImageApi,
  getFooterImageListApi,
  updateFooterImageApi,
} from "../../services/adminApi";
// import { EditOffOutlined } from "@mui/icons-material";
import Config from "../../config.json";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
// import { toast } from "react-toastify";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
const schema = Yup.object().shape({
  // Define validation schema using Yup for your form fields
  delay: Yup.number().positive("Delay must be a positive number"),
  add_footer_image: Yup.mixed()
    .test("fileSize", "The file is too large", (value) => {
      return value.length && value[0].size <= 1024000; // 1MB
    })
    .test("fileFormat", "Unsupported file format", (value) => {
      return (
        value.length && ["image/jpeg", "image/png"].includes(value[0].type)
      );
    })
    .required("Please select an image"),
  sponsor_url: Yup.string()
    .url("Please enter a valid URL")
    .required("URL is required"),
});

const AddFooter = () => {
  const [sponsorList, getSponsorList] = useState([]);
  const [open, setOpen] = useState(false);
  const [sponsorConfig, setSponsorConfig] = useState(false);
  const [openUpdateModel, setOpenUpdateModel] = useState(false);
  const { id } = useParams();
  const [image, setImage] = useState({
    value: "",
    error: "",
  });
  const [configDelay, setConfigDelay] = useState({
    value: "1000",
    error: "",
  });
  const [configTransionEffect, setConfigTransionEffect] = useState({
    value: "",
    error: "",
  });
  const [url, setUrl] = useState({
    value: "",
    error: "",
  });
  const [tab, setTab] = useState({
    value: "0",
    error: "",
  });
  const handleOpen = () => setOpen(true);
  const handleUpdateModel = (id) => {
    setOpenUpdateModel(id);

    const filterRecord = sponsorList?.filter((record) => record.id == id);

    console.log(filterRecord);
    setUrl({ ...url, value: filterRecord[0]?.target_url });
    setTab({ ...tab, value: filterRecord[0]?.tab ? 1 : 0 });
    setImage({
      ...image,
      value: filterRecord[0]?.image,
      value1: Config.imageApiUrl + "/" + filterRecord[0]?.image,
    });
  };
  const handleClose = () => setOpen(false);

  const { register, handleSubmit, errors, watch, setValue } = useForm({
    // resolver: yupResolver(schema),
    defaultValues: {
      delay: 0,
      enableRadio: false,
    },
  });
  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setValue("add_footer_image", file);
    }
  };

  const onSubmit = (data) => {
    // Handle form submission here
    console.log(data);
  };

  const onFooterImageSubmit = () => {
    let errorCount = 0;
    if (!Boolean(image.value)) {
      setImage({ ...image, error: "Image is required" });
      errorCount += 1;
    }
    if (!Boolean(url.value?.trim())) {
      setUrl({ ...url, error: "Url is required" });
      errorCount += 1;
    }
    if (errorCount == 0)
      addFooterImageApi({
        image: image.value,
        company_id: id,
        image_url: url.value,
        open_tab: tab.value,
      })
        .then((result) => {
          setImage({ value: "", error: "" });
          setUrl({ value: "", error: "" });
          setOpen(false);
          getFooterImageList();
        })
        .catch((err) => console.log(err));
  };
  const onFooterConfigSubmit = () => {
    if (!Boolean(configDelay.value)) {
      setConfigDelay({ ...configDelay, error: "Delay is required" });
    }
    if (configDelay.value < 999) {
      setConfigDelay({
        ...configDelay,
        error: "Value must be greater than 1000ms",
      });
    }
    if (!Boolean(configTransionEffect.value)) {
      setConfigTransionEffect({
        ...configTransionEffect,
        error: "Effect is required",
      });
    }

    if (configTransionEffect?.error == "" && configDelay?.error == "")
      addFooterConfigApi({
        company_id: id,
        delay: configDelay.value,
        trasition_effect: configTransionEffect.value,
      }).then((result) => {
        setSponsorConfig(false);
        getFooterImageList();
      });
    // .catch((err) => toast.sucess(err.message));
  };
  const onFooterUpdateImageSubmit = () => {
    updateFooterImageApi({
      image: image.value,
      company_id: id,
      image_url: url.value,
      open_tab: tab.value,
      id: openUpdateModel,
    })
      .then((result) => {
        setImage({ value: "", error: "" });
        setUrl({ value: "", error: "" });
        setOpenUpdateModel(false);
        getFooterImageList();
      })
      .catch((err) => console.log(err));
  };
  const onFooterDeleteImageSubmit = (footer_id) => {
    deleteFooterImageApi({
      id: footer_id,
    })
      .then((result) => {
        setImage({ value: "", error: "" });
        setUrl({ value: "", error: "" });
        setOpenUpdateModel(false);
        getFooterImageList();
      })
      .catch((err) => console.log(err));
  };

  const getFooterImageList = () => {
    getFooterImageListApi(id)
      .then((result) => {
        getSponsorList(result.data.records);
        if (result.data?.details?.id) {
          setConfigDelay({ value: result.data.details?.delay, error: "" });
          setConfigTransionEffect({
            value: result.data.details?.transion_effect,
            error: "",
          });
        }
      })
      .catch((err) => console.log(err));
  };
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        // console.log("baseurl", baseURL);
        resolve(baseURL);
      };
      // console.log("fileinfo", fileInfo);
    });
  };

  const onSponsorImageChange = (e) => {
    const file = e.target.files[0];
    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        setImage({ value: result, error: "error", value1: result });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFooterImageList();
  }, []);
  console.log(id);
  return (
    <div>
      <div className="icon">
        <NavLink to={"/admin/ModuleForm"}>
          <button type="button" className="trans-btn mr-r10">
            <svg
              width="20"
              height="16"
              viewBox="0 0 20 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.33333 2.86765e-05C8.66437 -0.00183006 8.98849 0.0915604 9.26435 0.268294C9.54022 0.445028 9.75534 0.697096 9.88228 0.992364C10.0092 1.28763 10.0422 1.61272 9.97713 1.92618C9.91201 2.23965 9.75171 2.52728 9.51667 2.75241L4.01667 8.04794L9.51667 13.3435C9.78971 13.6514 9.93239 14.0475 9.91618 14.4525C9.89998 14.8576 9.7261 15.2419 9.42927 15.5286C9.13245 15.8152 8.73455 15.9832 8.31509 15.9988C7.89563 16.0144 7.4855 15.8767 7.16667 15.613L0.499999 9.17464C0.18958 8.87307 0.015344 8.46512 0.0153439 8.03989C0.0153439 7.61466 0.18958 7.20671 0.499999 6.90513L7.16667 0.466808C7.4771 0.169448 7.8961 0.00180636 8.33333 2.86765e-05Z"
                fill="#0099CC"
              />
              <path
                d="M1.66667 6.43835L18.3333 6.43835C18.7754 6.43835 19.1993 6.60793 19.5118 6.90979C19.8244 7.21164 20 7.62105 20 8.04793C20 8.47482 19.8244 8.88422 19.5118 9.18608C19.1993 9.48793 18.7754 9.65752 18.3333 9.65752L1.66667 9.65752C1.22464 9.65752 0.800716 9.48794 0.488155 9.18608C0.175594 8.88423 -6.57872e-07 8.47482 -6.95192e-07 8.04794C-7.32511e-07 7.62105 0.175594 7.21164 0.488155 6.90979C0.800716 6.60793 1.22464 6.43835 1.66667 6.43835Z"
                fill="#0099CC"
              />
            </svg>
          </button>
        </NavLink>
        <span className="">Dashboard</span>
      </div>
      {/* <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12">
            <div className="">
              <div className="form-group gray-fill-input mr-b30">
                <label htmlFor="">Footer Section Enabled/Disabled </label>

                <input
                  className="mr-l20 mr-t20 pt-1"
                  type="checkbox"
                  name="footer_section"
                  {...register("footer_section")}
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group gray-fill-input mr-b30">
              <label htmlFor="">Delay</label>
              <input
                className="form-control mr-t20"
                type="number"
                name="delay"
                {...register("delay")}
              />
            </div>
          </div>

          <div className="add-btn mr-t50 mr-b100 pd-b50 d-flex justify-content-center ">
         
            <button
              className="s-blue-btn m-btn mr-l20 mr-r10"
              id="previewButton"
              type="submit"
            >
              Submit
            </button>
            <button
              className="s-blue-btn m-btn mr-l20 mr-r10"
              id="previewButton"
              type="submit"
              onClick={handleOpen}
            >
              Add More Image
            </button>
          </div>
          <div className="add-btn mr-t50 mr-b100 pd-b50 d-flex justify-content-center "></div>
        </div>
      </form> */}
      {sponsorList?.map((record, index) => (
        <div
          className="row m-3"
          key={record.id}
          style={{ borderBottom: "1px solid black" }}
        >
          <div className="d-flex justify-content-between">
            <div>
              <h6>Sponsor {index + 1}</h6>
            </div>
            <div>
              <IconButton onClick={() => handleUpdateModel(record.id)}>
                <EditOutlined />
              </IconButton>
              <IconButton onClick={() => onFooterDeleteImageSubmit(record.id)}>
                <CloseOutlined />
              </IconButton>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group gray-fill-input mr-b30">
              <label htmlFor="">Url</label>
              <input
                type="text"
                className="form-control"
                name="buttonUrl"
                id=""
                placeholder="target_url"
                value={record.target_url}
                disabled={true}
              />
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group gray-fill-input mr-b30">
              <label htmlFor="">Open</label>
              <select
                // onChange={textFieldChangeHandler}
                className="form-control"
                value={record.open}
                disabled={true}
              >
                <option value="0">Open in current window</option>
                <option value="1">Open in new window</option>
              </select>
              {tab?.error !== "" ? (
                <p className="error-alert mr-b20">{tab?.error}</p>
              ) : null}
            </div>
          </div>
          <div className="col-md-4">
            <div>
              <div className="form-group gray-fill-input mr-b30">
                <label htmlFor="">Image</label>
              </div>
              <img
                src={`${Config.imageApiUrl}/` + record.image}
                alt=""
                className="img-upload-preview1"
              />
            </div>
          </div>
        </div>
      ))}{" "}
      <div className="add-btn mr-t50 mr-b100 pd-b50 d-flex justify-content-center ">
        {/* <button className="s-blue-btn m-btn mr-l20 mr-r10"
                            id="previewButton"
                            disabled={!logoImg && !sponsorImg && !defaultProfileImg && !appHeaderImg && !description}
                            onClick={() => previewHandler()}>
                            Preview</button> */}
        <button
          className="s-blue-btn m-btn mr-l20 mr-r10"
          id="previewButton"
          type="submit"
          onClick={() => setSponsorConfig(true)}
        >
          Config Sponsor
        </button>
        <button
          className="s-blue-btn m-btn mr-l20 mr-r10"
          id="previewButton"
          type="submit"
          onClick={handleOpen}
        >
          Add More Image
        </button>
      </div>
      <Modal
        open={open}
        disableBackdropClick={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className="modal modal-xl blue-modal fade"
          id="addInsuranceModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable"
            style={{ maxWidth: "1000px" }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add Footer Image
                </h5>
              </div>
              <div className="modal-body">
                <div className="ins-modal-body">
                  <form action="">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group gray-fill-input mr-b30">
                          <label htmlFor="">Url</label>
                          <input
                            type="text"
                            className="form-control"
                            name="buttonUrl"
                            id=""
                            placeholder="Url"
                            value={url?.value}
                            onChange={(event) =>
                              setUrl({
                                value: event.target.value,
                                error: "",
                              })
                            }
                          />
                          {url?.error !== "" ? (
                            <p className="error-alert mr-b20">{url?.error}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group gray-fill-input mr-b30">
                          <label htmlFor="">Open</label>
                          <select
                            name="windows"
                            id="windows"
                            value={tab?.value}
                            // onChange={textFieldChangeHandler}
                            className="form-control"
                            onChange={(event) =>
                              setTab({
                                value: event.target.value,
                                error: "",
                              })
                            }
                          >
                            <option value="0">Open in current window</option>
                            <option value="1">Open in new window</option>
                          </select>
                          {tab?.error !== "" ? (
                            <p className="error-alert mr-b20">{tab?.error}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        {image?.value == "" ? (
                          <div className="form-group gray-fill-input mr-b30">
                            <label htmlFor="">Image</label>
                            <input
                              type="file"
                              className="form-control"
                              name="buttonLabel"
                              id=""
                              accept=".jpg, .jpeg, .png"
                              // value={buttonLabel}
                              onChange={onSponsorImageChange}
                              placeholder="Label"
                            />
                            {image?.error !== "" ? (
                              <p className="error-alert mr-b20">
                                {image?.error}
                              </p>
                            ) : null}
                          </div>
                        ) : (
                          <div>
                            <Close
                              className="img-icon"
                              onClick={() => setImage({ ...image, value: "" })}
                            />
                            <img
                              src={image.value}
                              className="img-upload-preview"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer pd-b30">
                <button
                  type="button"
                  className="s-blue-border-btn m-btn"
                  data-dismiss="modal"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="s-blue-btn m-btn"
                  data-dismiss="modal"
                  id="addButtonSubmit"
                  onClick={onFooterImageSubmit}
                  // onClick={addButtonHandler}
                  // onClick={() => addButtonHandler(editFlag ? selectedId : 0)}
                >
                  {/* {editFlag ? "Update " : "Add "} */}
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={openUpdateModel}
        disableBackdropClick={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className="modal modal-xl blue-modal fade"
          id="addInsuranceModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable"
            style={{ maxWidth: "1000px" }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Update Footer Image
                </h5>
              </div>
              <div className="modal-body">
                <div className="ins-modal-body">
                  <form action="">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group gray-fill-input mr-b30">
                          <label htmlFor="">Url</label>
                          <input
                            type="text"
                            className="form-control"
                            name="buttonUrl"
                            id=""
                            placeholder="Url"
                            value={url?.value}
                            onChange={(event) =>
                              setUrl({
                                value: event.target.value,
                                error: "",
                              })
                            }
                          />
                          {url?.error !== "" ? (
                            <p className="error-alert mr-b20">{url?.error}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group gray-fill-input mr-b30">
                          <label htmlFor="">Open</label>
                          <select
                            name="windows"
                            id="windows"
                            value={tab?.value}
                            // onChange={textFieldChangeHandler}
                            className="form-control"
                            onChange={(event) =>
                              setTab({
                                value: event.target.value,
                                error: "",
                              })
                            }
                          >
                            <option value="0">Open in current window</option>
                            <option value="1">Open in new window</option>
                          </select>
                          {tab?.error !== "" ? (
                            <p className="error-alert mr-b20">{tab?.error}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        {image?.value == "" ? (
                          <div className="form-group gray-fill-input mr-b30">
                            <label htmlFor="">Image</label>
                            <input
                              type="file"
                              className="form-control"
                              name="buttonLabel"
                              id=""
                              accept=".jpg, .jpeg, .png"
                              // value={buttonLabel}
                              onChange={onSponsorImageChange}
                              placeholder="Label"
                            />
                            {image?.error !== "" ? (
                              <p className="error-alert mr-b20">
                                {image?.error}
                              </p>
                            ) : null}
                          </div>
                        ) : (
                          <div>
                            <Close
                              className="img-icon"
                              onClick={() => setImage({ ...image, value: "" })}
                            />
                            <img
                              src={image.value1}
                              className="img-upload-preview"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer pd-b30">
                <button
                  type="button"
                  className="s-blue-border-btn m-btn"
                  data-dismiss="modal"
                  onClick={() => {
                    setOpenUpdateModel(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="s-blue-btn m-btn"
                  data-dismiss="modal"
                  id="addButtonSubmit"
                  onClick={onFooterUpdateImageSubmit}
                  // onClick={addButtonHandler}
                  // onClick={() => addButtonHandler(editFlag ? selectedId : 0)}
                >
                  {/* {editFlag ? "Update " : "Add "} */}
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={sponsorConfig}
        disableBackdropClick={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className="modal modal-xl blue-modal fade"
          id="addInsuranceModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable"
            style={{ maxWidth: "1000px" }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Footer Config
                </h5>
              </div>
              <div className="modal-body">
                <div className="ins-modal-body">
                  <form action="">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group gray-fill-input mr-b30">
                          <label htmlFor="">
                            Delay In Milliseconds{" "}
                            <span className="text-danger">*</span>{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="buttonUrl"
                            id=""
                            placeholder="Delay"
                            value={configDelay?.value}
                            onChange={(event) =>
                              setConfigDelay({
                                value: event.target.value,
                                error: "",
                              })
                            }
                          />
                          {configDelay?.error !== "" ? (
                            <p className="error-alert mr-b20">
                              {configDelay?.error}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group gray-fill-input mr-b30">
                          <label htmlFor="">
                            Effect <span className="text-danger">*</span>{" "}
                          </label>
                          <select
                            name="windows"
                            id="windows"
                            value={configTransionEffect?.value}
                            // onChange={textFieldChangeHandler}
                            className="form-control"
                            onChange={(event) =>
                              setConfigTransionEffect({
                                value: event.target.value,
                                error: "",
                              })
                            }
                          >
                            <option value="">None</option>
                            <option value="animate__fadeIn">Fade In</option>
                            <option value="animate__fadeInDown">
                              fade In Down
                            </option>
                            <option value="animate__fadeInDownBig">
                              Fade In Down Big
                            </option>
                            <option value="animate__fadeInLeft">
                              Fade In Left
                            </option>
                            <option value="animate__fadeInLeftBig">
                              Fade In Left Big
                            </option>
                            <option value="animate__fadeInUp">
                              Fade In Up
                            </option>
                            <option value="animate__fadeInTopLeft">
                              Fade In Top Left
                            </option>
                            <option value="animate__slideInLeft">
                              Slide In Left
                            </option>
                            <option value="animate__slideInRight">
                              Slide In Right
                            </option>
                          </select>
                          {configTransionEffect?.error !== "" ? (
                            <p className="error-alert mr-b20">
                              {configTransionEffect?.error}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer pd-b30">
                <button
                  type="button"
                  className="s-blue-border-btn m-btn"
                  data-dismiss="modal"
                  onClick={() => {
                    setSponsorConfig(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="s-blue-btn m-btn"
                  data-dismiss="modal"
                  id="addButtonSubmit"
                  // onClick={onFooterImageSubmit}
                  // onClick={addButtonHandler}
                  onClick={() => onFooterConfigSubmit()}
                >
                  {/* {editFlag ? "Update " : "Add "} */}
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddFooter;
