import React, { useState, useEffect } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { NavLink } from "react-router-dom";

import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Edit from "../../assets/images/edit.png";
import Trash from "../../assets/images/trash.png";
import LockOpenIcon from "@material-ui/icons/LockOpen";

import {
  getUserByCompanyId,
  addUser,
  getCheckInStationByCompanyId,
  getModuleListByCompanyId,
  getAllUsersByCompanyId,
  addManageUser,
  deleteUser,
  updatePassword,
} from "../../services/adminApi";
import StationDropdown from "../common/StationDropdown";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 275,
    maxWidth: 275,
  },
}));

const styles = (theme) => ({
  field: {
    margin: "10px 0",
  },
  countryList: {
    ...theme.typography.body1,
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const UserList = () => {
  const [userName, setUserName] = useState("");
  const [userNameError, setUserNameError] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [companyId, setCompanyId] = useState();
  const [companyName, setCompanyName] = useState("");

  const [userId, setUserId] = useState(0);

  const [tableCount, setTableCount] = useState();
  const [userListDisplay, setUserListDisplay] = useState("");

  const [addNewUserModalFlag, setAddNewUserModalFlag] = useState(false);
  const [changePasswordModalFlag, setChangePasswordModalFlag] = useState(false);
  const [deleteModalFlag, setDeleteModalFlag] = useState(false);

  const [stationList, setStationList] = useState([]);
  const [moduleList, setModuleList] = useState([]);

  const [selectedStations, setSelectedStations] = useState([]);
  const [selectedStationEditMode, setSelectedStationsEditMode] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);

  const [selectedStationsIds, setSelectedStationsIds] = useState([]);
  const [selectedStationsIdsEditMode, setSelectedStationsIdsEditMode] =
    useState([]);
  const [selectedModulesIds, setSelectedModulesIds] = useState([]);

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [newFlag, setNewFlag] = useState(false);

  const [error, setError] = useState();

  const classes = useStyles();
  const theme = useTheme();

  const [userListColumns, setUserListColumns] = useState([
    { name: "sr", title: "Sr. No." },
    { name: "name", title: "Name" },
    { name: "email", title: "Email" },
    { name: "stations", title: "Stations" },
    { name: "modules", title: "Modules" },
    { name: "action", title: "Action" },
  ]);

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("selectedCompany"));
    // console.log(data);
    setCompanyId(data.id);
    setCompanyName(data.company_name);
    getStationListHandler(data.id);
    getCheckedModuleHandler(data.id);
  }, []);

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("selectedCompany"));
    getUserListHandler(data.id);
  }, [stationList, moduleList]);

  useEffect(() => {
    setUserName("");
    setEmail("");
    setSelectedModules([]);
    setSelectedStations([]);
  }, [newFlag]);
  console.log(
    "selectedStationEditMode.includes(station.id)",
    selectedStationEditMode,
    stationList.filter((rec) =>
      selectedStationEditMode.includes(JSON.stringify(rec.id))
    )
  );
  const getUserListHandler = (id) => {
    getAllUsersByCompanyId(id).then(async (response) => {
      let tempData = response.data;

      // await setCompanies(tempData);
      setTableCount(response.data.length);

      const companyListTemp = [];
      tempData.map((usr, index) => {
        let tempStationList = [];
        if (usr.stations.length > 0) {
          usr.stations.map((station) => {
            tempStationList.push(
              stationList.filter((x) => x.id == station.station_id).length > 0
                ? `${
                    stationList.filter((x) => x.id == station.station_id)[0]
                      .name
                  }, `
                : ""
            );
          });
        }

        let tempModuleList = [];
        if (usr.modules.length > 0) {
          usr.modules.map((module) => {
            tempModuleList.push(
              moduleList.filter((x) => x.id == module.module_id).length > 0
                ? `${
                    moduleList.filter((x) => x.id == module.module_id)[0].name
                  }, `
                : ""
            );
          });
        }

        setTimeout(() => {
          companyListTemp.push(
            <TableRow key={usr.id}>
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell>{usr.name}</TableCell>
              <TableCell>{usr.email}</TableCell>
              <TableCell>{tempStationList}</TableCell>
              <TableCell>{tempModuleList}</TableCell>
              <TableCell>
                <div className="action-btn">
                  <button
                    type="button"
                    className="trans-btn"
                    onClick={() => {
                      setChangePasswordModalFlag(true);
                      sessionStorage.setItem(
                        "selectedUser",
                        JSON.stringify(usr)
                      );
                      setUserId(usr.id);
                    }}
                  >
                    {/* <img src={LockOpenIcon} alt="" title="" /> */}
                    <LockOpenIcon style={{ color: "#19375a" }} />
                  </button>

                  <button
                    type="button"
                    className="trans-btn"
                    onClick={() => {
                      sessionStorage.setItem(
                        "selectedUser",
                        JSON.stringify(usr)
                      );
                      getUserDetailsHandler();
                      setAddNewUserModalFlag(true);
                    }}
                  >
                    <img src={Edit} alt="" title="" />
                  </button>

                  <button
                    type="button"
                    className="trans-btn"
                    onClick={() => {
                      setUserId(usr.id);
                      setDeleteModalFlag(true);
                    }}
                  >
                    <img src={Trash} alt="" title="" />
                  </button>
                </div>
              </TableCell>
            </TableRow>
          );
          if (index === tempData.length - 1) {
            setUserListDisplay(companyListTemp);
          }
        }, 500);
      });
    });
  };

  const getUserDetailsHandler = () => {
    let data = JSON.parse(sessionStorage.getItem("selectedUser"));
    console.log(data);

    setUserName(data.name);
    setEmail(data.email);
    setUserId(data.id);

    let tempStationList = [];
    if (data.stations.length > 0) {
      data.stations.map((station) => {
        tempStationList.push(
          stationList.filter((x) => x.id == station.station_id).length > 0
            ? `${stationList.filter((x) => x.id == station.station_id)[0].id}`
            : ""
        );
      });
    }
    setSelectedStations(tempStationList);
    let tempStationListEdit = [];

    if (data.editStationsList.length > 0) {
      data.editStationsList.map((station) => {
        tempStationListEdit.push(
          stationList.filter((x) => x.id == station.station_id).length > 0
            ? `${stationList.filter((x) => x.id == station.station_id)[0].id}`
            : ""
        );
      });
    }
    setSelectedStationsEditMode(tempStationListEdit);

    let tempModuleList = [];
    if (data.modules.length > 0) {
      data.modules.map((module) => {
        tempModuleList.push(
          moduleList.filter((x) => x.id == module.module_id).length > 0
            ? `${moduleList.filter((x) => x.id == module.module_id)[0].name}`
            : ""
        );
      });
    }
    setSelectedModules(tempModuleList);
  };

  const textFieldChangeHandler = (e) => {
    let { name, value } = e.target;

    switch (name) {
      case "userName":
        setUserName(value);
        setUserNameError("");

        break;

      case "email":
        setEmail(value);
        setEmailError("");
        setError();

        break;

      case "password":
        setPassword(value);
        setPasswordError("");

        break;

      case "confirmPassword":
        setConfirmPassword(value);
        setConfirmPasswordError("");

        break;

      default:
        console.log("");
    }
  };

  const addUserHandler = () => {
    if (
      userName.trim() == "" ||
      email.trim() == "" ||
      !Boolean(email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
    ) {
      if (userName.trim() == "") {
        setUserNameError("Please enter user name");
      }

      if (
        email.trim() == "" ||
        !Boolean(email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
      ) {
        setEmailError("Please enter valid email");
      }
    } else {
      let tempObj = {
        id: userId,
        name: userName,
        email: email,
        role_id: 3,
        modules: selectedModulesIds,
        stations: selectedStations,
        editStationsList: selectedStationEditMode,
      };
      addManageUser(companyId, tempObj)
        .then((response) => {
          // console.log(response);
          setAddNewUserModalFlag(false);
          getUserListHandler(companyId);
          setUserId(0);
          setNewFlag(false);
        })
        .catch((error) => {
          setError(error.message);
        });
    }
  };

  const getStationListHandler = (id) => {
    // console.log("checked", checkedAt);

    getCheckInStationByCompanyId(id).then(async (response) => {
      let tempData = response.data;
      await setStationList(response.data);
    });
  };

  const getCheckedModuleHandler = (id) => {
    getModuleListByCompanyId(id).then(async (response) => {
      let tempData = response.data;
      setModuleList(response.data);
    });
  };

  const handleStationChange = (event) => {
    let tempStations = event.target.value;
    let stationIds = [];
    tempStations.map((station) => {
      stationIds.push(stationList.filter((x) => x.name == station)[0].id);
    });
    setSelectedStations(event.target.value);
    setSelectedStationsIds(stationIds);
  };
  const handleStationEditModeChange = (checked, id) => {
    console.log("event.target", id, checked);
    const tempArr = [...selectedStationEditMode];
    if (checked) {
      tempArr.push(JSON.stringify(id));
      setSelectedStationsEditMode(tempArr);
    } else {
      setSelectedStationsEditMode(
        tempArr.filter((idx) => idx != JSON.stringify(id))
      );
    }
  };

  const handleModuleChange = (event) => {
    console.log(event.target.value);
    setSelectedModules(event.target.value);
    let tempModules = event.target.value;
    let moduleIds = [];
    tempModules.map((module) => {
      moduleIds.push(moduleList.filter((x) => x.name == module)[0].id);
    });

    setSelectedModulesIds(moduleIds);
  };

  const deleteUserHandler = () => {
    deleteUser(userId).then((response) => {
      // console.log(response)
      setDeleteModalFlag(false);
      getUserListHandler(companyId);
      setUserId(0);
      setNewFlag(false);
    });
  };

  const changePasswordHandler = () => {
    if (
      password == "" ||
      confirmPassword == "" ||
      password !== confirmPassword
    ) {
      if (password == "") {
        setPasswordError("Please enter valid New Password");
      }

      if (confirmPassword == "") {
        setConfirmPasswordError("Please enter Confirm New Password");
      }

      if (password !== confirmPassword) {
        setConfirmPasswordError(
          "New password and confirm password do not match"
        );
      }
    } else {
      // console.log(`${email} ${password}`)

      let tempObj = {
        user_id: userId,
        password: password,
      };

      updatePassword(tempObj)
        .then((response) => {
          // console.log(response);
          setChangePasswordModalFlag(false);
          setConfirmPassword("");
          setConfirmPasswordError("");
          setPassword("");
          setPasswordError("");
          setUserId(0);
          // setChangePasswordSneakBarFlag(true);
        })
        .catch((error) => {
          console.log(error);
          setPasswordError(error.message);
        });
    }
  };

  return (
    <div>
      <div className="icon">
        <NavLink to={"/admin/companyList"}>
          <button type="button" className="trans-btn mr-r10">
            <svg
              width="20"
              height="16"
              viewBox="0 0 20 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.33333 2.86765e-05C8.66437 -0.00183006 8.98849 0.0915604 9.26435 0.268294C9.54022 0.445028 9.75534 0.697096 9.88228 0.992364C10.0092 1.28763 10.0422 1.61272 9.97713 1.92618C9.91201 2.23965 9.75171 2.52728 9.51667 2.75241L4.01667 8.04794L9.51667 13.3435C9.78971 13.6514 9.93239 14.0475 9.91618 14.4525C9.89998 14.8576 9.7261 15.2419 9.42927 15.5286C9.13245 15.8152 8.73455 15.9832 8.31509 15.9988C7.89563 16.0144 7.4855 15.8767 7.16667 15.613L0.499999 9.17464C0.18958 8.87307 0.015344 8.46512 0.0153439 8.03989C0.0153439 7.61466 0.18958 7.20671 0.499999 6.90513L7.16667 0.466808C7.4771 0.169448 7.8961 0.00180636 8.33333 2.86765e-05Z"
                fill="#0099CC"
              />
              <path
                d="M1.66667 6.43835L18.3333 6.43835C18.7754 6.43835 19.1993 6.60793 19.5118 6.90979C19.8244 7.21164 20 7.62105 20 8.04793C20 8.47482 19.8244 8.88422 19.5118 9.18608C19.1993 9.48793 18.7754 9.65752 18.3333 9.65752L1.66667 9.65752C1.22464 9.65752 0.800716 9.48794 0.488155 9.18608C0.175594 8.88423 -6.57872e-07 8.47482 -6.95192e-07 8.04794C-7.32511e-07 7.62105 0.175594 7.21164 0.488155 6.90979C0.800716 6.60793 1.22464 6.43835 1.66667 6.43835Z"
                fill="#0099CC"
              />
            </svg>
          </button>
        </NavLink>
        <span className="">Dashboard</span>
      </div>
      {/* <div className="mr-t30 mr-l10">
                <h3>User List</h3>
            </div> */}

      <div className="mr-t50">
        <div className="data-head">
          <div className="page-title mr-l10">
            <h3>Users</h3>
          </div>
          <div className="add-btn">
            <button
              type="button"
              className="s-blue-btn m-btn mr-l20 mr-r10"
              data-toggle="modal"
              data-target="#addInsuranceModal"
              onClick={() => {
                setNewFlag(true);
                setAddNewUserModalFlag(true);
              }}
            >
              + Add New
            </button>
          </div>
        </div>
      </div>
      <div className="cus-table">
        <div className="table-str">
          <TableContainer component={Paper}>
            <Table aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  {userListColumns.map((column) => (
                    <TableCell key={column.name} className="align-top">
                      {(column.name = column.title)}
                      {/* {column.name !== "action" ? (
                                                    <TableSortLabel
                                                        active={
                                                            inspectionListPayload
                                                                .sort_by === column.name
                                                        }
                                                        direction={
                                                            inspectionListPayload
                                                                .sort_by === column.name
                                                                ? inspectionListPayload
                                                                    .order_by
                                                                : "asc"
                                                        }
                                                        onClick={createSortHandler(
                                                            column.name
                                                        )}
                                                    >
                                                        {column.title}
                                                        {inspectionListPayload
                                                            .sort_by === column.name ? (
                                                            <span
                                                                style={{
                                                                    border: 0,
                                                                    clip: "rect(0 0 0 0)",
                                                                    height: 1,
                                                                    margin: -1,
                                                                    overflow: "hidden",
                                                                    padding: 0,
                                                                    position: "absolute",
                                                                    top: 20,
                                                                    width: 1,
                                                                }}
                                                            >
                                                                {inspectionListPayload
                                                                    .order_by === "desc"
                                                                    ? "sorted descending"
                                                                    : "sorted ascending"}
                                                            </span>
                                                        ) : null}
                                                    </TableSortLabel>
                                                ) : (
                                                    column.title
                                                )} */}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {this.state.reportsUpcomingList} */}
                {userListDisplay}
                {/* {this.state.loading ? <Spin indicator={<div class="loading">Loading&#8230;</div>}/> : this.state.reportsUpcomingList} */}
              </TableBody>
              {/* <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[
                                                5,
                                                10,
                                                25,
                                                50,
                                                { label: "All", value: -1 },
                                            ]}
                                            colSpan={9}
                                            count={tableCount}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: { "aria-label": "rows per page" },
                                                native: true,
                                            }}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={
                                                handleChangeRowsPerPage
                                            }
                                        />
                                    </TableRow>
                                </TableFooter> */}
            </Table>
          </TableContainer>
        </div>
      </div>
      <Modal
        open={addNewUserModalFlag}
        // disableBackdropClick={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className="modal blue-modal fade"
          id="addInsuranceModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add User
                </h5>
              </div>
              <div className="modal-body">
                <div className="ins-modal-body">
                  <form action="">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group gray-fill-input mr-b30">
                          <label htmlFor="">User Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="userName"
                            id=""
                            value={userName}
                            onChange={textFieldChangeHandler}
                            placeholder="User Name"
                          />
                          {userNameError !== "" ? (
                            <p className="error-alert mr-b20">
                              {userNameError}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group gray-fill-input mr-b30">
                          <label htmlFor="">Email</label>
                          <input
                            type="text"
                            className="form-control"
                            name="email"
                            id=""
                            value={email}
                            onChange={textFieldChangeHandler}
                            placeholder="Email"
                          />
                          {emailError !== "" ? (
                            <p className="error-alert mr-b20">{emailError}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group gray-fill-input mr-b30">
                          {/* <FormControl className={classes.formControl}>
                            <label id="mutiple-module-checkbox-label">
                              Stations
                            </label>
                            <Select
                              className="form-control"
                              labelId="mutiple-module-checkbox-label"
                              id="mutiple-module-checkbox"
                              multiple
                              value={selectedStations}
                              onChange={handleStationChange}
                              input={<Input />}
                              renderValue={(selected) => selected.join(", ")}
                              MenuProps={MenuProps}
                              disableUnderline
                            >
                              {stationList.map((station) => (
                                <MenuItem key={station.id} value={station.name}>
                                  <Checkbox
                                    checked={
                                      selectedStations.indexOf(station.name) >
                                      -1
                                    }
                                  />
                                  <ListItemText primary={station.name} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl> */}

                          <StationDropdown
                            classes={classes}
                            MenuProps={MenuProps}
                            list={stationList}
                            selectedRecord={selectedStations}
                            setSelectedRecord={setSelectedStations}
                            label={"Stations"}
                          />
                          {/* <div className="modules-list-item">
                                                                    <div className="left-section">Total Selected:</div>
                                                                    <div className="right-section">{totalSelected}</div>
                                                                </div> */}

                          {/* {moduleError !== '' ? <p className="error-alert mr-b20">{moduleError}</p> : null} */}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <StationDropdown
                          classes={classes}
                          MenuProps={MenuProps}
                          list={stationList}
                          selectedRecord={selectedStationEditMode}
                          setSelectedRecord={setSelectedStationsEditMode}
                          label={"Stations Edit Mode"}
                        />
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group gray-fill-input mr-b30">
                          <FormControl className={classes.formControl}>
                            <label id="mutiple-module-checkbox-label">
                              Modules
                            </label>
                            <Select
                              className="form-control"
                              labelId="mutiple-module-checkbox-label"
                              id="mutiple-module-checkbox"
                              multiple
                              value={selectedModules}
                              onChange={handleModuleChange}
                              input={<Input />}
                              renderValue={(selected) => selected.join(", ")}
                              MenuProps={MenuProps}
                              disableUnderline
                            >
                              {moduleList.map((module) => (
                                <MenuItem key={module.id} value={module.name}>
                                  <Checkbox
                                    checked={
                                      selectedModules.indexOf(module.name) > -1
                                    }
                                  />
                                  <ListItemText primary={module.name} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {/* <div className="modules-list-item">
                                                                    <div className="left-section">Total Selected:</div>
                                                                    <div className="right-section">{totalSelected}</div>
                                                                </div> */}

                          {/* {moduleError !== '' ? <p className="error-alert mr-b20">{moduleError}</p> : null} */}
                        </div>
                      </div>

                      {error !== "" ? (
                        <p className="error-alert mr-b20">{error}</p>
                      ) : null}
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer pd-b30">
                <button
                  type="button"
                  className="s-blue-border-btn m-btn"
                  data-dismiss="modal"
                  onClick={() => {
                    setUserId(0);
                    setNewFlag(false);
                    setAddNewUserModalFlag(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="s-blue-btn m-btn"
                  data-dismiss="modal"
                  onClick={addUserHandler}
                >
                  {userId ? "Update " : "Add "}
                  User
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={deleteModalFlag}
        disableBackdropClick={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className="modal blue-modal fade"
          id="addInsuranceModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Confirmation
                </h5>
              </div>
              <div className="modal-body">Want to delete Selected User ?</div>

              <div className="modal-footer pd-b30">
                <button
                  type="button"
                  className="s-blue-border-btn m-btn"
                  data-dismiss="modal"
                  onClick={() => {
                    setUserId(0);
                    setDeleteModalFlag(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="s-blue-btn m-btn"
                  onClick={deleteUserHandler}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={changePasswordModalFlag}
        // disableBackdropClick={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className="modal blue-modal fade"
          id="addInsuranceModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Change Password
                </h5>
              </div>
              <div className="modal-body">
                <div className="ins-modal-body">
                  <form action="">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group gray-fill-input mr-b30">
                          <label htmlFor="">Password</label>
                          <input
                            type="text"
                            className="form-control mr-t20"
                            name="password"
                            id=""
                            // value={password}
                            onChange={textFieldChangeHandler}
                            placeholder="Password"
                          />
                          {passwordError !== "" ? (
                            <p className="error-alert mr-b20">
                              {passwordError}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group gray-fill-input mr-b30">
                          <label htmlFor="">Confirm Password</label>
                          <input
                            type="text"
                            className="form-control mr-t20"
                            name="confirmPassword"
                            id=""
                            // disabled={editFlag}
                            value={confirmPassword}
                            onChange={textFieldChangeHandler}
                            placeholder="Confirm Password"
                          />
                          {confirmPasswordError !== "" ? (
                            <p className="error-alert mr-b20">
                              {confirmPasswordError}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer pd-b30">
                <button
                  type="button"
                  className="s-blue-border-btn m-btn"
                  data-dismiss="modal"
                  onClick={() => {
                    setChangePasswordModalFlag(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="s-blue-btn m-btn"
                  data-dismiss="modal"
                  id="addCompanySubmit"
                  onClick={() => {
                    changePasswordHandler();
                  }}
                >
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UserList;
