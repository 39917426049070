import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {
  addParticipant,
  getParticipantDetailsByNfcId,
  getCompanyIdByCompanyName,
  participantLogin,
  getCompanyConfigByCompanyId,
  getCompanyFieldsById,
  getParticipantDetailsByEmailOrName,
} from "../../services/adminApi";
import Config from "../../config.json";
import ClipLoader from "react-spinners/ClipLoader";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { Box, LinearProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 275,
    maxWidth: 275,
  },
}));

const styles = (theme) => ({
  field: {
    margin: "10px 0",
  },
  countryList: {
    ...theme.typography.body1,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Registration = (props) => {
  const classes = useStyles();
  const [searchReg, setSearchReg] = useState(false);

  const [name, setName] = useState("");
  const [registationModal, setRegistationModal] = useState(false);
  const [searchModal, setSearchModal] = useState(false);
  const [searchList, setSearchList] = useState({ records: [], loading: false });

  const [participantNameError, setParticipantNameError] = useState("");
  const [searchText, setSearchText] = useState("");

  const [last_name, setLastName] = useState("");
  const [address, setAddress] = useState("");

  const [participantId, setParticipantId] = useState();
  const [participantIdError, setParticipantIdError] = useState("");

  const [email_id, setEmailId] = useState("");
  const [emailError, setEmailError] = useState("");

  const [business, setBusiness] = useState("");
  const [businessError, setBusinessError] = useState("");

  const [contact, setContact] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [linkedin_id, setLinkedInId] = useState("");
  const [linkedInError, setLinkedInError] = useState("");

  const [companyId, setCompanyId] = useState("");
  const [companyName, setCompanyName] = useState("");

  const [nfc_tag_id, setNfcTagId] = useState("");
  const [nfcTagIdError, setNfcTagIdError] = useState("");

  const [facebook_id, setFacebookId] = useState("");
  const [instagram_id, setInstagramId] = useState("");
  const [twitter_id, setTwitterId] = useState("");
  const [youtube_id, setYoutubeId] = useState("");
  const [pintrest_id, setPinterestId] = useState("");
  const [snapchat_id, setSnapchatId] = useState("");
  const [tiktok_id, setTiktokId] = useState("");

  const [field_16, setField16] = useState("");
  const [field_17, setField17] = useState("");
  const [field_18, setField18] = useState("");
  const [field_19, setField19] = useState("");
  const [field_20, setField20] = useState("");
  const [field_21, setField21] = useState("");
  const [field_22, setField22] = useState("");
  const [field_23, setField23] = useState("");
  const [field_24, setField24] = useState("");
  const [field_25, setField25] = useState("");

  const [logoImg, setLogoImg] = useState();
  const [logoUrl, setLogoUrl] = useState();
  const [customInstruction, setCustomInstruction] = useState("");
  const [companyConfigloading, setCompanyConfigLoading] = useState(true);
  const [spinnerColor, setSpinnerColor] = useState("#000000");
  const [addNewFormFieldsList, setAddNewFormFieldsList] = useState([]);

  const [skipRegistration, setSkipRegistration] = useState();

  const [error, setError] = useState("");

  const [participantListColumns, setParticipantListColumns] = useState([]);

  const [participantListDisplay, setParticipantListDisplay] = useState("");

  const [addNewParticipantModalFlag, setAddNewParticipantModalFlag] =
    useState(false);

  const [addSneakBarFlag, setAddSneakBarFlag] = useState(false);
  const [isRequiredList, setIsRequiredList] = useState([]);

  const [registerButtonText, setRegisterButtonText] = useState("Register");

  let tempList16 = [];
  let tempList17 = [];
  let tempList18 = [];
  let tempList19 = [];
  let tempList20 = [];
  let tempList21 = [];
  let tempList22 = [];
  let tempList23 = [];
  let tempList24 = [];
  let tempList25 = [];

  useEffect(() => {
    // console.log(props);
    setNfcTagId(props.participantId);
    setCompanyName(props.companyName);
    setCompanyId(props.companyId);
    // getCompanyId(props.companyName);
    getCompanyConfigHandler(props.companyId);
    setRegisterButtonText(props.registerButtonText);
    getParticipantDetailByNfcIdHandler(props.participantId);
  }, []);

  useEffect(() => {
    getAddNewFormFields(props.companyId, props.participantId);
  }, [field_25]);

  // useEffect(() => {
  //     setParticipantName('');
  //     setParticipantNameError('')

  //     setBusiness('');
  //     setBusinessError('')

  //     setEmail('');
  //     setEmailError('')

  //     setPhone('');
  //     setPhoneError('')

  //     setLinkedIn('');
  //     setLinkedInError('');

  //     setNfcTagId('');
  //     setNfcTagIdError('');

  //     setError('');

  // }, [])

  const getValue = (id) => {
    if (id == "name") {
      return name;
    }
    if (id == "last_name") {
      return last_name;
    }
    if (id == "contact") {
      return contact;
    }
    if (id == "business") {
      return business;
    }
    if (id == "address") {
      return address;
    }
    if (id == "email_id") {
      return email_id;
    }
    if (id == "nfc_tag_id") {
      return nfc_tag_id;
    }
    if (id == "facebook_id") {
      return facebook_id;
    }
    if (id == "instagram_id") {
      return instagram_id;
    }
    if (id == "twitter_id") {
      return twitter_id;
    }
    if (id == "linkedin_id") {
      return linkedin_id;
    }
    if (id == "youtube_id") {
      return youtube_id;
    }
    if (id == "pintrest_id") {
      return pintrest_id;
    }
    if (id == "snapchat_id") {
      return snapchat_id;
    }
    if (id == "tiktok_id") {
      return tiktok_id;
    }
    if (id == "field_16") {
      return field_16;
    }
    if (id == "field_17") {
      return field_17;
    }
    if (id == "field_18") {
      return field_18;
    }
    if (id == "field_19") {
      return field_19;
    }
    if (id == "field_20") {
      return field_20;
    }
    if (id == "field_21") {
      return field_21;
    }
    if (id == "field_22") {
      return field_22;
    }
    if (id == "field_23") {
      return field_23;
    }
    if (id == "field_24") {
      return field_24;
    }
    if (id == "field_25") {
      return field_25;
    }
  };

  const getAddNewFormFields = (id, participant_id) => {
    setAddNewFormFieldsList([]);
    getCompanyFieldsById(id).then((response) => {
      let tempData = response.data;
      let tempList = response.data.filter((x) => x.is_active);
      setIsRequiredList(
        response.data.filter((x) => x.is_required && x.is_active)
      );
      // console.log(response.data.filter((x) => x.is_required && x.is_active ))
      // setCompanyFormFieldList(response.data);
      // setElements(response.data.filter((x) => x.is_active))
      let participantListColumnsTempList = [];
      participantListColumnsTempList.push({
        name: "sr. no.",
        title: "Sr. No.",
      });
      tempData.map((row) => {
        // console.log("row",row);
        participantListColumnsTempList.push({
          name: row.field_name,
          title: row.label ? row.label : row.field_name,
        });
      });
      participantListColumnsTempList.push(
        { name: "is_registered", title: "Is Registered" },
        { name: "action", title: "Action" }
      );
      setParticipantListColumns(participantListColumnsTempList);

      let addParticipantFormFieldsTempList = [];

      tempList.map((field, i) => {
        if (field.type.toLowerCase() == "text") {
          addParticipantFormFieldsTempList.push(
            inputField(
              field.field_name,
              field.label,
              // getParticipantDetailByNfcIdHandler(participant_id, field.field_name),
              getValue(field.field_name),
              field.is_required
            )
          );
        } else if (field.type.toLowerCase() == "checkbox") {
          addParticipantFormFieldsTempList.push(
            checkboxField(
              field.field_name,
              field.label,
              // getParticipantDetailByNfcIdHandler(participant_id, field.field_name),
              getValue(field.field_name),
              field.value,
              // getParticipantDetailByNfcIdHandler(participant_id, field.field_name),
              getValue(field.field_name),
              field.is_required
            )
          );
        } else if (field.type.toLowerCase() == "dropdown") {
          addParticipantFormFieldsTempList.push(
            dropdownField(
              field.field_name,
              field.label,
              // getParticipantDetailByNfcIdHandler(participant_id, field.field_name),
              getValue(field.field_name),
              field.value,

              field.is_required
            )
          );
        } else if (field.type.toLowerCase() == "radio") {
          addParticipantFormFieldsTempList.push(
            radioField(
              field.field_name,
              field.label,
              // getParticipantDetailByNfcIdHandler(participant_id, field.field_name),
              getValue(field.field_name),
              // "Male",
              field.value,
              // getParticipantDetailByNfcIdHandler(participant_id, field.field_name),
              getValue(field.field_name),
              field.is_required
            )
          );
        }
      });
      setAddNewFormFieldsList(addParticipantFormFieldsTempList);
    });
    setAddNewParticipantModalFlag(true);
  };

  const getAddNewFormFields1 = (id, newData) => {
    setAddNewFormFieldsList([]);
    getCompanyFieldsById(id).then((response) => {
      let tempData = response.data;
      let tempList = response.data.filter((x) => x.is_active);
      setIsRequiredList(
        response.data.filter((x) => x.is_required && x.is_active)
      );
      // console.log(response.data.filter((x) => x.is_required && x.is_active ))
      // setCompanyFormFieldList(response.data);
      // setElements(response.data.filter((x) => x.is_active))
      let participantListColumnsTempList = [];
      participantListColumnsTempList.push({
        name: "sr. no.",
        title: "Sr. No.",
      });
      tempData.map((row) => {
        // console.log("row",row);
        participantListColumnsTempList.push({
          name: row.field_name,
          title: row.label ? row.label : row.field_name,
        });
      });
      participantListColumnsTempList.push(
        { name: "is_registered", title: "Is Registered" },
        { name: "action", title: "Action" }
      );
      setParticipantListColumns(participantListColumnsTempList);

      let addParticipantFormFieldsTempList = [];

      tempList.map((field, i) => {
        if (field.type.toLowerCase() == "text") {
          const entries = Object.entries(newData).find(
            ([key, value]) => key == field.field_name
          );
          //   console.log("entries", entries[1]);

          addParticipantFormFieldsTempList.push(
            inputField(
              field.field_name,
              field.label,
              // getParticipantDetailByNfcIdHandler(participant_id, field.field_name),
              entries?.length == 2 ? entries[1] : "",
              field.is_required
            )
          );
        } else if (field.type.toLowerCase() == "checkbox") {
          addParticipantFormFieldsTempList.push(
            checkboxField(
              field.field_name,
              field.label,
              // getParticipantDetailByNfcIdHandler(participant_id, field.field_name),
              getValue(field.field_name),
              field.value,
              // getParticipantDetailByNfcIdHandler(participant_id, field.field_name),
              getValue(field.field_name),
              field.is_required
            )
          );
        } else if (field.type.toLowerCase() == "dropdown") {
          addParticipantFormFieldsTempList.push(
            dropdownField(
              field.field_name,
              field.label,
              // getParticipantDetailByNfcIdHandler(participant_id, field.field_name),
              getValue(field.field_name),
              field.value,

              field.is_required
            )
          );
        } else if (field.type.toLowerCase() == "radio") {
          addParticipantFormFieldsTempList.push(
            radioField(
              field.field_name,
              field.label,
              // getParticipantDetailByNfcIdHandler(participant_id, field.field_name),
              getValue(field.field_name),
              // "Male",
              field.value,
              // getParticipantDetailByNfcIdHandler(participant_id, field.field_name),
              getValue(field.field_name),
              field.is_required
            )
          );
        }
      });
      setAddNewFormFieldsList(addParticipantFormFieldsTempList);
    });
    setAddNewParticipantModalFlag(true);
  };

  const inputField = (id, name, value, isRequired, error) => {
    console.log("value", value);
    return (
      <div className={`col-sm-6 registration-${id}-input-div`}>
        <div className="form-group gray-fill-input mr-b30">
          <label htmlFor="">{isRequired ? "*" + name : name}</label>
          <input
            type="text"
            className={`form-control registration-${id}-input-field`}
            name={id}
            id={id}
            defaultValue={value ? value : ""}
            onChange={(e) => {
              textFieldChangeHandler(id, e);
            }}
            // value={getValue(name)}
            placeholder={name}
            disabled={id === "nfc_tag_id" ? true : false}
          />

          {error !== "" ? <p className="error-alert mr-b20">{error}</p> : null}
        </div>
      </div>
    );
  };

  const checkboxField = (
    id,
    name,
    value,
    option,
    checked,
    isRequired,
    error
  ) => {
    let typeValuesTemp = option.split(",");
    let selectedValues = value ? value.split(",") : [];
    // console.log("selectedValues", selectedValues)
    // console.log("type values", typeValuesTemp);
    let valuesTemp = [];
    typeValuesTemp.map((tvalue) => {
      // console.log("check array", selectedValues.includes(tvalue));
      valuesTemp.push(
        <FormControlLabel
          value={tvalue}
          control={
            <Checkbox defaultChecked={selectedValues.includes(tvalue)} />
          }
          label={tvalue}
          name={id}
        />
      );
    });

    return (
      <div className={`col-sm-6 registration-${id}-checkbox-div`}>
        <div className="form-group gray-fill-input mr-b30">
          <label htmlFor="">{isRequired ? "*" + name : name}</label>
          <div>
            <FormGroup
              onChange={(e) => {
                checkboxChangeHandler(e);
              }}
              defaultValue="Delhi"
            >
              {valuesTemp}
            </FormGroup>
          </div>
          {error !== "" ? <p className="error-alert mr-b20">{error}</p> : null}
        </div>
      </div>
    );
  };

  const dropdownField = (id, name, value, option, isRequired, error) => {
    let typeValuesTemp = option.split(",");

    let valuesTemp = [];
    typeValuesTemp.map((tvalue) => {
      valuesTemp.push(
        { name: tvalue, id: tvalue.toLowerCase() }
        // <Dropdown.Item eventKey={tvalue} value={tvalue}>{tvalue}</Dropdown.Item>
      );
    });

    return (
      <div className={`col-sm-6 registration-${id}-dropdown-div`}>
        <div className="form-group gray-fill-input mr-b30">
          <label htmlFor="">{isRequired ? "*" + name : name}</label>
          <FormControl className={classes.formControl}>
            <Select
              className={`form-control registration-${id}-dropdown-field`}
              labelId="mutiple-module-checkbox-label"
              defaultValue={value ? value : ""}
              // value={value}
              onChange={(e) => {
                textFieldChangeHandler(id, e);
              }}
              input={<Input id={id} />}
              renderValue={(selected) => selected}
              MenuProps={MenuProps}
              disableUnderline
              name={id}
              placeholder="Choose value"
            >
              {valuesTemp.map((name) => (
                <MenuItem key={name.id} value={name.name}>
                  {/* <Checkbox checked={row.field_name.indexOf(name.name) > -1} /> */}
                  <ListItemText primary={name.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {error !== "" ? <p className="error-alert mr-b20">{error}</p> : null}
        </div>
      </div>
    );
  };

  const radioField = (id, name, value, option, checked, isRequired, error) => {
    let typeValuesTemp = option.split(",");
    // console.log("type values", typeValuesTemp);
    let valuesTemp = [];
    typeValuesTemp.map((tvalue) => {
      valuesTemp.push(
        <FormControlLabel
          value={tvalue}
          control={<Radio />}
          label={tvalue}
          name={id}
        />
      );
    });
    return (
      <div className={`col-sm-6 registration-${id}-radio-div`}>
        <div className="form-group gray-fill-input mr-b30">
          <label htmlFor="">{isRequired ? "*" + name : name}</label>
          <div>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="gender"
                name="row-radio-buttons-group"
                onChange={(e) => {
                  textFieldChangeHandler(id, e);
                }}
                defaultValue={value ? value : ""}
              >
                {valuesTemp}
              </RadioGroup>
            </FormControl>
          </div>
          {/* {participantNameError !== '' ? <p className="error-alert mr-b20">{participantNameError}</p> : null} */}
        </div>
      </div>
    );
  };

  const getCompanyConfigHandler = (id) => {
    getCompanyConfigByCompanyId(id).then(async (response) => {
      let data = response.data?.length ? response.data[0] : [];
      // console.log(data);
      setLogoImg(data?.event_logo);
      setLogoUrl(data.header_url ? data.header_url : null);
      setCompanyConfigLoading(false);
      setRegistationModal(data?.contact_save_search_button ? 1 : 0);
      setSearchReg(
        data?.custom_instruction_search ? data?.custom_instruction_search : ""
      );
      data.custom_instruction
        ? setCustomInstruction(data.custom_instruction)
        : setCustomInstruction("");
      data.skip_page === 1
        ? setSkipRegistration(true)
        : setSkipRegistration(false);
    });
  };

  const getCompanyId = (companyName) => {
    let tempObj = {
      company_name: companyName,
    };

    getCompanyIdByCompanyName(tempObj)
      .then((response) => {
        // console.log("getcompanyid",response.data[0].id);
        setCompanyId(response.data[0].id);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const getParticipantDetailByNfcIdHandler = (participant_id) => {
    getParticipantDetailsByNfcId(participant_id).then(async (response) => {
      let data = response.data[0];

      setParticipantId(data.id);
      setName(data.name);
      setLastName(data.last_name);
      setEmailId(data.email_id);
      setBusiness(data.business);
      setContact(data.contact);
      setAddress(data.address);
      setLinkedInId(data.linkedin_id);
      setNfcTagId(data.nfc_tag_id);
      setFacebookId(data.facebook_id);
      setInstagramId(data.instagram_id);
      setYoutubeId(data.youtube_id);
      setTwitterId(data.twitter_id);
      setPinterestId(data.pintrest_id);
      setSnapchatId(data.snapchat_id);
      setTiktokId(data.tiktok_id);
      setField16(data.field_16);
      setField17(data.field_17);
      setField18(data.field_18);
      setField19(data.field_19);
      setField20(data.field_20);
      setField21(data.field_21);
      setField22(data.field_22);
      setField23(data.field_23);
      setField24(data.field_24);
      setField25(data.field_25);

      //   getAddNewFormFields(companyId);
    });

    // let nfctag = document.getElementById(nfcTagId);
    // editFlag ? nfctag.disabled = "true": null;
  };

  const checkboxChangeHandler = (e) => {
    document.getElementById("addParticipantSubmit").disabled = false;

    let { name, defaultValue, checked } = e.target;

    switch (name) {
      case "field_16":
        if (checked) {
          tempList16.push(defaultValue);
        } else if (!checked) {
          tempList16 = tempList16.filter((x) => x != defaultValue);
        } else console.log(checked);

        setField16(tempList16.toString());
        break;

      case "field_17":
        if (checked) {
          tempList17.push(defaultValue);
        } else if (!checked) {
          tempList17 = tempList17.filter((x) => x != defaultValue);
        } else console.log(checked);

        setField17(tempList17.toString());
        break;

      case "field_18":
        if (checked) {
          tempList18.push(defaultValue);
        } else if (!checked) {
          tempList18 = tempList18.filter((x) => x != defaultValue);
        } else console.log(checked);

        setField18(tempList18.toString());
        break;

      case "field_19":
        if (checked) {
          tempList19.push(defaultValue);
        } else if (!checked) {
          tempList19 = tempList19.filter((x) => x != defaultValue);
        } else console.log(checked);

        setField19(tempList19.toString());
        break;

      case "field_20":
        if (checked) {
          tempList20.push(defaultValue);
        } else if (!checked) {
          tempList20 = tempList20.filter((x) => x != defaultValue);
        } else console.log(checked);

        setField20(tempList20.toString());
        break;

      case "field_21":
        if (checked) {
          tempList21.push(defaultValue);
        } else if (!checked) {
          tempList21 = tempList21.filter((x) => x != defaultValue);
        } else console.log(checked);

        setField21(tempList21.toString());
        break;

      case "field_22":
        if (checked) {
          tempList22.push(defaultValue);
        } else if (!checked) {
          tempList22 = tempList22.filter((x) => x != defaultValue);
        } else console.log(checked);

        setField22(tempList22.toString());
        break;

      case "field_23":
        if (checked) {
          tempList23.push(defaultValue);
        } else if (!checked) {
          tempList23 = tempList23.filter((x) => x != defaultValue);
        } else console.log(checked);

        setField23(tempList23.toString());
        break;

      case "field_24":
        if (checked) {
          tempList24.push(defaultValue);
        } else if (!checked) {
          tempList24 = tempList24.filter((x) => x != defaultValue);
        } else console.log(checked);

        setField24(tempList24.toString());
        break;

      case "field_25":
        if (checked) {
          tempList25.push(defaultValue);
        } else if (!checked) {
          tempList25 = tempList25.filter((x) => x != defaultValue);
        } else console.log(checked);

        setField25(tempList25.toString());
        break;

      default:
        console.log(e);
    }
  };

  const textFieldChangeHandler = (n, e) => {
    document.getElementById("addParticipantSubmit").disabled = false;
    console.log(n, e.target.value);
    let obj = { name: "", value: "" };
    if (typeof e == "string") {
      obj.name = n;
      obj.value = e;
      document.getElementById("dropdown" + n).title = e;
      document.getElementById("dropdown" + n).value = e;
    } else {
      obj.name = e.target.name;
      obj.value = e.target.value;
    }
    switch (obj.name) {
      case "name":
        setName(obj.value);
        setParticipantNameError("");
        // getValue(name);
        break;

      case "email_id":
        setEmailId(obj.value);
        setEmailError("");

        break;

      case "contact":
        setContact(obj.value);
        setPhoneError("");

        break;

      case "linkedin_id":
        setLinkedInId(obj.value);
        setLinkedInError("");

        break;

      case "business":
        setBusiness(obj.value);
        setBusinessError("");

        break;

      case "nfc_tag_id":
        setNfcTagId(obj.value);
        setNfcTagIdError("");

        break;

      case "last_name":
        setLastName(obj.value);
        // setNfcTagIdError('')

        break;
      case "address":
        setAddress(obj.value);
        // setNfcTagIdError('')

        break;
      case "facebook_id":
        setFacebookId(obj.value);
        // setNfcTagIdError('')

        break;
      case "instagram_id":
        setInstagramId(obj.value);
        // setNfcTagIdError('')

        break;
      case "twitter_id":
        setTwitterId(obj.value);
        // setNfcTagIdError('')

        break;
      case "youtube_id":
        setYoutubeId(obj.value);
        // setNfcTagIdError('')

        break;
      case "pintrest_id":
        setPinterestId(obj.value);
        // setNfcTagIdError('')

        break;
      case "snapchat_id":
        setSnapchatId(obj.value);
        // setNfcTagIdError('')

        break;
      case "tiktok_id":
        setTiktokId(obj.value);
        // setNfcTagIdError('')

        break;
      case "field_16":
        setField16(obj.value);
        // setNfcTagIdError('')

        break;
      case "field_17":
        setField17(obj.value);
        // setNfcTagIdError('')

        break;

      case "field_18":
        setField18(obj.value);
        // setNfcTagIdError('')

        break;
      case "field_19":
        setField19(obj.value);
        // setNfcTagIdError('')

        break;
      case "field_20":
        setField20(obj.value);
        // setNfcTagIdError('')

        break;
      case "field_21":
        setField21(obj.value);
        // setNfcTagIdError('')

        break;
      case "field_22":
        setField22(obj.value);
        // setNfcTagIdError('')

        break;
      case "field_23":
        setField23(obj.value);
        // setNfcTagIdError('')

        break;
      case "field_24":
        setField24(obj.value);
        // setNfcTagIdError('')

        break;
      case "field_25":
        setField25(obj.value);
        // setNfcTagIdError('')

        break;

      default:
        console.log("");
    }
  };

  const addParticipantHandler = (skip) => {
    if (skip) {
      let requiredFields = isRequiredList.map((x) => x.field_name);

      if (
        (requiredFields.includes("name") && (!name || name.trim() == "")) ||
        (requiredFields.includes("last_name") &&
          (!last_name || last_name.trim() == "")) ||
        (requiredFields.includes("email_id") &&
          (!email_id || email_id.trim() == "")) ||
        (requiredFields.includes("contact") &&
          (!contact || contact.trim() == "")) ||
        (requiredFields.includes("address") &&
          (!address || address.trim() == "")) ||
        (requiredFields.includes("business") &&
          (!business || business.trim() == "")) ||
        (requiredFields.includes("nfc_tag_id") &&
          (!nfc_tag_id || nfc_tag_id.trim() == "")) ||
        (requiredFields.includes("faebook_id") &&
          (!facebook_id || facebook_id.trim() == "")) ||
        (requiredFields.includes("instagram_id") &&
          (!instagram_id || instagram_id.trim() == "")) ||
        (requiredFields.includes("twitter_id") &&
          (!twitter_id || twitter_id.trim() == "")) ||
        (requiredFields.includes("youtube_id") &&
          (!youtube_id || youtube_id.trim() == "")) ||
        (requiredFields.includes("linkedin_id") &&
          (!linkedin_id || linkedin_id.trim() == "")) ||
        (requiredFields.includes("pintrest_id") &&
          (!pintrest_id || pintrest_id.trim() == "")) ||
        (requiredFields.includes("snapchat_id") &&
          (!snapchat_id || snapchat_id.trim() == "")) ||
        (requiredFields.includes("tiktok_id") &&
          (!tiktok_id || tiktok_id.trim() == "")) ||
        (requiredFields.includes("field_16") &&
          (!field_16 || field_16.trim() == "")) ||
        (requiredFields.includes("field_17") &&
          (!field_17 || field_17.trim() == "")) ||
        (requiredFields.includes("field_18") &&
          (!field_18 || field_18.trim() == "")) ||
        (requiredFields.includes("field_19") &&
          (!field_19 || field_19.trim() == "")) ||
        (requiredFields.includes("field_20") &&
          (!field_20 || field_20.trim() == "")) ||
        (requiredFields.includes("field_21") &&
          (!field_21 || field_21.trim() == "")) ||
        (requiredFields.includes("field_22") &&
          (!field_22 || field_22.trim() == "")) ||
        (requiredFields.includes("field_23") &&
          (!field_23 || field_23.trim() == "")) ||
        (requiredFields.includes("field_24") &&
          (!field_24 || field_24.trim() == "")) ||
        (requiredFields.includes("field_25") &&
          (!field_25 || field_25.trim() == ""))
      ) {
        if (requiredFields.includes("name") && (!name || name.trim() == "")) {
          setError(`error, please check Name for data input error`);
        }
        if (
          requiredFields.includes("last_name") &&
          (!last_name || last_name.trim() == "")
        ) {
          setError(`error, please check Last Name for data input error`);
        }
        if (
          requiredFields.includes("email_id") &&
          (!email_id || email_id.trim() == "")
        ) {
          setError(`error, please check Email Id for data input error`);
        }
        if (
          requiredFields.includes("contact") &&
          (!contact || contact.trim() == "")
        ) {
          setError(`error, please check Contact for data input error`);
        }
        if (
          requiredFields.includes("address") &&
          (!address || address.trim() == "")
        ) {
          setError(`error, please check Address for data input error`);
        }
        if (
          requiredFields.includes("business") &&
          (!business || business.trim() == "")
        ) {
          setError(`error, please check Business for data input error`);
        }
        if (
          requiredFields.includes("nfc_tag_id") &&
          (!nfc_tag_id || nfc_tag_id.trim() == "")
        ) {
          setError(`error, please check NFC Tag Id for data input error`);
        }
        if (
          requiredFields.includes("faebook_id") &&
          (!facebook_id || facebook_id.trim() == "")
        ) {
          setError(`error, please check Facebook Id for data input error`);
        }
        if (
          requiredFields.includes("instagram_id") &&
          (!instagram_id || instagram_id.trim() == "")
        ) {
          setError(`error, please check Instagram Id for data input error`);
        }
        if (
          requiredFields.includes("twitter_id") &&
          (!twitter_id || twitter_id.trim() == "")
        ) {
          setError(`error, please check Twitter Id for data input error`);
        }
        if (
          requiredFields.includes("youtube_id") &&
          (!youtube_id || youtube_id.trim() == "")
        ) {
          setError(`error, please check Youtube Id for data input error`);
        }
        if (
          requiredFields.includes("linkedin_id") &&
          (!linkedin_id || linkedin_id.trim() == "")
        ) {
          setError(`error, please check LinkedIn Id for data input error`);
        }
        if (
          requiredFields.includes("pintrest_id") &&
          (!pintrest_id || pintrest_id.trim() == "")
        ) {
          setError(`error, please check Pinterest Id for data input error`);
        }
        if (
          requiredFields.includes("snapchat_id") &&
          (!snapchat_id || snapchat_id.trim() == "")
        ) {
          setError(`error, please check Snapchat Id for data input error`);
        }
        if (
          requiredFields.includes("tiktok_id") &&
          (!tiktok_id || tiktok_id.trim() == "")
        ) {
          setError(`error, please check Tiktok Id for data input error`);
        }
        if (
          requiredFields.includes("field_16") &&
          (!field_16 || field_16.trim() == "")
        ) {
          setError(`error, please check Website for data input error`);
        }
        if (
          requiredFields.includes("field_17") &&
          (!field_17 || field_17.trim() == "")
        ) {
          setError(
            `error, please check ${
              participantListColumns.filter((x) => x.name == "field_17")[0]
                ? participantListColumns.filter((x) => x.name == "field_17")[0]
                    .title
                : null
            } for data input error`
          );
        }
        if (
          requiredFields.includes("field_18") &&
          (!field_18 || field_18.trim() == "")
        ) {
          setError(
            `error, please check ${
              participantListColumns.filter((x) => x.name == "field_18")[0]
                ? participantListColumns.filter((x) => x.name == "field_18")[0]
                    .title
                : null
            }  for data input error`
          );
        }
        if (
          requiredFields.includes("field_19") &&
          (!field_19 || field_19.trim() == "")
        ) {
          setError(
            `error, please check ${
              participantListColumns.filter((x) => x.name == "field_19")[0]
                ? participantListColumns.filter((x) => x.name == "field_19")[0]
                    .title
                : null
            }  for data input error`
          );
        }
        if (
          requiredFields.includes("field_20") &&
          (!field_20 || field_20.trim() == "")
        ) {
          setError(
            `error, please check ${
              participantListColumns.filter((x) => x.name == "field_20")[0]
                ? participantListColumns.filter((x) => x.name == "field_20")[0]
                    .title
                : null
            }  for data input error`
          );
        }
        if (
          requiredFields.includes("field_21") &&
          (!field_21 || field_21.trim() == "")
        ) {
          setError(
            `error, please check ${
              participantListColumns.filter((x) => x.name == "field_21")[0]
                ? participantListColumns.filter((x) => x.name == "field_21")[0]
                    .title
                : null
            }  for data input error`
          );
        }
        if (
          requiredFields.includes("field_22") &&
          (!field_22 || field_22.trim() == "")
        ) {
          setError(
            `error, please check ${
              participantListColumns.filter((x) => x.name == "field_22")[0]
                ? participantListColumns.filter((x) => x.name == "field_22")[0]
                    .title
                : null
            }  for data input error`
          );
        }
        if (
          requiredFields.includes("field_23") &&
          (!field_23 || field_23.trim() == "")
        ) {
          setError(
            `error, please check ${
              participantListColumns.filter((x) => x.name == "field_23")[0]
                ? participantListColumns.filter((x) => x.name == "field_23")[0]
                    .title
                : null
            }  for data input error`
          );
        }
        if (
          requiredFields.includes("field_24") &&
          (!field_24 || field_24.trim() == "")
        ) {
          setError(
            `error, please check ${
              participantListColumns.filter((x) => x.name == "field_24")[0]
                ? participantListColumns.filter((x) => x.name == "field_24")[0]
                    .title
                : null
            }  for data input error`
          );
        }
        if (
          requiredFields.includes("field_25") &&
          (!field_25 || field_25.trim() == "")
        ) {
          setError(
            `error, please check ${
              participantListColumns.filter((x) => x.name == "field_25")[0]
                ? participantListColumns.filter((x) => x.name == "field_25")[0]
                    .title
                : null
            }  for data input error`
          );
        }
      } else {
        let tempObj = {
          id: participantId,
          name: name ? name.trim() : "",
          email: email_id ? email_id.trim() : "",
          contact: contact ? contact.trim() : "",
          company_id: companyId,
          business: business ? business.trim() : "",
          nfc_tag_id: nfc_tag_id ? nfc_tag_id.trim() : "",
          linkedin_id: linkedin_id ? linkedin_id.trim() : "",
          is_registered: true,
          last_name: last_name ? last_name.trim() : "",
          address: address ? address.trim() : "",
          facebook_id: facebook_id ? facebook_id.trim() : "",
          instagram_id: instagram_id ? instagram_id.trim() : "",
          twitter_id: twitter_id ? twitter_id.trim() : "",
          youtube_id: youtube_id ? youtube_id.trim() : "",
          pintrest_id: pintrest_id ? pintrest_id.trim() : "",
          snapchat_id: snapchat_id ? snapchat_id.trim() : "",
          tiktok_id: tiktok_id ? tiktok_id.trim() : "",
          field_16: field_16 ? field_16.trim() : "",
          field_17: field_17 ? field_17.trim() : "",
          field_18: field_18 ? field_18.trim() : "",
          field_19: field_19 ? field_19.trim() : "",
          field_20: field_20 ? field_20.trim() : "",
          field_21: field_21 ? field_21.trim() : "",
          field_22: field_22 ? field_22.trim() : "",
          field_23: field_23 ? field_23.trim() : "",
          field_24: field_24 ? field_24.trim() : "",
          field_25: field_25 ? field_25.trim() : "",
        };

        document.getElementById("addParticipantSubmit").disabled = true;

        addParticipant(tempObj)
          .then((response) => {
            window["gtag"]("event", companyName, {
              nfc_id: nfc_tag_id,
              event_name: "total_registration",
              registration_mode: "self",
            });

            document.getElementById("addParticipantSubmit").disabled = true;

            setAddSneakBarFlag(true);
            loginHandler();
          })
          .catch((error) => {
            setError(error.message);

            document.getElementById("addParticipantSubmit").disabled = false;
          });
      }
    } else {
      let tempObj = {
        id: participantId,
        name: name ? name.trim() : "",
        email: email_id ? email_id.trim() : "",
        contact: contact ? contact.trim() : "",
        company_id: companyId,
        business: business ? business.trim() : "",
        nfc_tag_id: nfc_tag_id ? nfc_tag_id.trim() : "",
        linkedin_id: linkedin_id ? linkedin_id.trim() : "",
        is_registered: true,
        last_name: last_name ? last_name.trim() : "",
        address: address ? address.trim() : "",
        facebook_id: facebook_id ? facebook_id.trim() : "",
        instagram_id: instagram_id ? instagram_id.trim() : "",
        twitter_id: twitter_id ? twitter_id.trim() : "",
        youtube_id: youtube_id ? youtube_id.trim() : "",
        pintrest_id: pintrest_id ? pintrest_id.trim() : "",
        snapchat_id: snapchat_id ? snapchat_id.trim() : "",
        tiktok_id: tiktok_id ? tiktok_id.trim() : "",
        field_16: field_16 ? field_16.trim() : "",
        field_17: field_17 ? field_17.trim() : "",
        field_18: field_18 ? field_18.trim() : "",
        field_19: field_19 ? field_19.trim() : "",
        field_20: field_20 ? field_20.trim() : "",
        field_21: field_21 ? field_21.trim() : "",
        field_22: field_22 ? field_22.trim() : "",
        field_23: field_23 ? field_23.trim() : "",
        field_24: field_24 ? field_24.trim() : "",
        field_25: field_25 ? field_25.trim() : "",
      };

      document.getElementById("addParticipantSubmit").disabled = true;

      addParticipant(tempObj)
        .then((response) => {
          window["gtag"]("event", companyName, {
            nfc_id: nfc_tag_id,
            event_name: "total_registration",
            registration_mode: "self",
          });

          document.getElementById("addParticipantSubmit").disabled = true;

          setAddSneakBarFlag(true);
          window.location.reload();
        })
        .catch((error) => {
          setError(error.message);

          document.getElementById("addParticipantSubmit").disabled = false;
        });
    }
  };

  const loginHandler = () => {
    let tempObj = {
      email: email_id.toLowerCase().trim(),
      nfc_tag_id: nfc_tag_id.trim(),
    };
    // console.log(tempObj);

    participantLogin(tempObj)
      .then((response) => {
        // console.log(response);

        sessionStorage.setItem("participantLoginToken", response.data.token);
        localStorage.setItem("participantToken", response.data.token);
        localStorage.setItem(
          "companyName",
          response.data?.companyName ? response.data?.companyName : ""
        );

        localStorage.setItem("participantNfcId", nfc_tag_id.trim());
        debugger;
        window.location.reload();
      })
      .catch((error) => {
        setEmailError(
          "Invalid email address. Please make sure you entered the correct email address. Note that your entry is case sensitive."
        );
        setEmailId("");
        // setError(error.message)
      });
  };

  const searchBtnHandler = () => {
    setSearchList({ ...searchList, loading: true });
    getParticipantDetailsByEmailOrName(searchText, companyId)
      .then((response) => {
        console.log("search", response.data);
        setSearchList({ loading: false, records: response.data });
      })
      .catch((error) => setSearchList({ ...searchList, loading: false }));
  };
  const handleSelectedRecord = (data) => {
    try {
      const newData = { ...data, nfc_tag_id: nfc_tag_id };
      setName(newData.name);
      setLastName(newData.last_name);
      setEmailId(newData.email_id);
      setBusiness(newData.business);
      setContact(newData.contact);
      setAddress(newData.address);
      setLinkedInId(newData.linkedin_id);
      setNfcTagId(newData.nfc_tag_id);
      setFacebookId(newData.facebook_id);
      setInstagramId(newData.instagram_id);
      setYoutubeId(newData.youtube_id);
      setTwitterId(newData.twitter_id);
      setPinterestId(newData.pintrest_id);
      setSnapchatId(newData.snapchat_id);
      setTiktokId(newData.tiktok_id);
      setField16(newData.field_16);
      setField17(newData.field_17);
      setField18(newData.field_18);
      setField19(newData.field_19);
      setField20(newData.field_20);
      setField21(newData.field_21);
      setField22(newData.field_22);
      setField23(newData.field_23);
      setField24(newData.field_24);
      setField25(newData.field_25);
      setRegistationModal(false);
      setSearchModal(false);
      getAddNewFormFields1(props.companyId, newData);
    } catch (err) {
      console.log(err);
    }
    // setName("kishor");
    // setRegistationModal(false);
    // setSearchModal(false);
  };

  return (
    <div
      className={`${companyName}-registration-page-main-div registration-page-main-div`}
    >
      <a href={logoUrl ? logoUrl : ""} target="_blank" rel="noreferrer">
        <div className="participant-page-logo-div">
          {
            companyConfigloading ? (
              <div className="d-flex justify-content-center">
                <ClipLoader
                  color={spinnerColor}
                  loading={companyConfigloading}
                  size={50}
                />
              </div>
            ) : logoImg ? (
              <img
                className="responsive participant-page-logo"
                src={`${Config.imageApiUrl}/` + logoImg}
                // src={logoImg ? logoImg : LogoPlaceholder }
              ></img>
            ) : (
              <img className="responsive participant-page-default-logo"></img>
            )
            // <ClipLoader className="d-flex justify-content-center" color={spinnerColor} loading={loading}  size={150} />
          }
        </div>
      </a>
      <div
        className={`${companyName}-registration-custom-instruction-div registration-custom-instruction-div `}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: customInstruction,
          }}
        ></div>
      </div>
      <div
        className={`${companyName}-registration-self-registration-div registration-self-registration-div `}
      >
        <div
          className="modal blue-modal fade  "
          id="addInsuranceModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  User Self Registration
                </h5>
              </div>
              {!searchModal &&
                (!registationModal || props.isRegistered == true) && (
                  <div className="modal-body">
                    <div className="ins-modal-body">
                      <form action="">
                        <div className="row">{addNewFormFieldsList}</div>
                      </form>
                    </div>
                  </div>
                )}
              <div className="mr-l20 text-danger fw-bold">
                Note: *marked are mandatory fields
              </div>
              {error !== "" ? (
                <p className="error-alert mr-b20 mr-l40">{error}</p>
              ) : null}
              <div className="modal-footer pd-b30">
                {skipRegistration ? (
                  <button
                    type="button"
                    className="s-blue-border-btn m-btn"
                    data-dismiss="modal"
                    onClick={() => {
                      addParticipantHandler(false);
                    }}
                  >
                    Skip
                  </button>
                ) : null}
                <button
                  type="button"
                  className="s-blue-btn m-btn"
                  data-dismiss="modal"
                  id="addParticipantSubmit"
                  onClick={() => addParticipantHandler(true)}
                >
                  {/* Register */}
                  {registerButtonText}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={registationModal && props.isRegistered == false}
        disableBackdropClick={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className="modal blue-modal fade"
          id="addInsuranceModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className={`modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable  ${companyName}-modal-dialog-search modal-dialog-search`}
          >
            <div
              className={`modal-content  ${companyName}-search-modal-content search-modal-content`}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  New user registration with search
                </h5>
              </div>
              {/* <div className="modal-body">
                Are you sure you want to delete image?
              </div> */}

              <div className=" pd-b30 mx-auto">
                <div className="m-3">
                  <button
                    type="button"
                    className={`s-blue-btn search-user-btn ${companyName}-search-user-btn`}
                    data-dismiss="modal"
                    onClick={() => {
                      setSearchModal(true);
                      setRegistationModal(false);
                      props.setIsRegistered1(false);
                    }}
                  >
                    Search Existent User
                  </button>
                </div>
                <div className="m-3">
                  <button
                    type="button"
                    className={`s-blue-btn search-user-btn ${companyName}-search-user-btn`}
                    onClick={() => {
                      setRegistationModal(false);
                      props.setIsRegistered1(false);
                    }}
                  >
                    New Registration
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={searchModal}
        disableBackdropClick={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className="modal blue-modal fade"
          id="addInsuranceModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className={`modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable  ${companyName}-search-modal-reg search-modal-reg`}
          >
            <div className="modal-content m-3">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Search User By Email/Name
                </h5>
              </div>
              <div className="m-3">
                <div dangerouslySetInnerHTML={{ __html: searchReg }}></div>
                <div
                  className={`search-filter mt-3  ${companyName}-search-filter`}
                >
                  <div className="row d-flex justify-content-end">
                    <div className="col-sm-10 mr-2 mb-3">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control"
                          className={`form-control ${companyName}-form-control`}
                          placeholder="Search"
                          value={searchText}
                          onChange={(e) => {
                            setSearchText(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-sm-2 mb-3">
                      <button
                        type="button"
                        className={`s-blue-btn w-100  ${companyName}-s-blue`}
                        onClick={() => {
                          //   setFilterType("");
                          searchBtnHandler();
                        }}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
                {searchList?.loading && (
                  <Box sx={{ width: "100%", color: "red", my: 1 }} spacing={2}>
                    <LinearProgress />
                  </Box>
                )}
                <div className={`search-body  ${companyName}-search-body`}>
                  {searchList?.records?.length ? (
                    searchList?.records?.map((data, index) => (
                      <div
                        className={`label-search  ${companyName}-label-search ${
                          index % 2 == 0 ? "color-gray " : ""
                        }`}
                        key={data.id}
                        onClick={() => handleSelectedRecord(data)}
                      >
                        <span>
                          {" "}
                          {data.name + " "} {data?.last_name}
                        </span>
                        <span> {data.contact}</span>
                        <span> {data.email_id}</span>
                      </div>
                    ))
                  ) : (
                    <h6 className="text-center">No Data Available</h6>
                  )}
                </div>
              </div>
              <div className=" pd-b30 mx-auto">
                <div className="m-3">
                  <button
                    type="button"
                    className={`s-blue-btn search-user-btn ${companyName}-search-user-btn`}
                    onClick={() => {
                      setRegistationModal(true);
                      setSearchModal(false);
                    }}
                  >
                    Go Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Snackbar
        open={addSneakBarFlag}
        autoHideDuration={6000}
        onClose={() => {
          setAddSneakBarFlag(false);
        }}
      >
        <Alert
          onClose={() => {
            setAddSneakBarFlag(false);
          }}
          severity="success"
        >
          Participant Is Successfully Registered
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Registration;
