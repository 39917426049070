import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  saveCompanyFields,
  getCompanyFieldsById,
} from "../../services/adminApi";
import { keys } from "@material-ui/core/styles/createBreakpoints";
import { Button } from "@material-ui/core";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
    maxWidth: 100,
  },
}));

const styles = (theme) => ({
  field: {
    margin: "10px 0",
  },
  countryList: {
    ...theme.typography.body1,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const DynamicForm = (props) => {
  const classes = useStyles();

  const [companyName, setCompanyName] = useState("");
  const [companyId, setCompanyId] = useState("");

  const [formTableDataDisplayList, setFormTableDataDisplayList] = useState([]);
  const [addSneakBarFlag, setAddSneakBarFlag] = useState(false);

  const [companyFormFieldList, setCompanyFormFieldList] = useState([]);
  const [error, setError] = useState("");

  const [formColumnNameList, setFormColumnNameList] = useState([
    { name: "fieldId", title: "Field Id" },
    { name: "label", title: "Label" },

    { name: "type", title: "Type" },
    { name: "typeValue", title: "Type Value" },
    { name: "isRequired", title: "Is Required" },
    { name: "includeCnC", title: "Include in C & C" },
    { name: "isActive", title: "Active" },
    { name: "fieldIcon", title: "Field Icon" },
  ]);

  const [typeList, setTypeList] = useState([
    { id: "0", name: "Text" },
    { id: "1", name: "Checkbox" },
    { id: "2", name: "Dropdown" },
    { id: "3", name: "Radio" },
  ]);

  let [formFieldRowsList, setFormFieldRowsList] = useState({
    F1: {
      field_name: "name",
      label: "Name",
      type: "Text",
      type_value: "",
      is_required: true,
      include_cnc: true,
      is_active: true,
      field_icon: "",
      can_delete: 0,
    },
    F2: {
      field_name: "last_name",
      label: "Last Name",
      type: "Text",
      type_value: "",
      is_required: true,
      include_cnc: false,
      is_active: true,
      field_icon: "",
      can_delete: 0,
    },
    F3: {
      field_name: "email_id",
      label: "Email",
      type: "Text",
      type_value: "",
      is_required: true,
      include_cnc: false,
      is_active: true,
      field_icon: "",
      can_delete: 0,
    },
    F4: {
      field_name: "contact",
      label: "Contact",
      type: "Text",
      type_value: "",
      is_required: true,
      include_cnc: false,
      is_active: true,
      field_icon: "",
      can_delete: 0,
    },
    F5: {
      field_name: "business",
      label: "Business",
      type: "Text",
      type_value: "",
      is_required: true,
      include_cnc: false,
      is_active: true,
      field_icon: "",
      can_delete: 0,
    },
    F6: {
      field_name: "address",
      label: "Address",
      type: "Text",
      type_value: "",
      is_required: true,
      include_cnc: false,
      is_active: true,
      field_icon: "",
      can_delete: 0,
    },
    F7: {
      field_name: "nfc_tag_id",
      label: "Nfc Tag Id",
      type: "Text",
      type_value: "",
      is_required: true,
      include_cnc: false,
      is_active: true,
      field_icon: "",
      can_delete: 0,
    },
    F8: {
      field_name: "linkedin_id",
      label: "Linked-In Id",
      type: "Text",
      type_value: "",
      is_required: true,
      include_cnc: false,
      is_active: true,
      field_icon: "",
      can_delete: 0,
    },
    F9: {
      field_name: "facebook_id",
      label: "Facebook Id",
      type: "Text",
      type_value: "",
      is_required: false,
      include_cnc: false,
      is_active: false,
      field_icon: "",
      can_delete: 0,
    },
    F10: {
      field_name: "instagram_id",
      label: "Instagram Id",
      type: "Text",
      type_value: "",
      is_required: false,
      include_cnc: false,
      is_active: false,
      field_icon: "",
      can_delete: 0,
    },
    F11: {
      field_name: "twitter_id",
      label: "Twitter Id",
      type: "Text",
      type_value: "",
      is_required: false,
      include_cnc: false,
      is_active: false,
      field_icon: "",
      can_delete: 0,
    },
    F12: {
      field_name: "youtube_id",
      label: "Youtube Id",
      type: "Text",
      type_value: "",
      is_required: false,
      include_cnc: false,
      is_active: false,
      field_icon: "",
      can_delete: 0,
    },
    F13: {
      field_name: "pintrest_id",
      label: "Pinterest Id",
      type: "Text",
      type_value: "",
      is_required: false,
      include_cnc: false,
      is_active: false,
      field_icon: "",
      can_delete: 0,
    },
    F14: {
      field_name: "snapchat_id",
      label: "Snapchat Id",
      type: "Text",
      type_value: "",
      is_required: false,
      include_cnc: false,
      is_active: false,
      field_icon: "",
      can_delete: 0,
    },
    F15: {
      field_name: "tiktok_id",
      label: "Tiktok Id",
      type: "Text",
      type_value: "",
      is_required: false,
      include_cnc: false,
      is_active: false,
      field_icon: "",
      can_delete: 0,
    },
    F16: {
      field_name: "field_16",
      label: "Website",
      type: "Text",
      type_value: "",
      is_required: false,
      include_cnc: false,
      is_active: false,
      field_icon: "",
      can_delete: 0,
    },
    F17: {
      field_name: "field_17",
      label: "",
      type: "",
      type_value: "",
      is_required: false,
      include_cnc: false,
      is_active: false,
      field_icon: "",
      can_delete: 1,
    },
    F18: {
      field_name: "field_18",
      label: "",
      type: "",
      type_value: "",
      is_required: false,
      include_cnc: false,
      is_active: false,
      field_icon: "",
      can_delete: 1,
    },
    F19: {
      field_name: "field_19",
      label: "",
      type: "",
      type_value: "",
      is_required: false,
      include_cnc: false,
      is_active: false,
      field_icon: "",
      can_delete: 1,
    },
    F20: {
      field_name: "field_20",
      label: "",
      type: "",
      type_value: "",
      is_required: false,
      include_cnc: false,
      is_active: false,
      field_icon: "",
      can_delete: 1,
    },
    F21: {
      field_name: "field_21",
      label: "",
      type: "",
      type_value: "",
      is_required: false,
      include_cnc: false,
      is_active: false,
      field_icon: "",
      can_delete: 1,
    },
    F22: {
      field_name: "field_22",
      label: "",
      type: "",
      type_value: "",
      is_required: false,
      include_cnc: false,
      is_active: false,
      field_icon: "",
      can_delete: 1,
    },
    F23: {
      field_name: "field_23",
      label: "",
      type: "",
      type_value: "",
      is_required: false,
      include_cnc: false,
      is_active: false,
      field_icon: "",
      can_delete: 1,
    },
    F24: {
      field_name: "field_24",
      label: "",
      type: "",
      type_value: "",
      is_required: false,
      include_cnc: false,
      is_active: false,
      field_icon: "",
      can_delete: 1,
    },
    F25: {
      field_name: "field_25",
      label: "",
      type: "",
      type_value: "",
      is_required: false,
      include_cnc: false,
      is_active: false,
      field_icon: "",
      can_delete: 1,
    },
  });

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("selectedCompany"));
    setCompanyName(data.company_name);
    setCompanyId(data.id);

    getCompanyFieldsHandler(data.id);
  }, []);

  useEffect(() => {
    // getDisplayTable()
  }, [formTableDataDisplayList]);

  const labelInputFieldChangeHandler = (e) => {
    setError("");
    formFieldRowsList[e.target.name]["label"] = e.target.value;
  };

  const typeValueInputFieldChangeHandler = (e) => {
    setError("");
    formFieldRowsList[e.target.name]["type_value"] = e.target.value;
  };

  const isRequiredCheckboxChangeHandler = (e) => {
    setError("");
    document.getElementById(`isRequired${e.target.name}`).checked =
      !formFieldRowsList[e.target.name]["is_required"];
    formFieldRowsList[e.target.name].is_required =
      !formFieldRowsList[e.target.name].is_required;
  };

  const includeCncCheckboxChangeHandler = (e) => {
    setError("");
    document.getElementById(`includeCnc${e.target.name}`).checked =
      !formFieldRowsList[e.target.name]["include_cnc"];
    formFieldRowsList[e.target.name].include_cnc =
      !formFieldRowsList[e.target.name].include_cnc;
  };

  const isActiveCheckboxChangeHandler = (e) => {
    setError("");
    document.getElementById(`isActive${e.target.name}`).checked =
      !formFieldRowsList[e.target.name]["is_active"];
    formFieldRowsList[e.target.name].is_active =
      !formFieldRowsList[e.target.name].is_active;
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        // console.log("baseurl", baseURL);
        resolve(baseURL);
      };
      // console.log("fileinfo", fileInfo);
    });
  };

  const fieldIconHandler = (e) => {
    setError("");
    let { file } = {
      file: null,
      base64URL: "",
    };
    file = e.target.files[0];
    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        formFieldRowsList[e.target.name]["field_icon"] = result;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const typeHandler = (e) => {
    setError("");
    document.getElementById(`type${e.target.name}`).value = e.target.value;
    // document.getElementById(`typeRadio${e.target.name}`).checked = e.target.value.indexOf(name.name) > -1;
    formFieldRowsList[e.target.name].type = e.target.value;
  };

  const getDisplayTable = () => {
    let formTableDataDisplayListTemp = [];

    for (var i = 0; i < 25; i++) {
      formTableDataDisplayListTemp.push(
        <TableRow
          key={i}
          // onClick={() => { localStorage.setItem('selectedCompany', JSON.stringify(ins)); props.history.push('/admin/userList') }}
        >
          <TableCell component="th" scope="row">
            {"F" + (i + 1)}
          </TableCell>
          <TableCell>
            <div className="form-group gray-fill-input mr-b30">
              <input
                className="form-control mr-t20 w-auto overflow-auto"
                name={"F" + (i + 1)}
                id={"labelF" + (i + 1)}
                onChange={(e) => {
                  labelInputFieldChangeHandler(e);
                }}
                defaultValue={
                  i == 15 ? "Website" : formFieldRowsList["F" + (i + 1)].label
                }
              />
            </div>
          </TableCell>

          <TableCell>
            <div className="form-group gray-fill-input mr-b10 ">
              <FormControl className={classes.formControl}>
                <Select
                  className="form-control w-auto overflow-auto"
                  labelId="mutiple-module-checkbox-label"
                  id={"typeF" + (i + 1)}
                  name={"F" + (i + 1)}
                  disabled={i < 16 ? true : false}
                  defaultValue={
                    i == 15 ? "text" : formFieldRowsList["F" + (i + 1)].type
                  }
                  onChange={typeHandler}
                  input={<Input />}
                  renderValue={(selected) => selected}
                  MenuProps={MenuProps}
                  disableUnderline
                >
                  {typeList.map((name) => (
                    <MenuItem key={name.id} value={name.name}>
                      {/* <Radio
                                                id={'typeRadioF' + (i + 1)}
                                            checked={formFieldRowsList['F' + (i + 1)]["type"].indexOf(name.name) > -1} 
                                            /> */}
                      <ListItemText primary={name.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* {moduleError !== '' ? <p className="error-alert mr-b20">{moduleError}</p> : null} */}
            </div>
          </TableCell>
          <TableCell>
            <div className="form-group gray-fill-input mr-b30 ">
              <input
                className="form-control mr-t20 w-auto overflow-auto"
                name={"F" + (i + 1)}
                id={"typeValueF" + (i + 1)}
                disabled={i < 16 ? true : false}
                onChange={(e) => {
                  typeValueInputFieldChangeHandler(e);
                }}
                defaultValue={formFieldRowsList["F" + (i + 1)].type_value}
              />
            </div>
          </TableCell>
          <TableCell>
            <div className="form-group gray-fill-input mr-b30 ">
              <Checkbox
                defaultChecked={formFieldRowsList["F" + (i + 1)].is_required}
                onChange={isRequiredCheckboxChangeHandler}
                value={formFieldRowsList["F" + (i + 1)].is_required}
                name={"F" + (i + 1)}
                id={"isRequiredF" + (i + 1)}
                inputProps={{ "aria-label": "A" }}
                disabled={i == 0 || i == 2 || i == 6 ? true : false}
              />
            </div>
          </TableCell>
          <TableCell>
            <div className="form-group gray-fill-input mr-b30 ">
              <Checkbox
                defaultChecked={formFieldRowsList["F" + (i + 1)].include_cnc}
                onChange={includeCncCheckboxChangeHandler}
                value={formFieldRowsList["F" + (i + 1)].include_cnc}
                name={"F" + (i + 1)}
                id={"includeCncF" + (i + 1)}
                inputProps={{ "aria-label": "A" }}
                disabled={i == 0 ? true : false}
              />
            </div>
          </TableCell>
          <TableCell>
            <div className="form-group gray-fill-input mr-b30 ">
              <Checkbox
                defaultChecked={formFieldRowsList["F" + (i + 1)].is_active}
                onChange={isActiveCheckboxChangeHandler}
                value={formFieldRowsList["F" + (i + 1)].is_active}
                name={"F" + (i + 1)}
                id={"isActiveF" + (i + 1)}
                inputProps={{ "aria-label": "A" }}
                disabled={i == 0 || i == 2 || i == 6 ? true : false}
              />
            </div>
          </TableCell>
          <TableCell>
            <div className="form-group gray-fill-input mr-b30">
              <input
                name={"F" + (i + 1)}
                type="file"
                accept=".png, .jpg, .jpeg"
                id={"fieldIconF" + (i + 1)}
                className=" mr-t20"
                onChange={(e) => fieldIconHandler(e)}
                // title=" "
              />
              <img
                src={formFieldRowsList["F" + (i + 1)]?.field_icon}
                height={43}
                className="mt-3"
              ></img>
            </div>
          </TableCell>
        </TableRow>
      );
    }
    setFormTableDataDisplayList(formTableDataDisplayListTemp);
    // return formTableDataDisplayListTemp
  };

  const submitFormFieldHandler = () => {
    // console.log(formFieldRowsList);

    let errorFlag = false;

    let tempObj = {
      company_fields: [],
    };
    Object.keys(formFieldRowsList).forEach((row) => {
      // console.log(row);
      if (
        (formFieldRowsList[row].type.toLowerCase() == "radio" &&
          formFieldRowsList[row].type_value == "") ||
        (formFieldRowsList[row].type.toLowerCase() == "checkbox" &&
          formFieldRowsList[row].type_value == "") ||
        (formFieldRowsList[row].type.toLowerCase() == "dropdown" &&
          formFieldRowsList[row].type_value == "")
      ) {
        errorFlag = true;
        setError(
          'Please fill the values if you have selected "Radio" or "Dropdown" or "Checkbox" for a field'
        );
        return;
      } else {
        tempObj.company_fields.push({
          company_id: companyId,
          field_name: formFieldRowsList[row].field_name,
          label: formFieldRowsList[row].label,
          type: formFieldRowsList[row].type,
          is_required: formFieldRowsList[row].is_required,
          is_active: formFieldRowsList[row].is_active,
          field_icon: formFieldRowsList[row].field_icon,
          include_in_cc: formFieldRowsList[row].include_cnc,
          can_delete: formFieldRowsList[row].can_delete,
          value: formFieldRowsList[row].type_value,
        });
      }
    });

    if (!errorFlag) {
      saveCompanyFields(companyId, tempObj).then((response) => {
        setAddSneakBarFlag(true);
        getCompanyFieldsHandler(companyId);
      });
    }
  };

  const getCompanyFieldsHandler = (id) => {
    let tempData;
    let formFieldRowsListTemp = [];
    formFieldRowsListTemp = formFieldRowsList;

    getCompanyFieldsById(id).then(async (response) => {
      tempData = response.data;
      setCompanyFormFieldList(response.data);

      Object.keys(formFieldRowsListTemp).forEach((row) => {

        let tempRow = tempData.filter(
          (x) => x.field_name == formFieldRowsListTemp[row].field_name
        );
        formFieldRowsListTemp[row].label = tempRow[0]
          ? tempRow[0].label
          : formFieldRowsListTemp[row].label;
        formFieldRowsListTemp[row].type = tempRow[0]
          ? tempRow[0].type
          : formFieldRowsListTemp[row].type;
        formFieldRowsListTemp[row].type_value = tempRow[0]
          ? tempRow[0].value
          : formFieldRowsListTemp[row].type_value;
        formFieldRowsListTemp[row].is_required = tempRow[0]
          ? tempRow[0].is_required
          : formFieldRowsListTemp[row].is_required;
        formFieldRowsListTemp[row].include_cnc = tempRow[0]
          ? tempRow[0].include_in_cc
          : formFieldRowsListTemp[row].include_cnc;
        formFieldRowsListTemp[row].is_active = tempRow[0]
          ? tempRow[0].is_active
          : formFieldRowsListTemp[row].is_active;
        formFieldRowsListTemp[row].field_icon = tempRow[0]
          ? tempRow[0].field_icon
          : formFieldRowsListTemp[row].field_icon;
      });

      await setFormFieldRowsList(formFieldRowsListTemp);

      getDisplayTable();
    });
  };

  return (
    <div>
      <div className="icon">
        <NavLink to={"/admin/companyList"}>
          <button type="button" className="trans-btn mr-r10">
            <svg
              width="20"
              height="16"
              viewBox="0 0 20 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.33333 2.86765e-05C8.66437 -0.00183006 8.98849 0.0915604 9.26435 0.268294C9.54022 0.445028 9.75534 0.697096 9.88228 0.992364C10.0092 1.28763 10.0422 1.61272 9.97713 1.92618C9.91201 2.23965 9.75171 2.52728 9.51667 2.75241L4.01667 8.04794L9.51667 13.3435C9.78971 13.6514 9.93239 14.0475 9.91618 14.4525C9.89998 14.8576 9.7261 15.2419 9.42927 15.5286C9.13245 15.8152 8.73455 15.9832 8.31509 15.9988C7.89563 16.0144 7.4855 15.8767 7.16667 15.613L0.499999 9.17464C0.18958 8.87307 0.015344 8.46512 0.0153439 8.03989C0.0153439 7.61466 0.18958 7.20671 0.499999 6.90513L7.16667 0.466808C7.4771 0.169448 7.8961 0.00180636 8.33333 2.86765e-05Z"
                fill="#0099CC"
              />
              <path
                d="M1.66667 6.43835L18.3333 6.43835C18.7754 6.43835 19.1993 6.60793 19.5118 6.90979C19.8244 7.21164 20 7.62105 20 8.04793C20 8.47482 19.8244 8.88422 19.5118 9.18608C19.1993 9.48793 18.7754 9.65752 18.3333 9.65752L1.66667 9.65752C1.22464 9.65752 0.800716 9.48794 0.488155 9.18608C0.175594 8.88423 -6.57872e-07 8.47482 -6.95192e-07 8.04794C-7.32511e-07 7.62105 0.175594 7.21164 0.488155 6.90979C0.800716 6.60793 1.22464 6.43835 1.66667 6.43835Z"
                fill="#0099CC"
              />
            </svg>
          </button>
        </NavLink>
        <span className="">Dashboard</span>
        <span className=""> &#62; {companyName}</span>
      </div>
      <div className="cus-table">
        <div className="table-str">
          <TableContainer component={Paper}>
            <Table aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  {formColumnNameList.map((column) => (
                    <TableCell key={column.name} className="align-top">
                      {(column.name = column.title)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {getDisplayTable()} */}
                {formTableDataDisplayList}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div>
          {error !== "" ? (
            <p className="error-alert mr-b20 mr-l40">{error}</p>
          ) : null}
        </div>
      </div>

      <div className="modal-footer pd-b30">
        {/* <button type="button" className="s-blue-border-btn m-btn" data-dismiss="modal" onClick={() => { setChangePasswordModalFlag(false); }}>Cancel</button> */}
        <button
          type="button"
          className="s-blue-btn m-btn"
          data-dismiss="modal"
          id="addFormFieldSubmit"
          onClick={() => {
            submitFormFieldHandler();
          }}
        >
          {/* {editFlag ? 'Update Company' : 'Add Company'} */}
          Save
        </button>
      </div>

      <Snackbar
        open={addSneakBarFlag}
        autoHideDuration={6000}
        onClose={() => {
          setAddSneakBarFlag(false);
        }}
      >
        <Alert onClose={() => setAddSneakBarFlag(false)} severity="success">
          Dynamic Form is Saved
          {/* {editFlag ? "Updated" : "Added"} */}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default DynamicForm;
