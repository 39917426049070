import React from "react";
import Config from "../../config.json";
import $ from "jquery";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const SponsorList = ({ options, sponsorImages, sponsorClickHandler }) => {
  const setOwlStageHeight = (event) => {
    var maxHeight = 0;
    $(".owl-item.active").each(function () {
      // LOOP THROUGH ACTIVE ITEMS
      var thisHeight = parseInt($(this).height());
      maxHeight = maxHeight >= thisHeight ? maxHeight : thisHeight;
    });
    $(".owl-carousel").css("height", maxHeight);
    $(".owl-stage-outer").css("height", maxHeight); // CORRECT DRAG-AREA SO BUTTONS ARE CLICKABLE
    $(".owl-nav").css("top", (maxHeight * -1) / 2);
  };

  return (
    <OwlCarousel
      className="owl-theme"
      {...options}
      //   onInitialized={setOwlStageHeight}
      //   onResized={setOwlStageHeight}
      //   onTranslated={setOwlStageHeight}
    >
      {sponsorImages?.map((record) => (
        <img
          src={Config?.imageApiUrl + "/" + record.image}
          loading="lazy"
          onClick={() => {
            sponsorClickHandler(record?.target_url, record.open);
          }}
          alt=""
          key={record.id}
          //   onLoad={(event) => setOwlStageHeight(event)}
        />
      ))}
    </OwlCarousel>
  );
};

export default SponsorList;
