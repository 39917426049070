import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { addCompanyConfig, getCompanyConfigByCompanyId } from '../../services/adminApi';
import Config from '../../config.json';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertFromHTML, convertFromRaw } from 'draft-js';
import { ContentState, convertToRaw } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';

const Preview = (props) => {

    const [logoImg, setLogoImg] = useState();
    const [defaultProfileImg, setDefaultProfileImg] = useState();
    const [sponsorImg, setSponsorImg] = useState([]);
    const [appHeaderImg, setAppHeaderImg] = useState();
    const [description, setDescription] = useState('');
    const [homeUrl, setHomeUrl] = useState('');
    const [mapUrl, setMapUrl] = useState('');
    const [photoFrameUrl, setPhotoFrameUrl] = useState('');
    const [logoUrl, setLogoUrl] = useState('');
    const [sponsorUrl, setSponsorUrl] = useState('');
    const [backgroundColor, setBackgroundColor] = useState();
    const [buttonBackgroundColor, setButtonBackgroundColor] = useState();
    const [buttonTextColor, setButtonTextColor] = useState();
    const [textColor, setTextColor] = useState();
    const [appThemeColor, setAppThemeColor] = useState();
    const [companyId, setCompanyId] = useState();
    const [configId, setConfigId] = useState(0);
    const [customInstruction, setCustomInstruction] = useState("");
    const [skipRegistration, setSkipRegistration] = useState();
    const [enableProfilePhotoEdit, setEnableProfilePhotoEdit] = useState(0);

    const [logoFlag, setLogoFlag] = useState(false);
    const [sponsorFlag, setSponsorFlag] = useState(false);
    const [defaultProfileFlag, setDefaultProfileFlag] = useState(false);
    const [appHeaderImgFlag, setAppHeaderImgFlag] = useState(false);

    const [editorState, setEditorState] = useState('');
    const [customInstructionEditorState, setCustomInstructionEditorState] = useState(EditorState.createEmpty());

    const [error, setError] = useState('')





    useEffect(() => {
        let data = JSON.parse(localStorage.getItem('companyPage'));
        let data1 = JSON.parse(localStorage.getItem('selectedCompany'))
        // console.log("preview", data1);
        setLogoImg(data.logo);
        setDefaultProfileImg(data.defaultProfileImg);
        setAppHeaderImg(data.appHeaderImg);
        setDescription(data.description);
        setSponsorImg(data.sponsor);
        setHomeUrl(data.homeUrl);
        setMapUrl(data.mapUrl);
        setLogoUrl((data.logoUrl.split(":")[0] === "http" || data.logoUrl.split(":")[0] === "https") ? data.logoUrl : "//" + data.logoUrl);
        setSponsorUrl((data.sponsorUrl.split(":")[0] === "http" || data.sponsorUrl.split(":")[0] === "https") ? data.sponsorUrl : "//" + data.sponsorUrl);
        setPhotoFrameUrl(data.photoFrameUrl);
        setBackgroundColor(data.backgroundColor);
        setTextColor(data.textColor);
        setButtonBackgroundColor(data.buttonBackgroundColor);
        setButtonTextColor(data.buttonTextColor);
        setLogoFlag(data.logoFlag);
        setSponsorFlag(data.sponsorFlag);
        setDefaultProfileFlag(data.defaultProfileFlag);
        setAppHeaderImgFlag(data.appHeaderImgFlag);
        setAppThemeColor(data.appThemeColor);
        setCustomInstruction(data.customInstruction);
        setSkipRegistration(data.skipRegistration);
        setEnableProfilePhotoEdit(data.enableProfilePhotoEdit)
        // console.log(htmlToDraft(data.editorState));
        // setEditorState(htmlToDraft(data.editorState));
        // const blocksFromHtml = convertFromHTML(data.editorState);
        // console.log("blocksFromHtml",blocksFromHtml);
        // const { contentBlocks, entityMap } = blocksFromHtml;
        // console.log("contentBlocks",contentBlocks);
        // const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        // console.log(EditorState.createWithContent(contentState));
        // setEditorState(EditorState.createWithContent(contentState));

        setEditorState(data.editorState);
        setCustomInstructionEditorState(data.customInstructionEditorState)

        // debugger
        setCompanyId(data1.id);

        getCompanyConfigHandler(data1.id);

    }, [])

    const getCompanyConfigHandler = (id) => {

        getCompanyConfigByCompanyId(id).then(
            async (response) => {
                // console.log(response.data);
                let data = response.data ? response.data[0] : {};
                data.id ? setConfigId(data.id) : setConfigId(0);
            }
        )
    }

    const submitHandler = () => {
        let tempObj = {
            "id": configId,
            "company_id": companyId,
            "logo_base64": logoImg,
            "app_header_img": appHeaderImg,
            "home_url": homeUrl,
            "map_url": mapUrl,
            "photo_frame_url": photoFrameUrl,
            "background_color": backgroundColor,
            "button_background_color": buttonBackgroundColor,
            "text_color": textColor,
            "description": description,
            "sponsor_logo_files": sponsorImg,
            "footer_url": sponsorUrl,
            "header_url": logoUrl,
            "button_text_color": buttonTextColor,
            "app_theme_color": appThemeColor,
            "default_profile_photo": defaultProfileImg,
            "custom_instruction" : customInstruction,
            "skip_page" : skipRegistration ? 1 : 0,
            "enable_profile_photo_edit" : enableProfilePhotoEdit ? 1 : 0,
        }
        
        
        addCompanyConfig(tempObj).then(
            (response) => {
                props.history.push("/admin/companyList")
            }
        ).catch(
            (error) => {
                setError(error.message)


            }
        )


    }

    const onClickPhotoHandler = () => {
        window.open(logoUrl, "_blank");
    }

    return (
        <div>
            <div className="icon">
                <NavLink to={'/admin/moduleForm'}>
                    <button type="button" className="trans-btn mr-r10">
                        <svg width="20" height="16" viewBox="0 0 20 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.33333 2.86765e-05C8.66437 -0.00183006 8.98849 0.0915604 9.26435 0.268294C9.54022 0.445028 9.75534 0.697096 9.88228 0.992364C10.0092 1.28763 10.0422 1.61272 9.97713 1.92618C9.91201 2.23965 9.75171 2.52728 9.51667 2.75241L4.01667 8.04794L9.51667 13.3435C9.78971 13.6514 9.93239 14.0475 9.91618 14.4525C9.89998 14.8576 9.7261 15.2419 9.42927 15.5286C9.13245 15.8152 8.73455 15.9832 8.31509 15.9988C7.89563 16.0144 7.4855 15.8767 7.16667 15.613L0.499999 9.17464C0.18958 8.87307 0.015344 8.46512 0.0153439 8.03989C0.0153439 7.61466 0.18958 7.20671 0.499999 6.90513L7.16667 0.466808C7.4771 0.169448 7.8961 0.00180636 8.33333 2.86765e-05Z"
                                fill="#0099CC" />
                            <path
                                d="M1.66667 6.43835L18.3333 6.43835C18.7754 6.43835 19.1993 6.60793 19.5118 6.90979C19.8244 7.21164 20 7.62105 20 8.04793C20 8.47482 19.8244 8.88422 19.5118 9.18608C19.1993 9.48793 18.7754 9.65752 18.3333 9.65752L1.66667 9.65752C1.22464 9.65752 0.800716 9.48794 0.488155 9.18608C0.175594 8.88423 -6.57872e-07 8.47482 -6.95192e-07 8.04794C-7.32511e-07 7.62105 0.175594 7.21164 0.488155 6.90979C0.800716 6.60793 1.22464 6.43835 1.66667 6.43835Z"
                                fill="#0099CC" />
                        </svg>
                    </button>
                </NavLink>
                <span className="">Form</span>
            </div>
            <div className="mr-t20"
                style={{ backgroundColor: backgroundColor }}
            >
                <div className='h-25'>
                    <a href={logoUrl} target="_blank" rel="noreferrer">
                        <img className="w-100"
                            alt=""
                            src={logoFlag ? logoImg : `${Config.imageApiUrl}/${logoImg}`}
                        />
                    </a>
                </div>

                <div className='profile-photo-div'>
                    <img className=" responsive profile-photo"
                        src={defaultProfileFlag ? defaultProfileImg : `${Config.imageApiUrl}/${defaultProfileImg}`}
                    />
                </div>
                {/* <div className="container mr-t20"
                
                >
                    <h5 className="text-wrap text-break" style={{ color: textColor }}>{description}</h5>
                </div> */}

                <div className = "d-flex justify-content-center" dangerouslySetInnerHTML={{__html:description
                }}>
                    
                </div>
                    
                

                <div className="mb-5"
                // style={{backgroundColor:backgroundColor}}
                >
                    <div className="container mt-5">
                        <a className="btn w-100 " style={{ color: buttonTextColor, backgroundColor: buttonBackgroundColor }} type="button" href={homeUrl} target="_blank">Home</a>
                    </div>
                    <div className="container mt-5">
                        <a className="w-100 btn" style={{ color: buttonTextColor, backgroundColor: buttonBackgroundColor }} type="button" href={mapUrl} target="_blank">Map</a>
                    </div>
                    <div className="container mt-5">
                        <a className="w-100 btn" style={{ color: buttonTextColor, backgroundColor: buttonBackgroundColor }} type="button" href={photoFrameUrl} target="_blank">Photo Frame</a>
                    </div>
                    {/* <div className="container mt-5">
                        <a className="w-100 btn" style={{color:buttonTextColor, backgroundColor:buttonBackgroundColor}} type="button" href={logoUrl} target="_blank">Logo Image Url</a>
                    </div>
                    <div className="container mt-5">
                        <a className="w-100 btn" style={{color:buttonTextColor, backgroundColor:buttonBackgroundColor}} type="button" href={sponsorUrl} target="_blank">Sponsor Image Url</a>
                    </div> */}
                </div>
                <div className="container w-100">
                    <a href={sponsorUrl} target="_blank" rel="noreferrer" >
                        <img className="w-100"
                            name="sponsorImg"
                            alt=""
                            src={sponsorFlag ? sponsorImg : `${Config.imageApiUrl}/${sponsorImg}`}
                        // onClick={onClickPhotoHandler}
                        // href={sponsorUrl}
                        // target="_blank"
                        />
                    </a>
                </div>
            </div>
            {error !== '' ? <p className="error-alert mr-b20 mr-l40">{error}</p> : null}
            <div className="add-btn mr-t30 mr-b100 d-flex justify-content-center">
                <button type="button" className="s-blue-btn m-btn mr-l20 mr-r10"
                    onClick={submitHandler}
                >Submit</button>
            </div>

        </div>
    )
}

export default Preview
