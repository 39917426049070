const socialMediaList = [
  { id: 1, name: "None", components: "Fb" },
  { id: 2, name: "X(Twitter)", components: "Fb" },
  { id: 4, name: "LinkedIn", components: "Fb" },
  { id: 1, name: "Facebook", components: "Fb" },
  { id: 5, name: "TikTok", components: "Fb" },
  { id: 7, name: "YouTube", components: "Fb" },
  { id: 3, name: "Instagram", components: "Fb" },
  { id: 6, name: "SnapChat", components: "Fb" },
  { id: 8, name: "Pinterest", components: "Fb" },
  { id: 19, name: "Blog", components: "Fb" },
  { id: 91, name: "Website", components: "Fb" },
  { id: 9, name: "Email", components: "Fb" },
  { id: 10, name: "Phone", components: "Fb" },
  { id: 11, name: "Venmo", components: "Fb" },
  { id: 12, name: "PayPal", components: "Fb" },
];
export default socialMediaList;
