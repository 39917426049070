import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ClientLogin from "./components/clientLogin/clientLogin";
import ClientContainer from "./containers/clientContainer/clientContainer";
// import ClientPage2 from './components/ClientPage/ClientPage2';
import ClientPage from "./components/ClientPage/ClientPage";
import ClientPageWithoutNfc from "./components/ClientPage/ClientPageWithoutNfc";
import Ga4Public from "./components/GoogleAnalyticsDashboard/Ga4Public";
import StationLogin from "./components/StationLogin/Index";
import HomePage from "./components/StationLogin/HomePage";
import { Spin } from "antd";
import { useRecoilState } from "recoil";
import { spinnerState } from "./components/StationLogin/state";

function App() {
  const [loading, setLoading] = useRecoilState(spinnerState);
  console.log("state", loading);

  return (
    <Spin tip="Loading" size="large" spinning={loading}>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={ClientLogin} />
          <Route path="/StationLogin/:id" exact component={StationLogin} />
          <Route path="/station/landing/home/:id" exact component={HomePage} />
          <Route path="/admin" component={ClientContainer} />
          <Route
            exact
            path="/dnyamicNfc/:companyName?/:id?"
            component={ClientPageWithoutNfc}
          />
          <Route path="/public/ga4/dashboard/:id?" component={Ga4Public} />
          <Route path="/:companyName?/:id?" component={ClientPage} />
        </Switch>
      </BrowserRouter>
    </Spin>
  );
}

export default App;
