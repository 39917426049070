import React, { useState, useEffect } from 'react';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import './index.css';
import ScrollToTop from '../../assets/images/scrollToTop.png'

const ScrollButton = (props) => {

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
    }, []);

    const toggleVisibility = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 10) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
   
    };

    // const scrollToTop = () => {
    //     window.scrollBy({
    //         top: 0,
    //         behavior: "smooth"
    //     });

    // };


    return (
        <div className="scroll-to-top">
            
                {/* <ArrowUpwardIcon onClick={scrollToTop} fontSize="large" className="border rounded-circle bg-primary"
                style={{display: isVisible ? 'inline' : 'none'}}
                /> */}
                <img src={ScrollToTop} onClick={props.scrollToTop} className={`scrollToTopImg`}/>
        </div>
    )
}

export default ScrollButton
