import React, { useEffect, useState } from 'react';
import Logo from '../../assets/images/Saly-2.png';
import { login } from '../../services/adminApi';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}



const ClientLogin = (props) => {

    // const [email, setEmail] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const [passwordError, setPasswordError] = useState('');
    const [password, setPassword] = useState('');

    const [error, setError] = useState('');
    const [sneakBarFlag, setSneakBarFlag] = useState(false);

    // const history = useHistory();

    useEffect(() => {
        
        setEmailError('');

        setPasswordError('');

        setError('')
    }, [email, password])

    const signInHandler = () => {

        if (email.trim() === '' || !Boolean(email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
            || password === '') {
            if (email.trim() === '' || !Boolean(email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))) {
                setEmailError('Please enter valid email')
            }

            if (password.trim() === '') {
                setPasswordError('Please enter valid password')
            }
        } else {

            let tempObj = {
                "email": email,
                "password": password
            }

            login(tempObj).then(
                (response) => {
                    sessionStorage.setItem('token', response.data.token);
                    localStorage.setItem('userLoginId', JSON.stringify(response.data));
                    
                    // props.history.push('/admin/companyList');
                    // sessionStorage.setItem('token', response.data.token)
                    // localStorage.setItem('userLoginId', JSON.stringify(response.data))
                    if (response.data?.role_id == 1) {
                        // setSneakBarFlag(true);
                        props.history.push('/admin/companyList');
                        
                    } else {
                        setError("Invalid Credentials, Please Login with valid admin credentials")
                        localStorage.clear();
                        sessionStorage.clear();
                    }
                }
            )
                .catch(
                    (error) => {
                        setError(error.message)
                    }
                )
        }

    }

    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        signInHandler();
      }
    };

    return (
        <section className="form-grid-wrap">
            <div className="form-img-col">
                <div className="form-logo-block">
                    <img className="" style={{width:"500px", marginLeft:"20%"}} src={Logo} />
                </div>
            </div >
            <div className="right-form-col ">
                <div className="admin-form">
                    <h5 className="mr-b40">LOGIN</h5>
                    <form action="">
                        <div className="form-group gray-fill-input mr-b20">
                            <div className="i-lable-icon">
                                {/* <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                        d="M3.33325 4.16667C2.87682 4.16667 2.49992 4.54357 2.49992 5V15C2.49992 15.4564 2.87682 15.8333 3.33325 15.8333H16.6666C17.123 15.8333 17.4999 15.4564 17.4999 15V5C17.4999 4.54357 17.123 4.16667 16.6666 4.16667H3.33325ZM0.833252 5C0.833252 3.6231 1.95635 2.5 3.33325 2.5H16.6666C18.0435 2.5 19.1666 3.6231 19.1666 5V15C19.1666 16.3769 18.0435 17.5 16.6666 17.5H3.33325C1.95635 17.5 0.833252 16.3769 0.833252 15V5Z"
                                        fill="#0099CC" />
                                    <path fillRule="evenodd" clipRule="evenodd"
                                        d="M0.983964 4.52211C1.24789 4.14507 1.7675 4.05337 2.14454 4.3173L9.99999 9.81611L17.8554 4.3173C18.2325 4.05337 18.7521 4.14507 19.016 4.52211C19.2799 4.89915 19.1882 5.41876 18.8112 5.68269L10.4779 11.516C10.1909 11.7169 9.80904 11.7169 9.52211 11.516L1.18877 5.68269C0.81173 5.41876 0.720035 4.89915 0.983964 4.52211Z"
                                        fill="#0099CC" />
                                </svg> */}

                                <input type="text" className="form-control" value={email}
                                    onChange={(e) => { setEmail(e.target.value); setEmailError('') }} placeholder="Email" />

                            </div>
                            {emailError !== '' ? <p className="error-alert mr-b20">{emailError}</p> : null}
                        </div>
                        <div className="form-group gray-fill-input mr-b30">
                            <div className="i-lable-icon gray-fill-input">
                                {/* <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                        d="M4.16675 9.99999C3.70651 9.99999 3.33341 10.3731 3.33341 10.8333V16.6667C3.33341 17.1269 3.70651 17.5 4.16675 17.5H15.8334C16.2937 17.5 16.6667 17.1269 16.6667 16.6667V10.8333C16.6667 10.3731 16.2937 9.99999 15.8334 9.99999H4.16675ZM1.66675 10.8333C1.66675 9.45261 2.78604 8.33332 4.16675 8.33332H15.8334C17.2141 8.33332 18.3334 9.45261 18.3334 10.8333V16.6667C18.3334 18.0474 17.2141 19.1667 15.8334 19.1667H4.16675C2.78604 19.1667 1.66675 18.0474 1.66675 16.6667V10.8333Z"
                                        fill="#0099CC" />
                                    <path fillRule="evenodd" clipRule="evenodd"
                                        d="M10 2.49999C9.11594 2.49999 8.2681 2.85118 7.64298 3.4763C7.01786 4.10142 6.66667 4.94927 6.66667 5.83332V9.16666C6.66667 9.6269 6.29357 9.99999 5.83333 9.99999C5.3731 9.99999 5 9.6269 5 9.16666V5.83332C5 4.50724 5.52678 3.23547 6.46447 2.29779C7.40215 1.36011 8.67392 0.833324 10 0.833324C11.3261 0.833324 12.5979 1.36011 13.5355 2.29779C14.4732 3.23547 15 4.50724 15 5.83332V9.16666C15 9.6269 14.6269 9.99999 14.1667 9.99999C13.7064 9.99999 13.3333 9.6269 13.3333 9.16666V5.83332C13.3333 4.94927 12.9821 4.10142 12.357 3.4763C11.7319 2.85118 10.8841 2.49999 10 2.49999Z"
                                        fill="#0099CC" />
                                </svg> */}
                                <input type="password" className="form-control" value={password} onKeyPress={(e)=>{handleKeyPress(e)}}
                                    onChange={(e) => { setPassword(e.target.value); setPasswordError('') }} placeholder="Password" />

                            </div>
                            {passwordError !== '' ? <p className="error-alert mr-b20">{passwordError}</p> : null}
                        </div>
                        <div className="form-btn text-center">

                            {error !== '' ? <p className="error-alert mr-b20">{error}</p> : null}
                            <button type="button" className="s-black-btn w-100 mr-b20" onClick={signInHandler}>
                                Sign In</button>

                        </div>
                    </form>
                </div>
            </div>

            <Snackbar open={sneakBarFlag} autoHideDuration={6000} onClose={() => setSneakBarFlag(false)}>
                <Alert onClose={() => setSneakBarFlag(false)} severity="success">
                    Login Successful
                </Alert>
            </Snackbar>

        </section>
    )
}

export default ClientLogin;
