import axios from "axios";
import Config from '../config.json';


const participantApi = axios.create({
  // baseURL: 'http://52.140.79.232:4089/api/v1.0',
   baseURL: Config.apiUrl,
  // baseURL: 'http://52.53.53.203:3001/api/v1.0',
  // baseURL: 'http://361a-122-175-228-255.ngrok.io',
  headers: {
    "Content-Type": "application/json",
  },
});

participantApi.interceptors.request.use(function (config) {
  const token = localStorage.getItem("participantToken") ? localStorage.getItem("participantToken") : '';
  // console.log("token",token);
  let tokenVar = "x-auth-token"
  config.headers[tokenVar] = token;
  return config;
});

participantApi.interceptors.response.use(
  function (response) {
    
    // if (response.data.statusCode === 401) {
    //   message.error(response.data.message);
    //   sessionStorage.removeItem("token");

    //   window.location = "/";
    // }
    return response;
  }
);

// Common api code like interceptors will come here
export default participantApi;
