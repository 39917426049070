import { yupResolver } from "@hookform/resolvers/yup";
import { Snackbar, Typography, makeStyles } from "@material-ui/core";
import { AccountCircle, Lock } from "@material-ui/icons";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useRecoilState } from "recoil";
import * as yup from "yup";
import Config from "../../config.json";
import { companyDetailsApi } from "../../services/StationLoginApi";
import { login } from "../../services/adminApi";
import {
  companyImageState,
  companyNameState,
  loginState,
  spinnerState,
} from "./state";

const useStyles = makeStyles((theme) => ({
  paper: {
    // marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#fdfdff",
    // height: "100vh",
  },
  form: {
    width: "100%",
    //marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#a4dba6",
    color: "#fff",
    fontWeight: 600,
  },
}));

// Yup validation schema
const schema = yup
  .object({
    email: yup.string().required("Email is required").email("Email is invalid"),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters long"),
  })
  .required();

function Index() {
  const [loading, setLoading] = useRecoilState(spinnerState);

  const [message, setMessage] = useState({
    title: "",
    open: false,
  });
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const history = useHistory();
  const param = useParams();

  const { isPending, data } = useQuery({
    queryKey: ["company_details"],
    queryFn: () =>
      fetch(companyDetailsApi(param.id), {}).then((res) => res.json()),
  });
  const [loginDetails, setLoginDetails] = useRecoilState(loginState);
  const [companyState, setCompanyState] = useRecoilState(companyImageState);
  const [companyName, setCompanyName] = useRecoilState(companyNameState);

  const onSubmit = (data) => {
    setLoading(true);
    login(data)
      .then((response) => {
        sessionStorage.setItem("stationLogin", response.data.token);
        localStorage.setItem("stationLogin", JSON.stringify(response.data));
        // queryClient.setQueryData('login', response.data);

        if (response.data?.role_id === 3) {
          history.push(`/station/landing/home/${param.id}`);
          setLoginDetails(response.data);
          // console.log("login", response.data);
        } else {
          setMessage({
            title:
              "Invalid Credentials, Please Login with valid admin credentials",
            open: true,
          });
          localStorage.clear();
          sessionStorage.clear();
        }
      })
      .catch((err) => {
        setMessage({
          title: err.message,
          open: true,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (data?.data?.event_logo||data?.data?.company_name) {
      setCompanyState(`${Config?.imageApiUrl}/${data?.data?.event_logo}`);
      sessionStorage.setItem(
        "image",
        `${Config?.imageApiUrl}/${data?.data?.event_logo}`
      );
      sessionStorage.setItem(
        "companyName",
        data?.data?.company_name?.split(" ").join("")
      );
      setCompanyName(data?.data?.company_name?.split(" ").join(""));
    }
  }, [data]);
  useEffect(() => {
    setLoading(isPending);
  }, [isPending]);
  return (
    <div className="row justify-content-center">
      <div className="mobile-view">
        <Snackbar
          open={message.open}
          onClose={() => setMessage({ message: "", open: false })}
          autoHideDuration={2000}
          color="white"
          // TransitionComponent={transition}
          message={message.title}
        />
        <div className={classes.paper}>
          <img className="company_img" src={companyState}></img>
          <div className="border-bottom-1"></div>
          <div style={{ position: "relative", top: -90 }}>
            <Typography component="h1" variant="h5" className="stationheading">
              Sign in
            </Typography>
          </div>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="p-3"
            style={{ width: "100%", marginTop: "-40px" }}
          >
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  <div class="wrap-input100 validate-input">
                    <input
                      {...field}
                      class="input100"
                      type="text"
                      name="email"
                      placeholder="Username"
                    />
                    <span class="focus-input100"></span>
                    <span class="symbol-input100">
                      <AccountCircle />
                    </span>
                  </div>
                  <div className="error-message ">
                    {errors.email ? errors.email.message : ""}
                  </div>
                </>
              )}
            />
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  <div class="wrap-input100 validate-input">
                    <input
                      {...field}
                      class="input100 "
                      type="password"
                      name="password"
                      placeholder="Password"
                    />
                    <span class="focus-input100"></span>
                    <span class="symbol-input100">
                      <Lock />
                    </span>
                  </div>
                  <div className="error-message ">
                    {errors.password ? errors.password.message : ""}
                  </div>
                </>
              )}
            />

            {/*  */}
            <div className="container-login100-form-btn">
              <button type="submit" className="login100-form-btn">
                {" "}
                Login
              </button>
            </div>
            <div className="mx-3">
              <a href="https://go2.tech/t2i-passrecovery">Click here</a> if you
              forgot your password or if you need to request to new account.
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Index;
