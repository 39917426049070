const chartsConfig = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  title: {
    show: "",
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    labels: {
      style: {
        // colors: "#fff",
        fontSize: "13px",
        fontFamily: "inherit",
        fontWeight: 300,
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        // colors: "#fff",
        fontSize: "13px",
        fontFamily: "inherit",
        fontWeight: 300,
      },
    },
  },
  grid: {
    show: true,
    // borderColor: "#ffffff40",
    strokeDashArray: 5,
    xaxis: {
      lines: {
        show: true,
      },
    },
    padding: {
      top: 5,
      right: 20,
    },
  },
  fill: {
    opacity: 0.8,
  },
  tooltip: {
    theme: "dark",
  },
};

const websiteViewsChart = {
  type: "bar",
  height: 220,
  series: [
    {
      name: "Views",
      data: [50, 20, 10, 22, 50, 10, 40],
    },
  ],
  options: {
    ...chartsConfig,
    // colors: "#fff",
    plotOptions: {
      bar: {
        columnWidth: "16%",
        borderRadius: 5,
      },
    },
    xaxis: {
      ...chartsConfig.xaxis,
      categories: ["M", "T", "W", "T", "F", "S", "S"],
    },
  },
};

const dailySalesChart = {
  type: "area",
  height: 220,
  series: [
    {
      name: "Sales",
      data: [50, 40, 300, 320, 500, 350, 200, 230, 500],
    },
  ],
  options: {
    ...chartsConfig,
    // colors: ["#fff"],
    stroke: {
      lineCap: "round",
    },
    markers: {
      size: 5,
    },
    xaxis: {
      ...chartsConfig.xaxis,
      categories: [
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
  },
};

const completedTasksChart = {
  ...dailySalesChart,
  series: [
    {
      name: "Tasks",
      data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
    },
  ],
};

const pieChartData = {
  type: "donut",
  height: 220,
  series: [44, 55, 13, 33],
  // options: {
  //   ...chartsConfig,
  //   colors: "#fff",
  //   plotOptions: {
  //     bar: {
  //       columnWidth: "16%",
  //       borderRadius: 5,
  //     },
  //   },
  //   xaxis: {
  //     ...chartsConfig.xaxis,
  //     categories: ["M", "T", "W", "T", "F", "S", "S"],
  //   },
  // },
  options: {
    colors: ["#3bba7b", "#fa5446", "#7d58bd", "#0072a5"], //#ffcd61
    labels: ["station", "reception", "play zone", "other"],
    // plotOptions: {
    //   pie: {
    //     donut: {
    //       size: "65%",
    //     },
    //   },
    // },
    // plotOptions: {
    //   pie: {
    //     expandOnClick: false,
    //   },
    // },

    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      colors: undefined,
      width: 2,
      dashArray: 0,
    },
  },
};

// const pieChartData = {
//   options: {
//   chart: {
//     type: "donut",
//     animations: {
//       enabled: false,
//     },
//   },
//   colors: ["#62cdcc", "#0DEDEB", "#0EE1DF", "#FB9747"],
//   labels: [
//     "Water Supply Line and Valve",
//     "Sink Plumbing",
//     "Toilet Plumbing",
//     "Other",
//   ],
//   plotOptions: {
//     pie: {
//       donut: {
//         size: "65%",
//       },
//     },
//   },
//   plotOptions: {
//     pie: {
//       expandOnClick: false,
//     },
//   },
// },

// series: [44, 55, 13, 33],
// }

export const statisticsChartsData = [
  {
    color: "ga-blue",
    title: "Company Count",
    description: "Last Performance",
    // footer: "campaign sent 2 days ago",
    chart: websiteViewsChart,
  },
  {
    color: "ga-pink",
    title: "Total Registration",
    description: "15% increase in today registration",
    // footer: "updated 4 min ago",
    chart: dailySalesChart,
  },
  {
    color: "ga-green",
    title: "Total Clicks Sponsor",
    description: "Last Performance",
    // footer: "just updated",
    chart: completedTasksChart,
  },
  {
    color: "ga-orange",
    title: "Total Clicks Sponsor",
    description: "Last Performance",
    // footer: "just updated",
    chart: pieChartData,
  },
];

export default statisticsChartsData;
