import {
  Checkbox,
  FormControlLabel,
  TextField,
  withStyles,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { CloudUploadOutlined, Delete } from "@material-ui/icons";
import { Image, Tooltip } from "antd";
import React, { useRef } from "react";
const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const ImagePreview = ({
  fileImage,
  setFileImage,
  imageUrl,
  setImageUrl,
  error,
  setError,
  url,
  setUrl,
  openIn,
  setOpenIn,
}) => {
  const imageChangeHandle = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type.substr(0, 5) === "image") {
        if (file.size < 1048576) {
          // Check if the file size is less than 1 MB
          setFileImage(file);
          setError(""); // Clear any previous error messages
        } else {
          setError("File size should be less than 1 MB");
          setFileImage(null);
        }
      } else {
        setError("Please select an image file.");
        setFileImage(null);
      }
    }
  };
  const fileRef = useRef();

  return (
    <div className="form-group gray-fill-input mr-b30 mt-3">
      <TextField
        type="text"
        variant="outlined"
        value={url}
        onChange={(event) => setUrl(event.target.value)}
        name="stationName"
        className="mt-3"
        id=""
        placeholder="URL"
        fullWidth
      />{" "}
      <FormControlLabel
        control={
          <Checkbox
            checked={openIn}
            onChange={(e) => setOpenIn(e.target.checked)}
            name="checkedB"
          />
        }
        color="primary"
        style={{ color: "black" }}
        label="Open in new tab"
      />
      {fileImage ? (
        <div>
          <Tooltip title="Remove image">
            <div
              className="upload-file mb-2"
              onClick={() => {
                setFileImage(null);
              }}
            >
              <Delete fontSize="24" /> <h6 className=" mx-1"> Remove File</h6>
            </div>
          </Tooltip>
          <Image src={URL.createObjectURL(fileImage)} />
        </div>
      ) : (
        <div>
          <input
            type="file"
            name="fileImage"
            className="mt-3"
            id=""
            value={fileImage}
            onChange={imageChangeHandle}
            placeholder="Station Name"
            ref={fileRef}
            style={{ display: "none" }}
          />

          <div className="upload-file" onClick={() => fileRef.current.click()}>
            <CloudUploadOutlined fontSize="24" />{" "}
            <h6 className=" mx-1"> Upload File</h6>
          </div>

          {imageUrl ? (
            <div>
              <Tooltip title="Remove image">
                {/* <IconButton
                  onClick={() => {
                    setImageUrl(null);
                  }}
                >
                  <Delete />
                  
                </IconButton> */}
                <div
                  className="upload-file mb-2"
                  onClick={() => {
                    setImageUrl(null);
                  }}
                >
                  <Delete fontSize="24" />{" "}
                  <h6 className=" mx-1"> Remove File</h6>
                </div>
              </Tooltip>
              <Image src={imageUrl} />
            </div>
          ) : null}
        </div>
      )}
      {error !== "" ? <p className="error-alert mr-b20">{error}</p> : null}
    </div>
  );
};

export default ImagePreview;
