import { Checkbox, ListItemText, MenuItem, Select } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";

import React from "react";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const StationDropdown = ({
  classes,
  list,
  selectedRecord,
  setSelectedRecord,
  label,
}) => {
  const handleChange = (checked, id) => {
    const tempArr = [...selectedRecord];
    if (checked) {
      tempArr.push(JSON.stringify(id));
      setSelectedRecord(tempArr);
    } else {
      setSelectedRecord(tempArr.filter((idx) => idx != JSON.stringify(id)));
    }
  };
  return (
    <div className="form-group gray-fill-input mr-b30">
      <FormControl className={classes.formControl}>
        <label id="mutiple-module-checkbox-label">{label}</label>
        <Select
          className="form-control"
          labelId="mutiple-module-checkbox-label"
          id="mutiple-module-checkbox"
          multiple
          value={selectedRecord}
          input={<Input />}
          renderValue={(selected) =>
            list
              .filter((rec) => selected.includes(JSON.stringify(rec.id)))
              .map((rec) => rec.name)
              .join(", ")
          }
          MenuProps={MenuProps}
          disableUnderline
        >
          {list.map((station) => (
            <MenuItem
              key={station.id}
              value={station.id}
              onChange={(event) =>
                handleChange(event.target.checked, station.id)
              }
            >
              <Checkbox
                checked={
                  selectedRecord.filter((rec) => rec == station.id)?.length
                    ? true
                    : false
                }
              />
              <ListItemText primary={station.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default StationDropdown;
