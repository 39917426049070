import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { NavLink } from "react-router-dom";

import Edit from "../../assets/images/edit.png";
import Trash from "../../assets/images/trash.png";

import {
  addCustomButton,
  getAllCustomButtonsByCompanyId,
  deleteCustomButton,
} from "../../services/adminApi";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CustomButtons = (props) => {
  const [buttonLabel, setButtonLabel] = useState("");
  const [buttonLabelError, setButtonLabelError] = useState("");

  const [buttonId, setButtonId] = useState("");
  const [buttonIdError, setButtonIdError] = useState("");

  const [buttonUrl, setButtonUrl] = useState("");
  const [buttonUrlError, setButtonUrlError] = useState("");

  const [firstQueryParam, setFirstQueryParam] = useState("");
  const [firstQueryParamError, setFirstQueryParamError] = useState("");

  const [secondQueryParam, setSecondQueryParam] = useState("");
  const [selfOpenStatus, setSelfOpenStatus] = useState(0);
  const [secondQueryParamError, setSecondQueryParamError] = useState("");

  const [companyId, setCompanyId] = useState();
  const [companyName, setCompanyName] = useState("");

  const [selectedId, setSelectedId] = useState();

  const [addNewButtonModalFlag, setAddNewButtonModalFlag] = useState(false);

  const [tableCount, setTableCount] = useState();

  const [buttonListDisplay, setButtonListDisplay] = useState("");

  const [addSneakBarFlag, setAddSneakBarFlag] = useState(false);
  const [deleteSneakBarFlag, setDeleteSneakBarFlag] = useState(false);

  const [deleteModalFlag, setDeleteModalFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [editSneakerFlag, setEditSneakerFlag] = useState(false);
  const [addNewButtonFlag, setAddNewButtonFlag] = useState(false);

  const [error, setError] = useState("");

  const [loaderFlag, setLoaderFlag] = useState(false);

  const [buttonListColumns, setButtonListColumns] = useState([
    { name: "sr", title: "Sr. No." },
    { name: "label", title: "Label" },
    { name: "buttonUrl", title: "Url" },
    { name: "param_1", title: "Param 1" },
    { name: "param_2", title: "Param 2" },
    { name: "windows", title: "Open" },
    { name: "action", title: "Action" },
  ]);

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("selectedCompanyDetails"));
    // console.log(data);
    setCompanyId(data.id);
    setCompanyName(data.company_name);
    getButtonListHandler(data.id);
  }, [tableCount]);

  useEffect(() => {
    setButtonLabel("");
    setButtonLabelError("");

    setButtonUrl("");
    setButtonUrlError("");

    setFirstQueryParam("");

    setSecondQueryParam("");
  }, [addNewButtonFlag]);

  const getButtonListHandler = (id) => {
    setLoaderFlag(true);
    getAllCustomButtonsByCompanyId(id).then(async (response) => {
      // console.log(response);
      let tempData = response.data;

      setTableCount(response.data.length);

      const buttonListTemp = [];
      tempData.map((btn, index) => {
        buttonListTemp.push(
          <TableRow
            key={btn.id}
            // onClick={() => { localStorage.setItem('selectedCompany', JSON.stringify(ins)); props.history.push('/admin/userList') }}
          >
            <TableCell component="th" scope="row">
              {index + 1}
            </TableCell>
            <TableCell>{btn.label}</TableCell>
            <TableCell>{btn.url}</TableCell>
            <TableCell>{btn.query_param_1}</TableCell>
            <TableCell>{btn.query_param_2}</TableCell>
            <TableCell>
              {btn.windows == "1"
                ? "Open in new window"
                : "Open in current window"}
            </TableCell>
            <TableCell>
              <div className="action-btn">
                <button
                  type="button"
                  className="trans-btn"
                  onClick={() => {
                    localStorage.setItem("selectedButton", JSON.stringify(btn));
                    getButtonDetails();
                    setAddNewButtonModalFlag(true);
                    setEditFlag(true);
                    // setEditorState(EditorState.createEmpty());
                    setEditSneakerFlag(true);
                  }}
                >
                  <img src={Edit} alt="" title="" />
                </button>

                <button
                  type="button"
                  className="trans-btn"
                  onClick={() => {
                    setSelectedId(btn.id);
                    setDeleteModalFlag(true);
                  }}
                >
                  <img src={Trash} alt="" title="" />
                </button>
              </div>
            </TableCell>
          </TableRow>
        );
        if (index === tempData.length - 1) {
          setButtonListDisplay(buttonListTemp);
        }
      });
      setLoaderFlag(false);
    });
  };

  const textFieldChangeHandler = (e) => {
    console.log("e", e);
    let { name, value, checked } = e.target;

    switch (name) {
      case "buttonLabel":
        setButtonLabel(value);
        setButtonLabelError("");

        break;

      case "buttonUrl":
        setButtonUrl(value);
        setButtonUrlError("");

        break;

      case "param1":
        setFirstQueryParam(value);

        break;

      case "param2":
        setSecondQueryParam(value);

        break;
      case "windows":
        setSelfOpenStatus(value);

        break;

      default:
        console.log("");
    }
  };

  const getButtonDetails = () => {
    let data = JSON.parse(localStorage.getItem("selectedButton"));
    // console.log(data);
    setSelectedId(data.id);
    setButtonLabel(data.label);
    setButtonUrl(data.url);
    setFirstQueryParam(data.query_param_1);
    setSecondQueryParam(data.query_param_2);
    setSelfOpenStatus(data?.windows);
  };

  const addButtonHandler = (id) => {
    setLoaderFlag(true);
    if (buttonLabel.trim() == "" || buttonUrl == "") {
      setLoaderFlag(false);
      if (buttonLabel.trim() == "") {
        setButtonLabelError("Please enter label");
      }

      if (buttonUrl.trim() == "") {
        setButtonUrlError("Please enter a valid url");
      }
    } else {
      let tempObj = {
        id: id,
        label: buttonLabel,
        company_id: companyId,
        url: buttonUrl,
        query_param_1: firstQueryParam,
        query_param_2: secondQueryParam,
        windows: selfOpenStatus,
      };
      console.log(tempObj);
      document.getElementById("addButtonSubmit").disabled = true;

      addCustomButton(companyId, tempObj)
        .then((response) => {
          // console.log(response);
          document.getElementById("addButtonSubmit").disabled = false;
          setAddNewButtonModalFlag(false);
          setAddSneakBarFlag(true);
          getButtonListHandler(companyId);
          setAddNewButtonFlag(false);
          setEditFlag(false);
          setLoaderFlag(false);
        })
        .catch((error) => {
          console.log(error);
          setButtonIdError(error.message);
          document.getElementById("addButtonSubmit").disabled = false;
          setLoaderFlag(false);
        });
    }
  };

  const deleteButtonHandler = () => {
    setLoaderFlag(true);
    deleteCustomButton(selectedId).then((response) => {
      // console.log(response)
      setDeleteModalFlag(false);
      setDeleteSneakBarFlag(true);
      getButtonListHandler(companyId);
    });
  };

  return (
    <div>
      <div className="icon">
        <NavLink to={"/admin/companyList"}>
          <button type="button" className="trans-btn mr-r10">
            <svg
              width="20"
              height="16"
              viewBox="0 0 20 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.33333 2.86765e-05C8.66437 -0.00183006 8.98849 0.0915604 9.26435 0.268294C9.54022 0.445028 9.75534 0.697096 9.88228 0.992364C10.0092 1.28763 10.0422 1.61272 9.97713 1.92618C9.91201 2.23965 9.75171 2.52728 9.51667 2.75241L4.01667 8.04794L9.51667 13.3435C9.78971 13.6514 9.93239 14.0475 9.91618 14.4525C9.89998 14.8576 9.7261 15.2419 9.42927 15.5286C9.13245 15.8152 8.73455 15.9832 8.31509 15.9988C7.89563 16.0144 7.4855 15.8767 7.16667 15.613L0.499999 9.17464C0.18958 8.87307 0.015344 8.46512 0.0153439 8.03989C0.0153439 7.61466 0.18958 7.20671 0.499999 6.90513L7.16667 0.466808C7.4771 0.169448 7.8961 0.00180636 8.33333 2.86765e-05Z"
                fill="#0099CC"
              />
              <path
                d="M1.66667 6.43835L18.3333 6.43835C18.7754 6.43835 19.1993 6.60793 19.5118 6.90979C19.8244 7.21164 20 7.62105 20 8.04793C20 8.47482 19.8244 8.88422 19.5118 9.18608C19.1993 9.48793 18.7754 9.65752 18.3333 9.65752L1.66667 9.65752C1.22464 9.65752 0.800716 9.48794 0.488155 9.18608C0.175594 8.88423 -6.57872e-07 8.47482 -6.95192e-07 8.04794C-7.32511e-07 7.62105 0.175594 7.21164 0.488155 6.90979C0.800716 6.60793 1.22464 6.43835 1.66667 6.43835Z"
                fill="#0099CC"
              />
            </svg>
          </button>
        </NavLink>
        <span className="">Dashboard</span>
        <span className=""> &#62; {companyName}</span>
      </div>

      <div className="mr-t50">
        <div className="data-head">
          {/* <div className="cus-search">
                            <input type="text" name="" id="" value={inspectionListPayload.search_by} onChange={searchByKeyword} placeholder="Search" />
                        </div> */}
          <div className="page-title mr-l10">
            <h3>Buttons</h3>
          </div>
          <div className="add-btn">
            <button
              type="button"
              className="s-blue-btn m-btn mr-l20 mr-r10"
              data-toggle="modal"
              data-target="#addInsuranceModal"
              onClick={() => {
                setAddNewButtonModalFlag(true);
                setAddNewButtonFlag(true);
              }}
            >
              + Add New
            </button>
          </div>
        </div>
      </div>
      <div className="cus-table">
        <div className="table-str">
          <TableContainer component={Paper}>
            <Table aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  {buttonListColumns.map((column) => (
                    <TableCell key={column.name} className="align-top">
                      {(column.name = column.title)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {this.state.reportsUpcomingList} */}
                {buttonListDisplay}
                {/* {this.state.loading ? <Spin indicator={<div class="loading">Loading&#8230;</div>}/> : this.state.reportsUpcomingList} */}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <Modal
        open={addNewButtonModalFlag}
        disableBackdropClick={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className="modal modal-xl blue-modal fade"
          id="addInsuranceModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable"
            style={{ maxWidth: "1000px" }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add Button
                </h5>
              </div>
              <div className="modal-body">
                <div className="ins-modal-body">
                  <form action="">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group gray-fill-input mr-b30">
                          <label htmlFor="">Label</label>
                          <input
                            type="text"
                            className="form-control"
                            name="buttonLabel"
                            id=""
                            value={buttonLabel}
                            onChange={textFieldChangeHandler}
                            placeholder="Label"
                          />
                          {buttonLabelError !== "" ? (
                            <p className="error-alert mr-b20">
                              {buttonLabelError}
                            </p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group gray-fill-input mr-b30">
                          <label htmlFor="">Url</label>
                          <input
                            type="text"
                            className="form-control"
                            name="buttonUrl"
                            id=""
                            value={buttonUrl}
                            onChange={textFieldChangeHandler}
                            placeholder="Url"
                          />
                          {buttonUrlError !== "" ? (
                            <p className="error-alert mr-b20">
                              {buttonUrlError}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group gray-fill-input mr-b30">
                          <label htmlFor="">Param 1</label>
                          <select
                            name="param1"
                            id="param1"
                            value={firstQueryParam}
                            onChange={textFieldChangeHandler}
                            className="form-control"
                          >
                            <option value="">None</option>
                            <option value="Event Id">Event Id</option>
                            <option value="NFC Id">NFC Id</option>
                          </select>
                          {/* {buttonUrlError !== '' ? <p className="error-alert mr-b20">{buttonUrlError}</p> : null} */}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group gray-fill-input mr-b30">
                          <label htmlFor="">Param 2</label>
                          <select
                            name="param2"
                            id="param2"
                            value={secondQueryParam}
                            onChange={textFieldChangeHandler}
                            className="form-control"
                          >
                            <option value="">None</option>
                            <option value="Event Id">Event Id</option>
                            <option value="NFC Id">NFC Id</option>
                          </select>
                          {/* {buttonUrlError !== '' ? <p className="error-alert mr-b20">{buttonUrlError}</p> : null} */}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group gray-fill-input mr-b30">
                          <label htmlFor="">Open</label>
                          <select
                            name="windows"
                            id="windows"
                            value={selfOpenStatus}
                            onChange={textFieldChangeHandler}
                            className="form-control"
                          >
                            <option value="0">Open in current window</option>
                            <option value="1">Open in new window</option>
                          </select>
                          {/* {buttonUrlError !== '' ? <p className="error-alert mr-b20">{buttonUrlError}</p> : null} */}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer pd-b30">
                <button
                  type="button"
                  className="s-blue-border-btn m-btn"
                  data-dismiss="modal"
                  onClick={() => {
                    setAddNewButtonModalFlag(false);
                    setEditFlag(false);
                    setAddNewButtonFlag(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="s-blue-btn m-btn"
                  data-dismiss="modal"
                  id="addButtonSubmit"
                  // onClick={addButtonHandler}
                  onClick={() => addButtonHandler(editFlag ? selectedId : 0)}
                >
                  {editFlag ? "Update " : "Add "}
                  Button
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={deleteModalFlag}
        disableBackdropClick={false}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          className="modal blue-modal fade"
          id="addInsuranceModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Confirmation
                </h5>
              </div>
              <div className="modal-body">Want to delete Selected Button ?</div>

              <div className="modal-footer pd-b30">
                <button
                  type="button"
                  className="s-blue-border-btn m-btn"
                  data-dismiss="modal"
                  onClick={() => setDeleteModalFlag(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="s-blue-btn m-btn"
                  onClick={deleteButtonHandler}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Snackbar
        open={addSneakBarFlag}
        autoHideDuration={6000}
        onClose={() => {
          setAddSneakBarFlag(false);
          setEditSneakerFlag(false);
        }}
      >
        <Alert
          onClose={() => {
            setAddSneakBarFlag(false);
            setEditSneakerFlag(false);
          }}
          severity="success"
        >
          Button Is Successfully {editSneakerFlag ? "Updated" : "Added"}
        </Alert>
      </Snackbar>

      <Snackbar
        open={deleteSneakBarFlag}
        autoHideDuration={6000}
        onClose={() => setDeleteSneakBarFlag(false)}
      >
        <Alert onClose={() => setDeleteSneakBarFlag(false)} severity="success">
          Button Is Successfully Deleted
        </Alert>
      </Snackbar>

      {loaderFlag ? <div className="loading">Loading&#8230;</div> : null}
    </div>
  );
};

export default CustomButtons;
